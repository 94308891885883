import React from 'react'
import styled from 'styled-components'

import { EstimateTableRow } from '@/dal'
import { NewButton } from '@/ui'

type Props = {
  onAccept: () => void
  onCancel: () => void
  rowId: EstimateTableRow['id']
  isPending: boolean
}

export const ActionsRow = ({ rowId, isPending, onAccept, onCancel }: Props) => {
  return (
    <Wrapper>
      <NewButton
        label="Отменить"
        size="S"
        buttonType="grey"
        isDisabled={isPending}
        dataTestId={`cancel-update-${rowId}`}
        onClick={onCancel}
      />
      <NewButton
        label="Сохранить изменения"
        size="S"
        isPending={isPending}
        dataTestId={`send-update-${rowId}`}
        onClick={onAccept}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;

  margin-left: auto;
`
