import React, { Key } from 'react'
import styled, { css } from 'styled-components'

import { Icon2 } from '../icons'
import { palette } from '../palette'
import { TextMLight } from '../typography'

type Props<T = unknown> = {
  label?: string
  checkPosition?: 'left' | 'right'
  isChecked: boolean
  isError?: boolean
  isDisabled?: boolean
  isIndeterminate?: boolean
  dataTestId?: string
} & (
  | {
      value: T
      isBoolean?: false
      onChange: (val: T) => void
    }
  | {
      value?: never
      isBoolean: true
      onChange: (val: boolean) => void
    }
)

export const NewCheckbox = <T extends Key>({
  isChecked,
  label,
  value,
  isDisabled,
  isError,
  isBoolean,
  checkPosition = 'right',
  isIndeterminate = false,
  dataTestId,
  onChange,
}: Props<T>) => {
  const checkboxRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = isIndeterminate
    }
  }, [checkboxRef.current, isIndeterminate])

  return (
    <Wrapper isError={isError} isDisabled={isDisabled} onClick={(e) => e.stopPropagation()}>
      <HiddenCheckbox
        ref={checkboxRef}
        disabled={isDisabled}
        checked={Boolean(isChecked)}
        value={value}
        data-testid={dataTestId}
        onChange={() => (isBoolean ? onChange(!isChecked) : onChange(value))}
      />

      <CustomCheckbox>
        {isChecked && <Icon2 icon="checkboxMark" />}
        {isIndeterminate && !isChecked && <IndeterminateLine />}
      </CustomCheckbox>

      {label && <Text $isFirst={checkPosition === 'right'}>{label}</Text>}
    </Wrapper>
  )
}

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
`

const Text = styled.div<{ $isFirst: boolean }>`
  ${TextMLight}
  padding-top: 1px;

  ${({ $isFirst }) =>
    $isFirst &&
    css`
      order: -1;
    `}
`

const CustomCheckbox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 18px;
  min-width: 18px;
  height: 18px;
  min-height: 18px;
  border: 1px solid ${palette.grey50};

  border-radius: 4px;
  background-color: ${palette.white};
  color: transparent;
  transition: 0.15s linear;
  transition-property: background-color, border-color, color;

  ${HiddenCheckbox}:checked ~ &,
  ${HiddenCheckbox}:indeterminate ~ & {
    background-color: ${palette.accent100};
    border-color: ${palette.accent100};
    color: ${palette.white};
  }

  ${HiddenCheckbox}:focus-visible ~ & {
    border: 2px solid ${palette.grey90};
  }

  ${HiddenCheckbox}:disabled ~ & {
    background-color: ${palette.grey30};
    border-color: ${palette.grey40};
    color: transparent;

    & ~ ${Text} {
      color: ${palette.grey60};
    }
  }

  ${HiddenCheckbox}:checked:disabled ~ &,
  ${HiddenCheckbox}:indeterminate:disabled ~ & {
    background-color: ${palette.grey30};
    border-color: ${palette.grey30};
    color: ${palette.grey60};
  }
`

const IndeterminateLine = styled.div`
  width: 8px;
  height: 2px;
  border-radius: 99px;
  background-color: currentColor;
`

const Wrapper = styled.label<Pick<Props, 'isError' | 'isDisabled'>>`
  position: relative;
  display: flex;
  gap: 8px;
  cursor: pointer;
  align-items: center;
  user-select: none;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
    `}

  @media (hover: hover) {
    &:hover {
      ${HiddenCheckbox}:not(:disabled) ~ ${CustomCheckbox} {
        border-color: ${palette.grey60};
      }

      ${HiddenCheckbox}:not(:disabled):checked ~ ${CustomCheckbox},
      ${HiddenCheckbox}:not(:disabled):indeterminate ~ ${CustomCheckbox} {
        border-color: ${palette.accent90};
        background-color: ${palette.accent90};
      }
    }
  }

  &:active {
    ${HiddenCheckbox}:not(:disabled):not(:checked) ~ ${CustomCheckbox},
    ${HiddenCheckbox}:not(:disabled):not(:indeterminate) ~ ${CustomCheckbox} {
      background-color: ${palette.grey20};
    }

    ${HiddenCheckbox}:not(:disabled):checked ~ ${CustomCheckbox},
    ${HiddenCheckbox}:not(:disabled):indeterminate ~ ${CustomCheckbox} {
      border-color: ${palette.accent80};
      background-color: ${palette.accent80};
    }
  }

  ${({ isError }) =>
    isError &&
    css`
      ${CustomCheckbox} {
        border-color: ${palette.red100} !important;
      }
    `}
`
