import React from 'react'
import styled from 'styled-components'

import { DefaultButton, Icon2, palette, TextL, usePopup } from '@/ui'
import { SelectAppealWindow } from '@/features/support/support-appeals'

export const CreateRequestButton = React.memo(() => {
  const { close, getReferenceProps, isOpen, open, refs, ...tooltipProps } = usePopup({
    placement: 'bottom-end',
    offsetNumber: 28,
  })

  return (
    <>
      <Button ref={refs.setReference} {...getReferenceProps()}>
        <Icon2 icon="messages" />
        Создать обращение
      </Button>

      {isOpen && <SelectAppealWindow {...tooltipProps} refs={refs} onClose={() => close()} />}
    </>
  )
})

const Button = styled(DefaultButton)`
  ${TextL}
  display: flex;
  gap: 8px;
  align-items: center;
  background-color: rgba(${palette.rgbGrey}, 0.25);
  border: 1px solid rgba(${palette.rgbGrey}, 0.1);
  border-radius: 8px;
  color: ${palette.white};
  padding: 6px 20px;
  white-space: nowrap;
`
