import { LotTableItem } from '@/dal'
import { TreeType } from '@/lib/tree'

export type Tree = TreeType<LotTableItem>

export enum ItemAction {
  AddWork = 1,
  DeleteWork,
  AddMaterial,
  DeleteMaterial,
  EditItem,
  ClearItem,
}

export type ActionPayload = {
  item: LotTableItem
  type: ItemAction
}

export type LotTableItemWithDepth = LotTableItem & {
  depth: number
}
