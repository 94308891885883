import { MaterialType } from '@/dal'

import { EstimateLotTab } from './types'

export const ESTIMATE_LOT_TABS = [
  { id: EstimateLotTab.Info, label: 'Информация' },
  { id: EstimateLotTab.CommercialOffer, label: 'Коммерческое предложение' },
]

export const MATERIAL_TYPE_NAMES = {
  [MaterialType.Required]: 'Обязательный материал',
  [MaterialType.Additional]: 'Дополнительный материал',
  [MaterialType.Accompanying]: 'Сопутствующий материал',
}

export const MATERIAL_TYPE_OPTIONS = [
  {
    id: MaterialType.Required,
    label: 'Обязательные',
  },
  {
    id: MaterialType.Additional,
    label: 'Дополнительные',
  },
  {
    id: MaterialType.Accompanying,
    label: 'Сопутствующие',
  },
]
