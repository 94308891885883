import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { HeadL, NewTextButton, palette } from '@/ui'
import { $canEdit } from '@/features/estimate-lot/model'
import {
  $readOnlyAcceptedMorphology,
  changeMorphologyModal,
} from '@/features/estimate-lot/selected-morphology/model/private'

import { BuildingReadOnlyItem } from '../containers/BuildingReadOnlyItem'

export const LotMorphology = React.memo(() => {
  const [acceptedMorphologyItems, canEdit] = useUnit([$readOnlyAcceptedMorphology, $canEdit])

  return (
    <Wrapper>
      <Header>
        <p>Структура элементов объектов строительства</p>
        {canEdit && (
          <NewTextButton
            label="Редактировать структуру"
            postfixIcon="editStroke"
            textStyle="M"
            dataTestId="open-morphology-modal-btn"
            onClick={changeMorphologyModal.open}
          />
        )}
      </Header>

      {acceptedMorphologyItems.map((item) => (
        <BuildingReadOnlyItem key={item.id} {...item} />
      ))}
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  padding: 20px;
  border-radius: 20px;
  background-color: ${palette.white};
`

const Header = styled.header`
  ${HeadL}
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`
