import Accreditation from './accreditation.svg'
import Administrative from './administration.svg'
import B2B from './b2b.svg'
import BuildDocs from './build-docs.svg'
import GStation from './g-station.svg'
import Instruction from './instruction.svg'
import ISUP from './isup.svg'
import SupportIcon from './support-icon.svg'
import TechSupervision from './tech-supervision.svg'
import Telegram from './telegram.svg'
import WhatsApp from './whatsapp.svg'

export const serviceIcons = {
  techsupervision: TechSupervision,
  accreditation: Accreditation,
  b2b: B2B,
  buildDocs: BuildDocs,
  gStation: GStation,
  isup: ISUP,
  supportIcon: SupportIcon,
  instruction: Instruction,
  administrative: Administrative,
  telegram: Telegram,
  whatsApp: WhatsApp,
}
