import React from 'react'
import styled from 'styled-components'

import { noop } from '@/lib/noop'
import { palette } from '@/ui'

import { SectionGroupOption } from '../../model'
import { ObjectAndBuildingInfo, SectionMultipleSelect } from '../parts'

type Props = {
  name: string
  objectName: string
  sections: SectionGroupOption[]
}

export const BuildingReadOnlyItem = React.memo(({ name, objectName, sections }: Props) => {
  return (
    <Wrapper>
      <ObjectAndBuildingInfo objectName={objectName} buildingName={name} />
      <Sections>
        <SectionMultipleSelect
          label="Секция и этаж"
          sections={sections}
          isReadOnly
          onChangeFloor={noop}
          onChangeSection={noop}
        />
      </Sections>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 16px;

  padding: 16px;
  border: 1px solid ${palette.grey40};
  border-radius: 12px;
`

const Sections = styled.div`
  grid-column: span 2;
`
