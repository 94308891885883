import { sample } from 'effector'

import { isErrorAsIssueError } from '@/dal'
import { onErrorAsIssueOccured } from '@/features/support/error-modal/model'
import { createToast } from '@/features/toast-service/model'

import { requestActualSurvey } from '../../../survey/model'
import { updateDocumentInfo } from '../../shared-model'
import { onKorDocSentToApprove } from '../../shared-model/private'
import { $targetDocumentId, approveForm, closeModal, sendEstimateToApproveFx } from './private'
import { openSendApproveModal } from './public'

$targetDocumentId.on(openSendApproveModal, (_, id) => id).reset(closeModal)

sample({
  clock: approveForm.formValidated,
  source: $targetDocumentId,
  filter: Boolean,
  fn: (id, { comment }) => ({
    id,
    comment,
  }),
  target: sendEstimateToApproveFx,
})

sample({
  clock: sendEstimateToApproveFx,
  target: onKorDocSentToApprove,
})

sample({
  clock: sendEstimateToApproveFx.doneData,
  target: updateDocumentInfo,
})

sample({
  clock: sendEstimateToApproveFx.doneData,
  target: [closeModal, requestActualSurvey],
})

sample({
  clock: sendEstimateToApproveFx.failData,
  filter: (error) => isErrorAsIssueError(error),
  target: onErrorAsIssueOccured,
})

sample({
  clock: closeModal,
  target: approveForm.reset,
})

createToast({
  effect: sendEstimateToApproveFx,
  doneText: 'Отправлено на согласование',
})
