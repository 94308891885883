import React from 'react'
import styled, { css } from 'styled-components'

import { useTextareaAutoHeight } from '../../hooks'
import { palette } from '../../palette'
import { TextMLight } from '../../typography'
import { CellInputWrapper } from './CellInputWrapper'

type Props = {
  value: string
  textAlign?: 'right' | 'center' | 'left'
  placeholder?: string
  height?: number
  maxLength?: number
  disabled?: boolean
  dataTestId: string
  autoGrow?: boolean
  isInvalid?: boolean
  maxHeight?: number
  maxLines?: number
  popupWidth?: number
  padding?: string
  onChange: (val: string) => void
  onToggle?: (val: boolean) => void
}

export const CellTextArea = ({
  dataTestId,
  placeholder,
  textAlign = 'left',
  value,
  height,
  maxLength,
  disabled,
  autoGrow,
  isInvalid = false,
  maxHeight,
  maxLines,
  popupWidth,
  padding = '12px',
  onChange,
  onToggle,
}: Props) => {
  const [isVisible, setIsVisible] = React.useState(false)

  const { inputRef, autoHeight } = useTextareaAutoHeight({
    value,
    height,
    maxHeight,
    isAutoGrow: autoGrow,
    deps: [value, isVisible],
  })

  const toggleVisibilityHandler = (val: boolean) => {
    onToggle?.(val)
    setIsVisible(val)
  }

  React.useEffect(() => {
    if (isVisible) {
      if (!inputRef.current) return
      inputRef.current.focus()
      const endIndex = inputRef.current.value.length
      inputRef.current.setSelectionRange(endIndex, endIndex)
    }
  }, [isVisible])

  return (
    <CellInputWrapper
      value={value || placeholder || ''}
      isInvalid={isInvalid}
      maxLines={maxLines}
      popupWidth={popupWidth}
      padding={padding}
      textAlign={textAlign}
      isDisable={disabled}
      isOpen={isVisible}
      onClick={() => toggleVisibilityHandler(true)}
    >
      <TextArea
        ref={inputRef}
        data-testid={dataTestId}
        disabled={disabled}
        maxLength={maxLength}
        value={value}
        placeholder={placeholder}
        $height={autoGrow ? autoHeight : height}
        $textAlign={textAlign}
        $isInvalid={isInvalid}
        onChange={(e) => onChange(e.target.value)}
        onBlur={() => toggleVisibilityHandler(false)}
      />
    </CellInputWrapper>
  )
}

type TextAreaProps = {
  $isInvalid: boolean
  $height?: number | null
  $textAlign: 'right' | 'center' | 'left'
}

const TextArea = styled.textarea<TextAreaProps>`
  height: ${({ $height }) => ($height ? `${$height}px` : '100%')};
  width: 100%;
  ${TextMLight}
  resize: none;
  outline: none;
  transition: color 0.15s linear;
  border: none;
  text-align: ${({ $textAlign }) => $textAlign};

  &:not(:disabled)::placeholder {
    color: ${palette.grey60};
  }

  &:disabled {
    color: ${palette.grey70};
  }

  ${({ $isInvalid }) =>
    $isInvalid &&
    css`
      color: ${palette.red100};

      &::placeholder {
        color: ${palette.red100};
      }
    `}
`
