import React from 'react'
import styled from 'styled-components'

import { LotTableItem, LotVolumes, MorphologyCellInfo, MorphologyType } from '@/dal'
import { palette, PaletteColor } from '@/ui'
import { CELL_MIN_HEIGHT, CellWrapper, TABLE_BORDER_STYLE } from '@/features/estimate-lot/shared'

import { changeElementPrice } from '../../model/private'
import { EditableCell, END_NODE_CELL_WIDTH, FilterCell } from '../parts'

const isVolumeObject = (item: LotVolumes | MorphologyCellInfo): item is LotVolumes =>
  'volume' in item

type Props = {
  rowId?: LotTableItem['id']
  cellsTree: LotVolumes[] | MorphologyCellInfo[]
  endNodeCount: number
  areFilterCells?: boolean
  canEdit?: boolean
}

type RowWithChildrenProps = {
  item: LotVolumes | MorphologyCellInfo
  heightPercent?: number
  rowCount: number
}

type Payload = {
  value: string
  rowId: LotTableItem['id']
  id: number
  type: MorphologyType
}

export const MorphologyCells = React.memo(
  ({ rowId, cellsTree, endNodeCount, canEdit, areFilterCells = false }: Props) => {
    const handleChange = ({ value, id, type }: Omit<Payload, 'rowId'>) => {
      if (!rowId) return
      changeElementPrice({
        type,
        price: value,
        elementId: id,
        workId: rowId,
      })
    }

    const emptyRowColor = areFilterCells ? 'grey20' : 'grey10'

    const RowWithChildren = React.useCallback(
      ({ item, rowCount, heightPercent = 100 }: RowWithChildrenProps) => {
        return (
          <RowWithChildrenWrapper $heightPercent={heightPercent}>
            <SingleRow $heightPercent={100 / rowCount}>
              {!isVolumeObject(item) ? (
                <FilterCell label={item.name} textAlign="center" />
              ) : !item.children?.length && canEdit ? (
                <EditableCell
                  value={item.volume}
                  decimalScale={4}
                  canEdit
                  onSubmit={(value) => handleChange({ value, ...item })}
                />
              ) : (
                <CellWrapper>{item.volume}</CellWrapper>
              )}
            </SingleRow>

            {rowCount > 1 &&
              (item.children?.length ? (
                <Rows $heightPercent={100 - 100 / rowCount}>
                  {item.children.map((item) => (
                    <RowWithChildren key={item.id} item={item} rowCount={rowCount - 1} />
                  ))}
                </Rows>
              ) : (
                <EmptyRow $bgColor={emptyRowColor} />
              ))}
          </RowWithChildrenWrapper>
        )
      },
      [rowId, canEdit, emptyRowColor],
    )

    return (
      <Wrapper $span={endNodeCount}>
        {cellsTree.map((item) => (
          // TODO убрать хардкод rowCount, когда бэк дойдёт до реализации ручки
          <RowWithChildren key={item.id} item={item} rowCount={3} />
        ))}
      </Wrapper>
    )
  },
)

const Wrapper = styled.div<{ $span: number }>`
  display: flex;
  grid-column: span ${({ $span = 1 }) => $span};
  padding: 0;
  border-color: var(--table-border-color);
`

const RowWithChildrenWrapper = styled.div<{ $heightPercent: number }>`
  height: ${({ $heightPercent }) => $heightPercent}%;
  min-height: ${CELL_MIN_HEIGHT};
`

const Rows = styled(RowWithChildrenWrapper)`
  display: flex;
`

const SingleRow = styled.div<{ $heightPercent: number }>`
  position: relative;
  display: flex;
  min-width: ${END_NODE_CELL_WIDTH};
  height: ${({ $heightPercent }) => $heightPercent}%;
  border-right: ${TABLE_BORDER_STYLE};
  border-color: var(--table-border-color);
  word-break: break-word;
  z-index: 1;
  min-height: ${CELL_MIN_HEIGHT};

  &:not(:last-child) {
    border-bottom: 1px solid var(--table-border-color);
  }
`

const EmptyRow = styled.div<{ $bgColor: PaletteColor }>`
  position: relative;
  height: 50%;
  flex-grow: 1;

  &::before {
    position: absolute;
    content: '';
    inset: 0;
    left: -2px;
    background-color: ${({ $bgColor }) => palette[$bgColor]};
    border-right: ${TABLE_BORDER_STYLE};
  }
`
