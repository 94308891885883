import { useForm } from 'effector-forms'
import React, { FormEvent } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import {
  InputField,
  NewButton,
  NewDropDownInput,
  NumberInput,
} from '@/ui'
import { useCheckFieldsDirty } from '@/lib/hooks/useCheckFieldsDirty'

import {
  $companyInfo,
  $organizations,
  companyInfoForm,
  goToFirstStep,
  sendCompanyInfoFx,
} from '../../model/private'

const $projects = $companyInfo.map((info) => info?.projects
  .map((item) => ({ ...item, label: item.name })) || null)

const $organizationOptions = $organizations.map(
  (data) => data?.map((item) => ({ ...item, label: item.name })) ?? null,
)

export const SecondStepForm = React.memo(() => {
  const {
    fields,
    isValid,
    submit,
    errorText,
  } = useForm(companyInfoForm)
  const isPending = useUnit(sendCompanyInfoFx.pending)
  const isDirty = useCheckFieldsDirty(fields)
  const projects = useUnit($projects)
  const organizationOptions = useUnit($organizationOptions)

  const isButtonDisabled = !isDirty || !isValid || isPending

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    submit()
  }

  return (
    <Form onSubmit={handleSubmit}>
      <InputsWrapper>
        <InputField
          dataTestId="name-field"
          placeholder="Введите ФИО"
          onChange={fields.name.onChange}
          value={fields.name.value}
          error={errorText('name')}
          disabled={isPending}
          onClear={fields.name.reset}
          size="L"
        />
        <InputField
          dataTestId="profession-field"
          placeholder="Укажите вашу должность"
          onChange={fields.profession.onChange}
          value={fields.profession.value}
          error={errorText('profession')}
          disabled={isPending}
          onClear={fields.profession.reset}
          size="L"
        />
        <InputField
          dataTestId="number-field"
          placeholder="Введите № договора"
          onChange={fields.number.onChange}
          value={fields.number.value}
          error={errorText('number')}
          onClear={fields.number.reset}
          disabled={isPending}
          size="L"
        />
        <NewDropDownInput
          value={fields.company_fsk.value}
          options={organizationOptions}
          placeholder="Организация, с которой заключен договор"
          isDisabled={isPending}
          size="L"
          error={errorText('company_fsk')}
          dataTestId="organization-field"
          onChange={fields.company_fsk.onChange}
        />
        <NewDropDownInput
          dataTestId="project-field"
          value={fields.project.value}
          onChange={fields.project.onChange}
          options={projects}
          placeholder="Выберите название проекта"
          isDisabled={isPending}
          size="L"
          error={errorText('project')}
        />
        <NumberInput
          dataTestId="phone-field"
          disabled={isPending}
          size="L"
          placeholder="+7"
          type="tel"
          format="+7 ### ###-##-##"
          allowEmptyFormatting
          mask="_"
          onChange={fields.phone.onChange}
          value={fields.phone.value.replace('+7', '')}
          onClear={fields.phone.reset}
          error={errorText('phone')}
        />
        <InputField
          dataTestId="email-field"
          type="email"
          placeholder="Введите e-mail"
          onChange={fields.email.onChange}
          value={fields.email.value}
          error={errorText('email')}
          onClear={fields.email.reset}
          disabled={isPending}
          size="L"
        />
      </InputsWrapper>

      <ButtonWrapper>
        <NewButton
          label="Назад к 1 шагу"
          onClick={goToFirstStep}
          buttonType="line-primary"
          isFill
          dataTestId="go-back"
        />

        <NewButton
          label="Отправить"
          type="submit"
          isFill
          isDisabled={isButtonDisabled}
          dataTestId="submit-button"
        />
      </ButtonWrapper>
    </Form>
  )
})

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const InputsWrapper = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: column;
`

const ButtonWrapper = styled.div`
  display: flex;
  gap: 12px;
`
