import React from 'react'
import styled from 'styled-components'

import { HEADER_HEIGHT } from '../const'
import { palette } from '../palette'

type Props = {
  header: React.ReactNode
  children: React.ReactNode
}

export const PageLayout = ({ children, header }: Props) => (
  <Template>
    <HeaderWrapper>{header}</HeaderWrapper>

    {children}
  </Template>
)

const Template = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const HeaderWrapper = styled.div`
  margin: 0 16px;
  position: sticky;
  z-index: 10;
  min-height: ${HEADER_HEIGHT}px;
  background-color: ${palette.grey10};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  top: 0;
`
