import { LotRowType, LotTableItem, LotVolumes, MaterialType, MorphologyType } from './types'

export const mockCellsBuilding1 = {
  id: 2,
  name: 'Корпус 1',
  type: MorphologyType.Building,
  volume: '12.1234',
  can_edit: true,
  children: [
    {
      id: 3,
      name: 'Секция 1',
      type: MorphologyType.Section,
      volume: '12.1234',
      can_edit: true,
      children: [
        {
          id: 4,
          name: 'Этаж 1',
          type: MorphologyType.Floor,
          volume: '12.1234',
          can_edit: true,
          children: [],
        },
        {
          id: 5,
          name: 'Этаж 2',
          type: MorphologyType.Floor,
          volume: '12.1234',
          can_edit: true,
          children: [],
        },
        {
          id: 6,
          type: MorphologyType.Floor,
          name: 'Этаж 3',
          volume: '12.1234',
          can_edit: true,
          children: [],
        },
      ],
    },
    {
      id: 7,
      name: 'Секция 2',
      type: MorphologyType.Section,
      volume: '12.1234',
      can_edit: true,
      children: [
        {
          id: 8,
          name: 'Этаж 1',
          type: MorphologyType.Floor,
          volume: '12.1234',
          can_edit: true,
          children: [],
        },
        {
          id: 9,
          name: 'Этаж 2',
          type: MorphologyType.Floor,
          volume: '12.1234',
          can_edit: true,
          children: [],
        },
        {
          id: 10,
          name: 'Этаж 3',
          type: MorphologyType.Floor,
          volume: '12.1234',
          can_edit: true,
          children: [],
        },
      ],
    },
  ],
}

export const mockCellsBuilding2 = {
  id: 12,
  name: 'Корпус 1',
  type: MorphologyType.Building,
  volume: '12.1234',
  can_edit: true,
  children: [
    {
      id: 13,
      name: 'Секция 1',
      type: MorphologyType.Section,
      volume: '12.1234',
      can_edit: true,
      children: [
        {
          id: 14,
          name: 'Этаж 1',
          type: MorphologyType.Floor,
          volume: '12.1234',
          can_edit: true,
          children: [],
        },
        {
          id: 15,
          name: 'Этаж 2',
          type: MorphologyType.Floor,
          volume: '12.1234',
          can_edit: true,
          children: [],
        },
        {
          id: 16,
          name: 'Этаж 3',
          type: MorphologyType.Floor,
          volume: '12.1234',
          can_edit: true,
          children: [],
        },
      ],
    },
    {
      id: 16,
      name: 'Секция 2',
      type: MorphologyType.Section,
      volume: '12.1234',
      can_edit: true,
      children: [],
    },
    {
      id: 17,
      name: 'Секция 3',
      type: MorphologyType.Section,
      volume: '13.7777',
      can_edit: true,
      children: [],
    },
  ],
}

export const mockCellsTree = [
  mockCellsBuilding1,
  mockCellsBuilding2,
  ...[...Array(4)].map((_, i) => ({ ...mockCellsBuilding1, id: 1000 + i })),
]

const removePrice = (items: LotVolumes[]) => {
  for (const item of items) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete item.volume
    removePrice(item.children)
  }
  return items
}

export const mockCellsTreeWithoutPrice = removePrice(structuredClone(mockCellsTree))

const mockBaseItem: LotTableItem = {
  id: '1',
  name: 'Test material',
  unit: {
    id: 1,
    name: 'м³',
  },
  parent_id: '1',
  count_total: '0.00',
  spend_coefficient: '1.4000',
  material_type: null,
  material_specie: null,
  section_name: 'Отделочные работы',
  folder_name: 'Штукатуривание поверхности стен и прилегающих откосов',
  is_grand_tender: false,
  price: {
    total: '0.00',
    material: {
      can_edit: true,
      value: '0.00',
    },
    work: {
      can_edit: true,
      value: '0.00',
    },
  },
  cost: {
    total: '0.00',
    material: '0.00',
    work: '0.00',
  },
  requires_materials: false,
  comment: {
    value: 'Произвольный комментарий',
    can_edit: false,
  },
  hierarchy_code: '1.1.1',
  has_children: false,
  node_type: LotRowType.Work,
  volumes: mockCellsTree,
  is_closed_price: false,
  is_automatically_added: false,
  is_required: false,
  material_names: null,
}

export const mockWorkItem = {
  ...mockBaseItem,
  is_folder: false,
  node_type: LotRowType.Work,
  comment: {
    value: null,
    can_edit: false,
  },
  has_children: true,
  material_names: ['Бетон Б25 F100', 'Бетон Б15', 'Бетон Б30 F100'],
}

export const mockMaterialItem = {
  ...mockBaseItem,
  is_folder: false,
  node_type: LotRowType.Material,
  comment: {
    value: 'Произвольный комментарий',
    can_edit: true,
  },
  material_specie: null,
  material_names: null,
}

export const mockTableItems = {
  total: 30,
  root: [
    {
      ...mockWorkItem,
      id: '999',
      is_folder: false,
      node_type: LotRowType.Work,
      name: 'Проведение испытаний, приемка наружного водопровода; Бетон Б25 F100, Бетон Б15, Бетон Б30 F100',
      is_closed_price: true,
    },
    ...[...Array(29)].map((_, i) => ({
      ...mockWorkItem,
      id: `${i + 200}`,
      name: `Моковая работа № ${i + 2}`,
    })),
  ],
  costs: {
    total: '1425280.00',
    material: '805280.00',
    work: '620 000.00',
  },
  morphology: mockCellsTree,
}

export const mockExpandedItems = {
  ...mockTableItems,
  root: [
    ...[...Array(20)].map((_, i) => ({
      ...mockMaterialItem,
      name: `Моковый МАТЕРИАЛ № ${i + 1}`,
      id: `${Math.round(Math.random() * 300)}-${i + 200}`,
      section_name: null,
      folder_name: null,
      is_grand_tender: Boolean(i % 2),
      is_required: Boolean(i % 2),
      material_type: Object.values(MaterialType)[Math.round(Math.random())],
      cost: {
        total: '0.00',
        material: '0.00',
        work: null,
      },
      price: {
        total: '0.00',
        material: {
          can_edit: true,
          value: '0.00',
        },
        work: {
          can_edit: false,
          value: null,
        },
      },
    })),
  ],
}

const changePrices = (item: LotVolumes) => {
  const children: LotVolumes['children'] = []
  for (const child of item.children) {
    children.push(changePrices(child))
  }
  return { ...item, children, volume: '4333.3333' }
}

export const mockUpdatedItems = {
  ...mockTableItems,
  root: mockTableItems.root.map((item) => ({
    ...item,
    name: 'Updated row',
    volumes: structuredClone(item.volumes).map(changePrices),
  })),
}
