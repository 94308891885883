import { KorDocFiltersPayload } from './types'

export const mapFilterParams = ({
  search,
  types,
  act_groups,
  buildings,
  sections,
  floors,
}: KorDocFiltersPayload) => {
  return {
    search: search || undefined,
    types: types?.join() || undefined,
    act_groups: act_groups?.join() || undefined,
    buildings: buildings?.join() || undefined,
    sections: sections?.join() || undefined,
    floors: floors?.join() || undefined,
  }
}
