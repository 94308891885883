import React from 'react'
import styled, { css } from 'styled-components'

import { numberWithSpaces } from '@/lib/number-represent'

import { palette } from '../../palette'
import { TextM, TextMLight } from '../../typography'
import { NewCheckbox } from '../NewCheckbox'
import { CellExpansionButton, CellExpansionButtonProps } from './CellExpansionButton'
import { TABLE_HEADER_BORDER_STYLE, TABLE_HEADER_THICK_BORDER_STYLE } from './const'
import { InfoIcon } from './InfoIcon'

export type TableHeaderCellProps = {
  children?: React.ReactNode
  isHiddenCell?: boolean
  hasRightDivider?: boolean
  hasBorder?: boolean
  textAlign?: 'left' | 'center' | 'right'
  verticalAlign?: 'top' | 'center' | 'bottom'
  label: string
  totalCost?: string
  info?: string
  checkboxLabel?: string
  checkboxPosition?: 'left' | 'right'
  isChecked?: boolean
  isDisableCheckbox?: boolean
  isFirst?: boolean
  padding?: string
  gap?: number
  isDividerTransparent?: boolean
  onToggle?: (isChecked: boolean) => void
} & CellExpansionButtonProps

export const TableHeaderCell = ({
  children,
  hasRightDivider,
  hasBorder,
  isHiddenCell,
  label,
  textAlign = 'center',
  verticalAlign = 'center',
  expandButtonAlign = 'center',
  groupNumber,
  isExpanded,
  info,
  totalCost,
  checkboxLabel,
  checkboxPosition = 'right',
  isChecked = false,
  isDisableCheckbox,
  isFirst,
  expandButtonZIndex,
  padding,
  gap = 2,
  isDividerTransparent,
  onToggle,
  onExpandButton,
}: TableHeaderCellProps) => {
  return (
    <Cell
      isHiddenCell={isHiddenCell}
      hasRightDivider={hasRightDivider}
      isFirst={isFirst}
      verticalAlign={verticalAlign}
      padding={padding}
      gap={gap}
      isDividerTransparent={isDividerTransparent}
      textAlign={textAlign}
      hasBorder={hasBorder}
    >
      <CellExpansionButton
        groupNumber={groupNumber}
        isExpanded={isExpanded}
        expandButtonZIndex={expandButtonZIndex}
        expandButtonAlign={expandButtonAlign}
        onExpandButton={onExpandButton}
      />

      <LabelWrapper textAlign={textAlign}>
        {label}
        {info && <InfoIcon tooltipText={info} />}
      </LabelWrapper>

      {totalCost && <SubLabel textAlign={textAlign}>{numberWithSpaces(totalCost)}</SubLabel>}

      {onToggle && checkboxLabel && (
        <CheckboxWrapper>
          <NewCheckbox
            label={checkboxLabel}
            onChange={onToggle}
            isBoolean
            isChecked={isChecked}
            isDisabled={isDisableCheckbox}
            checkPosition={checkboxPosition}
          />
        </CheckboxWrapper>
      )}

      {children}
    </Cell>
  )
}

const verticalAlignments = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end',
}

type CellProps = Pick<
  TableHeaderCellProps,
  | 'isHiddenCell'
  | 'hasRightDivider'
  | 'isFirst'
  | 'verticalAlign'
  | 'padding'
  | 'gap'
  | 'isDividerTransparent'
  | 'textAlign'
  | 'hasBorder'
>

const Cell = styled.div<CellProps>`
  position: relative;
  padding: 9px 12px;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: ${({ gap }) => `${gap}px`};
  background-color: ${({ isHiddenCell }) => (isHiddenCell ? palette.grey30 : palette.grey10)};
  text-align: ${({ textAlign }) => textAlign};

  ${({ hasBorder }) =>
    hasBorder &&
    css`
      border-right: ${TABLE_HEADER_BORDER_STYLE};
    `}

  &:not(:last-child) {
    border-right: ${TABLE_HEADER_BORDER_STYLE};

    ${({ hasRightDivider }) =>
      hasRightDivider &&
      css`
        border-right: ${TABLE_HEADER_THICK_BORDER_STYLE};
      `}
  }

  ${({ verticalAlign }) =>
    verticalAlign &&
    css`
      justify-content: ${verticalAlignments[verticalAlign]};
    `}

  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `}
  
  ${({ isFirst }) =>
    isFirst &&
    css`
      padding-left: 20px;
    `}

  ${({ isDividerTransparent }) =>
    isDividerTransparent &&
    css`
      && {
        padding-right: 11px;
        border-width: 1px;
      }
    `}
`

const alignments = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
}

const LabelWrapper = styled.div<Required<Pick<TableHeaderCellProps, 'textAlign'>>>`
  ${TextM}
  display: flex;
  width: 100%;
  justify-content: ${({ textAlign }) => alignments[textAlign]};
  gap: 6px;
  text-align: ${({ textAlign }) => textAlign};
  white-space: pre-line;
`

const SubLabel = styled.div<Pick<TableHeaderCellProps, 'textAlign'>>`
  ${TextMLight}
  color: ${palette.grey70};
  text-align: ${({ textAlign }) => textAlign};
`

const CheckboxWrapper = styled.div`
  width: max-content;
  margin-left: auto;
`
