import styled from 'styled-components'

import { NewCheckbox } from '@/ui'

import { SectionGroupOption } from '../../model'
import { SectionSelectGroup } from './SectionSelectGroup'

type Props = {
  sections: SectionGroupOption[]
  onChangeSection: (id: number, val: boolean) => void
  onChangeFloor: (id: number, val: boolean) => void
}

export const SectionSelectList = ({ sections, onChangeSection, onChangeFloor }: Props) => {
  return (
    <>
      {sections.map((item) =>
        item.floors.length ? (
          <SectionSelectGroup
            key={item.id}
            section={item}
            onChangeSection={onChangeSection}
            onChangeFloor={onChangeFloor}
          />
        ) : (
          <SingleSectionWrapper
            key={item.id}
            type="button"
            onClick={() => onChangeSection(item.id, !item.isSelected)}
          >
            <NewCheckbox
              label={item.label}
              value={item.id}
              isChecked={item.isSelected}
              checkPosition="left"
              onChange={() => onChangeSection(item.id, !item.isSelected)}
            />
          </SingleSectionWrapper>
        ),
      )}
    </>
  )
}

const SingleSectionWrapper = styled.button`
  width: 100%;
  padding: 8px 16px;
  text-align: left;
`
