import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { HeadL, NewIconButton, NewInput } from '@/ui'
import { addWorksModal } from '@/features/estimate-lot/model/private'

import { $searchValue, acceptSearch, changeSearchValue } from '../../model/private'

export const CatalogHeader = React.memo(() => {
  const [searchValue] = useUnit([$searchValue])

  const onClearSearch = () => {
    changeSearchValue('')
    acceptSearch()
  }

  return (
    <Wrapper>
      <Row>
        <Title>Добавление работы</Title>
        <NewIconButton
          icon="cross"
          size={20}
          color="grey90"
          dataTestId="add-works-modal-close-btn"
          onClick={() => addWorksModal.close()}
        />
      </Row>

      <NewInput
        value={searchValue}
        placeholder="Поиск"
        postfixIcon="search"
        dataTestId="add-works-modal-search-input"
        onChange={changeSearchValue}
        onClear={onClearSearch}
        onEnterPress={acceptSearch}
        onPostfixIconClick={acceptSearch}
      />
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`

const Title = styled.p`
  ${HeadL}
`
