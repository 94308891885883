import React from 'react'
import styled, { css } from 'styled-components'

import { numberWithSpaces } from '@/lib/number-represent'

import { NewIconButton } from '../../buttons/NewIconButton'
import { CellNumberInput } from '../../fields'
import { usePopup } from '../../hooks'
import { palette } from '../../palette/palette'
import { TextMLight, TextSLight } from '../../typography'
import { OverflowText } from '../OverflowText'
import { ToolTipV2 } from '../tooltip'
import { TABLE_BORDER_STYLE } from './const'

type Props = {
  children?: React.ReactNode
  align?: 'center' | 'right' | 'left'
  isGray?: boolean
  hasRightDivider?: boolean
  isText?: boolean
  text?: string
  canEdit?: boolean
  diff?: string
  approveValue?: string
  approveText?: string
  value?: string
  filledValue?: string
  decimalScale?: number
  isEmpty?: boolean
  hasToFocus?: boolean
  allowNegative?: boolean
  onError?: () => void
  onChange?: (val: string) => void
  onEnter?: () => void
  onFocus?: () => void
}

export const TableCell = ({
  children,
  hasRightDivider,
  align = 'right',
  isGray,
  isText,
  text,
  canEdit,
  diff,
  approveValue,
  value,
  filledValue,
  approveText,
  decimalScale = 2,
  isEmpty = false,
  hasToFocus,
  allowNegative,
  onError,
  onChange,
  onEnter,
  onFocus,
}: Props) => {
  const [inputVal, setInputVal] = React.useState(value || '')

  const { arrowProps, refs, getReferenceProps, ...tooltipProps } = usePopup()

  React.useEffect(() => {
    setInputVal(value || '')
  }, [value])

  const handleIconClick = () => {
    if (filledValue) {
      onChange?.(filledValue || '')
    }
  }

  const handleFocus = () => {
    setInputVal(value || '')
    onFocus?.()
  }

  const handleBlur = () => {
    if (!filledValue) return
    if (inputVal === value) return
    if (parseFloat(inputVal) > parseFloat(filledValue)) {
      onError?.()
      return
    }
    if (!inputVal) {
      onChange?.('0.00')
      return
    }
    onChange?.(inputVal)
  }

  const isEqual = value === approveValue
  const isPositiveDelta =
    !isEqual && parseFloat(approveValue || '0.00') > parseFloat(value || '0.00')
  const stringContent = (
    <CurrentValue>
      <OverflowText maxLines={4}>{text || ''}</OverflowText>
    </CurrentValue>
  )

  return (
    <CellWrapper
      hasRightDivider={hasRightDivider}
      align={align}
      isGray={isGray}
      $padding={canEdit ? '0' : '12px'}
    >
      {children ?? (
        <>
          {!isEmpty && isText && stringContent}
          {!isEmpty &&
            !isText &&
            (canEdit ? (
              <CellNumberInput
                icon="copy"
                textAlign="right"
                displayValue={value || '0.00'}
                inputValue={inputVal}
                fixedDecimalScale
                decimalScale={4}
                color={parseFloat(value ?? '0') ? 'grey90' : 'grey60'}
                hasToFocus={hasToFocus}
                allowNegative={allowNegative}
                popupZIndex={3}
                padding="12px"
                onChange={setInputVal}
                onIconClick={handleIconClick}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onSubmit={onEnter}
              />
            ) : (
              <>
                <div>
                  <CurrentValue>{numberWithSpaces(value, { decimalScale })}</CurrentValue>
                  {diff && (
                    <DeltaValue>
                      {isEqual ? '' : isPositiveDelta ? '+' : '–'}{' '}
                      {numberWithSpaces(diff, { decimalScale })}
                    </DeltaValue>
                  )}
                </div>

                {approveValue && (
                  <ApproveWrapper>
                    {approveText && (
                      <div ref={refs.setReference} {...getReferenceProps()}>
                        <NewIconButton
                          icon="message"
                          dataTestId=""
                          size={12}
                          color="accent100"
                          backgroundColor="accent10"
                          hoverBackgroundColor="accent10"
                          padding="6px"
                          borderRadius="50%"
                        />

                        <ToolTipV2 refs={refs} {...tooltipProps}>
                          {approveText}
                        </ToolTipV2>
                      </div>
                    )}
                    <ApproveValue>{numberWithSpaces(approveValue, { decimalScale })}</ApproveValue>
                  </ApproveWrapper>
                )}
              </>
            ))}
        </>
      )}
    </CellWrapper>
  )
}

type WrapperProps = {
  align?: 'center' | 'right' | 'left'
  isGray?: boolean
  hasRightDivider?: boolean
  $padding?: string
}

export const CellWrapper = styled.div<WrapperProps>`
  position: relative;
  width: 100%;
  padding: ${({ $padding = '12px' }) => $padding};
  text-align: ${({ align }) => align || 'inherit'};
  display: flex;
  flex-direction: column;
  gap: 6px;
  border-right: ${TABLE_BORDER_STYLE};

  ${({ hasRightDivider }) =>
    hasRightDivider &&
    css`
      border-width: 4px;
    `}

  transition: 0.15s linear;
  transition-property: background-color;

  &:last-child:not(:first-child) {
    border-right: none;
  }

  ${({ isGray }) =>
    isGray &&
    css`
      background-color: ${palette.grey10};
    `}
`

const CurrentValue = styled.div`
  ${TextMLight}
`

const DeltaValue = styled.div`
  ${TextSLight}
  color: ${palette.accent100};
`

const ApproveWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`

const ApproveValue = styled.div`
  ${TextMLight}
  padding: 0px 2px;
  background-color: ${palette.accent100};
  border-radius: 2px;
  color: ${palette.white};
  margin-right: 0;
  width: max-content;
  height: 16px;
`
