import React from 'react'

import { ContractItem } from '@/dal'
import { getContractNameAndIconByType } from '@/lib/documentResolvers'
import { useTooltip } from '@/lib/hooks'
import { Icon2, ToolTipV2 } from '@/ui'

type Props = {
  hasChildren: boolean
  isOpen?: boolean
  isSubItem?: boolean
  type: ContractItem['type']['map_id']
}

export const ContractIcon = ({ hasChildren, type, isOpen, isSubItem }: Props) => {
  const iconInfo = React.useMemo(() => {
    if (!isSubItem) return null
    return getContractNameAndIconByType(type)
  }, [type, isSubItem])

  const { referenceProps, tooltipProps } = useTooltip()

  return (
    <>
      {Boolean(hasChildren && !isSubItem) && (
        <Icon2 icon={isOpen ? 'minusWithCircle' : 'plusWithCircle'} size={16} />
      )}
      {iconInfo && (
        <>
          <div {...referenceProps}>
            <Icon2 icon={iconInfo.icon} color="accent100" size={16} />
          </div>
          <ToolTipV2 {...tooltipProps} size="M">
            {iconInfo.name}
          </ToolTipV2>
        </>
      )}
    </>
  )
}
