import { attach, combine } from 'effector'
import { createForm } from 'effector-forms'

import {
  AvansTableItem,
  fillTableReqFx,
  getAvansSumsReqFx,
  getPaymentAvansTableReqFx,
  putAvansItemReqFx,
  Summ,
} from '@/dal'
import { createSingleEffect } from '@/lib/createSingleEffect'
import { createValue } from '@/features/factories'

import { d } from './domain'
import { PaymentGate } from './public'
import { AvansInputChangeParams } from './types'

export const $disabledRowIds = d.store<AvansTableItem['id'][]>([])

export const onAvansInputChanged = d.event<AvansInputChangeParams>()
export const onMaxValueError = d.event<void>()

export const $advanceTableItems = d.store<AvansTableItem[] | null>(null)
export const $sums = d.store<Summ | null>(null)
export const $paymentWorkCount = $advanceTableItems.map((items) => items?.length ?? 0)

export const tableSearch = createValue('')

export const isTableOverflowed = createValue(false)
export const isTableExpanded = createValue(false)

export const $filteredAdvanceTableItems = combine(
  [$advanceTableItems, tableSearch.$value],
  ([items, search]) =>
    items?.filter((item) =>
      item.turnover_article.name.toLowerCase().includes(search.toLowerCase()),
    ) ?? [],
)

export const $isFilled = d.store<boolean>(false)
export const onFillTable = d.event<boolean>()

export const workFilters = createForm({
  domain: d,
  fields: {
    showOnlyFilled: {
      init: false,
    },
    availableAvans: {
      init: false,
    },
  },
})

export const updateTableItem = d.event<AvansTableItem>()

export const putAvansItemFx = attach({
  effect: putAvansItemReqFx,
})

export const { requestFx: getPaymentAvansTableFx } = createSingleEffect(getPaymentAvansTableReqFx, {
  abortEvents: [PaymentGate.close],
})

export const { requestFx: getAvansSumsFx } = createSingleEffect(getAvansSumsReqFx, {
  abortEvents: [PaymentGate.close],
})

export const fillTableFx = attach({
  effect: fillTableReqFx,
})

export const $isPutAvansItem = putAvansItemFx.pending
