import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { SimpleLoader } from '@/ui'

import { getGroupsTreeFx } from '../../model/private'
import { TopRowContainer, TreeBreadcrumbs, TreeList } from '../containers'

type Props = {
  workCount: number | null
  title?: string
  isModalOnly?: boolean
}

export const GroupTree = React.memo(({ workCount, title, isModalOnly = false }: Props) => {
  const isPending = useUnit(getGroupsTreeFx.pending)

  if (isPending && !isModalOnly) return <SimpleLoader />

  return (
    <>
      {!isModalOnly && (
        <Wrapper>
          <TopRowContainer workCount={workCount} title={title} />
          <TreeBreadcrumbs />
        </Wrapper>
      )}
      <TreeList />
    </>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
