import { Domain, EventCallable, Store } from 'effector'

import { AvansChangedParams, DocumentStatuses, SummaryTable } from '@/dal'
import { root } from '@/root-domain'

type Params = {
  domain?: Domain
  $documentStatus: Store<DocumentStatuses | null>
  $summary: Store<SummaryTable | null>
  $isPending?: Store<boolean>
  onFieldSubmit?: EventCallable<AvansChangedParams>
}

export type SummaryTableModelReturnType = ReturnType<typeof createSummaryTableModel>

export const createSummaryTableModel = ({
  domain,
  $summary,
  $documentStatus,
  $isPending,
  onFieldSubmit,
}: Params) => {
  const d = domain ?? root.domain()

  return {
    $summary,
    $documentStatus,
    $isPending: $isPending || d.store(false),
    onFieldSubmit: onFieldSubmit || d.event(),
  }
}
