import React from 'react'
import styled from 'styled-components'

import { palette } from '../../palette'

type Props = {
  children: React.ReactNode
  isExpanded: boolean
}

export const ExpandableTableWrapper = ({ children, isExpanded }: Props) => {
  if (!isExpanded) {
    return <>{children}</>
  }

  return (
    <ModalOverlay>
      <ModalWrapper>{children}</ModalWrapper>
    </ModalOverlay>
  )
}

const ModalOverlay = styled.div`
  position: fixed;
  inset: 0;
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: end;

  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
`

const ModalWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  width: 100%;
  height: calc(100% - 32px);
  padding: 16px 32px 32px;

  background-color: ${palette.white};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`
