import React from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import styled from 'styled-components'

import { ApprovementProcessItem } from '@/dal'

import { palette } from '../../palette'
import { HeadM } from '../../typography'
import { Divider } from './Divider'
import { Item } from './Item'

type Props = {
  items: ApprovementProcessItem[]
}

export const ApprovementProcess = ({ items }: Props) => {
  return (
    <Wrapper>
      <Scrollbars autoHeight autoHeightMax={546}>
        <Content>
          <Title>Процесс согласования документа</Title>
          <div>
            {items.map((item, i) => (
              <React.Fragment key={i}>
                <Item {...item} />
                {i + 1 < items.length && <Divider />}
              </React.Fragment>
            ))}
          </div>
        </Content>
      </Scrollbars>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 400px;
  background-color: ${palette.white};
  border-radius: 8px;
  box-shadow: 0 3px 18px 0 ${palette.tooltipShadow};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
`

const Title = styled.header`
  ${HeadM}
`
