import React from 'react'
import { createPortal } from 'react-dom'

import { useTooltip, UseTooltipParams } from '@/lib/hooks'

import { ToolTipV2 } from './tooltip'

type TooltipContentProps = {
  children: React.ReactNode
  tooltipContent: React.ReactNode
  maxTooltipWidth?: number
  useTooltipParams: UseTooltipParams
  size?: 'L' | 'M'
  onClose: () => void
}

const TooltipContent = ({
  children,
  tooltipContent,
  maxTooltipWidth,
  useTooltipParams,
  size,
  onClose,
}: TooltipContentProps) => {
  const { referenceProps, tooltipProps } = useTooltip({
    ...useTooltipParams,
    onTooltipClose: onClose,
  })

  return (
    <>
      <div {...referenceProps}>{children}</div>
      {createPortal(
        <ToolTipV2 maxTooltipWidth={maxTooltipWidth} size={size} {...tooltipProps}>
          {tooltipContent}
        </ToolTipV2>,
        document.body,
      )}
    </>
  )
}

type ContentWithTooltipProps = {
  children: React.ReactNode
  tooltipContent: React.ReactNode
  useTooltipParams?: UseTooltipParams
  canDisplayTooltip?: boolean
  maxTooltipWidth?: number
  size?: 'L' | 'M'
}

// для упрощения использования и оптимизации в некоторых кейсах, можно доработать
export const ContentWithTooltip = ({
  children,
  tooltipContent,
  useTooltipParams = {},
  canDisplayTooltip,
  maxTooltipWidth,
  size,
}: ContentWithTooltipProps) => {
  const [isHovered, setIsHovered] = React.useState(false)

  return (
    <div onMouseEnter={() => setIsHovered(true)}>
      {canDisplayTooltip && isHovered ? (
        <TooltipContent
          useTooltipParams={useTooltipParams}
          maxTooltipWidth={maxTooltipWidth}
          size={size}
          tooltipContent={tooltipContent}
          onClose={() => setIsHovered(false)}
        >
          {children}
        </TooltipContent>
      ) : (
        children
      )}
    </div>
  )
}
