import { attach } from 'effector'

import {
  clearLotMaterialReqFx,
  clearLotWorkReqFx,
  deleteEstimateLotFolderReqFx,
  deleteEstimateLotWorkReqFx,
  deleteLotMaterialReqFx,
  LotTableItem,
} from '@/dal'
import { createModal } from '@/features/factories'

import { d } from './domain'
import { ActionPayload } from './types'

export const workDeletionModal = createModal<LotTableItem>()
export const materialDeletionModal = createModal<LotTableItem>()
export const rowClearingModal = createModal<LotTableItem>()

export const onOptionClicked = d.event<ActionPayload>()

export const deleteLotWork = d.event<LotTableItem>()
export const deleteLotFolder = d.event<LotTableItem>()
export const deleteLotMaterial = d.event<LotTableItem>()

export const clearLotMaterial = d.event<LotTableItem>()
export const clearLotWork = d.event<LotTableItem>()

export const deleteLotWorkFx = attach({
  effect: deleteEstimateLotWorkReqFx,
})
export const deleteLotFolderFx = attach({
  effect: deleteEstimateLotFolderReqFx,
})
export const deleteLotMaterialFx = attach({
  effect: deleteLotMaterialReqFx,
})

export const clearLotMaterialFx = attach({
  effect: clearLotMaterialReqFx,
})
export const clearLotWorkFx = attach({
  effect: clearLotWorkReqFx,
})
