import React from 'react'
import styled from 'styled-components'

import { NewIconButton } from '../buttons'
import { IconName2 } from '../icons'
import { PaletteColor } from '../palette'

type Props = {
  icon?: IconName2
  iconColor?: PaletteColor
  hoverIconColor?: PaletteColor
  threshold?: number
  scrollContainerRef: React.RefObject<HTMLElement>
  bgColor?: PaletteColor
  hoverBgColor?: PaletteColor
  hasToScrollRef?: boolean
  onClick?: () => void
}

export const ScrollTopButton = ({
  icon = 'arrowUp',
  threshold = 100,
  bgColor = 'white',
  iconColor = 'grey60',
  hoverIconColor,
  scrollContainerRef,
  hoverBgColor,
  hasToScrollRef = true,
  onClick,
}: Props) => {
  const [show, setShow] = React.useState(false)

  React.useLayoutEffect(() => {
    if (!scrollContainerRef.current) return

    const handleScroll = () => {
      if ((scrollContainerRef.current?.scrollTop || 0) > threshold) {
        setShow(true)
      } else {
        setShow(false)
      }
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    scrollContainerRef.current.addEventListener('scroll', handleScroll)
    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      scrollContainerRef.current?.removeEventListener('scroll', handleScroll)
    }
  }, [scrollContainerRef.current, threshold])

  if (!show) return null

  const handleClick = () => {
    if (hasToScrollRef) {
      scrollContainerRef.current?.scrollTo({ top: 0 })
    }
    onClick?.()
  }

  return (
    <Wrapper>
      <NewIconButton
        dataTestId="scroll-top"
        icon={icon}
        size={16}
        backgroundColor={bgColor}
        borderRadius="50%"
        padding="16px"
        onClick={handleClick}
        color={iconColor}
        hoverColor={hoverIconColor || iconColor}
        hoverBackgroundColor={hoverBgColor || bgColor}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  right: 16px;
  bottom: 16px;
  z-index: 999;
`
