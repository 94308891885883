import React from 'react'
import styled, { css } from 'styled-components'

import { Icon2 } from '../icons'
import { palette } from '../palette'
import { DropDownInputList } from './DropDownInputList'
import { InputStyle } from './styles'
import { ActionOption, Option, OptionSize } from './types'
import { useDropDownInput } from './useDropDownInput'

type Props = {
  options: Option[] | null
  selectedId: Option['id'] | null
  hasSearch?: boolean
  optionSize?: OptionSize
  actionOptions?: ActionOption[] | null
  dataTestId: string
  isDisabled?: boolean
  isLoading?: boolean
  isInvalid?: boolean
  sameComponentWidth?: boolean
  listWidth?: string
  padding?: string
  hasOverflow?: boolean
  onChange: (id: Option['id'] | null) => void
  onToggle?: (val: boolean) => void
}

export const CellDropdownInput = ({
  options,
  selectedId,
  hasSearch,
  optionSize,
  dataTestId,
  isDisabled,
  isLoading,
  isInvalid,
  sameComponentWidth = true,
  listWidth,
  padding,
  hasOverflow,
  actionOptions,
  onChange,
  onToggle,
}: Props) => {
  const {
    activeLabel,
    floatingStyles,
    getFloatingProps,
    getReferenceProps,
    handleOptionClick,
    isFocus,
    isOpen,
    refs,
    value,
    sortedItems,
    closeDropDown,
    openDropDown,
  } = useDropDownInput({
    sameComponentWidth,
    onOptionClick: onChange,
    options,
    selectedId,
    hasSearch,
    onToggle,
  })

  const handleClick = () => {
    if (!isOpen) {
      openDropDown()
    }
  }

  return (
    <Wrapper type="button" onClick={handleClick}>
      <InputWrapper
        {...getReferenceProps()}
        ref={refs.setReference}
        $padding={padding}
        $isInvalid={Boolean(isInvalid)}
      >
        <Text data-testid={dataTestId} $hasOverflow={hasOverflow}>
          {isFocus && hasSearch ? value : activeLabel}
        </Text>
        <Icon2 icon={isOpen ? 'arrowUp' : 'arrowDown'} />
      </InputWrapper>

      <DropDownInputList
        isDisabled={Boolean(isDisabled)}
        isLoading={Boolean(isLoading)}
        isOpen={isOpen}
        listWidth={listWidth}
        items={sortedItems}
        searchValue={value}
        actionOptions={actionOptions}
        size={optionSize}
        dataTestId={dataTestId}
        selectedId={selectedId}
        handleOptionClick={handleOptionClick}
        floatingStyles={floatingStyles}
        onCloseDropDown={closeDropDown}
        getFloatingProps={getFloatingProps}
        refs={refs}
      />
    </Wrapper>
  )
}

const Wrapper = styled.button`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
`

const InputWrapper = styled.div<{ $padding?: string; $isInvalid: boolean }>`
  position: relative;
  z-index: 1;

  display: flex;
  gap: 4px;

  width: 100%;
  padding: ${({ $padding }) => $padding || 0};
  color: ${palette.grey90};
  transition: color 0.15s linear;
  cursor: pointer;

  ${({ $isInvalid }) =>
    $isInvalid &&
    css`
      color: ${palette.red100};
    `}
`

const Text = styled.p<{ $hasOverflow?: boolean }>`
  ${InputStyle}
  color: inherit;
  text-align: center;

  ${({ $hasOverflow }) =>
    $hasOverflow &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}

  ${({ $hasOverflow }) =>
    !$hasOverflow &&
    css`
      height: fit-content;
    `}

  && {
    cursor: pointer;
  }
`
