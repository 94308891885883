import React, { Key } from 'react'

import { SwiperWrapper } from '../SwiperWrapper'
import { Tab, TabsProps } from '../Tabs'
import { PillowButton } from './PillowButton'

type PillowSwiperItem<T> = Omit<Tab<T>, 'count'>

type Props<T> = TabsProps<T> & {
  disableArrow?: boolean
}

export const PillowSwiper = <T extends Key | null>({
  activeId,
  items,
  onItemClick,
  disableArrow,
}: Props<T>) => {
  const renderItemFunc = React.useCallback(
    (item: PillowSwiperItem<T>) => (
      <PillowButton
        isActive={activeId === item.id}
        label={item.label}
        id={item.id}
        onClick={onItemClick}
      />
    ),
    [activeId, items, onItemClick],
  )

  return <SwiperWrapper items={items} disableArrow={disableArrow} itemRenderFn={renderItemFunc} />
}
