import { LotRowType, LotTableItem } from '@/dal'
import { ActionForItem } from '@/ui'

import { ItemAction } from './types'

type Params = LotTableItem & {
  handleOptionClick: (val: ItemAction) => void
}

export const getActionsForItem = ({
  node_type: rowType,
  is_closed_price: isClosedPrice,
  is_required: isRequired,
  handleOptionClick,
}: Params) => {
  const items: ActionForItem[] = []

  /* пока не нужно
  if (rowType === LotRowType.Group) {
    items.push({
      label: 'Добавить работу',
      icon: 'plusWithCircle',
      onClick: () => handleOptionClick(ItemAction.AddWork),
    })
  }
  */
  if (rowType === LotRowType.Work) {
    items.push({
      label: 'Добавить материал',
      icon: 'plusWithCircle',
      tooltipText: isClosedPrice ? 'К данной работе нельзя добавить материал.' : '',
      isDisabled: isClosedPrice,
      onClick: () => handleOptionClick(ItemAction.AddMaterial),
    })
  }
  items.push(
    {
      label: 'Редактировать строку',
      icon: 'editStroke',
      onClick: () => handleOptionClick(ItemAction.EditItem),
    },
    {
      label: 'Очистить строку',
      icon: 'eraser',
      onClick: () => handleOptionClick(ItemAction.ClearItem),
    },
  )
  if (rowType === LotRowType.Work) {
    items.push({
      label: 'Удалить работу',
      icon: 'delete',
      onClick: () => handleOptionClick(ItemAction.DeleteWork),
    })
  }
  if (rowType === LotRowType.Material) {
    items.push({
      label: 'Удалить материал',
      icon: 'delete',
      tooltipText: isRequired ? 'Обязательный материал. Удаление недоступно.' : '',
      isDisabled: isRequired,
      onClick: () => handleOptionClick(ItemAction.DeleteMaterial),
    })
  }

  return items
}
