import React from 'react'
import styled from 'styled-components'

import { SummaryFieldAlias } from '@/dal'

import { CurrencyRow } from '../parts'
import { SummaryTableItemProps } from '../../model/types'

export const SummaryBody = React.memo(({
  summary, isDraft, onFieldSubmit,
}: SummaryTableItemProps) => {
  if (!summary) return null

  return (
    <Body>
      <CurrencyRow
        label="Материалы"
        {...summary.sum_material}
        isDraft={isDraft}
        onSubmit={(value) => onFieldSubmit({ field: SummaryFieldAlias.MATERIAL, value })}
      />
      <CurrencyRow
        label="Работы"
        {...summary.sum_work}
        isDraft={isDraft}
        onSubmit={(value) => onFieldSubmit({ field: SummaryFieldAlias.WORK, value })}
      />
      <CurrencyRow
        label="Общая"
        {...summary.sum_all}
        isDraft={isDraft}
        onSubmit={(value) => onFieldSubmit({ field: SummaryFieldAlias.TOTAL, value })}
        isTotal
      />

    </Body>
  )
})

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
