import { CheckboxState, EstimateTableRow } from '@/dal'

import { FlatResettingRows, ResettingRowItem, ResettingRowsMap } from '../types'

type Params = {
  map: ResettingRowsMap
  state: CheckboxState
  id: ResettingRowItem['id']
}

export const mapRowsToResettingRowsTree = (
  data: EstimateTableRow[],
  state = CheckboxState.Unchecked,
): ResettingRowsMap => {
  const arr = data.map((item) => ({
    id: item.id,
    parentId: item.parent,
    state,
    children: [] as string[],
    isFolder: item.is_folder,
    totalDescendantCount: item.total_items_count ?? 0,
  }))
  return arr.reduce((acc, curr) => {
    return {
      ...acc,
      [curr.id]: curr,
    }
  }, {})
}

export const mutateStateOfDescendants = ({ map, state, id }: Params) => {
  const children = map[id]?.children

  if (!children?.length) return

  children.forEach((childId) => {
    const item = map[childId]
    if (item) {
      item.state = state
      mutateStateOfDescendants({ map, state, id: childId })
    }
  })
}

export const mutateStateOfAncestors = ({ map, state, id }: Params) => {
  const parentId = map[id]?.parentId
  const parent = parentId && map[parentId]

  if (!parent) return

  if (state === CheckboxState.Indeterminate) {
    parent.state = CheckboxState.Indeterminate
    mutateStateOfAncestors({
      map,
      state: CheckboxState.Indeterminate,
      id: parentId,
    })
    return
  }

  let hasChecked = false
  let hasUnchecked = false

  parent.children?.forEach((childId) => {
    const child = map[childId]

    switch (child?.state) {
      case CheckboxState.Indeterminate:
        parent.state = CheckboxState.Indeterminate
        mutateStateOfAncestors({
          map,
          state: CheckboxState.Indeterminate,
          id: parentId,
        })
        return
      case CheckboxState.Checked:
        hasChecked = true
        break
      case CheckboxState.Unchecked:
      default:
        hasUnchecked = true
    }

    const stateToPass =
      hasChecked && !hasUnchecked
        ? CheckboxState.Checked
        : !hasChecked && hasUnchecked
        ? CheckboxState.Unchecked
        : CheckboxState.Indeterminate

    parent.state = stateToPass

    mutateStateOfAncestors({
      map,
      state: stateToPass,
      id: parentId,
    })
  })
}

export const getResettingRowsTreeData = (map: ResettingRowsMap) => {
  const roots = Object.values(map).filter((item) => !item.parentId)
  const rows: ResettingRowItem['id'][] = []
  const groups: ResettingRowItem['id'][] = []
  let count = 0

  const handleChildren = (item: ResettingRowItem) => {
    if (item.state === CheckboxState.Checked) {
      if (item.isFolder) {
        groups.push(item.id)
      } else {
        rows.push(item.id)
      }
      count += item.totalDescendantCount || 1
    } else if (item.state === CheckboxState.Indeterminate) {
      item.children?.forEach((id) => {
        const item = map[id]
        handleChildren(item)
      })
    }
  }
  roots.forEach(handleChildren)

  return {
    count,
    rows,
    groups,
  }
}

export const getFlatResettingRowsData = (map: FlatResettingRows) => {
  let count = 0
  const rows: EstimateTableRow['id'][] = []

  Object.values(map).forEach(({ id, state }) => {
    if (state === CheckboxState.Checked) {
      count += 1
      rows.push(id)
    }
  })

  return {
    count,
    rows,
    groups: [],
  }
}
