import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { NewInput, palette } from '@/ui'

import { addLotWorkMaterialsFx, search } from '../../model/private'

export const MaterialFilters = React.memo(() => {
  const [searchValue, isSending] = useUnit([search.$value, addLotWorkMaterialsFx.pending])

  return (
    <Wrapper>
      <NewInput
        value={searchValue}
        placeholder="Поиск по материалам"
        disabled={isSending}
        size="L"
        dataTestId=""
        onChange={search.set}
        onClear={search.reset}
      />
    </Wrapper>
  )
})

const Wrapper = styled.div`
  padding-block: 8px 16px;
  border-bottom: 2px solid ${palette.grey30};
`
