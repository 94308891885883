import React from 'react'
import styled, { css } from 'styled-components'

import { numberWithSpaces } from '@/lib/number-represent'
import { EditableValue, palette } from '@/ui'
import EditIcon from '@/ui/v2/icons/assets/edit.svg'
import { CellWrapper } from '@/features/estimate-lot/shared'

type Props = {
  value: string
  decimalScale?: number
  canEdit?: boolean
  isBold?: boolean
  onSubmit?: (val: string) => void
}

// тут есть небольшие оптимизации, сделанные на скорую руку,
// для более быстрого рендера кучи редактируемых ячеек в таблице лота
export const EditableCell = ({
  value,
  canEdit = true,
  isBold = false,
  decimalScale,
  onSubmit,
}: Props) => {
  const [isFocused, setIsFocused] = React.useState(false)
  const [hasToFocus, setHasToFocus] = React.useState(false)

  const handleBlur = () => {
    setIsFocused(false)
  }

  const handleClick = () => {
    setHasToFocus(true)
    setIsFocused(true)
  }

  return (
    <Wrapper padding={0} onClick={handleClick}>
      {canEdit && onSubmit ? (
        isFocused ? (
          <EditableValue
            value={value}
            decimalScale={decimalScale}
            hasToFocus={hasToFocus}
            onFocus={() => setHasToFocus(false)}
            onBlur={handleBlur}
            onSubmit={onSubmit}
          />
        ) : (
          <InertEditableValue>
            <Value $isBold={isBold}>{numberWithSpaces(value, { decimalScale })}</Value>
            <EditIcon width={16} height={16} color={palette.accent70} />
          </InertEditableValue>
        )
      ) : (
        numberWithSpaces(value, { decimalScale })
      )}
    </Wrapper>
  )
}

const Wrapper = styled(CellWrapper)`
  background-color: ${palette.white};
  cursor: pointer;
`

const InertEditableValue = styled.div`
  display: flex;
  width: 100%;
  padding: 8px;
`

const Value = styled.p<{ $isBold: boolean }>`
  flex-grow: 1;

  ${({ $isBold }) =>
    $isBold &&
    css`
      font-weight: 400;
    `}
`
