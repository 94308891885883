import { EstimateTableRow } from '@/dal'

import { ActionsForItem, ItemAction } from '../types'

type Params = {
  canEdit: boolean
  canCreateRowOrFolder: boolean
  canCopy: boolean
  canDelete: boolean
  canSplitVolume: boolean
  canReset: boolean
  handleOptionClick: (type: ItemAction) => void
}

export const getActionForItem = ({
  canCreateRowOrFolder,
  canCopy,
  canDelete,
  canEdit,
  canSplitVolume,
  canReset,
  handleOptionClick,
}: Params) => {
  const items = [] as ActionsForItem[]

  if (canEdit) {
    items.push({
      label: 'Редактировать',
      icon: 'edit',
      onClick: () => handleOptionClick(ItemAction.Edit),
    })
  }
  if (canReset) {
    items.push({
      label: 'Сбросить изменения',
      icon: 'refresh',
      onClick: () => handleOptionClick(ItemAction.Reset),
    })
  }
  if (canCreateRowOrFolder) {
    items.push({
      label: 'Добавить строку',
      icon: 'plusWithCircle',
      onClick: () => handleOptionClick(ItemAction.AddRow),
    })
  }
  if (canCopy) {
    items.push({
      label: 'Копировать строку',
      icon: 'extra-job',
      onClick: () => handleOptionClick(ItemAction.CopyRow),
    })
  }
  if (canCreateRowOrFolder) {
    items.push({
      label: 'Добавить папку',
      icon: 'addFolder',
      onClick: () => handleOptionClick(ItemAction.AddFolder),
    })
  }
  if (canDelete) {
    items.push({
      label: 'Удалить',
      icon: 'delete',
      onClick: () => handleOptionClick(ItemAction.Delete),
    })
  }
  if (canSplitVolume) {
    items.push({
      label: 'Разделить объемы',
      icon: 'splitVolume',
      onClick: () => handleOptionClick(ItemAction.SplitVolume),
    })
  }
  return items
}

export const updateFilterFn = <
  A extends { currDocId?: unknown },
  B extends { documentId?: unknown },
>(
  { currDocId }: A,
  { documentId }: B,
) => currDocId === documentId

export const readCommentsMapHelper = (item: EstimateTableRow, id: EstimateTableRow['id']) => {
  if (item.id !== id) return item
  return {
    ...item,
    comment_info: item.comment_info
      ? {
          new_count: 0,
          total: item.comment_info.total,
        }
      : null,
  }
}
