import { createGate } from 'effector-react'

import { EstimateLot, EstimateLotId } from '@/dal'

import { d } from './domain'

export const EstimateLotGate = createGate<EstimateLotId>('EstimateLotGate')

export const $documentInfo = d.store<EstimateLot | null>(null)

export const $documentId = $documentInfo.map((info) => info?.id ?? null)
export const $documentStatus = $documentInfo.map((info) => info?.status ?? null)
export const $documentFeatures = $documentInfo.map((info) => info?.features ?? null)
export const $documentAttachmentsCount = $documentInfo.map((info) => info?.attachments?.length ?? 0)
// TODO мок, пока нет флага
// export const $canEdit = $documentInfo.map((info) => Boolean(info?.features.can_edit))
export const $canEdit = d.store(true)

export const $isDocumentPending = d.store(false)
