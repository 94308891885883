import styled, { css } from 'styled-components'

import { TABLE_BOX_SHADOW } from '@/ui'

export const FirstCellWrapper = styled.div<{ $hasShadow: boolean }>`
  position: sticky;
  left: 0;
  z-index: 4;

  clip-path: inset(0px -16px 0px 0px);
  transition: 0.2s linear box-shadow;
  border-right: 1px solid var(--table-border-color);

  ${({ $hasShadow }) =>
    $hasShadow &&
    css`
      box-shadow: ${TABLE_BOX_SHADOW};
    `}
`
