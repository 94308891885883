import { useTooltip } from '@/lib/hooks'
import { Label, ToolTipV2 } from '@/ui'

type Props = {
  materialNames: string[] | null
  isGrandTender: boolean
  isEditMode?: boolean
}

export const MaterialSpecieLabel = ({ materialNames, isGrandTender, isEditMode }: Props) => {
  const { referenceProps, tooltipProps, isOpen } = useTooltip({
    placement: 'top',
    tooltipOffset: 10,
    hasArrow: true,
  })

  if (!isGrandTender && !materialNames) return null

  const isMultiMaterial = Boolean(materialNames?.length)
  const isAccented = !isEditMode && isMultiMaterial
  const text = isGrandTender ? 'Г Т' : `+${materialNames?.length} М`

  return (
    <>
      <div {...referenceProps}>
        <Label
          text={text}
          colorText={isAccented ? 'accent100' : 'grey90'}
          bgColor={isAccented ? 'accent10' : 'grey10'}
          borderColor={isAccented ? 'accent10' : 'grey30'}
          isBoldText={false}
        />
      </div>

      <ToolTipV2
        {...tooltipProps}
        size="M"
        bgColor="grey80"
        maxTooltipWidth={350}
        isOpen={isOpen && isMultiMaterial}
      >
        {materialNames?.join(', ')}
      </ToolTipV2>
    </>
  )
}
