import { sample } from 'effector'

import { MorphologyType } from '@/dal'
import { $documentId, EstimateLotGate } from '@/features/estimate-lot/model'

import { getMorphologyFx } from '../../model/private'
import { mapMorphologyListToTrees, recalculateSectionState } from './helpers'
import {
  $hasChangedSelectedMorphology,
  $morphologyItems,
  $morphologyTrees,
  changeMorphologyModal,
  changeMorphologyTreeItem,
  saveSelectedMorphology,
  setMorphologyTrees,
  updateLotSelectedMorphologyFx,
} from './private'

$hasChangedSelectedMorphology
  .on(changeMorphologyTreeItem, () => true)
  .reset(EstimateLotGate.close, changeMorphologyModal.open)

$morphologyItems
  .on([getMorphologyFx.doneData, updateLotSelectedMorphologyFx.doneData], (_, items) => items)
  .reset(EstimateLotGate.close)

$morphologyTrees
  .on(setMorphologyTrees, (_, data) => mapMorphologyListToTrees(data))
  .on(changeMorphologyTreeItem, (trees, { id, type, value }) => {
    if (!trees) return null

    const updatedTrees = {
      object: { ...trees.object },
      building: { ...trees.building },
      section: { ...trees.section },
      floor: { ...trees.floor },
    }

    if (type === MorphologyType.Floor) {
      const floor = updatedTrees.floor[id]
      updatedTrees.floor[id] = {
        ...floor,
        isSelected: value,
      }
      const sectionId = floor.parent_id as number
      updatedTrees.section[sectionId] = recalculateSectionState(updatedTrees, sectionId)
    }

    if (type === MorphologyType.Section) {
      const section = updatedTrees.section[id]
      updatedTrees.section[id] = {
        ...section,
        isSelected: value,
        isIndeterminate: false,
      }
      section.children.forEach((floorId) => {
        const floor = updatedTrees.floor[floorId]
        updatedTrees.floor[floorId] = {
          ...floor,
          isSelected: value,
        }
      })
    }
    return updatedTrees
  })
  .reset(EstimateLotGate.close)

sample({
  clock: [changeMorphologyModal.open, $morphologyItems.updates],
  source: $morphologyItems,
  target: setMorphologyTrees,
})

sample({
  clock: saveSelectedMorphology,
  source: {
    id: $documentId,
    trees: $morphologyTrees,
  },
  filter: ({ id, trees }) => Boolean(id && trees),
  fn: ({ id, trees }) => {
    // отправляем только секции или этажи
    const { section, floor } = trees!
    const flatItems = [...Object.values(section), ...Object.values(floor)]
    const mappedItems = flatItems
      .filter(({ children }) => !children.length)
      .map(({ id, type, isSelected }) => ({ id, type, is_selected: isSelected }))
    return {
      id: id!,
      items: mappedItems,
    }
  },
  target: updateLotSelectedMorphologyFx,
})
