import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { DocumentAlias } from '@/dal'
import { DocumentInfoBlock, LayoutGrid, WINDOW_WIDTH } from '@/ui'
import { DocumentFilesButton } from '@/features/document-files'
import { $documentInfo } from '@/features/estimate-lot/model'
import { openAdditionalModal } from '@/features/estimate-lot/model/private'
import { LotMorphology } from '@/features/estimate-lot/selected-morphology'

import { LotAttachments, LotCommercialInfo, LotMainInfo } from '../containers'

export const EstimateLotInfo = React.memo(() => {
  const info = useUnit($documentInfo)

  if (!info) return null

  return (
    <LayoutGrid>
      <Col>
        {/* TODO нет бэка с недостающей инфой */}
        <DocumentInfoBlock
          status={info.status}
          features={info.features}
          progress={info.progress}
          onOpenAdditional={openAdditionalModal}
        />

        <LotMainInfo />

        <LotMorphology />

        <Row>
          <LotAttachments />
          <DocumentFilesButton id={info.id} docType={DocumentAlias.ESTIMATE_LOT} />
        </Row>
      </Col>

      <CommercialInfoWrapper>
        <LotCommercialInfo />
      </CommercialInfoWrapper>
    </LayoutGrid>
  )
})

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  grid-column: span 6;
`

const Row = styled.div`
  display: grid;
  gap: 16px;

  @media (min-width: ${WINDOW_WIDTH.desktop}px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`

const CommercialInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  grid-column: span 6;
`
