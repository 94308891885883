import React from 'react'
import styled from 'styled-components'
import { useForm } from 'effector-forms'
import { useUnit } from 'effector-react'

import { HeadM, palette, SimpleLoader } from '@/ui'

import { $paymentInfo } from '../../../model/private'
import {
  $filteredAdvanceTableItems,
  getPaymentAvansTableFx,
  workFilters,
} from '../../../model/table.private'
import { TableRow } from './TableRow'

const $canEdit = $paymentInfo.map((payment) => Boolean(payment?.features.can_edit_tablepart))

export const AvansTable = React.memo(() => {
  const {
    fields: { availableAvans, showOnlyFilled },
  } = useForm(workFilters)
  const [items, canEdit] = useUnit([$filteredAdvanceTableItems, $canEdit])
  const isLoading = useUnit(getPaymentAvansTableFx.pending)

  const emptyText = React.useMemo(() => {
    if (availableAvans.value && !showOnlyFilled.value) return 'Нет строк с доступным авансом'
    if (!availableAvans.value && showOnlyFilled.value) return 'Нет заполненных строк'
    return 'Нет строк, удовлетворяющих фильтрам'
  }, [availableAvans, showOnlyFilled])

  if (isLoading) {
    return (
      <EmptyListWrapper>
        <SimpleLoader sizePx={40} />
      </EmptyListWrapper>
    )
  }

  if (!items?.length) {
    return (
      <EmptyListWrapper>
        <EmptyText>{emptyText}</EmptyText>
      </EmptyListWrapper>
    )
  }

  return (
    <BodyWrapper>
      {items.map((item) => (
        <TableRow key={item.id} canEdit={canEdit} {...item} />
      ))}
    </BodyWrapper>
  )
})

const BodyWrapper = styled.div`
  width: fit-content;
  background-color: ${palette.white};
`

const EmptyListWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  flex-grow: 1;
  background-color: ${palette.white};
`

const EmptyText = styled.p`
  ${HeadM}
  color: ${palette.grey60};
`
