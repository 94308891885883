import React from 'react'
import styled, { css } from 'styled-components'
import { ConnectedFields } from 'effector-forms'
import { useUnit } from 'effector-react'

import { EstimateTableRow } from '@/dal'
import { getHighlightedText } from '@/lib/getHighlightedText'
import { getNoun } from '@/lib/text-noun'
import {
  DefaultButton,
  DropDownList,
  FolderComponent,
  Icon2,
  NewIconButton,
  palette,
  TABLE_HEADER_THICK_BORDER_STYLE,
  TextMLight,
} from '@/ui'
import {
  $actingGroupOptions,
  $documentFeatures,
  $hasSplittingObjects,
  $projectList,
} from '@/features/estimate-correction/shared-model'

import { TABLE_BORDER_STYLE } from '../../../model'
import { $searchInputValue } from '../../../model/filters.private'
import { getActionForItem } from '../../../model/helpers'
import { $isTreeTable, onCommentButton, onOptionClicked } from '../../../model/private'
import { $isResetModeEnabled } from '../../../model/reset-multiple-items.private'
import { ItemAction, NonFormField } from '../../../model/types'
import { ActingGroupField, GreyText, NameField, ProjectField } from '../parts'

type Props = {
  item: EstimateTableRow
  fields: ConnectedFields<any> | { [key: string]: NonFormField<any> }
  isExpanded: boolean
  isEditMode: boolean
  disableActionMenu: boolean
  hasRightDivider: boolean
  canExpand: boolean
  onToggleExpand: () => void
}

const DEFAULT_ROW_PADDING = 38
const DEFAULT_FOLDER_PADDING = 20
const ROW_START_PADDING = 62
const STEP = 6
const $canCreate = $documentFeatures.map((features) => Boolean(features?.can_create_item))

export const FirstTableCell = React.memo(
  ({
    item,
    fields,
    isExpanded,
    disableActionMenu,
    isEditMode,
    hasRightDivider,
    canExpand,
    onToggleExpand,
  }: Props) => {
    const isTree = useUnit($isTreeTable)
    const isResetMode = useUnit($isResetModeEnabled)
    const projects = useUnit($projectList)
    const actingGroupOptions = useUnit($actingGroupOptions)
    const canCreate = useUnit($canCreate)
    const hasSplittingObjects = useUnit($hasSplittingObjects)
    const searchValue = useUnit($searchInputValue)

    const {
      depth,
      id,
      is_folder: isFolder,
      is_grand_tender: isGrandTender,
      name,
      parent,
      features,
      comment_info,
      new_lines,
    } = item

    const handleOptionClick = React.useCallback(
      (type: ItemAction) => {
        onOptionClicked({
          id,
          item,
          type,
          parent,
          isFolder,
        })
      },
      [item],
    )

    const actions = React.useMemo(() => {
      return getActionForItem({
        canCreateRowOrFolder: canCreate,
        canCopy: canCreate && !isFolder && !new_lines?.length,
        canDelete: Boolean(features?.can_delete),
        canEdit: Boolean(features?.can_edit),
        canSplitVolume: Boolean(features?.can_split_volume),
        canReset: Boolean(features?.can_reset),
        handleOptionClick,
      })
    }, [handleOptionClick, features, canCreate])

    const isNew = Boolean(comment_info?.new_count)

    const commentText = React.useMemo(() => {
      if (!comment_info) return null
      const visibleCount = isNew ? comment_info.new_count : comment_info.total
      return `${isNew ? '+ ' : ''}${visibleCount} ${getNoun(
        visibleCount,
        'комментарий',
        'комментария',
        'комментариев',
      )}`
    }, [comment_info])

    const leftPadding = React.useMemo(() => {
      if (!isTree) return DEFAULT_ROW_PADDING
      let result = isFolder
        ? DEFAULT_FOLDER_PADDING
        : depth
        ? ROW_START_PADDING
        : DEFAULT_ROW_PADDING
      result += depth * STEP
      return result
    }, [isFolder, isTree, depth])

    const highlightedName = getHighlightedText(name.value, searchValue, palette.yellow30)

    const highlightedProject =
      item.project && getHighlightedText(item.project.name, searchValue, palette.yellow30)

    const highlightedActingGroup =
      item.acting_group &&
      getHighlightedText(item.acting_group.value, searchValue, palette.yellow30)

    return (
      <Wrapper $leftPadding={leftPadding} $hasRightDivider={hasRightDivider}>
        <FolderComponent
          hasChildren={Boolean(item.children?.length)}
          isExpanded={isExpanded}
          isFolder={isFolder}
          onClick={() => canExpand && onToggleExpand()}
        />

        <Info>
          {isGrandTender && (
            <IconWrapper>
              <Icon2 icon="grandTender" size={14} color="lightBlue" />
            </IconWrapper>
          )}
          <TextWrapper>
            <NameField
              id={item.id}
              isEditMode={isEditMode}
              isEditable={name.editable}
              isValid={fields.name.isValid}
              onChange={fields.name.onChange}
              value={fields.name.value}
              name={highlightedName}
            />

            <ProjectField
              id={item.id}
              isEditMode={isEditMode}
              isFolder={isFolder}
              onChange={fields.project.onChange}
              projects={projects}
              project={highlightedProject ?? ''}
              value={fields.project.value}
              isEditable={Boolean(item.project?.editable && !hasSplittingObjects)}
            />

            <ActingGroupField
              id={item.id}
              isEditMode={isEditMode}
              isFolder={isFolder}
              onChange={fields.acting_group.onChange}
              options={actingGroupOptions}
              actingGroup={highlightedActingGroup ?? ''}
              value={fields.acting_group.value}
              isEditable={Boolean(item.acting_group?.editable)}
            />

            <GreyText>{item.code_1c}</GreyText>

            {Boolean(commentText) && (
              <CommentButton
                onClick={(e) => {
                  e.stopPropagation()
                  onCommentButton(id)
                }}
              >
                <Icon2 icon={isNew ? 'visaTask' : 'message'} size={12} />
                {commentText}
              </CommentButton>
            )}
          </TextWrapper>
        </Info>

        {!isEditMode && !isResetMode && Boolean(actions.length) && (
          <ListWrapper onClick={(e) => e.stopPropagation()}>
            <DropDownList options={actions}>
              <NewIconButton
                dataTestId={`open-menu-${id}`}
                icon="verticalDots"
                size={16}
                padding="10px"
                color="grey70"
                hoverBackgroundColor="accent20"
                hoverColor="accent100"
                borderRadius="50%"
                disabled={disableActionMenu}
              />
            </DropDownList>
          </ListWrapper>
        )}
      </Wrapper>
    )
  },
)

type WrapperProps = {
  $leftPadding: number
  $hasRightDivider: boolean
}

const Wrapper = styled.div<WrapperProps>`
  padding: 12px 8px 16px 38px;
  display: flex;
  gap: 10px;
  border-right: ${TABLE_BORDER_STYLE};
  width: 100%;
  height: 100%;
  text-align: left;

  ${({ $leftPadding }) => css`
    padding-left: ${$leftPadding}px;
  `};

  ${({ $hasRightDivider }) =>
    $hasRightDivider &&
    css`
      border-right: ${TABLE_HEADER_THICK_BORDER_STYLE};
    `}
`

const Info = styled.div`
  position: relative;
  display: flex;
  gap: 4px;
  width: 100%;
  min-width: 0;
  flex-grow: 1;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  flex-grow: 1;
  ${TextMLight}
`

const IconWrapper = styled.div`
  position: absolute;
  left: -22px;
  top: 0;
`

const ListWrapper = styled.div`
  display: flex;
  align-items: center;
`

const CommentButton = styled(DefaultButton)`
  color: ${palette.accent80};
  transition: color 0.15s linear;
  display: flex;
  align-items: center;
  gap: 6px;

  @media (hover: hover) {
    &:hover {
      color: ${palette.accent100};
    }
  }
`
