import React from 'react'
import Scrollbars, { ScrollbarProps } from 'react-custom-scrollbars-2'
import styled, { css } from 'styled-components'

import { palette, PaletteColor } from '../palette'

type Props = {
  children: React.ReactNode
  style?: React.CSSProperties
  thumbColor?: PaletteColor
  trackColor?: PaletteColor
  trackZIndex?: number
} & ScrollbarProps

export const FlexScrollbar = React.forwardRef(
  (
    { children, style = {}, thumbColor, trackColor, trackZIndex = 0, ...restProps }: Props,
    ref: React.ForwardedRef<Scrollbars>,
  ) => {
    return (
      <Scrollbars
        ref={ref}
        renderView={(props) => (
          <Wrapper
            {...props}
            className="view"
            $thumbColor={thumbColor}
            $trackColor={trackColor}
            $zIndex={trackZIndex}
          />
        )}
        style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, ...style }}
        autoHeight
        autoHeightMin={0}
        autoHeightMax="100%"
        hideTracksWhenNotNeeded
        {...restProps}
      >
        {children}
      </Scrollbars>
    )
  },
)

type WrapperProps = {
  $thumbColor: Props['thumbColor']
  $trackColor: Props['trackColor']
  $zIndex: number
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  & ~ div {
    z-index: ${({ $zIndex }) => $zIndex};

    ${({ $trackColor }) =>
      $trackColor &&
      css`
        background-color: ${palette[$trackColor]} !important;
      `}

    & > div {
      ${({ $thumbColor }) =>
        $thumbColor &&
        css`
          background-color: ${palette[$thumbColor]} !important;
        `}
    }
  }
`
