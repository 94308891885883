import styled from 'styled-components'

import { CELL_MIN_HEIGHT, TABLE_BORDER_STYLE } from './const'

export const SplitCellWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > *:first-child {
    flex-grow: 1;
  }

  & > *:last-child {
    height: ${CELL_MIN_HEIGHT};
  }

  &:not(:last-child) {
    border-right: ${TABLE_BORDER_STYLE};
  }
`

export const SplitCellRow = styled.div`
  display: flex;

  &:not(:last-child) {
    border-bottom: ${TABLE_BORDER_STYLE};
  }
`
