import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { NewInput } from '@/ui'

import { $isResetRowsPending } from '../../../model'
import { $searchInputValue, acceptSearch, changeSearch } from '../../../model/filters.private'
import { FilterDropdown } from '../containers'

export const TableFilter = React.memo(() => {
  const [value, isBlocked] = useUnit([$searchInputValue, $isResetRowsPending])

  return (
    <>
      <InputWrapper>
        <NewInput
          placeholder="Поиск"
          dataTestId="search-work-in-table-input"
          value={value}
          postfixIcon="search"
          disabled={isBlocked}
          onChange={changeSearch}
          onEnterPress={acceptSearch}
          onPostfixIconClick={acceptSearch}
        />
      </InputWrapper>
      <FilterDropdown isDisabled={isBlocked} />
    </>
  )
})

const InputWrapper = styled.div`
  width: 228px;
`
