import styled from 'styled-components'

import { palette, PaletteColor } from '../../palette'
import { HeadL, TextM, TextS } from '../../typography'
import { LabelSize } from './types'

type Props = {
  text: string
  additionalText?: string
  colorText: PaletteColor
  bgColor: PaletteColor
  borderColor?: PaletteColor
  size?: LabelSize
  isBoldText?: boolean
}

export const Label = ({
  text,
  additionalText,
  colorText,
  bgColor,
  borderColor,
  size = 'M',
  isBoldText = true,
}: Props) => (
  <Wrapper
    size={size}
    bgColor={bgColor}
    colorText={colorText}
    borderColor={borderColor}
    isBoldText={isBoldText}
  >
    {text}
    {additionalText && (
      <>
        <Separator />
        <p>{additionalText}</p>
      </>
    )}
  </Wrapper>
)

const textSizes = {
  S: TextS,
  M: TextM,
  L: HeadL,
}

const Wrapper = styled.div<Omit<Props, 'text'>>`
  display: flex;
  gap: 8px;
  ${({ size }) => textSizes[size as LabelSize]};
  background-color: ${({ bgColor }) => palette[bgColor]};
  color: ${({ colorText }) => palette[colorText]};
  border: 1px solid;
  border-color: ${({ borderColor }) => (borderColor ? palette[borderColor] : 'transparent')};
  padding: ${({ size }) => (size !== 'S' ? '2px 7px' : '0 1px')};
  border-radius: ${({ size }) => (size !== 'S' ? '4px' : '2px')};
  transition: 0.15s linear;
  transition-property: color, background-color, border-color;
  width: fit-content;
  height: fit-content;

  font-weight: ${({ isBoldText }) => (isBoldText ? 400 : 300)};
`

const Separator = styled.div`
  width: 1px;
  height: 17px;
  margin-block: auto;
  background-color: ${palette.grey50};
`
