import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { KORDOC_COMMERCIAL_OFFER_URL } from '@/dal'
import { NewTextButton, NotificationItem, NotificationType, palette, TextMLight } from '@/ui'
import { RouterPaths } from '@/features/app/model'
import { $documentId } from '@/features/estimate-correction/shared-model'

import { $isShowBusinessOffer } from '../../model/private'

export const CommercialOffer = React.memo(() => {
  const id = useUnit($documentId)
  const isShowBusinessOffer = useUnit($isShowBusinessOffer)

  if (!id || !isShowBusinessOffer) return null

  return (
    <NotificationItem
      type={NotificationType.Warning}
      title={
        <>
          <span>Проверьте сформированное </span>
          <NewTextButton
            textStyle="M"
            label="коммерческое предложение"
            url={`${KORDOC_COMMERCIAL_OFFER_URL}/${id}`}
            dataTestId="download-commercial-offer-btn"
            isInline
            isDownload
          />
        </>
      }
      body={
        <>
          Если в КП указаны неверные данные ген. директора, то перейдите в раздел{' '}
          <InfoLink to={RouterPaths.MyCompany}>«Моя компания»</InfoLink> и измените их. После
          нажатия на кнопку «Отправить на согласование» документ будет недоступен для редактирования
          и отмены.
        </>
      }
    />
  )
})

const InfoLink = styled(Link)`
  ${TextMLight}
  color: ${palette.accent100};
`
