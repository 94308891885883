import React from 'react'
import styled from 'styled-components'

import { HeadXL, ModalLayoutV2, NewButton, TextLLight } from '@/ui'

type Props = {
  children: React.ReactNode
  title: string
  submitLabel: string
  isPending: boolean
  onSubmit: () => void
  onClose: () => void
}

export const ActionModal = ({
  children,
  title,
  submitLabel,
  isPending,
  onSubmit,
  onClose,
}: Props) => {
  return (
    <ModalLayoutV2 dataTestId="" canClose={!isPending} closeModal={onClose}>
      <Wrapper>
        <Container>
          <Title>{title}</Title>
          <Body>{children}</Body>
        </Container>

        <Buttons>
          <NewButton
            label="Отменить"
            buttonType="grey"
            isDisabled={isPending}
            dataTestId=""
            onClick={onClose}
          />
          <NewButton label={submitLabel} isPending={isPending} dataTestId="" onClick={onSubmit} />
        </Buttons>
      </Wrapper>
    </ModalLayoutV2>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  width: 542px;
  padding: 32px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Title = styled.p`
  ${HeadXL}
`

const Body = styled.div`
  ${TextLLight}
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Buttons = styled.div`
  display: flex;
  gap: 12px;
  justify-content: end;
`
