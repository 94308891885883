import styled, { css } from 'styled-components'

export const AnimationWrapper = styled.div<{ $isFill?: boolean; $width?: number }>`
  position: relative;
  overflow: hidden;
  width: max-content;
  height: fit-content;
  border-radius: 8px;

  ${({ $isFill }) =>
    $isFill &&
    css`
      width: 100%;
      white-space: nowrap;
    `}

  ${({ $width }) =>
    $width &&
    css`
      width: ${$width}px;
    `}
`
