import styled from 'styled-components'

import { Icon2, palette, Typography, WINDOW_WIDTH } from '@/ui'
import telegramQr from '@/ui/assets/telegram-qr.png'
import { SUPPORT_BOT_LINK } from '@/features/support/model'
import { initBotSupportApplication } from '@/features/support/model/private'

import { CardDescription } from './CardDescription'
import { CardTitle } from './CardTitle'
import { cardStyle } from './styles'

export const TelegramLink = () => {
  return (
    <Wrapper href={SUPPORT_BOT_LINK} target="_blank" onClick={() => initBotSupportApplication()}>
      <Info>
        <CardTitle>
          Создать обращение через{' '}
          <Typography textStyle="L" color="lightBlue">
            Telegram
          </Typography>
        </CardTitle>

        <CardDescription>
          Нажмите для перехода в чат или наведите камеру смартфона на QR-код
        </CardDescription>
      </Info>

      <QrImg src={telegramQr} alt="QR-код чат-бота в Telegram" />

      <IconWrapper>
        <Icon2 icon="arrowRight" size={20} />
      </IconWrapper>
    </Wrapper>
  )
}

const IconWrapper = styled.div`
  flex-shrink: 0;
  color: ${palette.grey60};
  transition: color 0.15s linear;
`

const Wrapper = styled.a`
  ${cardStyle}
  flex-direction: row;
  gap: 16px;
  grid-column: span 2;

  &:hover {
    ${IconWrapper} {
      color: ${palette.grey100};
    }
  }
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const QrImg = styled.img`
  width: 119px;
  min-width: 119px;
  height: 119px;

  @media (max-width: ${WINDOW_WIDTH.desktop}px) {
    width: 107px;
    min-width: 107px;
    height: 107px;
  }
`
