import styled, { css } from 'styled-components'

import { palette, TextMLight } from '@/ui'

type Props = {
  $variant?: 'yellow' | 'grey'
}

export const TagWrapper = styled.div<Props>`
  ${TextMLight}
  display: flex;
  align-items: center;
  gap: 6px;

  padding: 1px 7px;
  border-radius: 4px;
  line-height: 14px;

  transition: 0.15s linear;
  transition-property: background-color, border-color;
  user-select: none;

  // много цветов вне UI kit
  ${({ $variant = 'yellow' }) => css`
    border: 1px solid ${$variant === 'yellow' ? '#fcb375' : '#d9d9d9'};
    background-color: ${$variant === 'yellow' ? '#fff6ed' : '#fafafa'};
    color: ${$variant === 'yellow' ? palette.grey90 : palette.grey70};
  `}
`
