import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { ExpandableTableWrapper } from '@/ui'
import { GaActTable, GaTableFilters } from '@/features/act/acting-group'
import { isGaTableExpandedValue } from '@/features/act/acting-group/model/private'

import { $documentFeatures, $isGaAct } from '../../../model'
import { isTableExpanded } from '../../model/private'
import { Table, TableFilters } from '../containers'
import { AutoCompleteAlert } from '../parts'

export const Works = React.memo(() => {
  const [features, isGa, isActTableExpanded, isGaTableExpanded] = useUnit([
    $documentFeatures,
    $isGaAct,
    isTableExpanded.$value,
    isGaTableExpandedValue.$value,
  ])

  return (
    <>
      {features?.has_negative_completions_counts && <AutoCompleteAlert />}

      <ExpandableTableWrapper isExpanded={isActTableExpanded || isGaTableExpanded}>
        <TableWrapper>
          {isGa ? (
            <>
              <GaTableFilters />
              <GaActTable />
            </>
          ) : (
            <>
              <TableFilters />
              <Table />
            </>
          )}
        </TableWrapper>
      </ExpandableTableWrapper>
    </>
  )
})

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`
