import React from 'react'
import styled, { css } from 'styled-components'

import { palette, PaletteColor } from '../../palette'
import { TooltipProps } from '../../types'
import { TextMLight, TextSLight } from '../../typography'
import { TooltipArrow } from './TooltipArrow'

type Props = TooltipProps & {
  isOpen: boolean
  children: React.ReactNode
  bgColor?: PaletteColor
  size?: 'L' | 'M'
  padding?: string
  maxTooltipWidth?: number
}

export const ToolTipV2 = ({
  refs,
  floatingStyles,
  isOpen,
  children,
  bgColor = 'grey100',
  size = 'L',
  padding,
  maxTooltipWidth,
  arrowProps,
  getFloatingProps,
}: Props) => {
  if (!isOpen) return null

  return (
    <Wrapper
      ref={refs.setFloating}
      style={floatingStyles}
      {...getFloatingProps()}
      $bgColor={bgColor}
      $padding={padding}
      $size={size}
      $maxTooltipWidth={maxTooltipWidth}
    >
      {children}
      {arrowProps && <TooltipArrow {...arrowProps} size={size} fill={bgColor} />}
    </Wrapper>
  )
}

type WrapperProps = {
  $size: Props['size']
  $bgColor: PaletteColor
  $padding?: string
  $maxTooltipWidth?: number
}

const Wrapper = styled.div<WrapperProps>`
  background-color: ${({ $bgColor }) => palette[$bgColor]};
  padding: ${({ $size, $padding }) => $padding ?? ($size === 'L' ? '12px 14px' : '8px')};
  border-radius: 8px;
  ${({ $size }) => ($size === 'L' ? TextMLight : TextSLight)};
  color: ${palette.white};
  z-index: 1100;
  word-break: break-word;

  ${({ $maxTooltipWidth }) =>
    $maxTooltipWidth &&
    css`
      max-width: ${$maxTooltipWidth}px;
    `}
`
