import { attach, combine } from 'effector'

import {
  addLotWorkMaterialsReqFx,
  CatalogMaterial,
  CatalogMaterialGroup,
  getLotWorkMaterialGroupsReqFx,
  getLotWorkMaterialsReqFx,
} from '@/dal'
import { createSelectedIds, createValue } from '@/features/factories'

import { addMaterialsModal } from '../../model/private'
import { d } from './domain'

export const $allMaterials = d.store<CatalogMaterial[]>([])
export const $materialGroups = d.store<CatalogMaterialGroup[]>([])

export const search = createValue('')
export const debouncedSearch = createValue('')
export const areFiltersAccepting = createValue(false)

export const addLotWorkMaterials = d.event()

export const {
  $ids: $selectedMaterialIds,
  toggleId: toggleSelectedMaterial,
  selectIds: selectMaterials,
  deselectIds: deselectMaterials,
} = createSelectedIds<string>({ resetUnits: [addMaterialsModal.close] })

export const $selectedMaterialCount = $selectedMaterialIds.map((items) => items.length)
export const $hasSelectedMaterials = $selectedMaterialIds.map((items) => Boolean(items.length))

export const { $ids: $expandedGroupIds, toggleId: toggleExpandedGroup } = createSelectedIds({
  resetUnits: [addMaterialsModal.close],
})

const $filteredMaterials = combine(
  [$allMaterials, debouncedSearch.$value],
  ([materials, search]) => {
    if (!search) {
      return materials
    }
    return materials.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()))
  },
)

export const $groupsWithChildren = combine(
  [$materialGroups, $filteredMaterials],
  ([groups, materials]) =>
    groups
      .map((group) => ({
        ...group,
        childrenIds: materials
          .filter(({ group_id }) => group_id === group.id)
          .map(({ uuid }) => uuid),
      }))
      .filter(({ childrenIds }) => childrenIds.length),
)

export const $hasSelectedEachGroup = combine(
  [$materialGroups, $allMaterials, $selectedMaterialIds],
  ([groups, materials, selectedMaterialIds]) =>
    groups.every(({ id: groupId }) =>
      materials.some(
        (material) => selectedMaterialIds.includes(material.uuid) && material.group_id === groupId,
      ),
    ),
)

export const getLotWorkMaterialGroupsFx = attach({
  effect: getLotWorkMaterialGroupsReqFx,
})
export const getLotWorkMaterialsFx = attach({
  effect: getLotWorkMaterialsReqFx,
})
export const addLotWorkMaterialsFx = attach({
  effect: addLotWorkMaterialsReqFx,
})
