import React from 'react'
import styled from 'styled-components'
import { useStoreMap } from 'effector-react'

import { CatalogMaterial } from '@/dal'
import { NewCheckbox, TextLLight } from '@/ui'

import { $allMaterials, $selectedMaterialIds, toggleSelectedMaterial } from '../../model/private'

type Props = {
  id: CatalogMaterial['uuid']
  isDisabled: boolean
}

export const MaterialItem = React.memo(({ id, isDisabled }: Props) => {
  const item = useStoreMap({
    store: $allMaterials,
    keys: [id],
    fn: (items, [id]) => items.find(({ uuid }) => uuid === id),
  })

  const isSelected = useStoreMap({
    store: $selectedMaterialIds,
    keys: [id],
    fn: (ids, [id]) => ids.includes(id),
  })

  if (!item) return null

  return (
    <Wrapper type="button" disabled={isDisabled} onClick={() => toggleSelectedMaterial(id)}>
      <NewCheckbox
        value={id}
        isChecked={isSelected}
        isDisabled={isDisabled}
        dataTestId={`toggle-material-${id}-checkbox`}
        onChange={toggleSelectedMaterial}
      />
      <Name>{item.name}</Name>
    </Wrapper>
  )
})

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  gap: 16px;

  width: 100%;
  text-align: left;
  user-select: none;
`

const Name = styled.p`
  ${TextLLight}
`
