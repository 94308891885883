import React from 'react'
import styled, { css } from 'styled-components'
import { ConnectedFields } from 'effector-forms'

import { EstimateTableRow, TableRowFields } from '@/dal'

import { ItemToFocus, NonFormField, OnEnterSubmitItem, TABLE_BORDER_STYLE } from '../../../model'
import { TableCell } from './TableCell'

type Props = {
  rowId: EstimateTableRow['id']
  item: TableRowFields
  isEditMode: boolean
  isFolder: boolean
  hiddenSecondGroup: boolean
  hiddenThirdGroup: boolean
  fields: ConnectedFields<any> | { [key: string]: NonFormField<any> }
  itemToFocus?: ItemToFocus | null
  onOpenAttachments: () => void
  onFocus?: () => void
  onEnter?: (item: Omit<OnEnterSubmitItem, 'isNewLines'>) => void
}

export const VolumeRow = ({
  fields,
  rowId,
  hiddenSecondGroup,
  hiddenThirdGroup,
  isEditMode,
  isFolder,
  item,
  itemToFocus,
  onOpenAttachments,
  onFocus,
  onEnter,
}: Props) => {
  return (
    <Wrapper hiddenSecondGroup={hiddenSecondGroup} hiddenThirdGroup={hiddenThirdGroup}>
      <TableCell
        itemId={rowId}
        isEmpty={isFolder}
        isNumber
        cellInfo={item.count_in_estimate}
        isEditMode={isEditMode}
        field={fields.count_in_estimate}
        placeholder="Кол-во по смете"
        isActive
        decimalScale={4}
        hasRightDivider={hiddenSecondGroup}
        itemToFocus={itemToFocus}
        onEnter={onEnter}
        onFocus={onFocus}
      />

      {!hiddenSecondGroup && (
        <>
          <TableCell
            itemId={rowId}
            isEmpty={isFolder}
            isNumber
            cellInfo={item.approved}
            isGray
            decimalScale={4}
          />
          <TableCell
            itemId={rowId}
            isEmpty={isFolder}
            isNumber
            cellInfo={item.on_approving}
            decimalScale={4}
            isGray
          />
          <TableCell
            itemId={rowId}
            isEmpty={isFolder}
            isNumber
            cellInfo={item.remain}
            isGray
            decimalScale={4}
          />
        </>
      )}

      <TableCell
        itemId={rowId}
        isEmpty={isFolder}
        isNumber
        cellInfo={item.price_material}
        placeholder="Цена материала"
        isEditMode={isEditMode}
        field={fields.material}
        isActive
        itemToFocus={itemToFocus}
        onEnter={onEnter}
        onFocus={onFocus}
      />
      <TableCell
        itemId={rowId}
        isEmpty={isFolder}
        isNumber
        cellInfo={item.price_work}
        placeholder="Цена работы"
        isEditMode={isEditMode}
        field={fields.work}
        isActive
        itemToFocus={itemToFocus}
        onEnter={onEnter}
        onFocus={onFocus}
      />
      <TableCell
        itemId={rowId}
        isEmpty={isFolder}
        isNumber
        cellInfo={item.price_all}
        isActive
        hasRightDivider={hiddenThirdGroup}
      />

      {!hiddenThirdGroup && (
        <>
          <TableCell itemId={rowId} isNumber cellInfo={item.cost_material} isGray />
          <TableCell itemId={rowId} isNumber cellInfo={item.cost_work} isGray />
        </>
      )}

      <TableCell itemId={rowId} isNumber cellInfo={item.cost_all} isActive />

      <TableCell
        itemId={rowId}
        isActive
        cellInfo={item.justification_of_changes}
        hasFileAttach
        isEmpty={isFolder}
        fileCount={item.attachments?.length ?? undefined}
        onOpenAttachments={onOpenAttachments}
        isEditMode={isEditMode}
        field={fields.justification_of_changes_current}
        placeholder="Основание изменений"
      />
    </Wrapper>
  )
}

type WrapperProps = {
  hiddenSecondGroup: boolean
  hiddenThirdGroup: boolean
}

const Wrapper = styled.div<WrapperProps>`
  display: grid;

  ${({ hiddenSecondGroup, hiddenThirdGroup }) => css`
    grid-template-columns:
      repeat(${hiddenSecondGroup ? 4 : 7}, minmax(145px, 145fr))
      repeat(${hiddenThirdGroup ? 2 : 4}, minmax(150px, 150fr));
  `}

  &:last-child:not(:first-child) {
    border-top: ${TABLE_BORDER_STYLE};
  }
`
