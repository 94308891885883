import React from 'react'

import { FileDTO } from '@/dal'
import { DropAreaWithList } from '@/ui'

type Props = {
  onChange: (val: File[]) => File[]
  currValue: File[]
  uploadedFiles?: FileDTO[] | null
  markUploadedFileToDelete?: (id: FileDTO['id']) => void
  isLoading?: boolean
  disabled?: boolean
}

export const FileField = ({
  currValue,
  uploadedFiles,
  disabled,
  onChange,
  markUploadedFileToDelete,
  isLoading,
}: Props) => {
  return (
    <div>
      <DropAreaWithList
        value={currValue}
        dataTestId="file-input"
        uploadedFiles={uploadedFiles}
        isLoading={isLoading}
        isDisabled={disabled}
        height={92}
        onChange={onChange}
        markUploadedFileToDelete={markUploadedFileToDelete}
      />
    </div>
  )
}
