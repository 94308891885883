import React from 'react'
import { TableVirtuoso, TableVirtuosoHandle } from 'react-virtuoso'
import { useUnit } from 'effector-react'

import { useCheckScrolled } from '@/lib/hooks'
import { openAddWorksModal } from '@/features/estimate-lot/model/private'

import { LotTableItemWithDepth } from '../../model'
import { $tableItems } from '../../model/private'
import { EmptyTableState } from '../parts'
import { TableHeader } from './TableHeader'
import { TableItem } from './TableItem'

export const Table = React.memo(
  React.forwardRef((_, ref: React.ForwardedRef<TableVirtuosoHandle>) => {
    const items = useUnit($tableItems)

    const { isScrolled, containerRef } = useCheckScrolled([items])

    const headerContent = React.useCallback(() => {
      return <TableHeader isScrolled={isScrolled.horizontal} />
    }, [isScrolled])

    const itemContent = React.useCallback(
      (_: number, item: LotTableItemWithDepth | null) =>
        item ? (
          <TableItem item={item} isScrolled={isScrolled.horizontal} />
        ) : (
          <EmptyTableState onAdd={openAddWorksModal} />
        ),
      [isScrolled],
    )

    const handleScrollerRef = React.useCallback(
      (ref) => {
        containerRef.current = ref
      },
      [containerRef],
    )

    if (!items) return null

    return (
      <TableVirtuoso
        style={{ height: '100%' }}
        ref={ref}
        data={items.length ? items : [null]}
        fixedHeaderContent={headerContent}
        itemContent={itemContent}
        scrollerRef={handleScrollerRef}
      />
    )
  }),
)
