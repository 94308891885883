import { sample } from 'effector'
import { debounce } from 'patronum'
import {
  onAdditionalRequestUpdated,
  openAdditionalRequestModal,
} from '@/features/additional-request/model'

import {
  $availableFilters,
  $estimateLotList,
  $filters,
  $headerInfo,
  getFiltersFx,
  initEstimateLotListFx,
  loadMore,
  loadMoreFx,
  onAdditionalButtonClicked,
  search,
  setFilter,
  setHeaderInfo,
} from './private'
import { EstimateLotListGate } from './public'

$estimateLotList
  .on(initEstimateLotListFx.doneData, (_, { data }) => data.items)
  .on(loadMoreFx.doneData, (list, { data }) => (list ? [...list, ...data.items] : data.items))
  .reset(EstimateLotListGate.close)

$headerInfo.on(setHeaderInfo, (_, info) => info).reset(EstimateLotListGate.close)

$availableFilters.on(getFiltersFx.doneData, (_, data) => data).reset(EstimateLotListGate.close)

sample({
  clock: [initEstimateLotListFx.doneData, loadMoreFx.doneData],
  fn: ({ data: { items, ...payload } }) => payload,
  target: setHeaderInfo,
})

sample({
  clock: [EstimateLotListGate.open, $filters.updates, onAdditionalRequestUpdated],
  source: {
    gate: EstimateLotListGate.status,
    filters: $filters,
  },
  filter: ({ gate }) => gate,
  fn: ({ filters }) => filters,
  target: [initEstimateLotListFx, getFiltersFx],
})

sample({
  clock: debounce(search.$value.updates, 700),
  fn: (val) => ({ val, key: 'search' as const }),
  target: setFilter,
})

sample({
  clock: loadMore,
  source: $filters,
  target: loadMoreFx,
})

sample({
  clock: onAdditionalButtonClicked,
  target: openAdditionalRequestModal,
})
