import { attachWrapper } from '@42px/effector-extra'

import { authRequestFx, Method, mockRequestFx } from '../request'
import {
  AddLotWorkMaterialsPayload,
  CatalogMaterial,
  CatalogMaterialGroup,
  LotTableItem,
} from './types'
import { AbortableRequest, DefaultResponseFail } from '../interfaces'
import { defaultErrorHandler } from '../error-handler'
import { mockMaterials, mockMatGroups } from './mockMaterial'

export const getLotWorkMaterialGroupsReqFx = attachWrapper({
  effect: mockRequestFx,
  mapParams: ({ signal, workId }: AbortableRequest<{ workId: LotTableItem['id'] }>) => ({
    method: Method.get,
    url: `/estimate_lots/ker_of_lot/${workId}/groups/`,
    signal,
  }),
  mapResult: ({ result }): CatalogMaterialGroup[] => mockMatGroups,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const getLotWorkMaterialsReqFx = attachWrapper({
  effect: mockRequestFx,
  mapParams: ({ signal, workId }: AbortableRequest<{ workId: LotTableItem['id'] }>) => ({
    method: Method.get,
    url: `/estimate_lots/ker_of_lot/${workId}/materials/`,
    signal,
  }),
  mapResult: ({ result }): CatalogMaterial[] => mockMaterials,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const addLotWorkMaterialsReqFx = attachWrapper({
  effect: mockRequestFx,
  mapParams: ({ workId, materials }: AddLotWorkMaterialsPayload) => ({
    method: Method.post,
    url: `/estimate_lots/ker_of_lot/${workId}/materials/add/`,
    body: materials,
  }),
  mapResult: ({ result }): void => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})
