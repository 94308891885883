import React from 'react'
import styled from 'styled-components'

import { MorphologyType } from '@/dal'
import { changeMorphologyTreeItem } from '@/features/estimate-lot/selected-morphology/model/private'

import { SectionGroupOption } from '../../model'
import { ObjectAndBuildingInfo, SectionMultipleSelect } from '../parts'

type Props = {
  sections: SectionGroupOption[]
  objectName: string
  name: string
}

export const BuildingItem = React.memo(({ objectName, name, sections }: Props) => {
  return (
    <Wrapper>
      <ObjectAndBuildingInfo objectName={objectName} buildingName={name} />
      <SectionMultipleSelect
        label="Секция и этаж"
        sections={sections}
        onChangeFloor={(id, value) =>
          changeMorphologyTreeItem({ id, value, type: MorphologyType.Floor })
        }
        onChangeSection={(id, value) =>
          changeMorphologyTreeItem({ id, value, type: MorphologyType.Section })
        }
      />
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
