import { MaterialType } from './types'

export const mockMatGroups = [
  {
    id: 1,
    name: 'Гвозди',
  },
  {
    id: 2,
    name: 'Бетон',
  },
  {
    id: 3,
    name: 'Металическая продукция',
  },
  {
    id: 4,
    name: 'Мешки',
  },
  {
    id: 5,
    name: 'Немешки',
  },
]

const mockMaterial = {
  uuid: '4',
  name: 'Гвозди 4',
  is_required: false,
  type: MaterialType.Accompanying,
  group_id: 5,
}

export const mockMaterials = [
  {
    uuid: '1',
    name: 'Гвозди',
    is_required: true,
    type: MaterialType.Required,
    group_id: 1,
  },
  {
    uuid: '2',
    name: 'Гвозди 2',
    is_required: true,
    type: MaterialType.Required,
    group_id: 2,
  },
  {
    uuid: '3',
    name: 'Гвозди 3',
    is_required: false,
    type: MaterialType.Additional,
    group_id: 3,
  },
  {
    uuid: '4',
    name: 'Гвозди 4',
    is_required: false,
    type: MaterialType.Accompanying,
    group_id: 4,
  },
  ...[...Array(100)].map((_, i) => ({
    ...mockMaterial,
    uuid: `${1000 + i}`,
    group_id: (i % 5) + 1,
  })),
]
