import { attach } from 'effector'

import { MorphologyNode, MorphologyType, updateLotSelectedMorphologyReqFx } from '@/dal'
import { createModal } from '@/features/factories'

import { d } from './domain'
import { mapMorphologyListToTrees, mapMorphologyTreesToBuildingItems } from './helpers'
import { MorphologyTrees } from './types'

export const saveSelectedMorphology = d.event()

export const $morphologyItems = d.store<MorphologyNode[]>([])
export const $readOnlyAcceptedMorphology = $morphologyItems.map((data) => {
  const mappedCatalog = mapMorphologyListToTrees(data)
  const buildingGroups = mapMorphologyTreesToBuildingItems(mappedCatalog)
  return buildingGroups.filter(({ sections }) =>
    sections.some(({ isSelected, isIndeterminate }) => isSelected || isIndeterminate),
  )
})

export const $morphologyTrees = d.store<MorphologyTrees | null>(null)
export const setMorphologyTrees = d.event<MorphologyNode[]>()
export const changeMorphologyTreeItem = d.event<{
  id: number
  type: MorphologyType
  value: boolean
}>()

export const $selectedMorphologyBuildings = $morphologyTrees.map((data) => {
  if (!data) return []
  return mapMorphologyTreesToBuildingItems(data)
})

export const $hasSelectedMorphology = $morphologyTrees.map((trees) => {
  if (!trees) return false

  let hasSelectedItems = false
  const { floor, section } = trees
  for (const tree in { floor, section }) {
    for (const item of Object.values(trees[tree as MorphologyType])) {
      if (item.isSelected) {
        hasSelectedItems = true
        break
      }
    }
  }
  return hasSelectedItems
})

export const $hasChangedSelectedMorphology = d.store(false)

export const changeMorphologyModal = createModal()

export const updateLotSelectedMorphologyFx = attach({ effect: updateLotSelectedMorphologyReqFx })
