import { sample } from 'effector'
import { debounce } from 'patronum'

import { AddLotWorkMaterialsPayload } from '@/dal'

import { $openedWorkId, addMaterialsModal } from '../../model/private'
import {
  $allMaterials,
  $materialGroups,
  $selectedMaterialIds,
  addLotWorkMaterials,
  addLotWorkMaterialsFx,
  areFiltersAccepting,
  debouncedSearch,
  getLotWorkMaterialGroupsFx,
  getLotWorkMaterialsFx,
  search,
} from './private'

$allMaterials.on(getLotWorkMaterialsFx.doneData, (_, data) => data).reset(addMaterialsModal.close)

$materialGroups
  .on(getLotWorkMaterialGroupsFx.doneData, (_, data) => data)
  .reset(addMaterialsModal.close)

sample({
  clock: addMaterialsModal.open,
  fn: ({ id }) => ({ workId: id }),
  target: [getLotWorkMaterialGroupsFx, getLotWorkMaterialsFx],
})

sample({
  clock: debounce(search.$value, 500),
  target: debouncedSearch.set,
})

sample({
  clock: search.$value.updates,
  fn: () => true,
  target: areFiltersAccepting.set,
})

sample({
  clock: debouncedSearch.$value.updates,
  fn: () => false,
  target: areFiltersAccepting.set,
})

sample({
  clock: addLotWorkMaterials,
  source: {
    workId: $openedWorkId,
    materials: $selectedMaterialIds,
  },
  filter: ({ workId, materials }) => Boolean(workId && materials.length),
  fn: (payload) => payload as AddLotWorkMaterialsPayload,
  target: addLotWorkMaterialsFx,
})

sample({
  clock: addLotWorkMaterialsFx.done,
  target: addMaterialsModal.close,
})
