import React from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import styled, { css } from 'styled-components'
import { useForm } from 'effector-forms'
import { useUnit } from 'effector-react'

import { AppealStatus } from '@/dal'
import { handleDeleteFileHelper } from '@/lib/handleDeleteFileHelper'
import { handleFileChangeHelper } from '@/lib/handleFileChangeHelper'
import { useScrollListToBottom } from '@/lib/hooks/useScrollListToBottom'
import {
  DefaultButton,
  FileInput,
  Icon2,
  MessageItem,
  NewFileItem,
  NewIconButton,
  NewTextArea,
  NewTextButton,
  palette,
  SimpleLoader,
  TextL,
  TextLLight,
  TextMLight,
  useDefaultDropzone,
} from '@/ui'
import {
  $isWaitingForScreenshotMode,
  askForScreenCapture,
  waitForScreenshotMode,
} from '@/features/screen-capture/model'
import { SUPPORT_BOT_LINK } from '@/features/support/model'
import { initBotSupportApplication } from '@/features/support/model/private'
import { setAppealIdToCopy } from '@/features/support/support-appeals/model/copy.private'
import { resumeAppeal } from '@/features/support/support-appeals/model/private'

import { commentForm } from '../../model/forms'
import {
  $appealMessages,
  $canSendMessage,
  $isAppealChatHidden,
  $selectedAppeal,
  appealChatModal,
  getAppealMessagesFx,
  sendMessageFx,
} from '../../model/private'
import { MinimizedChat } from '../parts'

export const AppealChat = React.memo(() => {
  const [item, isMinimized, messages, canSendMessage, isLoading, isMessageSending] = useUnit([
    $selectedAppeal,
    appealChatModal.$isMinimized,
    $appealMessages,
    $canSendMessage,
    getAppealMessagesFx.pending,
    sendMessageFx.pending,
  ])
  const isWaitingForScreenshot = useUnit($isWaitingForScreenshotMode)
  const isAppealChatHidden = useUnit($isAppealChatHidden)

  const refList = useScrollListToBottom([messages])

  const { fields, submit } = useForm(commentForm)

  const { getInputProps } = useDefaultDropzone()

  if (!item) return null

  const appealName = `Обращение №${item.number}`

  if (isMinimized) {
    if (isAppealChatHidden) return null

    return (
      <MinimizedChat
        hasScreenshotButton={isWaitingForScreenshot}
        title={appealName}
        onOpen={appealChatModal.unfold}
        onClose={appealChatModal.close}
        onScreenshot={askForScreenCapture}
      />
    )
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (isMessageSending) return
    submit()
  }

  const handleFileChange = (files: File[]) => {
    handleFileChangeHelper({
      onChange: fields.files.onChange,
      currValue: fields.files.value,
      newFiles: files,
    })
  }

  const handleDeleteFile = (fileName: File['name']) => {
    handleDeleteFileHelper({
      value: fields.files.value,
      fileName,
      onChange: fields.files.onChange,
    })
  }

  const isSubmitDisabled =
    (!fields.comment.value.trim() && fields.files.value.length === 0) || isMessageSending

  const isClosed = item.status === AppealStatus.Closed
  const isCanceled = item.status === AppealStatus.Canceled
  const canResume = item.can_resume

  return (
    <Wrapper>
      <Header>
        <Title>
          {appealName}
          <Buttons>
            <NewIconButton
              dataTestId={`close-messages-${item.id}`}
              icon="minimize"
              size={20}
              color="grey60"
              hoverColor="grey80"
              borderRadius="50%"
              disabled={isMessageSending}
              onClick={() => appealChatModal.minimize()}
            />
            <NewIconButton
              dataTestId={`close-messages-${item.id}`}
              icon="cross"
              size={20}
              color="grey60"
              hoverColor="grey80"
              borderRadius="50%"
              disabled={isMessageSending}
              onClick={() => appealChatModal.close()}
            />
          </Buttons>
        </Title>
        <TelegramLink
          href={SUPPORT_BOT_LINK}
          target="_blank"
          onClick={() => initBotSupportApplication()}
        >
          <Icon2 icon="telegram" />
          <p>Продолжить в Telegram</p>
        </TelegramLink>
      </Header>

      {isLoading ? (
        <LoaderWrapper>
          <SimpleLoader />
        </LoaderWrapper>
      ) : (
        <>
          <Scroll>
            <Scrollbars ref={refList}>
              <ScrollView>
                {messages?.map((message, i, arr) => {
                  const prevItem = arr[i - 1]
                  return (
                    <MessageItem
                      key={`${i}-${message.created_at}`}
                      author={message.author}
                      body={message.content}
                      files={message.files}
                      date_time={message.created_at}
                      isRight={message.user_lkp}
                      prevMessageDate={prevItem?.created_at}
                    />
                  )
                })}
              </ScrollView>
            </Scrollbars>
          </Scroll>

          {(isClosed || isCanceled) && !canSendMessage && (
            <ClosedInfo>
              Обращение {isClosed ? 'закрыто' : 'отменено'}
              . Если проблема не решена,
              <br />
              вы можете{' '}
              <NewTextButton
                label={canResume ? 'возобновить обращение' : 'пересоздать обращение'}
                textStyle="LL"
                dataTestId=""
                isInline
                onClick={() =>
                  canResume
                    ? resumeAppeal({ id: item.id, appealType: item.issue_type })
                    : setAppealIdToCopy(item.id)
                }
              />
            </ClosedInfo>
          )}

          {canSendMessage && (
            <Form onSubmit={handleSubmit}>
              {Boolean(fields.files.value.length) && (
                <AttachedFiles>
                  {fields.files.value.map(({ name }) => (
                    <NewFileItem
                      key={name}
                      name={name}
                      onDelete={isMessageSending ? undefined : handleDeleteFile}
                    />
                  ))}
                </AttachedFiles>
              )}
              <Row>
                <FileInput
                  {...getInputProps()}
                  multiple
                  isDisabled={isMessageSending}
                  onFile={handleFileChange}
                >
                  <BtnIconWrapper isDisabled={isMessageSending}>
                    <Icon2 icon="attach" size={24} data-testid={`attach-files-${item.id}`} />
                  </BtnIconWrapper>
                </FileInput>
                <ScreenshotBtnWrapper
                  isDisabled={isMessageSending}
                  onClick={() => waitForScreenshotMode()}
                >
                  <Icon2 icon="scissors" size={24} data-testid={`take-screenshot-${item.id}`} />
                </ScreenshotBtnWrapper>
                <NewTextArea
                  placeholder="Введите сообщение..."
                  dataTestId="send-comment-input"
                  maxHeight={350}
                  value={fields.comment.value}
                  onChange={fields.comment.onChange}
                  disabled={isMessageSending}
                  height={42}
                  autoGrow
                />
                <SubmitButton disabled={isSubmitDisabled} data-testid="send-comment" type="submit">
                  {isMessageSending ? (
                    <SimpleLoader sizePx={24} borderWidth={2} />
                  ) : (
                    <Icon2 icon="sendIcon" size={24} />
                  )}
                </SubmitButton>
              </Row>
            </Form>
          )}
        </>
      )}
    </Wrapper>
  )
})

const BOTTOM_OFFSET = '25px'
const RIGHT_OFFSET = '32px'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 555px;
  height: 629px;
  max-width: calc(100dvw - ${RIGHT_OFFSET});
  max-height: calc(100dvh - ${BOTTOM_OFFSET});
  position: fixed;
  bottom: ${BOTTOM_OFFSET};
  right: ${RIGHT_OFFSET};
  background: white;
  z-index: 10;
  border-radius: 12px;
  box-shadow: 0px 4px 20px 0px #1c1d1e26;
`

const Header = styled.div`
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Title = styled.h4`
  ${TextL}
  display: flex;
  gap: 10px;
`

const TelegramLink = styled.a`
  ${TextMLight}
  display: flex;
  gap: 8px;
  width: fit-content;
  color: ${palette.grey80};

  &:hover {
    color: ${palette.telegram};
  }
`

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const ClosedInfo = styled.div`
  ${TextLLight}
  padding: 32px;
  color: ${palette.grey70};
  text-align: center;
`

const Scroll = styled.div`
  flex-grow: 1;
  padding-bottom: 10px;
  height: 100%;
`

const Buttons = styled.div`
  position: absolute;
  z-index: 3;
  right: 20px;
  top: 20px;
  display: flex;
  gap: 8px;
`

const ScrollView = styled.div`
  padding: 24px;
  padding-top: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Form = styled.form`
  padding: 16px 16px 20px;
  max-height: 350px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-shadow: 0px 0px 20px 0px #474a5126;
`

const AttachedFiles = styled.div`
  padding: 0 44px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
`

const Row = styled.div`
  display: flex;
  align-items: flex-end;
  max-height: 100%;
`

const BtnIconWrapper = styled.div<{ isDisabled: boolean }>`
  color: ${palette.grey60};
  padding: 8px;
  display: inline-flex;
  transition: color 0.15s linear;

  ${({ isDisabled }) =>
    !isDisabled &&
    css`
      cursor: pointer;

      @media (hover: hover) {
        &:hover {
          color: ${palette.grey90};
        }
      }
    `}
`

const ScreenshotBtnWrapper = styled(BtnIconWrapper)`
  transform: rotate(-90deg);
`

const SubmitButton = styled(DefaultButton)`
  color: ${palette.accent100};
  padding: 8px;
  display: inline-flex;
  cursor: pointer;

  @media (hover: hover) {
    color: ${palette.accent90};
  }

  &:disabled {
    color: ${palette.accent40};
    cursor: not-allowed;
  }
`
