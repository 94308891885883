import { sample } from 'effector'

import {
  $groupsTree,
  $isOpen,
  $selectedNodeId,
  abortFx,
  clearSelectedGroup,
  closeModal,
  getGroupsTreeFx,
  onAcceptSelectedNode,
  selectNode,
} from './private'
import {
  $selectedGroupId,
  loadGroupTree,
  onSelectedGroupIdChanged,
  openGroupsTree,
  resetTree,
  selectGroup,
} from './public'

$isOpen.on(openGroupsTree, () => true).reset(closeModal, resetTree)

$selectedGroupId
  .on(selectGroup, (_, selectedWork) => selectedWork)
  .reset(resetTree, clearSelectedGroup)

$groupsTree.on(getGroupsTreeFx.doneData, (_, tree) => tree).reset(resetTree, getGroupsTreeFx)

$selectedNodeId.on(selectNode, (_, nodeId) => nodeId).reset(resetTree, closeModal)

sample({
  clock: resetTree,
  target: abortFx,
})

sample({
  clock: $selectedGroupId,
  target: onSelectedGroupIdChanged,
})

sample({
  clock: loadGroupTree,
  target: getGroupsTreeFx,
})

sample({
  clock: openGroupsTree,
  source: $selectedGroupId,
  filter: Boolean,
  target: selectNode,
})

sample({
  clock: $selectedGroupId.updates,
  filter: $isOpen,
  target: selectNode,
})

sample({
  clock: onAcceptSelectedNode,
  source: $selectedNodeId,
  target: [closeModal, selectGroup],
})
