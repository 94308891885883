import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { formatDateString } from '@/lib/date'
import { noop } from '@/lib/noop'
import { HeadL, InputField, palette } from '@/ui'
import { $documentInfo } from '@/features/estimate-lot/model'

export const LotMainInfo = React.memo(() => {
  const [info] = useUnit([$documentInfo])

  if (!info) return null

  return (
    <Wrapper>
      <Title>Информация о коммерческом предложении</Title>

      <Inputs>
        <InputField
          label="Номер документа"
          value={info.number_1c}
          size="L"
          dataTestId="lot-document-number-input"
          disabled
          onChange={noop}
        />
        <InputField
          label="Дата документа"
          value={formatDateString(info.created_at)}
          size="L"
          dataTestId="lot-document-date-input"
          disabled
          onChange={noop}
        />
        <InputField
          label="Проект строительства"
          value={info.project_name}
          size="L"
          dataTestId="lot-project-select"
          disabled
          onChange={noop}
        />
        <InputField
          label="Направление деятельности"
          value={info.direction_name}
          size="L"
          dataTestId="lot-direction-select"
          disabled
          onChange={noop}
        />
      </Inputs>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  padding: 20px;
  border-radius: 20px;
  background-color: ${palette.white};
`

const Title = styled.header`
  ${HeadL}
`

const Inputs = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 16px;
`
