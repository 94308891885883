import React, { FormEvent } from 'react'
import styled from 'styled-components'
import { useForm } from 'effector-forms'
import { useUnit } from 'effector-react'

import { useCheckFieldsDirty } from '@/lib/hooks/useCheckFieldsDirty'
import {
  DropAreaWithList,
  InputField,
  NewDropDownField,
  NumberInputField,
  TextAreaField,
} from '@/ui'
import {
  $buildings,
  $groupList,
  $hasSplittingObjects,
  $isGa,
  $projectList,
  $unitOptions,
} from '@/features/estimate-correction/shared-model'

import {
  $isCopying,
  $isItemCreationPending,
  closeModal,
  createRowForm,
} from '../../../model/create-table-item.private'
import { ButtonRow } from '../parts'
import { ActingGroupInputs } from './ActingGroupInputs'

export const CreateRowForm = React.memo(() => {
  const { fields, isValid, submit } = useForm(createRowForm)

  const [
    groups,
    unitOptions,
    projects,
    hasSplittingObjects,
    buildings,
    isCopying,
    isSending,
    isGa,
  ] = useUnit([
    $groupList,
    $unitOptions,
    $projectList,
    $hasSplittingObjects,
    $buildings,
    $isCopying,
    $isItemCreationPending,
    $isGa,
  ])

  const isWorkFieldRequired = !fields.material.value || parseFloat(fields.material.value) <= 0
  const isMaterialFieldRequired = !fields.work.value || parseFloat(fields.work.value) <= 0
  // TODO мб стоит как-то отрефачить всю эту логику с условно обязательными полями
  const isBuildingRequired = Boolean(
    fields.project.value && buildings.some(({ parent_id }) => parent_id === fields.project.value),
  )

  const areFieldsDirty = useCheckFieldsDirty(fields, [
    'group_id',
    'justification_of_changes_current',
    'files',
    'acting_group',
    'section',
    'floor',
    hasSplittingObjects ? 'project' : null,
    !isMaterialFieldRequired ? 'material' : null,
    !isWorkFieldRequired ? 'work' : null,
    !isBuildingRequired ? 'building' : null,
  ])

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    submit()
  }

  const foldersOptions = React.useMemo(() => {
    if (!groups) return []
    return [
      { label: 'Строка первого уровня (снаружи всех папок)', id: null },
      ...groups.map((item) => ({ label: `${item.code_1c} ${item.name}`, id: item.id })),
    ]
  }, [groups])

  const projectOptions = React.useMemo(() => {
    if (!projects) return []
    return projects.map((item) => ({ label: item.name, id: item.id }))
  }, [projects])

  return (
    <ContentWrapper onSubmit={handleSubmit}>
      <NewDropDownField
        dataTestId="create-row-parent-folder"
        label="Папка для хранения"
        required
        options={foldersOptions}
        hasSearch
        placeholder="Выберите папку или начните вводить название..."
        size="L"
        value={fields.group_id.value}
        error={fields.group_id.firstError?.errorText}
        isDisabled={isSending}
        onChange={fields.group_id.onChange}
      />
      <InputField
        dataTestId="create-row-name"
        label="Название услуг, работ"
        placeholder="Введите название работ и услуг"
        isRequired
        value={fields.name.value}
        onChange={fields.name.onChange}
        onClear={fields.name.reset}
        size="L"
        error={fields.name.firstError?.errorText}
        disabled={isSending}
      />

      <NewDropDownField
        dataTestId="create-row-project-input"
        label="Объект строительства"
        options={projectOptions}
        hasSearch
        placeholder="Выберите объект строительства"
        size="L"
        onChange={fields.project.onChange}
        value={fields.project.value}
        isDisabled={hasSplittingObjects || isSending}
        required={!hasSplittingObjects}
        error={fields.project.firstError?.errorText}
      />

      {isGa && !hasSplittingObjects && <ActingGroupInputs />}

      <InputCell>
        <NewDropDownField
          dataTestId="create-row-unit-input"
          label="Единицы измерения"
          required
          options={unitOptions}
          hasSearch
          placeholder="Выберите единицы измерения"
          size="L"
          onChange={fields.unit.onChange}
          value={fields.unit.value}
          error={fields.unit.firstError?.errorText}
          isDisabled={isSending}
        />
        <NumberInputField
          dataTestId="create-row-count-in-estimate-adjusted"
          label="Кол-во по смете"
          isRequired
          placeholder="Введите количество"
          size="L"
          onChange={fields.count_in_estimate.onChange}
          value={fields.count_in_estimate.value}
          onClear={fields.count_in_estimate.reset}
          decimalScale={4}
          decimalSeparator="."
          fixedDecimalScale
          error={fields.count_in_estimate.firstError?.errorText}
          thousandSeparator=" "
          disabled={isSending}
        />
        <NumberInputField
          dataTestId="create-row-material-adjusted"
          label="Цена материалов, руб"
          isRequired={isMaterialFieldRequired}
          placeholder="Введите цену"
          size="L"
          onChange={fields.material.onChange}
          value={fields.material.value}
          onClear={fields.material.reset}
          decimalScale={2}
          decimalSeparator="."
          error={fields.material.firstError?.errorText}
          fixedDecimalScale
          thousandSeparator=" "
          disabled={isSending}
        />
        <NumberInputField
          dataTestId="create-row-work-adjusted"
          label="Цена работ, руб"
          isRequired={isWorkFieldRequired}
          placeholder="Введите цену"
          size="L"
          onChange={fields.work.onChange}
          value={fields.work.value}
          onClear={fields.work.reset}
          decimalScale={2}
          fixedDecimalScale
          decimalSeparator="."
          error={fields.work.firstError?.errorText}
          thousandSeparator=" "
          disabled={isSending}
        />
      </InputCell>
      <TextAreaField
        dataTestId="create-row-justification_of_changes_current"
        label="Обоснование"
        placeholder="Укажите причину изменений и ссылку на РД"
        maxSymbolCount={800}
        autoGrow
        currentSymbolCount={fields.justification_of_changes_current.value.length}
        error={fields.justification_of_changes_current.firstError?.errorText}
        height={100}
        value={fields.justification_of_changes_current.value}
        onChange={fields.justification_of_changes_current.onChange}
        disabled={isSending}
      />

      <DropAreaWithList
        value={fields.files.value}
        dataTestId="create-row-files"
        disableRemoveFile={isSending}
        isDisabled={isSending}
        isMultiple
        onChange={fields.files.onChange}
      />
      <ButtonRow
        submitLabel={isCopying ? 'Копировать' : 'Добавить'}
        isDisableSubmit={!isValid || !areFieldsDirty}
        isPending={isSending}
        onCancel={closeModal}
      />
    </ContentWrapper>
  )
})

const ContentWrapper = styled.form`
  padding: 16px 32px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const InputCell = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
`
