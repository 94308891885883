import { useUnit } from 'effector-react'
import React from 'react'
import styled from 'styled-components'

import { HeadXL } from '@/ui'

import { $isCopying, $isCreateFolder } from '../../../model/create-table-item.private'

export const ModalTitle = React.memo(() => {
  const [isFolder, isCopying] = useUnit([$isCreateFolder, $isCopying])

  return (
    <Title>
      {isFolder
        ? 'Добавление новой папки'
        : isCopying
          ? 'Копирование строки'
          : 'Добавление новой строки'}
    </Title>
  )
})

const Title = styled.div`
  ${HeadXL}
  padding: 0 32px 16px;
`
