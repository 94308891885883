import React, { memo } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { DocumentStatuses } from '@/dal'
import { Loader, NewDivider, palette } from '@/ui'

import { CurrencyCol, GridWrapper, Label } from '../parts'
import { AvansRow, DateRow, SummaryBody } from '../containers'
import { SummaryTableModelReturnType } from '../../model/createSummaryTableModel'

export const createSummaryTableView = ({
  $summary, $documentStatus, $isPending, onFieldSubmit,
}: SummaryTableModelReturnType) => {
  return memo(() => {
    const summary = useUnit($summary)
    const isPending = useUnit($isPending)
    const status = useUnit($documentStatus)

    if (!summary) return null

    const isDraft = status === DocumentStatuses.Draft

    const innerProps = { summary, isDraft, onFieldSubmit }

    return (
      <Wrapper>
        <GridWrapper>
          <Label>Стоимость, руб</Label>
          <Label>Текущая, руб</Label>
          <Label>Скорректированная, руб</Label>
          {!isDraft && (
            <Label>Принято, руб</Label>
          )}
        </GridWrapper>
        <NewDivider />

        <SummaryBody {...innerProps} />
        <NewDivider />
        <AvansRow {...innerProps} />

        <GridWrapper>
          <Label>Выплаченный аванс</Label>
          <CurrencyCol value={summary.avans.paid_advance || '0.00'} />
          <div />
          {!isDraft && <div />}
        </GridWrapper>

        <NewDivider />

        <DateRow {...innerProps} />

        {isPending && (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}
      </Wrapper>
    )
  })
}

const Wrapper = styled.div`
  background-color: ${palette.white};
  border-radius: 20px;
  width: 100%;
  padding: 24px;
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 10px;
`

const LoaderWrapper = styled.div`
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background-color: inherit;
  opacity: 0.8;
  border-radius: inherit;
`
