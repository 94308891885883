import React from 'react'

import { Field } from './Field'
import { NewInput } from './NewInput'
import { DefaultInputProps } from './types'

type Props = {
  label?: string
  maxSymbolCount?: number
  currentSymbolCount?: number
  isRequired?: boolean
  isLabel?: boolean
} & Omit<DefaultInputProps, 'maxLength'>

export const InputField = React.forwardRef<HTMLInputElement, Props>(({
  label,
  isInvalid,
  currentSymbolCount,
  error,
  maxSymbolCount,
  isRequired,
  isLabel,
  ...inputProps
}, ref) => {
  return (
    <Field
      label={label}
      currentSymbolCount={currentSymbolCount}
      maxSymbolCount={maxSymbolCount}
      required={isRequired}
      isLabel={isLabel}
    >
      <NewInput
        {...inputProps}
        ref={ref}
        maxLength={maxSymbolCount}
        isInvalid={isInvalid || Boolean(error)}
      />
    </Field>
  )
})
