import { css } from 'styled-components'

import { palette } from '../palette'

const BaseTypography = css`
  font-family: 'FuturaFuturis';
  font-style: normal;
  font-weight: 400;
  color: ${palette.grey90};
`

export const HeadXXL = css`
  ${BaseTypography}
  font-size: 36px;
  line-height: 1.1;
`

/** `font-size: 24px;` */
export const HeadXL = css`
  ${BaseTypography}
  font-size: 24px;
  line-height: 1.25;
`

export const HeadXLLink = css`
  ${HeadXL}
  text-decoration: underline;
`

/** `font-size: 20px;` */
export const HeadL = css`
  ${BaseTypography}
  font-size: 20px;
  line-height: 1.25;
`

export const HeadLLink = css`
  ${HeadL}
  text-decoration: underline;
`

export const HeadM = css`
  ${BaseTypography}
  font-size: 18px;
  line-height: 1.25;
`

export const HeadMLink = css`
  ${HeadM}
  text-decoration: underline;
`

export const TextL = css`
  ${BaseTypography}
  font-size: 16px;
  line-height: 1.3;
`

export const TextLLight = css`
  ${TextL}
  font-weight: 300;
`

export const TextLLink = css`
  ${TextL}
  text-decoration: underline;
`

export const TextM = css`
  ${BaseTypography}
  font-size: 14px;
  line-height: 1.3;
`

export const TextMLight = css`
  ${TextM}
  font-weight: 300;
`

export const TextMLink = css`
  ${TextM}
  text-decoration: underline;
`

export const TextS = css`
  ${BaseTypography}
  font-size: 12px;
  line-height: 1.3;
`

export const TextSLight = css`
  ${TextS}
  font-weight: 300;
`

export const TextSLink = css`
  ${TextS}
  text-decoration: underline;
`
