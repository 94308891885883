import styled from 'styled-components'

import { WINDOW_WIDTH } from '@/ui'

export const InputWrapper = styled.div`
  width: 240px;
  cursor: pointer;

  @media (max-width: ${WINDOW_WIDTH.desktop}px) {
    width: 158px;
  }

  @media (min-width: ${WINDOW_WIDTH.desktop + 1}px) {
    &:first-child {
      width: 300px;
    }
  }
`

export const DateInputWrapper = styled(InputWrapper)`
  width: 250px;
`
