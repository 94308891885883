import { attach } from 'effector'

import { postEstimateAttachmentsReqFx } from '@/dal'
import { createAttachmentsModalModel } from '@/features/factories/attach-modal'

import { $documentInfo } from '../../shared-model'
import { d } from './domain'

export const onOpenModal = d.event<void>()

export const onOpenAdditionalModal = d.event<void>()

export const $documentId = $documentInfo.map((info) => info?.id ?? null)
export const $documentAttachmentsCount = $documentInfo.map(
  (info) => info?.attachments?.length ?? null,
)

export const $canSendFiles = $documentInfo.map((info) =>
  Boolean(info?.features.can_add_attachments),
)

export const sendFilesFx = attach({
  effect: postEstimateAttachmentsReqFx,
})

export const attachmentsModel = createAttachmentsModalModel({
  domain: d,
  sendFx: sendFilesFx,
  $canSendFiles,
  $files: $documentInfo.map((info) => info?.attachments || null),
})
