import React from 'react'
import { useUnit } from 'effector-react'

import { ContractDirection, DocumentStatuses, KORDOC_INSTRUCTION_URL } from '@/dal'
import { ContractDirectionLabel, DocumentHeader } from '@/ui'
import { $contractDirections } from '@/features/administrative/contract-list/model'
import { RouterPaths } from '@/features/app/model'
// TODO перенести юнит
import { $totalWorkCount } from '@/features/estimate-correction/estimate-table/model/private'
import {
  $documentStatus,
  $isGa,
  $kordocExclusiveBlock,
  KOR_DOC_TABS,
  KorDocTab,
} from '@/features/estimate-correction/shared-model'

import { $activeTabId, changeActiveTab } from '../../model'
import {
  $headDocumentInfo,
  $isHeaderEditingBlocked,
  cancelApproveFx,
  checkValidationBeforeApproveFx,
  onApproveButton,
  onCancelApprove,
  onCopy,
  onDeleteButton,
  onReject,
} from '../../model/private'

export const EstimateHeader = React.memo(() => {
  const [info, status, activeTab] = useUnit([$headDocumentInfo, $documentStatus, $activeTabId])
  const isPending = useUnit(checkValidationBeforeApproveFx.pending)
  const isCancelPending = useUnit(cancelApproveFx.pending)
  const isHeaderEditingBlocked = useUnit($isHeaderEditingBlocked)
  const exclusiveBlock = useUnit($kordocExclusiveBlock)
  const directions = useUnit($contractDirections)
  const isGa = useUnit($isGa)
  const workCount = useUnit($totalWorkCount)

  const GaTypeLabel = React.useCallback(() => {
    if (!isGa) return null

    return (
      <ContractDirectionLabel
        type={ContractDirection.SMR}
        subtype="Группы актирования"
        directions={directions}
        size="M"
      />
    )
  }, [isGa, directions])

  if (!info) return null

  const isBlocked = isHeaderEditingBlocked || Boolean(exclusiveBlock?.is_blocked)

  const {
    can_approve,
    can_cancel_approving,
    can_copy,
    can_delete,
    can_reject_approving,
    can_send_to_approving,
    is_second_approving,
    is_document_changed,
  } = info.feature

  const isDraft = status === DocumentStatuses.Draft
  const showApproveButton = can_approve || can_send_to_approving || isDraft
  const approveTitle =
    can_send_to_approving || isDraft ? 'Отправить на согласование' : 'Согласовать'

  const tabs = KOR_DOC_TABS.map((item) =>
    item.id === KorDocTab.Works ? { ...item, count: workCount } : item,
  )

  return (
    <DocumentHeader
      documentName={info.name}
      lastUpdate={info.lastUpdate}
      link={RouterPaths.Administrative}
      canCancelApproving={can_cancel_approving}
      canCopy={can_copy}
      canDelete={can_delete && !isBlocked}
      approveTitle={approveTitle}
      showApproveButton={showApproveButton}
      isPendingApprove={isPending}
      isCancelPending={isCancelPending}
      downloadInstructionURL={KORDOC_INSTRUCTION_URL}
      canRejectApproving={can_reject_approving}
      isSecondApproving={is_second_approving}
      approvementProcess={info.approvementProcess}
      isDisabled={isBlocked}
      isApproveButtonDisabled={!is_document_changed}
      tabs={tabs}
      activeTabId={activeTab}
      docSubTypeSlot={<GaTypeLabel />}
      onApproveButton={onApproveButton}
      onCancelApprove={onCancelApprove}
      onDeleteButton={onDeleteButton}
      onCopy={onCopy}
      onReject={onReject}
      onChangeTab={changeActiveTab}
    />
  )
})
