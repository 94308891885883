import React from 'react'
import { useUnit } from 'effector-react'

import { LotRowType } from '@/dal'
import { ActionModal } from '@/features/estimate-lot/shared'

import { clearLotMaterial, clearLotMaterialFx, rowClearingModal } from '../../model/actions.private'

export const RowClearingModal = React.memo(() => {
  const [item, isPending] = useUnit([rowClearingModal.$meta, clearLotMaterialFx.pending])

  if (!item) return null

  const { name, node_type: type } = item

  return (
    <ActionModal
      title="Очистить строку"
      submitLabel="Очистить"
      isPending={isPending}
      onClose={rowClearingModal.close}
      onSubmit={() => clearLotMaterial(item)}
    >
      <p>
        Вы действительно хотите очистить все заполненные данные у{' '}
        {type === LotRowType.Material ? 'материала' : 'работы'} «{name}»?
      </p>
    </ActionModal>
  )
})
