import { createDocumentBlocking, createSectionTabsModel } from '@/features/factories'

import { d } from './domain'
import { $approveTimeoutId, onSendToApprove } from './header.private'
import { updateFeaturesFx } from './private'
import { $documentFeatures, $documentId, ActGate } from './public'
import { ActTab } from './types'

export const $isEditBlocking = $approveTimeoutId.map(Boolean)

export const {
  $activeTabId,
  $invalidActiveTabId,
  changeActiveTab,
  resetActiveTab,
  setInvalidActiveTab,
} = createSectionTabsModel<ActTab>({
  defaultValue: ActTab.Info,
  clearEvent: ActGate.close,
  domain: d,
})

export const { checkDocumentBlocking, onDocumentUnlocked } = createDocumentBlocking({
  getFeaturesFx: updateFeaturesFx,
  $docId: $documentId,
  $features: $documentFeatures,
  triggerUnits: [onSendToApprove],
})
