import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { noop } from '@/lib/noop'
import { ModalLayoutV2, NewButton, palette, TextMLight } from '@/ui'
import { addWorksModal } from '@/features/estimate-lot/model/private'

import { $hasSelectedKers, addWorksToLot } from '../../model/private'
import { CatalogHeader, CatalogList } from '../containers'

export const KvrKerCatalog = React.memo(() => {
  const [isOpen, hasSelectedKers] = useUnit([addWorksModal.$isOpen, $hasSelectedKers])

  if (!isOpen) return null

  return (
    <ModalLayoutV2 hasCloseButton={false} closeModal={noop}>
      <ModalContent>
        <CatalogHeader />
        <CatalogList />
        <Actions>
          <NewButton
            label="Отменить"
            buttonType="grey"
            width={200}
            size="S"
            dataTestId="add-work-modal-cancel-btn"
            onClick={addWorksModal.close}
          />
          <NewButton
            label="Добавить"
            width={200}
            size="S"
            isDisabled={!hasSelectedKers}
            dataTestId="add-work-modal-submit-btn"
            onClick={addWorksToLot}
          />
        </Actions>
      </ModalContent>
    </ModalLayoutV2>
  )
})

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;

  width: 919px;
  max-width: 100%;
  height: 815px;
  max-height: 100%;

  background-color: ${palette.white};
  border-radius: 12px;
  box-shadow: 0px 2px 10px 0px #ababab40;
`

const Actions = styled.div`
  ${TextMLight}
  display: flex;
  justify-content: end;
  gap: 16px;
  padding: 24px 32px;
`
