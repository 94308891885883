import React from 'react'
import styled, { css } from 'styled-components'
import { useUnit } from 'effector-react'

import {
  CellExpansionButton,
  TABLE_BOX_SHADOW,
  TABLE_HEADER_THICK_BORDER_STYLE,
  TableHeaderCell,
} from '@/ui'
import {
  $isGa,
  $morphologyDepth,
  $summaryTotalSums,
} from '@/features/estimate-correction/shared-model'

import {
  FIRST_GROUP_ID,
  SECOND_GROUP_ID,
  TABLE_BORDER_STYLE,
  THIRD_GROUP_ID,
} from '../../../model/const'
import { toggleCellVisibility } from '../../../model/private'
import { $hiddenCellGroups } from '../../../model/public'
import { TableGridWrapper } from '../parts'

type Props = {
  isScrolled: boolean
}

export const TableHeader = React.memo(({ isScrolled }: Props) => {
  const [hiddenCols, summaryTotals, isGa, morphologyDepth] = useUnit([
    $hiddenCellGroups,
    $summaryTotalSums,
    $isGa,
    $morphologyDepth,
  ])

  const hiddenFirstGroup = hiddenCols.includes(FIRST_GROUP_ID)
  const hiddenSecondGroup = hiddenCols.includes(SECOND_GROUP_ID)
  const hiddenThirdGroup = hiddenCols.includes(THIRD_GROUP_ID)

  const handleExpandButton = React.useCallback((group: number) => {
    toggleCellVisibility(group)
  }, [toggleCellVisibility])

  return (
    <Wrapper>
      <Row
        as="td"
        hiddenFirstGroup={hiddenFirstGroup}
        hiddenSecondGroup={hiddenSecondGroup}
        hiddenThirdGroup={hiddenThirdGroup}
        $morphologyDepth={morphologyDepth}
      >
        <StickyWrapper hasRightDivider={hiddenFirstGroup} hasShadow={isScrolled}>
          <TableHeaderCell label="Наименование услуг, работ и материалов" />
        </StickyWrapper>

        {!hiddenFirstGroup && (
          <>
            <TableHeaderCell isHiddenCell label="Примечание" />
            <TableHeaderCell isHiddenCell label="Статья оборотов" />
          </>
        )}

        {Boolean(morphologyDepth) && (
          <RelativeWrapper $colSpan={morphologyDepth}>
            <CellExpansionButton
              groupNumber={FIRST_GROUP_ID}
              isExpanded={!hiddenFirstGroup}
              expandButtonZIndex={isScrolled ? undefined : 10}
              onExpandButton={handleExpandButton}
            />
            <SplitCellWrapper>
              <TableHeaderCell label="Место работы" />
              <CellRow>
                <TableHeaderCell label="Корпус" />
                {morphologyDepth >= 2 && <TableHeaderCell label="Секция" />}
                {morphologyDepth >= 3 && <TableHeaderCell label="Этаж" />}
              </CellRow>
            </SplitCellWrapper>
          </RelativeWrapper>
        )}

        <TableHeaderCell
          label="Ед. изм"
          groupNumber={isGa ? undefined : FIRST_GROUP_ID}
          isExpanded={!hiddenFirstGroup}
          expandButtonZIndex={isScrolled ? undefined : 10}
          onExpandButton={handleExpandButton}
        />

        <TableHeaderCell label="Количество по смете" hasRightDivider={hiddenSecondGroup} />

        {!hiddenSecondGroup && (
          <>
            <TableHeaderCell isHiddenCell label="Принято" />
            <TableHeaderCell isHiddenCell label="На согласовании" />
            <TableHeaderCell isHiddenCell label="Остаток" />
          </>
        )}
        <TableHeaderCell
          label="Цена материалов"
          groupNumber={SECOND_GROUP_ID}
          onExpandButton={handleExpandButton}
          isExpanded={!hiddenSecondGroup}
        />
        <TableHeaderCell label="Цена работ" />
        <TableHeaderCell label="Общая цена" hasRightDivider={hiddenThirdGroup} />

        {!hiddenThirdGroup && (
          <>
            <TableHeaderCell
              isHiddenCell
              label="Стоимость материалов"
              totalCost={summaryTotals?.materialTotalCost}
            />
            <TableHeaderCell
              isHiddenCell
              label="Стоимость работ"
              totalCost={summaryTotals?.workTotalCost}
            />
          </>
        )}

        <TableHeaderCell
          label="Общая стоимость"
          totalCost={summaryTotals?.totalCost}
          groupNumber={THIRD_GROUP_ID}
          onExpandButton={handleExpandButton}
          isExpanded={!hiddenThirdGroup}
        />
        <TableHeaderCell label="Обоснование изменений" />
      </Row>
    </Wrapper>
  )
})

const Wrapper = styled.tr`
  display: block;
`

const Row = styled(TableGridWrapper)`
  top: 0;
  width: 100%;
  min-height: 66px;
  padding: 0;
`

const StickyWrapper = styled.div<{ hasShadow: boolean; hasRightDivider: boolean }>`
  position: sticky;
  left: 0;
  height: 100%;
  z-index: 5;
  border-right: ${TABLE_BORDER_STYLE};
  clip-path: inset(0px -16px 0px 0px);
  transition: 0.15s linear box-shadow;

  ${({ hasShadow }) =>
    hasShadow &&
    css`
      box-shadow: ${TABLE_BOX_SHADOW};
    `}

  ${({ hasRightDivider }) =>
    hasRightDivider &&
    css`
      border-right: ${TABLE_HEADER_THICK_BORDER_STYLE};
    `}
`

const RelativeWrapper = styled.div<{ $colSpan?: number }>`
  position: relative;
  grid-column: span ${({ $colSpan = 1 }) => $colSpan};
`

const SplitCellWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  & > * {
    height: 50%;
  }
`

const CellRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  border-top: ${TABLE_BORDER_STYLE};
  border-right: ${TABLE_BORDER_STYLE};
`
