import { GetGroupTreeParams, TreeItem } from '@/dal'

import { d } from './domain'

export const $selectedGroupId = d.store<TreeItem['id'] | null>(null)

export const openGroupsTree = d.event()
export const loadGroupTree = d.event<GetGroupTreeParams>()
export const resetTree = d.event<void>()
export const selectGroup = d.event<TreeItem['id'] | null>()
export const onSelectedGroupIdChanged = d.event<TreeItem['id'] | null>()
