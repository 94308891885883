import React from 'react'
import styled from 'styled-components'
import { useGate } from 'effector-react'

import { noop } from '@/lib/noop'
import {
  ContentContainer,
  FlexScrollbar,
  HeadXL,
  NewButton,
  palette,
  TextL,
  TextLLight,
  TextMLight,
} from '@/ui'
import { SupportFilters } from '@/features/support/filters'
import { SUPPORT_BOT_LINK, SupportAppealsGate } from '@/features/support/model'
import { initBotSupportApplication } from '@/features/support/model/private'
import { RequestHistory } from '@/features/support/request-history'

export const SupportPage = React.memo(() => {
  useGate(SupportAppealsGate)

  return (
    <FlexScrollbar>
      <ContentContainer>
        <Container>
          <Title>Обращения</Title>

          <Cols>
            <Col>
              <RequestHistory />
            </Col>

            <RightCol>
              <SupportFilters />

              <Contacts>
                <ContactsHeader>Техническая поддержка</ContactsHeader>
                <Phone>+7 (495) 660-15-55, доб.: 4444, нажмите 3</Phone>
                <ContactsSubtitle>Отвечаем в рабочие дни с 9:00 до 18:00</ContactsSubtitle>
                <Link
                  href={SUPPORT_BOT_LINK}
                  target="_blank"
                  data-testid="support-telegram-link"
                  onClick={() => initBotSupportApplication()}
                >
                  <NewButton
                    label="Поддержка"
                    prefixIcon="technical-support"
                    size="S"
                    dataTestId=""
                    isFill
                    onClick={noop}
                  />
                </Link>
              </Contacts>
            </RightCol>
          </Cols>
        </Container>
      </ContentContainer>
    </FlexScrollbar>
  )
})

const Title = styled.h2`
  ${HeadXL}
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-grow: 1;
`

const Cols = styled.div`
  display: flex;
  gap: 16px;
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
  min-width: 0;
`

const RightCol = styled(Col)`
  position: sticky;
  top: 20px;
  width: 332px;
  height: fit-content;
  flex-grow: initial;
  flex-shrink: 0;
`

const Contacts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  padding: 20px;
  border-radius: 12px;
  background-color: ${palette.white};
`

const ContactsHeader = styled.header`
  ${TextL}
  color: ${palette.grey100};
`

const Phone = styled.p`
  ${TextLLight}
  color: ${palette.grey70};
`

const ContactsSubtitle = styled.p`
  ${TextMLight}
  color: ${palette.grey70};
`

const Link = styled.a`
  text-decoration: none;
`
