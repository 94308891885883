import React from 'react'
import styled from 'styled-components'
import { useStoreMap, useUnit } from 'effector-react'

import { KerElement } from '@/dal'
import { Icon2, NewCheckbox, palette, PaletteColor, TextL, TextLLight, TextMLight } from '@/ui'
import { $units } from '@/features/estimate-lot/table/model'

import { $selectedKerIds, toggleSelectedKer } from '../../model/private'

type Props = {
  item: KerElement
}

export const KerItem = React.memo(({ item }: Props) => {
  const {
    id,
    name,
    unit,
    description,
    kvr_id: kvrId,
    is_ker_in_use: isKerInUse,
    hierarchy_code: code,
  } = item

  const [isExpanded, setIsExpanded] = React.useState(false)

  const [units] = useUnit([$units])

  const isSelected = useStoreMap({
    store: $selectedKerIds,
    keys: [id],
    fn: (ids, [id]) => ids.includes(`${kvrId}-${id}`),
  })

  const unitName = React.useMemo(() => {
    return units.find(({ id }) => id === unit)?.short_name ?? ''
  }, [unit, units])

  // на данный момент состав работ и результат приходят в одном текстовом поле
  const splitDescription = description?.split('Результат:')

  const handleSelect = () => toggleSelectedKer(`${kvrId}-${id}`)

  return (
    <Wrapper>
      <Col>
        <NameWrapper onClick={handleSelect}>
          <CheckboxWrapper>
            <NewCheckbox
              value={id}
              isChecked={isSelected || isKerInUse}
              isDisabled={isKerInUse}
              dataTestId={`toggle-ker-${id}-checkbox`}
              onChange={handleSelect}
            />
          </CheckboxWrapper>
          <Name $color={isSelected ? 'accent100' : isKerInUse ? 'grey60' : 'grey90'}>
            {code} {name}
          </Name>
        </NameWrapper>

        {(description || unit) && (
          <DescriptionToggler
            $color={isExpanded ? 'grey80' : 'grey70'}
            data-testid={`toggle-ker-${id}-description-btn`}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <p>Описание работы</p>
            <Icon2 icon={isExpanded ? 'arrowUp' : 'arrowDown'} size={12} />
          </DescriptionToggler>
        )}
      </Col>

      {isExpanded && (
        <DescriptionAndUnit>
          <div>
            {splitDescription?.[0] && (
              <Paragraph>
                <FirstWords>Состав работ:</FirstWords> {splitDescription[0]}
              </Paragraph>
            )}

            {splitDescription?.[1] && (
              <Paragraph>
                <FirstWords>Результат:</FirstWords> {splitDescription[1]}
              </Paragraph>
            )}
          </div>

          <Unit>{unitName}</Unit>
        </DescriptionAndUnit>
      )}
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`

const NameWrapper = styled.button`
  display: flex;
  align-items: start;
  gap: 8px;
  text-align: left;

  &:disabled {
    cursor: default;
  }
`

const CheckboxWrapper = styled.div`
  padding-top: 1.5px;
`

const DescriptionToggler = styled.button<{ $color: PaletteColor }>`
  ${TextMLight}
  display: flex;
  align-items: center;
  gap: 2px;

  margin-left: 28px;
  color: ${({ $color }) => palette[$color]};
  transition: 0.15s linear color;
`

const Name = styled.p<{ $color: PaletteColor }>`
  ${TextL}
  flex-grow: 1;
  color: ${({ $color }) => palette[$color]};
  text-align: left;
  transition: 0.15s linear color;
`

const DescriptionAndUnit = styled.div`
  display: flex;
  gap: 4px;

  width: 100%;
  padding-inline: 28px 24px;
  color: ${palette.grey70};
`

const FirstWords = styled.span`
  ${TextL}
  color: ${palette.grey70};
`

const Paragraph = styled.p`
  ${TextLLight}
  color: ${palette.grey70};
`

const Unit = styled.p`
  ${TextLLight}
  width: 100px;
  margin-left: auto;
  text-align: center;
  flex-shrink: 0;
`
