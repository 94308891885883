import { attach, combine } from 'effector'

import {
  LkpSection,
  JiraDocument,
  MyUsersInfo,
  addUserReqFx,
  blockUserReqFx,
  getJiraDocumentsReqFx,
  getMyUsersReqFx,
  postDocumentProblemReqFx,
  AppealAlias,
  DocumentAlias,
  postLkpSectionProblemAppealReqFx,
  resumeSupportAppealReqFx,
  ResumeAppealPayload,
} from '@/dal'
import { Option, DOCUMENT_STATUS_COLOR_MAP } from '@/ui'
import { createModal } from '@/features/factories'
import { createSingleEffect } from '@/lib/createSingleEffect'
import { getStatusName } from '@/lib/getStatusName'

import { d } from './domain'
import { blockUserForm } from './forms'
import { DOCUMENT_TYPES } from '../../model'
import {
  AppealTypeForSending,
  ContactsFormFields,
  SendFormWithContactsPayload,
  TechSupportAppealType,
} from './types'
import { sendAppealForResponsibleFx } from '../../new-appeal/model/private'

export const $selectedAppealType = d.store<AppealAlias | null>(null)
export const selectAppealType = d.event<AppealAlias>()
// отдельный event для клика пользователя из-за наличия копирования обращений, где не нужен reset
export const onSelectAppealTypeClicked = d.event<AppealAlias>()

export const selectDocumentProblem = d.event<DocumentAlias>()
export const selectLkpSectionProblem = d.event<LkpSection>()

export const $contactsStep = d.store<AppealTypeForSending | null>(null)

export const $techSupportAppealType = d.store<TechSupportAppealType | null>(null)
export const setTechSupportAppealType = d.event<TechSupportAppealType>()

export const resumeAppeal = d.event<ResumeAppealPayload>()

export const $documentTypeOptions = d.store<Option[]>(DOCUMENT_TYPES)

export const $documents = d.store<JiraDocument[] | null>(null)
export const $documentOptions = $documents.map(
  (items) =>
    items?.map((item) => {
      const statusName = getStatusName(item.status)

      return {
        label: item.name,
        id: item.id,
        subLabel: {
          text: statusName,
          color: DOCUMENT_STATUS_COLOR_MAP[item.status] || 'accent100',
        },
      } as Option
    }) ?? null,
)

export const $createdUsers = d.store<MyUsersInfo[] | null>(null)
export const $selectedUser = combine(
  {
    users: $createdUsers,
    id: blockUserForm.fields.userId.$value,
  },
  ({ users, id }) => users?.find((user) => user.id === id) ?? null,
)

export const $usersOptions = $createdUsers.map(
  (users) => users?.map((user) => ({ label: user.fio, id: user.id })) ?? null,
)

export const createAppealModal = createModal()

export const sendFormWithContacts = d.event<SendFormWithContactsPayload>()

export const sendAppealForResponsible = d.event<{ contacts: ContactsFormFields }>()
export const sendDocumentProblemAppeal = d.event<{ contacts: ContactsFormFields }>()
export const sendLkpSectionPromlemAppeal = d.event<{ contacts: ContactsFormFields }>()

export const { abortFx: abortGetJiraDocumentsFx, requestFx: getJiraDocumentsFx } =
  createSingleEffect(getJiraDocumentsReqFx)

export const createProblemsWithApprovalFx = attach({
  effect: postDocumentProblemReqFx,
})

export const createLkpSectionProblemAppealFx = attach({
  effect: postLkpSectionProblemAppealReqFx,
})

export const addUserFx = attach({
  effect: addUserReqFx,
})

export const { abortFx: abortGetMyUsersFx, requestFx: getMyUsersFx } =
  createSingleEffect(getMyUsersReqFx)

export const blockUserFx = attach({
  effect: blockUserReqFx,
})

export const resumeAppealFx = attach({
  effect: resumeSupportAppealReqFx,
})

export const $isAppealSending = combine(
  sendAppealForResponsibleFx.pending,
  createProblemsWithApprovalFx.pending,
  createLkpSectionProblemAppealFx.pending,
  addUserFx.pending,
  blockUserFx.pending,
  (...args) => args.some(Boolean),
)

// TODO было раньше, но флоу поменялся, можно уточнить позже
/* export const readFormQueryFx: Effect<void, FormQueryParams> = attach({
  effect: readQueryFx,
}) */
