import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { useTooltip } from '@/lib/hooks'

import { ToolTipV2 } from '../components/tooltip/ToolTipV2'
import { TEXT_SIZE_MAP } from '../const'
import { Icon2, IconName2 } from '../icons'
import { palette, PaletteColor } from '../palette'
import { TextSize } from '../types'
import { TextSLight } from '../typography'
import { DefaultButton } from './DefaultButton'

type ClickHandler = (e?: React.MouseEvent) => void;

type Props = {
  url?: string
  label: string
  iconSize?: number
  textStyle?: TextSize
  hasUnderline?: boolean
  hasUnderlineOnHover?: boolean
  onClick?: ClickHandler | (() => void);
  isDisabled?: boolean
  dataTestId: string
  isNewBlank?: boolean
  tooltip?: string
  tooltipWidth?: number
  tooltipWithArrow?: boolean
  isDownload?: boolean
  isInline?: boolean
  color?: PaletteColor
  gap?: number
} & ({
  isInline: true
  prefixIcon?: null
  postfixIcon?: null
} | {
  isInline?: false
  prefixIcon?: IconName2
  postfixIcon?: IconName2
})

export const NewTextButton = React.forwardRef(({
  label,
  url,
  isDisabled,
  iconSize = 12,
  prefixIcon,
  postfixIcon,
  textStyle = 'L',
  hasUnderline = false,
  hasUnderlineOnHover = false,
  isNewBlank,
  tooltip,
  isInline = false,
  dataTestId,
  tooltipWithArrow = true,
  color,
  gap = 4,
  isDownload,
  onClick,
}: Props, ref: React.ForwardedRef<HTMLButtonElement>) => {
  const {
    refs,
    arrowProps,
    ...tooltipParams
  } = useTooltip({ tooltipOffset: 4 })

  const target = isNewBlank ? '_blank' : '_self'

  const Tooltip = tooltip && (
    <ToolTipV2
      {...tooltipParams}
      arrowProps={tooltipWithArrow ? arrowProps : undefined}
      refs={refs}
    >
      <TooltipText>
        {tooltip}
      </TooltipText>
    </ToolTipV2>
  )

  const children = (
    <>
      <Wrapper
        isDisabled={Boolean(isDisabled)}
        hasUnderlineOnHover={hasUnderlineOnHover}
        isInline={isInline}
        color={color}
        gap={gap}
        ref={refs.setReference}
      >
        {prefixIcon && (
          <Icon2
            icon={prefixIcon}
            size={iconSize}
          />
        )}

        <Text textSize={textStyle} isInline={isInline} hasUnderline={hasUnderline}>
          {label}
        </Text>

        {postfixIcon && (
          <Icon2
            icon={postfixIcon}
            size={iconSize}
          />
        )}
      </Wrapper>
      {Tooltip}
    </>
  )

  if (url) {
    if (isDisabled) return children

    if (isDownload) {
      return (
        <a href={url} target={target} onClick={onClick} download>
          {children}
        </a>
      )
    }

    return (
      <StyledLink to={url} target={target} onClick={onClick}>
        {children}
      </StyledLink>
    )
  }

  return (
    <Button
      ref={ref}
      type="button"
      disabled={Boolean(isDisabled)}
      onClick={onClick}
      isInline={isInline}
      data-testid={dataTestId}
    >
      {children}
    </Button>
  )
})

const StyledLink = styled(Link)`
  width: fit-content;
  text-decoration: none;
`

const Text = styled.p<{ isInline: boolean, textSize: TextSize, hasUnderline: boolean }>`
  ${({ textSize }) => TEXT_SIZE_MAP[textSize]}
  color: inherit;

  ${({ isInline }) => isInline && css`
    display: inline;
  `}

  ${({ hasUnderline }) => hasUnderline && css`
    text-decoration: underline;
  `}
`

const Wrapper = styled.div<{
  isInline: boolean
  isDisabled: boolean
  hasUnderlineOnHover: boolean
  color?: PaletteColor
  gap: number
}>`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => gap}px;
  transition: color 0.15s linear;
  color: ${palette.accent100};  
  width: 100%;

  &:hover {
    color: ${palette.accent80};
  }

  &:active {
    color: ${palette.accent70};
  }

  ${({ isDisabled, hasUnderlineOnHover }) => !isDisabled && hasUnderlineOnHover && css`
    &:hover ${Text} {
      text-decoration: underline;
    }
  `}

  ${({ isDisabled }) => isDisabled && css`
    color: ${palette.accent50};
    cursor: not-allowed;

    &:hover {
      color: ${palette.accent50};
    }
  `}

  ${({ isInline }) => isInline && css`
    display: inline;
  `}

  ${({ color }) => color && css`
    color: ${palette[color]} !important;
  `}
`

const Button = styled(DefaultButton)<{ isInline: boolean }>`
  display: flex;
  gap: 2px;
  width: fit-content;
  color: inherit;
  text-align: left;

  ${({ isInline }) => isInline && css`
    display: inline;
  `}
`

const TooltipText = styled.div<{ width?: number }>`
  ${TextSLight}
  color: ${palette.white};
  text-align: center;

  ${({ width }) => width && css`
    width: ${width}px;
  `}
`
