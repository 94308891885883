import React from 'react'
import styled from 'styled-components'

import { NewButton } from '../buttons'
import { ModalLayoutV2 } from '../layouts'
import { HeadXL, TextLLight } from '../typography'

type Props = {
  title: string
  body: string | React.ReactElement
  confirmLabel: string
  cancelLabel: string
  dataTestId: string
  isOpen: boolean
  isPending?: boolean
  areButtonsFill?: boolean
  closeModal: () => void
  onConfirm: () => void
  onCancel: () => void
}

export const ConfirmModalLayout = ({
  title,
  body,
  confirmLabel,
  cancelLabel,
  dataTestId,
  isOpen,
  isPending,
  areButtonsFill = true,
  closeModal,
  onConfirm,
  onCancel,
}: Props) => {
  if (!isOpen) return null
  return (
    <ModalLayoutV2 dataTestId={dataTestId} canClose={!isPending} closeModal={closeModal}>
      <Wrapper>
        <ContentWrapper>
          <Title>{title}</Title>
          <Body>{body}</Body>
        </ContentWrapper>

        <Buttons>
          <NewButton
            dataTestId={`${dataTestId}-confirm`}
            label={confirmLabel}
            onClick={onConfirm}
            isPending={isPending}
            isFill={areButtonsFill}
          />
          <NewButton
            dataTestId={`${dataTestId}-reject`}
            label={cancelLabel}
            onClick={onCancel}
            buttonType="grey"
            isDisabled={isPending}
            isFill={areButtonsFill}
          />
        </Buttons>
      </Wrapper>
    </ModalLayoutV2>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px;
  max-width: 510px;
  width: 100%;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-right: 16px;
`

const Title = styled.h5`
  ${HeadXL}
`

const Body = styled.p`
  ${TextLLight}
`

const Buttons = styled.div`
  display: flex;
  gap: 16px;
`
