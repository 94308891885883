import { createForm } from 'effector-forms'

import { EstimateLot } from '@/dal'
import { rules } from '@/lib/form-validators'

export const lotInfoForm = createForm({
  validateOn: ['change'],
  fields: {
    is_typical_form_of_contract: {
      init: false,
    },
    advance_type: {
      init: null as EstimateLot['advance_type_id'] | null,
      rules: [rules.required()],
    },
    percent_advance: {
      init: '',
    },
    type_security: {
      init: null as EstimateLot['type_of_security_id'] | null,
      rules: [rules.required()],
    },
    percent_warranty_retention: {
      init: '',
    },
    comment: {
      init: '',
    },
    files: {
      init: [] as File[],
    },
  },
})
