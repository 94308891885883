import React from 'react'
import { useUnit } from 'effector-react'

import { ContractDirection, DocumentAlias } from '@/dal'
import { ContractDirectionLabel, DocumentHeader, GroupTreeOpeningButton } from '@/ui'
import { $gaTableItemsCount } from '@/features/act/acting-group/model/private'
import { $actTotalWorks } from '@/features/act/works/model/private'
import { $contractDirections } from '@/features/administrative/contract-list/model'
import { RouterPaths } from '@/features/app/model'
import { usePendingDocument } from '@/features/document-manager/hooks'
import { $selectedGroupId, openGroupsTree, selectGroup } from '@/features/group-tree/model'

import { $documentId, $isEditBlocking, $isGaAct, ActTab } from '../../../model'
import { ACT_PIR_TABS, ACT_TABS } from '../../../model/const'
import {
  $headDocumentInfo,
  cancelActApproveFx,
  onCopy,
  onDeleteButton,
  onSendToApprove,
  openCancelApprovingModal,
  sendActToApproveFx,
} from '../../../model/header.private'
import { $activeTabId, changeActiveTab } from '../../../model/header.public'

export const ActHeader = React.memo(() => {
  const [info, id] = useUnit([$headDocumentInfo, $documentId])
  const isPendingApprove = useUnit(sendActToApproveFx.pending)
  const isCancelPending = useUnit(cancelActApproveFx.pending)
  const isBlocked = useUnit($isEditBlocking)
  const activeTabId = useUnit($activeTabId)
  const directions = useUnit($contractDirections)
  const workCount = useUnit($actTotalWorks)
  const gaTableItemsCount = useUnit($gaTableItemsCount)
  const isGa = useUnit($isGaAct)
  const selectedGroupId = useUnit($selectedGroupId)

  const { isDeleting } = usePendingDocument({
    id,
    type: DocumentAlias.ACT,
  })

  const ActTypeLabel = React.useCallback(() => {
    if (!info?.actType || !directions) return null

    const subtype = info.isGa ? 'Группы актирования' : undefined

    return (
      <ContractDirectionLabel
        type={info.actType}
        subtype={subtype}
        directions={directions}
        size="M"
      />
    )
  }, [info, directions])

  const isPIR = info?.actType === ContractDirection.PDRD

  const tabs = React.useMemo(() => {
    const tabItems = isPIR ? ACT_PIR_TABS : ACT_TABS
    const count = isGa ? gaTableItemsCount : workCount
    return tabItems.map((item) => (item.id === ActTab.Works ? { ...item, count } : item))
  }, [isPIR, isGa, workCount, gaTableItemsCount])

  if (!info) return null

  const canDelete = info.feature.can_delete
  const canCancel = info.feature.can_cancel_approving
  const showApproveButton = info.feature.can_approve || info.feature.can_send_to_approving
  const approveTitle = info.feature.can_send_to_approving
    ? 'Отправить на согласование'
    : 'Согласовать'
  const canCopy = info.feature.can_copy

  const groupTree = (
    <GroupTreeOpeningButton
      selectedGroupCount={selectedGroupId ? 1 : 0}
      onClick={openGroupsTree}
      onReset={() => selectGroup(null)}
    />
  )

  return (
    <DocumentHeader
      documentName={info.name}
      lastUpdate={info.lastUpdate}
      link={RouterPaths.Administrative}
      canCancelApproving={canCancel}
      canCopy={canCopy}
      canDelete={canDelete}
      approveTitle={approveTitle}
      showApproveButton={showApproveButton}
      isPendingApprove={isPendingApprove}
      isCancelPending={isCancelPending}
      isPendingDelete={isDeleting}
      isBlocked={isBlocked}
      unlockDate={info.rejectTime}
      onApproveButton={onSendToApprove}
      onCancelApprove={openCancelApprovingModal}
      onDeleteButton={onDeleteButton}
      docOnApproving={info.feature.on_approving}
      onCopy={onCopy}
      downloadInstructionText="Как подписать документы?"
      downloadInstructionURL={info.instructionLink}
      tabs={tabs}
      activeTabId={activeTabId}
      docSubTypeSlot={<ActTypeLabel />}
      rightBottomSlot={!isGa && activeTabId === ActTab.Works && groupTree}
      onChangeTab={changeActiveTab}
    />
  )
})
