import React, { useMemo } from 'react'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import { FileDTO } from '@/dal'

import { NewFileItem } from '../NewFileItem'
import { DropArea } from './DropArea'

type Props = {
  value: File[]
  height?: number
  maxFiles?: number
  isDisabled?: boolean
  isLoading?: boolean
  isMultiple?: boolean
  uploadedFiles?: FileDTO[] | null
  disableRemoveFile?: boolean
  dataTestId: string
  listPosition?: 'top' | 'bottom'
  showInfo?: boolean
  colCount?: number
  onChange: (files: File[]) => File[]
  markUploadedFileToDelete?: (id: FileDTO['id']) => void
}

export const DropAreaWithList = ({
  value,
  height,
  isDisabled,
  isLoading,
  isMultiple,
  maxFiles,
  dataTestId,
  disableRemoveFile,
  uploadedFiles,
  listPosition = 'bottom',
  showInfo,
  colCount = 2,
  onChange,
  markUploadedFileToDelete,
}: Props) => {
  // полукостыльный способ избежать проблем с файлами, имеющими одинаковое название
  const filesWithId = useMemo(() => value.map((file) => ({ file, id: uuidv4() })), [value])

  const handleDeleteFile = (uuid: string) => {
    const files = filesWithId.filter(({ id }) => uuid !== id)
    onChange(files.map(({ file }) => file))
  }

  const list = Boolean((uploadedFiles?.length || 0) + filesWithId.length) && (
    <List $colCount={colCount}>
      {uploadedFiles?.map((file) => (
        <NewFileItem
          key={file.id}
          name={file.name}
          url={file.url}
          onDelete={!disableRemoveFile ? () => markUploadedFileToDelete?.(file.id) : undefined}
        />
      ))}
      {filesWithId.map(({ file, id }) => (
        <NewFileItem
          key={id}
          name={file.name}
          onDelete={!disableRemoveFile ? () => handleDeleteFile(id) : undefined}
        />
      ))}
    </List>
  )
  return (
    <Wrapper>
      {listPosition === 'top' && list}
      <DropArea
        value={value}
        height={height}
        maxFiles={maxFiles}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isMultiple={isMultiple}
        dataTestId={dataTestId}
        showInfo={showInfo}
        onChange={onChange}
      />
      {listPosition === 'bottom' && list}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const List = styled.div<{ $colCount: number }>`
  display: grid;
  gap: 2px 20px;
  grid-template-columns: ${({ $colCount }) => `repeat(${$colCount}, minmax(0, 1fr))`};
`
