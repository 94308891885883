import React from 'react'

import { DocumentAlias, DocumentStatuses } from '@/dal'
import { SelectItemButton } from '@/ui'

import { getDocumentFiles } from '../../model/private'
import { DocumentFilesModal } from '../containers'

type Props = {
  id: number
  docType: DocumentAlias
  buttonLabel?: string
  /** @deprecated */
  downloadLink?: string
  /** @deprecated */
  status?: DocumentStatuses
  /** @deprecated */
  onClick?: () => void
}

export const DocumentFilesButton = React.memo(
  ({ id, docType, buttonLabel = 'Документы для согласования' }: Props) => {
    return (
      <>
        <SelectItemButton
          text={buttonLabel}
          icon="download"
          buttonStyle="secondary"
          hasBorder={false}
          dataTestId="open-doc-files-modal-btn"
          onClick={() => getDocumentFiles({ id, docType })}
        />
        <DocumentFilesModal />
      </>
    )
  },
)
