import { css } from 'styled-components'

import { palette } from '@/ui'

export const cardStyle = css`
  display: flex;
  flex-direction: column;
  gap: 10px;

  padding: 27px;
  border: 1px solid ${palette.grey40};

  border-radius: 12px;
  transition: 0.15s linear;
  transition-property: background-color, border-color;

  &:hover {
    background-color: ${palette.grey10};
    border-color: ${palette.accent80};
  }
`
