import React, { Key } from 'react'
import { Placement } from '@floating-ui/react'

import { usePopup } from '../../hooks'
import { MultiSelectList } from './MultiSelectList'

type Props<T> = {
  onAcceptClick: (items: T[]) => void
  onClear?: () => void
  triggerComponent: (props: { isOpen: boolean }) => React.ReactNode
  isDisabled?: boolean
  items: {
    id: T
    label: string
  }[]
  selectedIds: T[]
  testId: string
  placement?: Placement
  maxHeightAsComponentHeight?: boolean
  hasSelectAllOption?: boolean
  width?: number
  listMaxHeight?: string
  additionalSlot?: React.ReactNode
  positionRef?: React.RefObject<HTMLElement>
}

type SelectAllId = '__SELECT_ALL'

const SELECT_ALL_ID: SelectAllId = '__SELECT_ALL'
const SELECT_ALL_OPTION = {
  id: SELECT_ALL_ID,
  label: 'Выбрать все',
}

export const MultiSelectPopup = React.forwardRef(
  <T extends Key>(
    {
      onAcceptClick,
      onClear,
      triggerComponent,
      isDisabled,
      testId,
      items,
      selectedIds,
      placement,
      maxHeightAsComponentHeight = false,
      hasSelectAllOption = false,
      width = 300,
      listMaxHeight,
      additionalSlot,
      positionRef,
    }: Props<T>,
    ref: React.ForwardedRef<HTMLElement>,
  ) => {
    const [selectedItems, setSelectedItems] = React.useState<T[]>([])

    const hasSelectedAllOptions = items.length === selectedItems.length
    const mappedOptions = hasSelectAllOption ? [SELECT_ALL_OPTION, ...items] : items
    const mappedSelectedItems = hasSelectedAllOptions
      ? [SELECT_ALL_ID, ...selectedItems]
      : selectedItems

    const handleToggle = (value: T | SelectAllId) => {
      if (value === SELECT_ALL_ID) {
        if (hasSelectedAllOptions) {
          setSelectedItems([])
        } else {
          setSelectedItems(items.map(({ id }) => id))
        }
        return
      }
      setSelectedItems((items) =>
        items.includes(value)
          ? items.filter((currValue) => currValue !== value)
          : [...items, value],
      )
    }

    const handleClose = () => {
      setSelectedItems(selectedIds)
    }

    const {
      floatingStyles,
      refs,
      isOpen,
      getFloatingProps,
      close: closeDropDown,
      getReferenceProps,
    } = usePopup({
      placement,
      maxHeightAsComponentHeight,
      hasShift: false,
      isDisable: isDisabled,
      onClose: handleClose,
    })

    const handleSubmit = () => {
      closeDropDown()
      onAcceptClick(selectedItems)
    }

    const handleClear = () => {
      onClear?.()
      closeDropDown()
      onAcceptClick([])
    }

    React.useEffect(() => {
      setSelectedItems(selectedIds)
    }, [selectedIds])

    React.useEffect(() => {
      if (positionRef?.current) {
        refs.setPositionReference(positionRef.current)
      }
    }, [positionRef?.current])

    return (
      <>
        <div ref={refs.setReference} {...getReferenceProps()} data-testid={`${testId}-open`}>
          {triggerComponent({ isOpen })}
        </div>
        {isOpen && (
          <MultiSelectList
            ref={ref}
            refs={refs}
            floatingStyles={floatingStyles}
            getFloatingProps={getFloatingProps}
            options={mappedOptions}
            testId={testId}
            value={mappedSelectedItems}
            width={width}
            listMaxHeight={listMaxHeight}
            additionalSlot={additionalSlot}
            onSubmit={handleSubmit}
            onClear={handleClear}
            onToggle={handleToggle}
          />
        )}
      </>
    )
  },
)
