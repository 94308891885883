import React from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { useUnit } from 'effector-react'

import { numberWithSpaces } from '@/lib/number-represent'
import {
  DocumentStatusLabel,
  HeadXL,
  ModalLayoutV2,
  NewTextButton,
  palette,
  TextL,
  TextLLight,
} from '@/ui'
import { RouterPaths } from '@/features/app/model'

import { $documentInfo } from '../../../model'
import {
  $isCompensationsModalOpen,
  closeCompensationsModal,
} from '../../../model/info.private'

export const CompensationsModal = React.memo(() => {
  const [isOpen, info] = useUnit([
    $isCompensationsModalOpen,
    $documentInfo,
  ])

  if (!isOpen || !info?.compensation_documents_info) return null

  const { compensation_documents_info: { items, total_sum } } = info

  return (
    <ModalLayoutV2 closeModal={closeCompensationsModal}>
      <Wrapper>
        <HeaderBlock>
          <Header>
            Компенсация затрат Застройщика по электроэнергии
          </Header>
          <Description>
            Здесь отображаются документы с размером удержания
          </Description>
        </HeaderBlock>

        <RowContainer>
          <Row hasTopBorder>
            <HeaderCell>Документ</HeaderCell>
            <HeaderCell>Период потребления</HeaderCell>
            <HeaderCell>Статус</HeaderCell>
            <HeaderCell align="right">Сумма</HeaderCell>
            <HeaderCell align="right">Подписанные документы</HeaderCell>
          </Row>

          <Scrollbars autoHeight autoHeightMin={0} autoHeightMax="60dvh">
            {items.map((item) => (
              <Row key={item.id}>
                <Cell>
                  <Link to={`${RouterPaths.Compensation}/${item.id}`} target="_blank">
                    <NewTextButton
                      label={item.name}
                      textStyle="LL"
                      dataTestId={`act-compensation-${item.id}-link-btn`}
                      hasUnderline
                    />
                  </Link>
                </Cell>

                <Cell>{item.consumption_period?.toLowerCase() ?? ''}</Cell>

                <Cell>
                  <DocumentStatusLabel status={item.status} />
                </Cell>

                <Cell align="right">
                  {numberWithSpaces(item.sum, { dot: ',' })}
                </Cell>

                <Cell>
                  <a href={item.signed_docs_link} download>
                    <NewTextButton
                      label="Скачать"
                      prefixIcon="download"
                      textStyle="LL"
                      iconSize={16}
                      dataTestId="download-signed-docs-btn"
                      hasUnderline
                    />
                  </a>
                </Cell>
              </Row>
            ))}
          </Scrollbars>
          <Row>
            <Cell align="left" colSpan={3} isLight={false} isUppercase>
              Итого
            </Cell>
            <Cell align="right" isLight={false}>
              {numberWithSpaces(total_sum, { dot: ',' })}
            </Cell>
            <Cell />
          </Row>
        </RowContainer>
      </Wrapper>
    </ModalLayoutV2>
  )
})

const BORDER_STYLE = `1px solid ${palette.grey30}`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 1080px;
  max-width: 100%;
  padding: 32px;
`

const HeaderBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Header = styled.header`
  ${HeadXL}
`

const Description = styled.p`
  ${TextLLight}
`

const RowContainer = styled.div`
  overflow: hidden;
  border-radius: 4px;
`

const Row = styled.div<{ hasTopBorder?: boolean }>`
  display: grid;
  grid-template-columns: minmax(0, 350fr) minmax(0, 180fr)
    minmax(0, 120fr) minmax(0, 140fr) minmax(0, 230fr);
  border-bottom: ${BORDER_STYLE};

  ${({ hasTopBorder }) => hasTopBorder && css`
    border-top: ${BORDER_STYLE};
  `}
`

type CellProps = {
  align?: 'left' | 'center' | 'right'
  isLight?: boolean
  colSpan?: number
  isUppercase?: boolean
}

const Cell = styled.div<CellProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 16px;
  border-right: ${BORDER_STYLE};
  text-align: center;

  &:first-child {
    border-left: ${BORDER_STYLE};
  }

  ${({ align }) => align === 'left' && css`
    justify-content: flex-start;
    text-align: left;
  `}

  ${({ align }) => align === 'right' && css`
    justify-content: flex-end;
    text-align: right;
  `}

  ${({ colSpan }) => colSpan && css`
    grid-column: span ${colSpan};
  `}

  ${({ isLight = true }) => css`
    ${isLight ? TextLLight : TextL}
  `}

  ${({ isUppercase }) => isUppercase && css`
    text-transform: uppercase;
  `}
`

const HeaderCell = styled(Cell)`
  ${TextL}
  background-color: ${palette.grey10};
`
