import React from 'react'
import styled from 'styled-components'

import { Icon2, NewCheckbox, Typography } from '@/ui'

import { SectionGroupOption } from '../../model'
import { TagWrapper } from './TagWrapper'

type Props = {
  section: SectionGroupOption
  onChangeSection: (id: number, val: boolean) => void
  onChangeFloor: (id: number, val: boolean) => void
}

export const SectionSelectGroup = React.memo(
  ({ section, onChangeSection, onChangeFloor }: Props) => {
    const [isExpanded, setIsExpanded] = React.useState(false)

    const { id, label, floors, isSelected, isIndeterminate } = section

    return (
      <Wrapper>
        <Container>
          <OverlayButton type="button" onClick={() => setIsExpanded(!isExpanded)} />
          <Icon2 icon={isExpanded ? 'arrow-up-lg' : 'arrow-down-lg'} color="grey90" size={24} />
          <GroupWrapper>
            <NewCheckbox
              value={id}
              isChecked={isSelected}
              isIndeterminate={isIndeterminate}
              onChange={() => onChangeSection(id, !isSelected)}
            />
            <Typography>{label}</Typography>
          </GroupWrapper>
        </Container>

        {isExpanded && (
          <Children>
            {floors.map(({ id: floorId, label, isSelected }) => (
              <TagWrapper
                key={floorId}
                as="button"
                $variant={isSelected ? 'yellow' : 'grey'}
                onClick={() => onChangeFloor(floorId, !isSelected)}
              >
                {label}
              </TagWrapper>
            ))}
          </Children>
        )}
      </Wrapper>
    )
  },
)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Container = styled.div`
  position: relative;

  display: flex;
  align-items: center;

  padding-inline: 8px;
`

const OverlayButton = styled.button`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
`

const GroupWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  padding: 8px;
`

const Children = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding-left: 40px;
`
