import React from 'react'
import styled from 'styled-components'
import { combine } from 'effector'
import { useUnit } from 'effector-react'

import { LoaderOverlay } from '@/ui'

import { $catalogItems, getCatalogRootFx, getKvrItemsFx } from '../../model/private'
import { KvrItem } from './KvrItem'

const $isLoading = combine([getCatalogRootFx.pending, getKvrItemsFx.pending], ([a, b]) => a || b)

export const CatalogList = React.memo(() => {
  const [items, isLoading] = useUnit([$catalogItems, $isLoading])

  if (!items) return null

  return (
    <Wrapper>
      <List>
        {items?.map(({ depth, ...item }) => (
          <KvrItem key={item.id} item={item} depth={depth} />
        ))}
      </List>

      {isLoading && <LoaderOverlay isShown />}
    </Wrapper>
  )
})

const Wrapper = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  flex-grow: 1;
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;

  height: 0;
  padding-inline: 24px 20px;
  margin-right: 4px;

  flex-grow: 1;
  overflow: auto;
`
