import { TreeType } from './types'

type Id = string | number

export type GetFlatTreeParams<T extends { id: Id }> = {
  isTree: boolean
  root: T[] | null
  tree: TreeType<T>
}

export const getFlatTree = <T extends { id: Id }>({ root, tree, isTree }: GetFlatTreeParams<T>) => {
  if (!root || !isTree) return root
  const result: T[] = []

  const mapFn = (item: T) => {
    result.push(item)

    if (!tree[item.id]) return
    const children = tree[item.id]
    children.forEach(mapFn)
  }

  root.forEach(mapFn)
  return result
}

export const getFlatTreeWithDepth = <T extends { id: Id }>({
  root,
  tree,
  isTree,
}: GetFlatTreeParams<T>): (T & { depth: number })[] | null => {
  if (!root) {
    return null
  }
  if (!isTree) {
    return root.map((item) => ({ ...item, depth: 0 }))
  }
  const result: (T & { depth: number })[] = []

  const mapFn = (item: T, depth: number = 0) => {
    result.push({ ...item, depth })

    if (!tree[item.id]) return
    const children = tree[item.id]
    children.forEach((item) => mapFn(item, depth + 1))
  }

  root.forEach((item) => mapFn(item, 0))
  return result
}
