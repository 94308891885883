import React from 'react'
import styled, { css } from 'styled-components'

import { DropDownInputList } from './DropDownInputList'
import { NewInput } from './NewInput'
import { ActionOption, InputSize, Option, OptionSize } from './types'
import { useDropDownInput } from './useDropDownInput'

export type SelectProps = {
  options: Option[] | null
  size?: InputSize
  optionSize?: OptionSize
  actionOptions?: ActionOption[] | null
  hasSearch?: boolean
  hasClear?: boolean
  placeholder?: string
  dataTestId: string
  error?: string | null
  isDisabled?: boolean
  isLoading?: boolean
  defaultOptionText?: string
  isInvalid?: boolean
  isFill?: boolean
  minWidth?: string
  onToggle?: (val: boolean) => void
} & (
  | {
      isMultiple?: false
      value: Option['id'] | null
      onChange: (id: Option['id'] | null) => void
    }
  | {
      isMultiple: true
      value: Option['id'][]
      onChange: (id: Option['id'][]) => void
    }
)

export const NewDropDownInput = ({
  options,
  size,
  optionSize,
  value,
  hasSearch,
  hasClear = true,
  placeholder,
  dataTestId,
  error,
  isDisabled,
  isLoading,
  actionOptions,
  defaultOptionText,
  isInvalid,
  isMultiple,
  isFill,
  minWidth,
  onChange,
  onToggle,
}: SelectProps) => {
  const {
    activeLabel,
    floatingStyles,
    getFloatingProps,
    getReferenceProps,
    handleClear,
    handleInputChange,
    handleOptionClick,
    closeDropDown,
    isFocus,
    isOpen,
    refs,
    sortedItems,
    value: searchValue,
    selectedOptions,
  } = useDropDownInput({
    onToggle,
    options,
    onOptionClick: onChange,
    selectedId: value,
    hasSearch,
    offsetNumber: 4,
    defaultOptionText,
    minWidth: minWidth ?? '300px',
    isMultiple,
  })

  return (
    <>
      <Wrapper $isFullWidth={isFill}>
        <OverlayButton ref={refs.setReference} type="button" {...getReferenceProps()} />
        <NewInput
          dataTestId={dataTestId}
          value={isOpen && hasSearch ? searchValue : activeLabel}
          placeholder={placeholder}
          readOnly={!hasSearch}
          error={isOpen ? null : error}
          disabled={isDisabled}
          size={size}
          type="text"
          isSelectAfterClear={isOpen}
          isSelect={isFocus}
          postfixIcon={isOpen ? 'arrowUp' : 'arrowDown'}
          isInvalid={isInvalid || Boolean(error)}
          onChange={handleInputChange}
          onClear={hasClear ? handleClear : undefined}
        />
      </Wrapper>

      <DropDownInputList
        isDisabled={Boolean(isDisabled)}
        isLoading={Boolean(isLoading)}
        isOpen={isOpen}
        items={sortedItems}
        searchValue={searchValue}
        size={optionSize}
        dataTestId={dataTestId}
        selectedId={isMultiple ? selectedOptions : value}
        actionOptions={actionOptions}
        isMultiple={isMultiple}
        handleOptionClick={handleOptionClick}
        floatingStyles={floatingStyles}
        getFloatingProps={getFloatingProps}
        refs={refs}
        onCloseDropDown={closeDropDown}
      />
    </>
  )
}

const Wrapper = styled.div<{ $isFullWidth?: boolean }>`
  position: relative;

  ${({ $isFullWidth }) =>
    $isFullWidth &&
    css`
      width: 100%;
    `}
`

const OverlayButton = styled.button`
  position: absolute;
  width: 100%;
  height: 100%;
`
