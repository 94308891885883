import { sample } from 'effector'

import { EstimateCorrectionGate } from '../../shared-model'
import { mapAndSortMorphologyList } from '../../shared-model/helpers'
import { getKorDocGaFiltersFx } from '../../shared-model/private'
import {
  $acceptedActingGroupIds,
  $acceptedTypes,
  $actingGroupOptions,
  $morphologyFilterObjects,
  $searchInputValue,
  acceptMorphologyFilters,
  changeSearch,
  clearSearch,
  setAcceptedActingGroupIds,
  setAcceptedTypes,
  updateAcceptedMorphologyFiltersFx,
} from './filters.private'
import { resetFilters, setRowType } from './filters.public'
import { recalculateMorphologyFilter } from './helpers/filter-helpers'
import { TableGate } from './private'
import { MorphologyFilterObject } from './types.filters'

$searchInputValue.on(changeSearch, (_, val) => val).reset(clearSearch)

$acceptedTypes.on([setAcceptedTypes, setRowType], (_, types) => types).reset(resetFilters)

$actingGroupOptions
  .on(getKorDocGaFiltersFx.doneData, (_, { act_groups }) => mapAndSortMorphologyList(act_groups))
  .reset(EstimateCorrectionGate.state.updates)

$acceptedActingGroupIds.on(setAcceptedActingGroupIds, (_, ids) => ids).reset(resetFilters)

const MORPHOLOGY_ORDER = ['buildings', 'sections', 'floors'] as const

$morphologyFilterObjects
  .on(getKorDocGaFiltersFx.doneData, (data, response) => ({
    buildings: recalculateMorphologyFilter({
      ...data.buildings,
      allOptions: mapAndSortMorphologyList(response.buildings),
    }),
    sections: recalculateMorphologyFilter({
      ...data.sections,
      allOptions: mapAndSortMorphologyList(response.sections),
    }),
    floors: recalculateMorphologyFilter({
      ...data.floors,
      allOptions: mapAndSortMorphologyList(response.floors),
    }),
  }))
  .on(updateAcceptedMorphologyFiltersFx.doneData, (_, data) => data)
  .reset(EstimateCorrectionGate.state.updates)

updateAcceptedMorphologyFiltersFx.use(
  ({ filters: data, payload: { type: payloadType, names: payloadSelectedNames } }) => {
    const updatedData = { ...data }
    const startIndex = MORPHOLOGY_ORDER.findIndex(
      (morphologyType) => morphologyType === payloadType,
    )

    for (let i = startIndex; i < MORPHOLOGY_ORDER.length; i++) {
      const currentType = MORPHOLOGY_ORDER[i]
      const parentType = MORPHOLOGY_ORDER[i - 1]
      const parentTypeFilters = updatedData[parentType] as MorphologyFilterObject | undefined

      updatedData[currentType] = recalculateMorphologyFilter(
        {
          ...data[currentType],
          selectedNames:
            currentType === payloadType ? payloadSelectedNames : data[currentType].selectedNames,
        },
        parentTypeFilters?.selectedIds,
      )
    }

    return updatedData
  },
)

sample({
  clock: acceptMorphologyFilters,
  source: $morphologyFilterObjects,
  filter: Boolean,
  fn: (filters, payload) => ({
    filters,
    payload,
  }),
  target: updateAcceptedMorphologyFiltersFx,
})

sample({
  clock: resetFilters,
  fn: () => ({
    type: 'buildings' as const,
    names: [],
  }),
  target: acceptMorphologyFilters,
})

sample({
  clock: [TableGate.close, EstimateCorrectionGate.state.updates],
  target: [resetFilters, clearSearch],
})
