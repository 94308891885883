import { attach } from 'effector'

import {
  AdditionalRequestModalInfo,
  EstimateLotFiltersResponse,
  EstimateLotListItem,
  EstimateLotsFilterPayload,
  getEstimateLotFiltersReqFx,
  getEstimateLotListReqFx,
} from '@/dal'
import { createSingleEffect } from '@/lib/createSingleEffect'
import { getStatusName } from '@/lib/getStatusName'
import { createValue } from '@/features/factories'
import { createPagination } from '@/features/shared/createPagination'
import { createFilters } from '@/features/shared/filterFactory/createFilters'

import { d } from './domain'
import { DocHeaderInfo } from './types'

export const $estimateLotList = d.store<EstimateLotListItem[] | null>(null)

export const $headerInfo = d.store<DocHeaderInfo | null>(null)
export const setHeaderInfo = d.event<DocHeaderInfo>()

export const $totalDocCount = $headerInfo.map((info) => info?.all_estimate_lots_count ?? 0)

export const search = createValue('')

export const loadMore = d.event()
export const onAdditionalButtonClicked = d.event<AdditionalRequestModalInfo>()

export const $availableFilters = d.store<EstimateLotFiltersResponse | null>(null)

export const $statuses = $availableFilters.map((info) =>
  (info?.available_statuses ?? []).map((id) => ({
    id,
    label: getStatusName(id),
  })),
)

export const $projects = $availableFilters.map((info) =>
  (info?.available_projects ?? []).map((label) => ({
    label,
    id: label,
  })),
)

export const { $filters, removeFilter, setFilter, clearFilter } = createFilters<
  Omit<EstimateLotsFilterPayload, 'limit' | 'offset'>
>({}, d)

export const { requestFx: getEstimateLotListFx, abortFx } =
  createSingleEffect(getEstimateLotListReqFx)

export const {
  $hasMore,
  initEffect: initEstimateLotListFx,
  paginationEffect: loadMoreFx,
} = createPagination({
  domain: d,
  fetchEffect: getEstimateLotListFx,
  limit: 30,
})

export const getFiltersFx = attach({
  effect: getEstimateLotFiltersReqFx,
})

export const $areDocumentsLoading = initEstimateLotListFx.pending
export const $areFiltersLoading = getFiltersFx.pending
