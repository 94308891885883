import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { MultiSelectGroup, palette } from '@/ui'

import {
  $acceptedActingGroupIds,
  $actingGroupOptions,
  $morphologyFilterObjects,
  acceptMorphologyFilters,
  setAcceptedActingGroupIds,
} from '../../../model/filters.private'

type Props = {
  positionRef: React.RefObject<HTMLElement>
}

export const AdditionalGaFilters = React.memo(({ positionRef }: Props) => {
  const [actingGroupOptions, acceptedActingGroupIds, morphologyFilters] = useUnit([
    $actingGroupOptions,
    $acceptedActingGroupIds,
    $morphologyFilterObjects,
  ])

  if (!morphologyFilters) return null

  const { buildings, sections, floors } = morphologyFilters

  return (
    <>
      <Block>
        <MultiSelectGroup
          name="Группа актирования"
          positionRef={positionRef}
          value={acceptedActingGroupIds}
          items={actingGroupOptions}
          acceptedCount={acceptedActingGroupIds.length}
          paddingBlock={12}
          onSubmit={setAcceptedActingGroupIds}
        />
      </Block>
      <Block>
        <MultiSelectGroup
          name="Корпус"
          description="Место работы"
          positionRef={positionRef}
          value={buildings.selectedNames}
          acceptedCount={buildings.selectedNames.length}
          items={buildings.mergedOptions}
          onSubmit={(names) => acceptMorphologyFilters({ names, type: 'buildings' })}
        />
        <MultiSelectGroup
          name="Секция"
          positionRef={positionRef}
          value={sections.selectedNames}
          acceptedCount={sections.selectedNames.length}
          items={sections.mergedOptions}
          isDisabled={!buildings.selectedNames.length}
          onSubmit={(names) => acceptMorphologyFilters({ names, type: 'sections' })}
        />
        <MultiSelectGroup
          name="Этаж"
          positionRef={positionRef}
          value={floors.selectedNames}
          acceptedCount={floors.selectedNames.length}
          items={floors.mergedOptions}
          isDisabled={!sections.selectedNames.length}
          onSubmit={(names) => acceptMorphologyFilters({ names, type: 'floors' })}
        />
      </Block>
    </>
  )
})

const Block = styled.div`
  &:not(:first-child) {
    border-top: 1px solid ${palette.grey30};
  }
`
