import React from 'react'
import styled from 'styled-components'
import { combine } from 'effector'
import { useUnit } from 'effector-react'

import { useTooltip } from '@/lib/hooks'
import {
  CloseModalButton,
  HeadXL,
  ModalLayoutV2,
  NewButton,
  NotificationItem,
  NotificationType,
  ToolTipV2,
} from '@/ui'
import { addMaterialsModal } from '@/features/estimate-lot/model/private'

import {
  $hasSelectedEachGroup,
  $hasSelectedMaterials,
  addLotWorkMaterials,
  addLotWorkMaterialsFx,
  getLotWorkMaterialGroupsFx,
  getLotWorkMaterialsFx,
} from '../../model/private'
import { MaterialFilters, MaterialList } from '../containers'

const $isLoading = combine(
  getLotWorkMaterialGroupsFx.pending,
  getLotWorkMaterialsFx.pending,
  (...args) => args.some(Boolean),
)

export const AddMaterialsModal = React.memo(() => {
  const [info, hasSelectedMaterials, hasSelectedEachGroup, isLoading, isSending] = useUnit([
    addMaterialsModal.$meta,
    $hasSelectedMaterials,
    $hasSelectedEachGroup,
    $isLoading,
    addLotWorkMaterialsFx.pending,
  ])

  const { referenceProps, tooltipProps, isOpen } = useTooltip({
    tooltipOffset: 4,
    placement: 'top',
    hasArrow: true,
  })

  if (!info) return null

  // TODO вычислять по будущему флагу из info
  const requiresEachGroup = true
  const canSubmit = requiresEachGroup ? hasSelectedEachGroup : hasSelectedMaterials

  const handleClose = addMaterialsModal.close

  return (
    <ModalLayoutV2
      hasCloseButton={false}
      dataTestId="lot-add-materials-modal"
      closeModal={handleClose}
    >
      <Wrapper>
        <Header>
          <Title>Добавить материалы (mock)</Title>
          <CloseModalButton
            isAbsolute={false}
            isDisabled={isSending}
            dataTestId="lot-add-materials-modal-close-btn"
            onClick={handleClose}
          />
        </Header>

        <Content>
          <NotificationItem
            type={NotificationType.Info}
            body={
              requiresEachGroup
                ? 'Вам необходимо выбрать хотя бы 1 материал из каждой выбранной группы'
                : 'Вам необходимо выбрать хотя бы 1 материал для продолжения'
            }
          />
          <MaterialFilters />
          <MaterialList />
        </Content>

        <Buttons>
          <NewButton
            label="Отменить"
            buttonType="grey"
            isDisabled={isSending}
            dataTestId="lot-add-materials-modal-close-btn"
            isFill
            onClick={handleClose}
          />
          <div style={{ width: '100%' }} {...referenceProps}>
            <NewButton
              label="Добавить"
              isDisabled={isLoading || !canSubmit}
              isPending={isSending}
              dataTestId="lot-add-materials-modal-submit-btn"
              isFill
              onClick={addLotWorkMaterials}
            />
          </div>
          <ToolTipV2
            {...tooltipProps}
            bgColor="grey80"
            size="M"
            isOpen={isOpen && requiresEachGroup && !hasSelectedEachGroup}
          >
            Выберите материал во всех группах
          </ToolTipV2>
        </Buttons>
      </Wrapper>
    </ModalLayoutV2>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 732px;
  height: min(100dvh, 886px);
  padding: 32px;
`

const Header = styled.header`
  display: flex;
  gap: 10px;
`

const Title = styled.p`
  ${HeadXL}
  flex-grow: 1;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
`

const Buttons = styled.div`
  display: flex;
  gap: 16px;
`
