import React from 'react'
import styled, { css } from 'styled-components'

import { palette } from '../palette'
import { TextM, TextSLight } from '../typography'

type Props = {
  children: React.ReactElement
  label?: string
  maxSymbolCount?: number
  currentSymbolCount?: number
  isLabel?: boolean
  required?: boolean
  hasLengthError?: boolean
  isLabelGrey?: boolean
}

export const Field = ({
  children,
  label,
  currentSymbolCount,
  maxSymbolCount,
  isLabel = true,
  required = false,
  hasLengthError = false,
  isLabelGrey = false,
}: Props) => {
  const content = (
    <>
      {Boolean(label) && (
        <Label>
          <LabelText $isGrey={isLabelGrey}>
            <span>{label}</span>
            {required && <RequiredAsterisk>*</RequiredAsterisk>}
          </LabelText>
          {Boolean(maxSymbolCount !== undefined && currentSymbolCount !== undefined) && (
            <SymbolsInfo $isInvalid={hasLengthError}>
              {currentSymbolCount}/{maxSymbolCount}
            </SymbolsInfo>
          )}
        </Label>
      )}
      {children}
    </>
  )

  if (isLabel) {
    return <WrapperLabel>{content}</WrapperLabel>
  }

  return <Wrapper>{content}</Wrapper>
}

const RequiredAsterisk = styled.span`
  color: ${palette.accent100};
  user-select: none;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`

const WrapperLabel = styled.label`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`

const Label = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const LabelText = styled.div<{ $isGrey: boolean }>`
  ${TextM}
  display: flex;
  gap: 4px;

  ${({ $isGrey }) =>
    $isGrey &&
    css`
      color: ${palette.grey60};
    `}
`

const SymbolsInfo = styled.div<{ $isInvalid: boolean }>`
  ${TextSLight}
  color: ${({ $isInvalid }) => palette[$isInvalid ? 'red100' : 'grey60']};
`
