import React from 'react'
import { useUnit } from 'effector-react'

import { ActWorkSMR, ContractDirection } from '@/dal'
import { noop } from '@/lib/noop'
import { numberWithSpaces } from '@/lib/number-represent'
import { CellWrapper, FirstTableCell, TableCell } from '@/ui'
import { $isGaAct } from '@/features/act/model'

import { $worksTableSearch } from '../../model/filters.private'
import {
  $isCellGroupHidden,
  $isTree,
  $itemToFocus,
  onChangeSMRActWork,
  onEnterSubmitItem,
  onWorkInputError,
  resetItemToFocus,
} from '../../model/private'
import { CellRow, FirstCellWrapper, SplitCellWrapper, TableRowWrapper } from '../parts'

type Props = {
  item: ActWorkSMR
  isScrolled?: boolean
}

export const TableRow = React.memo(({ item, isScrolled }: Props) => {
  const [isHiddenGroup, isTree, searchName, itemToFocus, isGa] = useUnit([
    $isCellGroupHidden,
    $isTree,
    $worksTableSearch,
    $itemToFocus,
    $isGaAct,
  ])

  const {
    id,
    article,
    name,
    note,
    number_1c,
    completed,
    estimate,
    period_cost,
    project,
    remainder,
    total_cost,
    unit,
    depth,
  } = item

  const handleChange = (value: string) => {
    onChangeSMRActWork({ workId: id, value })
  }

  return (
    <TableRowWrapper as="td" actType={ContractDirection.SMR} hiddenFirstGroup={isHiddenGroup}>
      <FirstCellWrapper hasShadow={isScrolled}>
        <FirstTableCell
          hierarchyCode={number_1c}
          depth={depth}
          hasChildren={false}
          isFolder={false}
          isExpanded={false}
          isTree={isTree || isGa}
          name={name}
          project={project}
          searchValue={searchName}
          hasRightDivider={isHiddenGroup}
          onToggleExpand={noop}
        />
      </FirstCellWrapper>

      {!isHiddenGroup && (
        <>
          <CellWrapper isGray>{note}</CellWrapper>
          <CellWrapper isGray>{article}</CellWrapper>
        </>
      )}

      <CellWrapper>{numberWithSpaces(estimate.current, { decimalScale: 4 })}</CellWrapper>

      <CellWrapper>{numberWithSpaces(remainder.current, { decimalScale: 4 })}</CellWrapper>
      <TableCell
        value={completed.current}
        canEdit={completed.can_edit}
        diff={completed.diff ?? ''}
        filledValue={completed.filled_value}
        approveValue={completed.corrected.value ?? ''}
        approveText={completed.corrected.comment}
        decimalScale={4}
        hasToFocus={itemToFocus === item.id}
        allowNegative
        onChange={handleChange}
        onEnter={() => onEnterSubmitItem(id)}
        onFocus={resetItemToFocus}
        onError={onWorkInputError}
      />
      <CellWrapper align="center">{unit}</CellWrapper>

      <SplitCellWrapper>
        <CellWrapper align="center">{numberWithSpaces(total_cost.total)}</CellWrapper>
        <CellRow>
          <CellWrapper align="center">{numberWithSpaces(total_cost.material)}</CellWrapper>
          <CellWrapper align="center">{numberWithSpaces(total_cost.work)}</CellWrapper>
        </CellRow>
      </SplitCellWrapper>

      <SplitCellWrapper>
        <CellWrapper align="center">{numberWithSpaces(period_cost.total)}</CellWrapper>
        <CellRow>
          <CellWrapper align="center">{numberWithSpaces(period_cost.material)}</CellWrapper>
          <CellWrapper align="center">{numberWithSpaces(period_cost.work)}</CellWrapper>
        </CellRow>
      </SplitCellWrapper>
    </TableRowWrapper>
  )
})
