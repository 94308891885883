import styled from 'styled-components'

import { palette } from '../palette'

export const DialogSheetWrapper = styled.div`
  background-color: ${palette.white};
  border-radius: 8px;
  box-shadow: 0px 2px 10px 0px ${palette.shadow};
  color: initial;
  z-index: 1000;
`
