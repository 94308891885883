import { sample } from 'effector'

import {
  DocumentAlias,
  DocumentStatuses,
  EstimateDocId,
  VisasDocType,
} from '@/dal'
import { openAdditionalRequestModal } from '@/features/additional-request/model'

import {
  $documentInfo,
  EstimateCorrectionGate,
  updateAttachments,
} from '../../shared-model'
import {
  attachmentsModel,
  onOpenAdditionalModal,
  onOpenModal,
  sendFilesFx,
} from './private'

sample({
  clock: onOpenModal,
  source: $documentInfo,
  filter: Boolean,
  fn: (estimate) => ({
    id: estimate.id,
    files: estimate.attachments,
  }),
  target: attachmentsModel.open,
})

sample({
  clock: onOpenAdditionalModal,
  source: {
    isOpen: EstimateCorrectionGate.status,
    documentInfo: $documentInfo,
  },
  filter: ({ isOpen, documentInfo }) => Boolean(isOpen && documentInfo),
  fn: ({ documentInfo }) => ({
    docType: DocumentAlias.KOR_DOC as VisasDocType,
    docId: documentInfo?.id as EstimateDocId,
    status: documentInfo?.status as DocumentStatuses,
    isSpecialVisa: Boolean(documentInfo?.features.is_special_visa),
  }),
  target: openAdditionalRequestModal,
})

sample({
  clock: sendFilesFx.doneData,
  target: updateAttachments,
})
