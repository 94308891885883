import React from 'react'

export const useCheckVerticallyOverflowed = <T extends HTMLElement>({
  deps = [],
}: {
  deps?: unknown[]
} = {}) => {
  const ref = React.useRef<T>(null)

  const [isOverflowed, setIsOverflowed] = React.useState(false)

  React.useEffect(() => {
    const checkIsOverflowed = () => {
      if (!ref.current) return
      const isOverflowed = ref.current.clientHeight < ref.current.scrollHeight
      setIsOverflowed(isOverflowed)
    }
    checkIsOverflowed()

    window.addEventListener('resize', checkIsOverflowed)

    return () => window.removeEventListener('resize', checkIsOverflowed)
  }, [ref.current, ...deps])

  return {
    ref,
    isOverflowed,
  }
}
