import React from 'react'
import styled from 'styled-components'
import { useGate } from 'effector-react'

import { ContentContainer, FlexScrollbar, palette } from '@/ui'
import {
  EstimateLotList,
  EstimateLotListGate,
  EstimateLotsFilters,
  EstimateLotsHeader,
} from '@/features/estimate-lot/list'

export const EstimateLotListPage = () => {
  useGate(EstimateLotListGate)

  return (
    <FlexScrollbar>
      <ContentContainer gap={16} isAutoOverflow>
        <EstimateLotsHeader />

        <TableWrapper>
          <EstimateLotsFilters />
          <EstimateLotList />
        </TableWrapper>
      </ContentContainer>
    </FlexScrollbar>
  )
}

const TableWrapper = styled.div`
  width: fit-content;
  min-width: 100%;

  border-radius: 12px;
  background-color: ${palette.white};
`
