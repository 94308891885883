import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { ClosurePercentageStatus } from '@/dal'
import { formatDateString } from '@/lib/date'
import { numberWithSpaces } from '@/lib/number-represent'
import {
  HeadM,
  NewFileItem,
  NewTextButton,
  NotificationItem,
  NotificationType,
  palette,
  TextM,
  TextMLight,
  TextS,
} from '@/ui'
import { GetRetention } from '@/features/administrative/share'
import { RouterPaths } from '@/features/app/model'
import { CONTRACT_ID_KEY, CONTRACT_NAME_KEY } from '@/features/document-flow/filters/model'

import { $contract, onGetRetentionButton } from '../../../model/private'

const $data = $contract.map((contract) =>
  contract
    ? {
        id: contract?.id,
        name: contract?.name,
        data: contract?.additional_data,
        parentId: contract?.parent?.id,
        otherDeductions: contract.other_deductions,
        canGetRetention:
          contract.closure_percentage.status === ClosurePercentageStatus.CanGetGuaranteeRetention,
      }
    : null,
)

export const Description = React.memo(() => {
  const contractData = useUnit($data)

  if (!contractData) return null

  const { data, id, name, parentId, otherDeductions, canGetRetention } = contractData

  return (
    <Wrapper>
      <Row>
        <Title>Описание</Title>
        <NewTextButton
          dataTestId="go-to-documentflow"
          label="Все документы по договору"
          isNewBlank
          textStyle="M"
          url={`${RouterPaths.DocumentFlow}?${CONTRACT_ID_KEY}=${
            parentId || id
          }&${CONTRACT_NAME_KEY}=${name}`}
        />
      </Row>

      <ContentWrapper>
        <Sums>
          <SumsItem>
            <SumsItemLabel>Стоимость договора, руб</SumsItemLabel>
            <SumsItemValue>{numberWithSpaces(data.sum)}</SumsItemValue>
          </SumsItem>
          <SumsItem>
            <SumsItemLabel>НДС</SumsItemLabel>
            <SumsItemValue>{data.nds}%</SumsItemValue>
          </SumsItem>
          {data.guaranteed_percentage && (
            <SumsItem>
              <SumsItemLabel>Гарантийные удержания</SumsItemLabel>
              <SumsItemValue>{data.guaranteed_percentage}%</SumsItemValue>
            </SumsItem>
          )}
          {data.guaranteed_date && (
            <SumsItem>
              <SumsItemLabel>Гарантийный срок</SumsItemLabel>
              <SumsItemValue>{data.guaranteed_date}</SumsItemValue>
            </SumsItem>
          )}
          {data.avans_percentage && (
            <SumsItem>
              <SumsItemLabel>Условия авансирования</SumsItemLabel>
              <SumsItemValue>{data.avans_percentage}%</SumsItemValue>
            </SumsItem>
          )}
        </Sums>

        {canGetRetention && (
          <GetRetention contractId={id} isPending={false} onButton={onGetRetentionButton} />
        )}

        {Boolean(otherDeductions.length) && (
          <div>
            <AdditionalHoldsTitle>Дополнительные удержания</AdditionalHoldsTitle>

            <AdditionalHoldsItems>
              {otherDeductions.map((item) => (
                <AdditionalHoldsItem key={item.name}>
                  <AdditionalHoldsItemLabel>{item.name}</AdditionalHoldsItemLabel>
                  <AdditionalHoldsItemValue>{item.percent}</AdditionalHoldsItemValue>
                </AdditionalHoldsItem>
              ))}
            </AdditionalHoldsItems>
          </div>
        )}

        <WorkDescription>
          <WorkDescriptionTitle>Описание работ</WorkDescriptionTitle>
          <WorkDescriptionText>{data.subject}</WorkDescriptionText>
        </WorkDescription>
        <AdditionalInfo>
          <AdditionalInfoItem>
            <AdditionalInfoItemTitle>Срок строительства</AdditionalInfoItemTitle>
            {data.date && data.date_end ? (
              <AdditionalInfoItemText>
                {formatDateString(data.date)}
                {' – '}
                {formatDateString(data.date_end)}
              </AdditionalInfoItemText>
            ) : (
              '–'
            )}
          </AdditionalInfoItem>

          <AdditionalInfoItem>
            <AdditionalInfoItemTitle>Заказчик</AdditionalInfoItemTitle>
            <AdditionalInfoItemText>{data.company_fsk}</AdditionalInfoItemText>
          </AdditionalInfoItem>

          {data.estimate && (
            <AdditionalInfoItem>
              <AdditionalInfoItemTitle>Приложение к документу</AdditionalInfoItemTitle>
              <AdditionalInfoItemText>
                <NewFileItem {...data.estimate} size="M" />
              </AdditionalInfoItemText>
            </AdditionalInfoItem>
          )}
        </AdditionalInfo>

        <AdditionalInfo>
          <AdditionalInfoItem>
            <AdditionalInfoItemTitle>Фактические сроки</AdditionalInfoItemTitle>
            {data.date_start_fact && data.date_end_fact ? (
              <ActualDatesWrapper>
                <AdditionalInfoItemText>
                  {formatDateString(data.date_start_fact)}
                  {' – '}
                  {formatDateString(data.date_end_fact)}
                </AdditionalInfoItemText>
              </ActualDatesWrapper>
            ) : (
              '–'
            )}
          </AdditionalInfoItem>
        </AdditionalInfo>
      </ContentWrapper>

      <NotificationItem
        body={
          <>
            Если у вас возникли вопросы по договору, вы можете задать их в{' '}
            {/* TODO SUPPORT to={RouterPaths.NewAdministrationAppeal} */}
            <Link to={RouterPaths.Support} target="_blank">
              администрации проекта
            </Link>
          </>
        }
        type={NotificationType.Warning}
      />
    </Wrapper>
  )
})

const Wrapper = styled.div`
  padding: 24px;
  background-color: ${palette.white};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

const Title = styled.div`
  ${HeadM}
`

const Sums = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const SumsItem = styled.div`
  display: flex;
  gap: 12px;
`

const SumsItemLabel = styled.div`
  ${TextM};
  width: 153px;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const SumsItemValue = styled.div`
  ${TextMLight}
`

const AdditionalHoldsTitle = styled.div`
  width: 100%;
  ${TextS}
  text-transform: uppercase;
  background-color: ${palette.grey20};
  color: ${palette.grey70};
  padding-top: 2px;
  margin-bottom: 6px;
`

const AdditionalHoldsItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  max-width: max-content;
`

const AdditionalHoldsItem = styled.div`
  display: flex;
  gap: 12px;
`

const AdditionalHoldsItemLabel = styled.div`
  ${TextM}
  flex-grow: 1;
`

const AdditionalHoldsItemValue = styled.div`
  ${TextMLight}
`

const WorkDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const WorkDescriptionTitle = styled.div`
  ${TextM}
`

const WorkDescriptionText = styled.div`
  ${TextMLight}
`

const AdditionalInfo = styled.div`
  display: flex;
  gap: 50px;
`

const AdditionalInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const AdditionalInfoItemTitle = styled.div`
  ${TextM}
`

const AdditionalInfoItemText = styled.div`
  ${TextMLight}
`

const ActualDatesWrapper = styled.div`
  background-color: ${palette.accent10};
  color: ${palette.accent100};
`
