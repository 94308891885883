import React from 'react'
import styled, { type WebTarget } from 'styled-components'

import { TEXT_SIZE_MAP } from '../const'
import { getColor, PaletteColor } from '../palette'
import { TextSize } from '../types'

type Props = {
  children: React.ReactNode
  textStyle?: TextSize
  color?: PaletteColor | 'inherit'
  as?: WebTarget
}

export const Typography = ({ textStyle = 'ML', color = 'inherit', as, children }: Props) => {
  return (
    <Wrapper $textStyle={textStyle} $color={color} as={as}>
      {children}
    </Wrapper>
  )
}

type WrapperProps = {
  $textStyle: TextSize
  $color: Props['color']
}

const Wrapper = styled.p<WrapperProps>`
  ${({ $textStyle }) => $textStyle && TEXT_SIZE_MAP[$textStyle]}
  color: ${({ $color = 'inherit' }) => getColor($color)};
`
