import React, { memo } from 'react'
import { TableVirtuoso } from 'react-virtuoso'
import styled from 'styled-components'
import { useGate, useUnit } from 'effector-react'

import { ActGaGroupRow, ActGaRow, isGaGroupRow } from '@/dal'
import { LoaderOverlay } from '@/ui'
import { $isDocumentPending } from '@/features/act/model'
import { worksModal } from '@/features/act/model/private'

import {
  $gaTableItems,
  actingGroupModal,
  ActingGroupsGate,
  getGaActingGroupsFx,
} from '../../model/private'
import { GaGroupTableRow, GaTableHeader, GaTableRow } from '../parts'

export const GaActTable = memo(() => {
  const [items, isTableLoading, isDocumentLoading] = useUnit([
    $gaTableItems,
    getGaActingGroupsFx.pending,
    $isDocumentPending,
  ])

  const MemoHeader = React.useCallback(() => {
    return <GaTableHeader />
  }, [])

  const MemoComponent = React.useCallback((_: number, item: ActGaRow | ActGaGroupRow) => {
    if (isGaGroupRow(item)) {
      return <GaGroupTableRow {...item} onOpenTable={() => worksModal.open(item.type)} />
    }
    return <GaTableRow item={item} onOpenTable={() => actingGroupModal.open(item.id)} />
  }, [])

  useGate(ActingGroupsGate)

  if (!items) return null

  return (
    <TableWrapper>
      <TableVirtuoso
        style={{ flexGrow: 1 }}
        data={items}
        fixedHeaderContent={MemoHeader}
        itemContent={MemoComponent}
      />
      <LoaderOverlay isShown={isTableLoading && !isDocumentLoading} />
    </TableWrapper>
  )
})

const TableWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  table {
    width: 100%;
  }

  thead {
    z-index: 5 !important;
  }
`
