import React from 'react'

import { ContractItem, DocumentAlias, FeaturesForDocument } from '@/dal'
import { getDocumentNameByType } from '@/lib/documentResolvers'
import { useTooltip } from '@/lib/hooks'
import { useCreateDocumentTooltip } from '@/lib/hooks/useCreateDocumentTooltip'
import { LightTooltip, NewTextButton, usePopup } from '@/ui'

type Props = {
  id: ContractItem['id']
  label?: string
  canCreateFeature: FeaturesForDocument['create']
  docType: DocumentAlias
  onCreateDocument: (docType: DocumentAlias) => void
}

export const CreateDocumentButton = ({
  id,
  label,
  canCreateFeature,
  docType,
  onCreateDocument,
}: Props) => {
  const { open, close, ...popupHookProps } = usePopup()

  const { ...tooltipHookProps } = useTooltip({
    tooltipOffset: 10,
    hasCloseDelay: true,
  })

  const documentName = getDocumentNameByType(docType) || ''
  const canCreate = canCreateFeature.can

  const { tooltipConfig, isHint, hasDocumentLink } = useCreateDocumentTooltip(canCreateFeature)

  const isButtonDisabled = !canCreate && !hasDocumentLink
  const hasIcon = !canCreate && !isButtonDisabled && !isHint

  const tooltipProps = isButtonDisabled ? tooltipHookProps : popupHookProps

  const tooltipText = React.useMemo(() => {
    if (isHint && typeof tooltipConfig?.text === 'string') return tooltipConfig.text
    if (canCreate) return `Создать документ ${documentName}`
  }, [isHint, tooltipConfig, canCreate, documentName])

  const preventClick = (e?: React.MouseEvent) => {
    e?.preventDefault()
    e?.stopPropagation()
  }

  const handleBtnClick = () => {
    if (canCreate) {
      onCreateDocument(docType)
    }
    if (tooltipProps.isOpen) {
      close()
    } else {
      open()
    }
  }

  return (
    <div onClick={preventClick}>
      <NewTextButton
        ref={tooltipProps.refs.setReference}
        {...tooltipProps.getReferenceProps()}
        textStyle="ML"
        dataTestId={`create-${documentName}-for-${id}`}
        label={label || documentName}
        tooltip={tooltipText}
        tooltipWithArrow={false}
        prefixIcon={hasIcon ? 'infoCircle' : undefined}
        iconSize={16}
        isDisabled={isButtonDisabled}
        onClick={handleBtnClick}
      />

      {tooltipConfig && !isHint && (
        <LightTooltip
          floatingStyles={tooltipProps.floatingStyles}
          getFloatingProps={tooltipProps.getFloatingProps}
          isOpen={tooltipProps.isOpen}
          refs={tooltipProps.refs}
          arrowProps={tooltipProps.arrowProps}
          onClose={isButtonDisabled ? undefined : close}
        >
          {tooltipConfig.text}
        </LightTooltip>
      )}
    </div>
  )
}
