import React from 'react'
import styled from 'styled-components'
import { combine } from 'effector'
import { useUnit } from 'effector-react'

import { HeadM, LoaderOverlay, palette, TextMLight } from '@/ui'

import {
  $groupsWithChildren,
  $selectedMaterialCount,
  addLotWorkMaterialsFx,
  areFiltersAccepting,
  getLotWorkMaterialGroupsFx,
  getLotWorkMaterialsFx,
} from '../../model/private'
import { MaterialGroup } from './MaterialGroup'

const $isPending = combine(
  areFiltersAccepting.$value,
  getLotWorkMaterialGroupsFx.pending,
  getLotWorkMaterialsFx.pending,
  (...args) => args.some(Boolean),
)

export const MaterialList = React.memo(() => {
  const [groups, selectedCount, isPending, isSending] = useUnit([
    $groupsWithChildren,
    $selectedMaterialCount,
    $isPending,
    addLotWorkMaterialsFx.pending,
  ])

  return (
    <Wrapper>
      <Header>
        <p>Группы материалов</p>
        <SelectedCount>
          Выбрано материалов: <CountSpan>{selectedCount}</CountSpan>
        </SelectedCount>
      </Header>

      <Container>
        {groups.map((group) => (
          <MaterialGroup
            key={group.id}
            group={group}
            childrenIds={group.childrenIds}
            isDisabled={isSending}
          />
        ))}
        <LoaderOverlay isShown={isPending} />
      </Container>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
`

const Header = styled.header`
  ${HeadM}
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`

const SelectedCount = styled.p`
  ${TextMLight}
`

const CountSpan = styled.span`
  color: ${palette.accent100};
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  height: 0;
  padding-right: 18px;
  flex-grow: 1;
  overflow: auto;
`
