import { sample } from 'effector'

import { ActGaGroupRow, ActGaGroupType } from '@/dal'

import { $documentId, ActGate, updateKSData } from '../../model'
import {
  $hasChangedTables,
  markTablesAsChanged,
  refetchAct,
  resetHasChangedTables,
  worksModal,
} from '../../model/private'
import {
  $allGaTableItems,
  $gaTableSearch,
  $openedActingGroupId,
  actingGroupModal,
  ActingGroupsGate,
  changeGaTableSearch,
  clearMorphologyWorks,
  fillMorphologyWorks,
  getGaActingGroups,
  getGaActingGroupsFx,
  resetActingGroup,
  resetActingGroupFx,
  resetGaTableSearch,
  toggleMorphologyWorksFx,
} from './private'

$allGaTableItems
  .on(
    [getGaActingGroupsFx.doneData, toggleMorphologyWorksFx.doneData],
    (_, { action_groups, works_with_places, works_without_places }) => {
      const groupRows: ActGaGroupRow[] = []

      ;[works_with_places, works_without_places].forEach((item, i) => {
        if (!item) return
        groupRows.push({
          type: i ? ActGaGroupType.WithoutPlaces : ActGaGroupType.WithPlaces,
          ordering: action_groups.length + groupRows.length + 1,
          isAccepted: item.is_accepted,
          periodCost: item.period_total_cost,
        })
      })

      return [...action_groups, ...groupRows]
    },
  )
  .reset(ActGate.close)

$gaTableSearch.on(changeGaTableSearch, (_, val) => val).reset(ActGate.close, resetGaTableSearch)

$openedActingGroupId
  .on(actingGroupModal.open, (_, val) => val)
  .reset(ActGate.close, actingGroupModal.close)

sample({
  clock: [ActingGroupsGate.open, resetActingGroupFx.done],
  target: getGaActingGroups,
})

sample({
  clock: getGaActingGroups,
  source: $documentId,
  filter: Boolean,
  fn: (actId) => ({ actId }),
  target: getGaActingGroupsFx,
})

sample({
  clock: [actingGroupModal.close, worksModal.close],
  source: $hasChangedTables,
  filter: Boolean,
  target: [refetchAct, getGaActingGroups, resetHasChangedTables],
})

sample({
  clock: fillMorphologyWorks,
  source: $documentId,
  filter: Boolean,
  fn: (actId, worksToFill) => ({ actId, worksToFill }),
  target: toggleMorphologyWorksFx,
})

sample({
  clock: clearMorphologyWorks,
  source: $documentId,
  filter: Boolean,
  fn: (actId, worksToClear) => ({ actId, worksToClear }),
  target: toggleMorphologyWorksFx,
})

sample({
  clock: resetActingGroup,
  source: $documentId,
  filter: Boolean,
  fn: (actId, actingGroupId) => ({ actId, actingGroupId }),
  target: resetActingGroupFx,
})

sample({
  clock: resetActingGroupFx.doneData,
  target: updateKSData,
})

sample({
  clock: [toggleMorphologyWorksFx.done, resetActingGroupFx.done],
  target: markTablesAsChanged,
})
