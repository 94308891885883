import React from 'react'

import { noop } from '@/lib/noop'
import { InputField } from '@/ui'

type Props = {
  objectName: string
  buildingName: string
}

export const ObjectAndBuildingInfo = ({ objectName, buildingName }: Props) => {
  return (
    <>
      <InputField
        label="Объект строительства"
        value={objectName}
        size="L"
        dataTestId=""
        disabled
        onChange={noop}
      />
      <InputField
        label="Корпус"
        value={buildingName}
        size="L"
        dataTestId=""
        disabled
        onChange={noop}
      />
    </>
  )
}
