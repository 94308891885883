import React from 'react'
import styled, { css } from 'styled-components'

import { EstimateTableRow } from '@/dal'
import { NewDropDownInput, Option, OverflowText, palette, PaletteColor } from '@/ui'

type Props = {
  isFolder: boolean
  isEditMode: boolean
  value: Option['id']
  options: Option[]
  id: EstimateTableRow['id']
  actingGroup?: string | React.JSX.Element
  isEditable: boolean
  onChange: (val: Option['id']) => void
}

export const ActingGroupField = ({
  isEditMode,
  isFolder,
  options,
  value,
  id,
  actingGroup,
  isEditable,
  onChange,
}: Props) => {
  if (!isFolder && isEditMode && isEditable) {
    return (
      <NewDropDownInput
        size="S"
        dataTestId={`row-acting-group-${id}`}
        value={value}
        onChange={onChange}
        options={options}
        hasClear={false}
        placeholder="Выберите группу актирования"
      />
    )
  }

  if (actingGroup) {
    return (
      <Wrapper>
        <OverflowText maxTooltipWidth={400} maxLines={1}>
          {actingGroup}
        </OverflowText>
      </Wrapper>
    )
  }

  return null
}

const Wrapper = styled.div`
  color: ${palette.grey60};
  background-color: var(--acting-group-bg-color);
  transition: 0.15s linear background-color;
`
