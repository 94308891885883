import { attachWrapper } from '@42px/effector-extra'
import { prepareFormData } from '@/lib/form-data'

import { defaultErrorHandler } from '../error-handler'
import {
  AbortableRequest,
  CompanyItem,
  DefaultResponseFail,
  DocumentAlias,
  EstimateLotId,
  FileDTO,
  Response,
} from '../interfaces'
import { authRequestFx, Method, mockRequestFx } from '../request'
import { mockExpandedItems, mockTableItems, mockUpdatedItems } from './mock'
import {
  AddLotWorksPayload,
  ChangeSelectedMorphologyPayload,
  ChangeTableItemsPayload,
  ChangeWorkVolumesPayload,
  EstimateLot,
  EstimateLotFiltersResponse,
  EstimateLotMorphologyResponse,
  EstimateLotsFilterPayload,
  GetEstimateLotListResponse,
  GetLotTableParams,
  GetLotTableRowsParams,
  KvrElement,
  LotCatalogItem,
  LotTableItem,
  LotTableResponse,
  PostEstimateLotAttachmentsParams,
  UnitItem,
  UpdateEstimateLotPayload,
  UpdateLotWorkCommentPayload,
} from './types'

export const getEstimateLotReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ signal, id }: AbortableRequest<{ id: EstimateLotId }>) => ({
    method: Method.get,
    url: `/estimate_lots/lot/${id}/`,
    signal,
  }),
  mapResult: ({ result }): EstimateLot => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const getEstimateLotTableReqFx = attachWrapper({
  effect: mockRequestFx,
  mapParams: ({ signal, id, ...query }: AbortableRequest<GetLotTableParams>) => ({
    method: Method.get,
    url: `/mock/estimate_lots/flat/${id}/`,
    signal,
    query,
  }),
  mapResult: ({ result }): LotTableResponse => mockTableItems,
  // mapResult: ({ result }): LotTableResponse => ({
  //   total: 0,
  //   root: [],
  //   costs: { total: '0.00', material: '0.00', work: '0.00' },
  //   morphology: [],
  // }),
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const changeMorphologyElementPriceReqFx = attachWrapper({
  effect: mockRequestFx,
  mapParams: ({ workId, elementId, type, price }: ChangeWorkVolumesPayload) => ({
    method: Method.post,
    url: `/mock/${workId}`,
    body: {
      price,
      morphology_id: elementId,
      work_id: workId,
      morphology_type: type,
    },
  }),
  mapResult: ({ result }): LotTableResponse => mockUpdatedItems,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const getEstimateLotTableItemContentReqFx = attachWrapper({
  effect: getEstimateLotTableReqFx,
  mapParams: (params: AbortableRequest<GetLotTableRowsParams>) => params,
  mapResult: ({ result }) => mockExpandedItems,
})

export const getLotKvrItemsReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({
    signal,
    id,
    parentId,
    search,
  }: AbortableRequest<{ id: EstimateLotId; parentId: number | null; search?: string }>) => ({
    method: Method.get,
    url: `/estimate_lots/kvr_ker/${id}/`,
    signal,
    query: {
      parent_id: parentId || undefined,
      filter_by_name: search || undefined,
    },
  }),
  mapResult: ({ result }): KvrElement[] => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const addLotWorksReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, worksToAdd }: AddLotWorksPayload) => ({
    method: Method.post,
    url: `/estimate_lots/lot/${id}/add_kvrs/`,
    body: worksToAdd,
  }),
  mapResult: ({ result }): void => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const getEstimateLotFolderNestedWorksReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (id: LotTableItem['id']) => ({
    method: Method.get,
    url: `/estimate_lots/kers_of_kvr/${id}/`,
  }),
  mapResult: ({ result }): string[] => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const deleteEstimateLotWorkReqFx = attachWrapper({
  effect: mockRequestFx,
  mapParams: ({ id }: LotTableItem) => ({
    method: Method.delete,
    url: `/estimate_lots/lot_work/${id}/`,
  }),
  mapResult: ({ result }): void => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const deleteEstimateLotFolderReqFx = attachWrapper({
  effect: mockRequestFx,
  mapParams: ({ id }: LotTableItem) => ({
    method: Method.delete,
    url: `/estimate_lots/delete_group/${id}/`,
  }),
  mapResult: ({ result }): void => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const deleteLotMaterialReqFx = attachWrapper({
  effect: mockRequestFx,
  mapParams: ({ id }: LotTableItem) => ({
    method: Method.delete,
    url: `/estimate_lots/mock/${id}/`,
  }),
  mapResult: ({ result }): void => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const clearLotMaterialReqFx = attachWrapper({
  effect: mockRequestFx,
  mapParams: ({ id }: LotTableItem) => ({
    method: Method.post,
    url: `/estimate_lots/mock/${id}/`,
  }),
  mapResult: ({ result }): void => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const clearLotWorkReqFx = attachWrapper({
  effect: mockRequestFx,
  mapParams: ({ id }: LotTableItem) => ({
    method: Method.post,
    url: `/estimate_lots/mock/${id}/`,
  }),
  mapResult: ({ result }): void => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const changeLotTableItemsReqFx = attachWrapper({
  effect: mockRequestFx,
  mapParams: ({ id, items }: ChangeTableItemsPayload) => ({
    method: Method.post,
    url: `/mock/${id}/`,
    body: {
      items,
    },
  }),
  mapResult: ({ result }): void => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const changeEstimateLotWorkCommentReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, comment }: UpdateLotWorkCommentPayload) => ({
    method: Method.patch,
    url: `/estimate_lots/lot_work/${id}/set_comment/`,
    body: {
      comment,
    },
  }),
  mapResult: ({ result }): string => result.data.comment,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const addEstimateLotKvrReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, kvrId }: { id: EstimateLotId; kvrId: number }) => ({
    method: Method.post,
    url: `/estimate_lots/lot/${id}/add_all/kvr/${kvrId}/`,
  }),
  mapResult: ({ result }): { added_works: number[] } => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const postLotWorkAttachmentsReqFx = attachWrapper({
  effect: mockRequestFx,
  mapParams: ({ id, ...body }: any) => ({
    method: Method.post,
    url: `/mock/${id}/`,
    body: prepareFormData({ ...body, files: undefined }),
  }),
  mapResult: ({ result }): FileDTO[] => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const updateEstimateLotReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, ...body }: UpdateEstimateLotPayload) => ({
    method: Method.patch,
    url: `/estimate_lots/lot/${id}/`,
    body,
  }),
  mapResult: ({ result }): EstimateLot => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const createEstimateLotReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id }: { id: CompanyItem['id'] }) => ({
    method: Method.post,
    url: `/estimate_lots/lot/${id}/`,
  }),
  mapResult: ({ result }): EstimateLot => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const deleteEstimateLotReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (id: EstimateLotId) => ({
    method: Method.delete,
    url: `/estimate_lots/lot/${id}/`,
  }),
  mapResult: ({ result }): void => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const copyEstimateLotReqFx = attachWrapper({
  effect: mockRequestFx,
  mapParams: (id: EstimateLotId) => ({
    method: Method.post,
    url: `/mock/${id}`,
  }),
  mapResult: ({ result }): EstimateLot => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const sendEstimateLotToApproveReqFx = attachWrapper({
  effect: mockRequestFx,
  mapParams: (id: EstimateLotId) => ({
    method: Method.post,
    url: `/mock/${id}/`,
  }),
  mapResult: ({ result }): EstimateLot => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const cancelEstimateLotApprovingReqFx = attachWrapper({
  effect: mockRequestFx,
  mapParams: ({ id, comment }: { id: EstimateLotId; comment: string }) => ({
    method: Method.post,
    url: `/mock/${id}`,
    body: {
      message: comment,
    },
  }),
  mapResult: ({ result }): EstimateLot => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const getEstimateLotListReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ signal, status, ...params }: AbortableRequest<EstimateLotsFilterPayload>) => ({
    method: Method.get,
    url: '/estimate_lots/lots_list/',
    signal,
    query: {
      ...params,
      status: status?.join() || undefined,
    },
  }),
  mapResult: ({ result }): GetEstimateLotListResponse => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const getEstimateLotFiltersReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ signal, status, ...params }: AbortableRequest<EstimateLotsFilterPayload>) => ({
    method: Method.get,
    url: '/estimate_lots/lots_list/filters/',
    signal,
    query: {
      ...params,
      status: status?.join() || undefined,
    },
  }),
  mapResult: ({ result }): EstimateLotFiltersResponse => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const getEstimateLotDirectionsReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: () => ({
    method: Method.get,
    url: '/main/directions/contract_directions/',
  }),
  mapResult: ({ result }): LotCatalogItem[] => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const getUnitsReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: () => ({
    method: Method.get,
    url: '/main/units/',
  }),
  mapResult: ({ result }): UnitItem[] => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const getEstimateLotTypesOfSecurityReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: () => ({
    method: Method.get,
    url: '/estimate_lots/directions/type_of_security/',
  }),
  mapResult: ({ result }): LotCatalogItem[] => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const getEstimateLotAdvanceTypesReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: () => ({
    method: Method.get,
    url: '/estimate_lots/directions/advance_type/',
  }),
  mapResult: ({ result }): LotCatalogItem[] => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const getEstimateLotMorphologyReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id }: { id: EstimateLotId }) => ({
    method: Method.get,
    url: `/estimate_lots/morphology/lot/${id}/`,
  }),
  mapResult: ({ result }: Response<EstimateLotMorphologyResponse>) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const updateLotSelectedMorphologyReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, items }: ChangeSelectedMorphologyPayload) => ({
    method: Method.patch,
    url: `/estimate_lots/morphology/lot/${id}/`,
    body: items,
  }),
  mapResult: ({ result }: Response<EstimateLotMorphologyResponse>) => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})

export const postEstimateLotAttachmentsReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, ...body }: PostEstimateLotAttachmentsParams) => ({
    method: Method.post,
    url: `/files/by_document/${DocumentAlias.ESTIMATE_LOT}/${id}/`,
    body: prepareFormData({ ...body, files: undefined }),
  }),
  mapResult: ({ result }): FileDTO[] => result.data,
  mapError: ({ error }: DefaultResponseFail) => defaultErrorHandler(error.response?.data),
})
