import { sample } from 'effector'

import { DocumentAlias } from '@/dal'

import { $documentId, ActGate } from '../../model'
import {
  $actionGroups,
  $articleItems,
  $filters,
  $hasChangedGaFilters,
  $worksTableSearch,
  acceptGaFilters,
  changeSearchName,
  clearFilter,
  filtersForm,
  getActArticleFx,
  getGaActingGroupOptionsFx,
  resetFilters,
  resetGaFilters,
  resetSearchName,
  setFilter,
} from './filters.private'

$worksTableSearch.on(changeSearchName, (_, val) => val).reset(resetSearchName, ActGate.close)

$articleItems
  .on(getActArticleFx.doneData, (_, items) =>
    items.map((item) => ({ label: item.name, id: item.id })),
  )
  .reset(ActGate.close)

$hasChangedGaFilters.on(setFilter, () => true).reset(acceptGaFilters, resetGaFilters)

$actionGroups
  .on(getGaActingGroupOptionsFx.doneData, (_, items) =>
    items.map(({ id, name }) => ({ id, label: name })),
  )
  .reset(ActGate.close)

sample({
  clock: [ActGate.close, resetFilters],
  target: [filtersForm.reset, resetSearchName, clearFilter],
})

sample({
  clock: ActGate.open,
  source: $documentId,
  filter: Boolean,
  fn: (id) => ({ id, documentType: DocumentAlias.ACT }),
  target: getActArticleFx,
})

sample({
  clock: acceptGaFilters,
  source: $filters,
  target: filtersForm.setForm,
})

sample({
  clock: resetGaFilters,
  target: [clearFilter, filtersForm.reset],
})
