import React from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { useId } from '@floating-ui/react'
import {
  DialogSheetWrapper,
  Icon2,
  NewIconButton,
  palette,
  TextMLight,
  Typography,
  usePopup,
} from '@/ui'

import { getSectionTagName, SectionGroupOption } from '../../model'
import { SectionSelectList } from './SectionSelectList'
import { TagWrapper } from './TagWrapper'

type Props = {
  label: string
  sections: SectionGroupOption[]
  isReadOnly?: boolean
  onChangeSection: (id: number, val: boolean) => void
  onChangeFloor: (id: number, val: boolean) => void
}

export const SectionMultipleSelect = ({
  label,
  sections,
  isReadOnly = false,
  onChangeSection,
  onChangeFloor,
}: Props) => {
  const { floatingStyles, getFloatingProps, getReferenceProps, isOpen, refs } = usePopup({
    sameComponentWidth: true,
    offsetNumber: 16,
    placement: 'bottom',
  })

  const id = useId()

  const handleDeleteSection = (e: React.MouseEvent, id: number) => {
    e.stopPropagation()
    onChangeSection(id, false)
  }

  const selectedSections = sections.filter(
    ({ isIndeterminate, isSelected }) => isSelected || isIndeterminate,
  )

  return (
    <>
      <Wrapper>
        <label htmlFor={id}>
          <Typography textStyle="M">{label}</Typography>
        </label>

        <Container $hasAccent={isOpen} $isReadOnly={isReadOnly}>
          <OverlayButton
            type="button"
            disabled={isReadOnly}
            id={id}
            ref={refs.setReference}
            {...getReferenceProps()}
          />
          <Items>
            {selectedSections.length ? (
              selectedSections.map((item) => (
                <TagWrapper key={item.id}>
                  <p>{getSectionTagName(item)}</p>

                  {!isReadOnly && (
                    <DeleteBtnWrapper>
                      <NewIconButton
                        icon="cross-thin"
                        color="accent100"
                        size={10}
                        dataTestId=""
                        onClick={(e) => handleDeleteSection(e, item.id)}
                      />
                    </DeleteBtnWrapper>
                  )}
                </TagWrapper>
              ))
            ) : (
              <Typography color="grey60">Выберите элементы</Typography>
            )}
          </Items>

          {!isReadOnly && (
            <Icon2 icon={isOpen ? 'arrowUp' : 'arrowDown'} color="grey60" size={16} />
          )}
        </Container>
      </Wrapper>

      {isOpen &&
        !isReadOnly &&
        createPortal(
          <Dropdown ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
            <SectionSelectList
              sections={sections}
              onChangeSection={onChangeSection}
              onChangeFloor={onChangeFloor}
            />
          </Dropdown>,
          document.body,
        )}
    </>
  )
}

const DeleteBtnWrapper = styled.div`
  z-index: 1;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const Container = styled.div<{ $isReadOnly: boolean; $hasAccent: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;

  min-height: 40px;
  padding: 10px 11px;
  border: 1px solid;
  border-radius: 8px;

  border-color: ${({ $hasAccent }) => ($hasAccent ? palette.accent100 : palette.grey40)};
  background-color: ${palette.white};
  transition: 0.15s linear border-color;
  user-select: none;
  cursor: ${({ $isReadOnly }) => ($isReadOnly ? 'default' : 'pointer')};
`

const OverlayButton = styled.button`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  &:disabled {
    cursor: default;
  }
`

const Items = styled.div`
  ${TextMLight}
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  flex-grow: 1;
`

const Dropdown = styled(DialogSheetWrapper)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 0;
`
