import { createSectionTabsModel } from '@/features/factories'

import { EstimateCorrectionGate, KorDocTab } from '../../shared-model'
import { d } from './domain'

export const {
  $activeTabId,
  $invalidActiveTabId,
  changeActiveTab,
  resetActiveTab,
  setInvalidActiveTab,
} = createSectionTabsModel<KorDocTab>({
  defaultValue: KorDocTab.Info,
  clearEvent: EstimateCorrectionGate.close,
  domain: d,
})
