import React from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

import { usePopup } from '../hooks'
import { Icon2 } from '../icons'
import { palette } from '../palette'
import { ActionForItem } from '../types'
import { TextMLight } from '../typography'
import { ContentWithTooltip } from './ContentWithTooltip'
import { DialogSheetWrapper } from './DialogSheetWrapper'

type Props = {
  options: ActionForItem[]
  children: React.ReactNode
  sameComponentWidth?: boolean
  onClose?: () => void
  onOpen?: () => void
  onToggle?: (val: boolean) => void
}

const TOOLTIP_PARAMS = {
  useTooltipParams: {
    placement: 'top',
    hasArrow: true,
  },
  size: 'M',
} as const

export const DropDownList = ({
  children,
  options,
  sameComponentWidth,
  onClose,
  onOpen,
  onToggle,
}: Props) => {
  const {
    floatingStyles,
    getFloatingProps,
    getReferenceProps,
    close: closeDropDown,
    isOpen,
    refs,
  } = usePopup({
    onClose,
    onOpen,
    onToggle,
    sameComponentWidth,
    strategy: 'fixed',
  })

  return (
    <>
      <ButtonWrapper ref={refs.setReference} {...getReferenceProps()}>
        {children}
      </ButtonWrapper>

      {isOpen &&
        createPortal(
          <DropDown ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
            <Scrollbars autoHeight autoHeightMin="0" autoHeightMax="290px">
              {options.map(({ label, icon, iconColor, tooltipText, isDisabled, onClick }) => (
                <ContentWithTooltip
                  key={label + icon}
                  tooltipContent={tooltipText}
                  canDisplayTooltip={Boolean(tooltipText)}
                  {...TOOLTIP_PARAMS}
                >
                  <li>
                    <ListItemButton
                      disabled={isDisabled}
                      onClick={() => {
                        closeDropDown()
                        onToggle?.(false)
                        onClick()
                      }}
                    >
                      {icon && <Icon2 size={16} icon={icon} color={iconColor} />}
                      {label}
                    </ListItemButton>
                  </li>
                </ContentWithTooltip>
              ))}
            </Scrollbars>
          </DropDown>,
          document.body,
        )}
    </>
  )
}

const DropDown = styled(DialogSheetWrapper)`
  padding: 8px 0px;
  min-width: 200px;
  margin: 0;
`

const ButtonWrapper = styled.div``

const ListItemButton = styled.button`
  ${TextMLight}
  display: flex;
  gap: 8px;

  width: 100%;
  padding: 8px 12px;
  transition: 0.15s linear;
  transition-property: background-color;
  text-align: left;

  @media (hover: hover) {
    &:hover:not(:disabled) {
      background-color: ${palette.grey10};
    }
  }

  &:active {
    background-color: ${palette.grey20};
  }

  &:disabled {
    color: ${palette.grey60};
    cursor: not-allowed;
  }
`
