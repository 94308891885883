import React from 'react'
import styled, { css } from 'styled-components'
import { useUnit } from 'effector-react'

import { AvansTableItem, DocumentStatuses } from '@/dal'
import { numberWithSpaces } from '@/lib/number-represent'
import { OverflowText, palette, TextMLight } from '@/ui'

import { $paymentInfo } from '../../../model/private'
import { TableTemplate } from '../parts'
import { TableRowInput } from './TableRowInput'

type Props = AvansTableItem & {
  canEdit: boolean
}

const $isDraft = $paymentInfo.map((info) => info?.status === DocumentStatuses.Draft)

export const TableRow = ({
  avans_offset,
  avans_paid,
  advance_on_agreed,
  avans_remain,
  id,
  sum_avans,
  sum_avans_request,
  sum_contract,
  canEdit,
  turnover_article,
}: Props) => {
  const isDraft = useUnit($isDraft)

  return (
    <Template hasHover={isDraft}>
      <Col>
        <MainText>
          <OverflowText maxLines={2} maxTooltipWidth={250}>
            {turnover_article.name}
          </OverflowText>
        </MainText>
        <SubText>
          <OverflowText maxLines={1} maxTooltipWidth={250}>
            {turnover_article.project}
          </OverflowText>
        </SubText>
      </Col>
      <Col>{numberWithSpaces(sum_contract)}</Col>
      <Col>{numberWithSpaces(sum_avans)}</Col>
      <Col>{numberWithSpaces(avans_paid)}</Col>
      <Col>{numberWithSpaces(advance_on_agreed)}</Col>
      <Col>{numberWithSpaces(avans_offset)}</Col>
      <Col>{numberWithSpaces(avans_remain)}</Col>

      {canEdit ? (
        <TableRowInput id={id} avansRemain={avans_remain} sumAvansRequest={sum_avans_request} />
      ) : (
        <Col>{numberWithSpaces(sum_avans_request)}</Col>
      )}
    </Template>
  )
}

const Template = styled.div<{ hasHover: boolean }>`
  ${TableTemplate}
  &:not(:last-child) {
    border-bottom: 1px solid ${palette.grey20};
  }

  ${({ hasHover }) =>
    hasHover &&
    css`
      @media (hover: hover) {
        transition: background-color 0.15s linear;

        &:hover {
          background-color: ${palette.grey10};
        }
      }
    `}
`

const Col = styled.div`
  padding: 12px;
  gap: 4px;
  ${TextMLight}

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;

  &:first-child {
    padding-left: 20px;
    align-items: flex-start;
  }

  &:last-child {
    padding-right: 20px;
  }

  &:not(:last-child) {
    border-right: 1px solid ${palette.grey20};
  }
`

const MainText = styled.p``

const SubText = styled.p`
  color: ${palette.grey70};
`
