import { attach } from 'effector'

import {
  AvansChangedParams,
  EstimateDoc,
  getEstimateInfoByIdReqFx,
  getKorDocDirectoriesReqFx,
  getKorDocGaFiltersReqFx,
  sendSummaryInfoReqFx,
  updateFeaturesInfoReqFx,
} from '@/dal'
import { createSingleEffect } from '@/lib/createSingleEffect'
import { createDocumentBlocking } from '@/features/factories'
import { createSummaryTableModel } from '@/features/shared/summary-table'

import { d } from './domain'
import { $documentFeatures, $documentId, $documentStatus, $documentTable } from './public'

export const getKorDocGaFilters = d.event()
export const updateSummary = d.event<EstimateDoc['summary_table']>()

export const { abortFx: abortGetDocumentFx, requestFx: getEstimateInfoByIdFx } =
  createSingleEffect(getEstimateInfoByIdReqFx)

export const { requestFx: getKorDocDirectoriesFx } = createSingleEffect(getKorDocDirectoriesReqFx)
export const { requestFx: getKorDocGaFiltersFx } = createSingleEffect(getKorDocGaFiltersReqFx)

export const updateFeaturesInfoFx = attach({ effect: updateFeaturesInfoReqFx })

export const onFieldSubmit = d.event<AvansChangedParams>()
export const onKorDocSentToApprove = d.event()

export const sendSummaryInfoFx = attach({
  effect: sendSummaryInfoReqFx,
})

export const summaryTableModel = createSummaryTableModel({
  $documentStatus,
  $summary: $documentTable,
  $isPending: sendSummaryInfoFx.pending,
  onFieldSubmit,
})

export const { onDocumentUnlocked } = createDocumentBlocking({
  getFeaturesFx: updateFeaturesInfoFx,
  $docId: $documentId,
  $features: $documentFeatures,
  triggerUnits: [onKorDocSentToApprove],
})
