import React from 'react'
import styled from 'styled-components'
import { useStoreMap } from 'effector-react'

import { KvrElement } from '@/dal'
import { DefaultButton, Icon2, TextL } from '@/ui'

import { $expandedKvrIds, toggleExpandedKvr } from '../../model/private'
import { KerItem } from './KerItem'

type Props = {
  item: KvrElement
  depth: number
}

const DEPTH_STEP = 32

export const KvrItem = React.memo(({ item, depth }: Props) => {
  const { id, name, hierarchy_code: code, ker_elements: kerItems } = item

  const isExpanded = useStoreMap({
    store: $expandedKvrIds,
    keys: [id],
    fn: (ids, [id]) => ids.includes(id),
  })

  return (
    <>
      <Wrapper style={{ marginLeft: depth * DEPTH_STEP }} onClick={() => toggleExpandedKvr(id)}>
        <FolderIcons>
          <Icon2
            icon={isExpanded ? 'minusWithCircle' : 'plusWithCircle'}
            color={isExpanded ? 'accent100' : 'grey60'}
            size={12}
          />
          <Icon2 icon={isExpanded ? 'activeFolder' : 'folder'} size={16} />
        </FolderIcons>

        <Name>
          {code} {name}
        </Name>
      </Wrapper>

      {isExpanded && kerItems?.length && (
        <KerItems style={{ paddingLeft: depth * DEPTH_STEP + 24 }}>
          {kerItems.map((item) => (
            <KerItem key={item.id} item={item} />
          ))}
        </KerItems>
      )}
    </>
  )
})

const Wrapper = styled(DefaultButton)`
  display: flex;
  gap: 8px;
  width: fit-content;
  text-align: left;
`

const FolderIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const Name = styled.p`
  ${TextL}
`

const KerItems = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
