import { createGate } from 'effector-react'

import { EstimateDoc, EstimateDocId, GroupListItem, ProjectListItem } from '@/dal'
import { Option } from '@/ui'

import { d } from './domain'
import { OptionWithParentId, UpdateSummaryParams } from './types'

export const $allDocumentInfo = d.store<EstimateDoc | null>(null)
export const $documentId = $allDocumentInfo.map((info) => info?.id ?? null)
export const $documentTable = $allDocumentInfo.map((info) => info?.summary_table ?? null)
export const $documentInfo = $allDocumentInfo.map((info) =>
  info
    ? {
        ...info,
        summary_table: undefined,
      }
    : null,
)
export const $documentStatus = $documentInfo.map((info) => info?.status ?? null)
export const $documentFeatures = $documentInfo.map((info) => info?.features ?? null)
export const $hasSplittingObjects = $documentInfo.map(
  (info) => info?.has_splitting_objects ?? false,
)
export const $kordocExclusiveBlock = $documentInfo.map(
  (info) => info?.features.document_exclusive_block ?? null,
)
export const $isGa = $documentInfo.map((info) => info?.is_act_group_used ?? false)

export const $morphologyDepth = d.store(0)
export const $morphologyDepthFromDocInfo = $documentInfo.map((info) => info?.morphology_depth ?? 0)
export const setMorphologyDepth = d.event<number>()

export const updateDocumentInfo = d.event<EstimateDoc>()

export const $summaryTotalSums = $documentTable.map((summary) =>
  summary
    ? {
        totalCost: summary.sum_all.approved || summary.sum_all.adjusted || summary.sum_all.current,
        materialTotalCost:
          summary.sum_material.approved ||
          summary.sum_material.adjusted ||
          summary.sum_material.current,
        workTotalCost:
          summary.sum_work.approved || summary.sum_work.adjusted || summary.sum_work.current,
      }
    : null,
)

export const EstimateCorrectionGate = createGate<EstimateDocId>()
export const onUpdateSummary = d.event<UpdateSummaryParams>()
export const $unitOptions = d.store<Option[]>([])
export const $groupList = d.store<GroupListItem[] | null>(null)
export const $projectList = d.store<ProjectListItem[] | null>(null)

export const $actingGroupOptions = d.store<Option[]>([])
export const $buildings = d.store<OptionWithParentId[]>([])
export const $sections = d.store<OptionWithParentId[]>([])
export const $floors = d.store<OptionWithParentId[]>([])

export const updateEstimateDirectories = d.event<void>()
export const refetchEstimateDocument = d.event<void>()

export const getUpdatedFeatures = d.event<EstimateDocId>()
export const updateFeatures = d.event<EstimateDoc['features']>()
export const updateAttachments = d.event<EstimateDoc['attachments']>()

export const $isDocumentPending = d.store(false)
