import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { useTooltip } from '@/lib/hooks'
import {
  HeadL,
  LoaderOverlay,
  NewButton,
  NewIconButton,
  NotificationItem,
  NotificationType,
  palette,
  TextSLight,
  ToolTipV2,
} from '@/ui'
import { getMorphologyFx, updateEstimateLotFx } from '@/features/estimate-lot/model/private'
import {
  $hasChangedSelectedMorphology,
  $hasSelectedMorphology,
  $selectedMorphologyBuildings,
  changeMorphologyModal,
  saveSelectedMorphology,
} from '@/features/estimate-lot/selected-morphology/model/private'

import { BuildingItem } from '../containers'

export const ChangeMorphologyModal = React.memo(() => {
  const [
    buildings,
    hasSelectedMorphology,
    hasChangedSelectedMorphology,
    isModalOpen,
    isLoading,
    isSending,
  ] = useUnit([
    $selectedMorphologyBuildings,
    $hasSelectedMorphology,
    $hasChangedSelectedMorphology,
    changeMorphologyModal.$isOpen,
    getMorphologyFx.pending,
    // TODO поменять
    updateEstimateLotFx.pending,
  ])

  const { referenceProps, tooltipProps, isOpen } = useTooltip({
    tooltipOffset: 6,
    tooltipCrossOffset: -8,
    placement: 'top-end',
    hasArrow: true,
  })

  if (!isModalOpen) return null

  const handleClose = changeMorphologyModal.close

  return (
    <Overlay onClick={() => handleClose()}>
      <Wrapper onClick={(e) => e.stopPropagation()}>
        <UpperBlock>
          <p>Редактировать структуру</p>
          <NewIconButton icon="cross" size={20} dataTestId="" onClick={() => handleClose()} />
        </UpperBlock>

        <ObjectList>
          <NotificationItem
            body={
              <NotificationText>
                Вы можете корректировать места работы, выбирая или удаляя нужные вам элементы. Все
                места работ удалить нельзя, необходимо оставить минимум одно.
              </NotificationText>
            }
            type={NotificationType.Warning}
          />

          {buildings.map((item) => (
            <BuildingItem key={item.id} {...item} />
          ))}

          <LoaderOverlay isShown={isLoading} />
        </ObjectList>

        <Buttons>
          <NewButton
            label="Отменить изменения"
            isDisabled={isSending}
            buttonType="line-grey"
            size="S"
            dataTestId=""
            onClick={handleClose}
          />
          <div {...referenceProps}>
            <NewButton
              label="Сохранить"
              type="submit"
              size="S"
              isDisabled={!hasSelectedMorphology || !hasChangedSelectedMorphology}
              isPending={isSending}
              dataTestId=""
              onClick={saveSelectedMorphology}
            />
          </div>
          <ToolTipV2
            {...tooltipProps}
            bgColor="grey80"
            size="M"
            isOpen={isOpen && !hasSelectedMorphology}
          >
            Необходимо выбрать минимум 1 место работы
          </ToolTipV2>
        </Buttons>
      </Wrapper>
    </Overlay>
  )
})

const Overlay = styled.div`
  position: fixed;
  inset: 0;
  z-index: 1000;

  cursor: default;
`

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;

  display: flex;
  flex-direction: column;

  width: min(600px, 100dvw);
  background-color: ${palette.white};
  box-shadow: 0px 5px 14px 0px #aeaeae40;
  cursor: auto;
`

const UpperBlock = styled.div`
  ${HeadL}
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;

  padding: 24px;
  border-bottom: 1px solid ${palette.grey20};
`

const ObjectList = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 16px;

  overflow: auto;
  flex-grow: 1;
  padding: 16px 24px 0;
`

const NotificationText = styled.p`
  ${TextSLight}
`

const Buttons = styled.div`
  display: flex;
  justify-content: end;
  gap: 12px;
  padding: 20px 24px;
`
