import React from 'react'
import styled from 'styled-components'
import { useForm } from 'effector-forms'
import { useUnit } from 'effector-react'

import {
  ExpandableText,
  HeadL,
  NotificationItem,
  NotificationType,
  palette,
  Switch,
  TextAreaField,
  TextM,
} from '@/ui'
import { $canEdit, $documentInfo } from '@/features/estimate-lot/model'
import { lotInfoForm } from '@/features/estimate-lot/model/forms'
import { $isActionPending } from '@/features/estimate-lot/model/header.private'

import { CommercialInfoData } from './CommercialInfoData'
import { CommercialInfoForm } from './CommercialInfoForm'

export const LotCommercialInfo = React.memo(() => {
  const [info, canEdit, isPending] = useUnit([$documentInfo, $canEdit, $isActionPending])

  const { fields } = useForm(lotInfoForm)

  if (!info) return null

  const handleChangeIsTypicalForm = () => {
    fields.is_typical_form_of_contract.onChange(!fields.is_typical_form_of_contract.value)
  }

  return (
    <Wrapper>
      <Header>
        <Title>Данные коммерческого предложения</Title>
        <Switch
          label="Типовая форма договора"
          isChecked={fields.is_typical_form_of_contract.value}
          dataTestId="lot-typical-contract-form-switch"
          disabled={!canEdit || isPending}
          onChange={handleChangeIsTypicalForm}
        />
      </Header>

      {canEdit && (
        <NotificationItem
          body="Условия предзаполнены по Финансовой политике ГК ФСК. В случае изменении информации процесс согласования документа может проходить дольше нормативных сроков."
          type={NotificationType.Warning}
        />
      )}

      <Content>
        {canEdit ? <CommercialInfoForm /> : <CommercialInfoData />}

        {canEdit ? (
          <TextAreaField
            value={fields.comment.value}
            label="Комментарий"
            placeholder="Введите ваш комментарий..."
            height={100}
            maxSymbolCount={1000}
            dataTestId="lot-comment-textarea"
            disabled={!canEdit || isPending}
            autoGrow
            onChange={fields.comment.onChange}
          />
        ) : (
          info.comment && (
            <div>
              <CommentTitle>Комментарий</CommentTitle>
              <ExpandableText text={info.comment} lineCount={3} />
            </div>
          )
        )}
      </Content>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 32px;
  border-radius: 20px;
  background-color: ${palette.white};
`

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  gap: 4px;
`

const Title = styled.h4`
  ${HeadL}
`

const Content = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const CommentTitle = styled.p`
  ${TextM}
  margin-bottom: 4px;
`
