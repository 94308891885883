import styled from 'styled-components'

import { SquareButton } from '../buttons'
import { palette } from '../palette'
import { TextL } from '../typography'

type Props = {
  selectedGroupCount: number
  onClick: () => void
  onReset: () => void
}

export const GroupTreeOpeningButton = ({ selectedGroupCount, onClick, onReset }: Props) => {
  return (
    <Wrapper>
      {Boolean(selectedGroupCount) && (
        <ResetButton type="button" onClick={onReset}>
          Сбросить
        </ResetButton>
      )}

      <OpeningButtonsContainer>
        <TextButton type="button" onClick={onClick}>
          Стоимость по разделам{' '}
          {Boolean(selectedGroupCount) && <SelectedCount>{selectedGroupCount}</SelectedCount>}
        </TextButton>

        <SquareButton icon="tree" size="S" iconSize={20} dataTestId="" onClick={onClick} />
      </OpeningButtonsContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const ResetButton = styled.button`
  ${TextL}
  color: ${palette.grey70};
`

const OpeningButtonsContainer = styled.div`
  display: flex;
`

const TextButton = styled.button`
  ${TextL}
  padding-top: 5px;
  padding-right: 12px;
`

const SelectedCount = styled.span`
  color: ${palette.grey60};
`
