import { sample, split } from 'effector'

import { createToast } from '@/features/toast-service/model'

import { addMaterialsModal, openAddWorksModal } from '../../model/private'
import {
  clearLotMaterial,
  clearLotMaterialFx,
  clearLotWork,
  clearLotWorkFx,
  deleteLotFolder,
  deleteLotFolderFx,
  deleteLotMaterial,
  deleteLotMaterialFx,
  deleteLotWork,
  deleteLotWorkFx,
  materialDeletionModal,
  onOptionClicked,
  rowClearingModal,
  workDeletionModal,
} from './actions.private'
import { setEditingItem } from './private'
import { ActionPayload, ItemAction } from './types'

const cases = {
  addWork: openAddWorksModal,
  deleteWork: workDeletionModal.open.prepend(({ item }: ActionPayload) => item),
  addMaterial: addMaterialsModal.open.prepend(({ item }: ActionPayload) => item),
  deleteMaterial: materialDeletionModal.open.prepend(({ item }: ActionPayload) => item),
  editItem: setEditingItem.prepend(({ item }: ActionPayload) => item.id),
  clearItem: rowClearingModal.open.prepend(({ item }: ActionPayload) => item),
}

split({
  source: onOptionClicked,
  match: {
    addWork: ({ type }) => type === ItemAction.AddWork,
    deleteWork: ({ type }) => type === ItemAction.DeleteWork,
    addMaterial: ({ type }) => type === ItemAction.AddMaterial,
    deleteMaterial: ({ type }) => type === ItemAction.DeleteMaterial,
    editItem: ({ type }) => type === ItemAction.EditItem,
    clearItem: ({ type }) => type === ItemAction.ClearItem,
  },
  cases,
})

// удаление работы или раздела

sample({
  clock: deleteLotWork,
  target: deleteLotWorkFx,
})

sample({
  clock: deleteLotFolder,
  target: deleteLotFolderFx,
})

sample({
  clock: [deleteLotWorkFx.done, deleteLotFolderFx.done],
  target: workDeletionModal.close,
})

// удаление материала

sample({
  clock: deleteLotMaterial,
  target: deleteLotMaterialFx,
})

sample({
  clock: deleteLotMaterialFx.done,
  target: materialDeletionModal.close,
})

// очистка строки

sample({
  clock: clearLotMaterial,
  target: clearLotMaterialFx,
})

sample({
  clock: clearLotWork,
  target: clearLotWorkFx,
})

sample({
  clock: [clearLotMaterialFx.done, clearLotWorkFx.done],
  target: rowClearingModal.close,
})

// тосты

createToast({
  effect: deleteLotWorkFx,
  doneText: ({ params }) => `Строка ${params.name} удалена`,
})

createToast({
  effect: deleteLotFolderFx,
  doneText: ({ params }) => `Строка ${params.name} удалена`,
})

createToast({
  effect: clearLotMaterialFx,
  doneText: ({ params }) => `Строка ${params.name} очищена`,
})

createToast({
  effect: clearLotWorkFx,
  doneText: ({ params }) => `Строка ${params.name} очищена`,
})
