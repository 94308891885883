import { sample } from 'effector'
import { debounce } from 'patronum'

import { DocumentAlias, DocumentStatuses, EstimateLotId, VisasDocType } from '@/dal'
import { openAdditionalRequestModal } from '@/features/additional-request/model'
import { replaceNavigate, RouterPaths } from '@/features/app/model'
import { $documentId } from '@/features/estimate-lot/model'
import { createToast } from '@/features/toast-service/model'

import { lotInfoForm } from './forms'
import { cancelEstimateLotApprovingFx } from './header.private'
import { mapCatalogItemsToOptions, trimTrailingZeros } from './helpers'
import {
  $advanceTypes,
  $directions,
  $projects,
  $typesOfSecurity,
  attachmentsModel,
  getDirectionsFx,
  getEstimateLotAdvanceTypesFx,
  getEstimateLotFx,
  getMorphologyFx,
  getProjectsFx,
  getTypesOfSecurityFx,
  onLotInfoChanged,
  openAdditionalModal,
  openAttachmentsModal,
  sendFilesFx,
  updateDocumentInfo,
  updateEstimateLotFx,
} from './private'
import { $documentInfo, EstimateLotGate } from './public'

import './public.init'
import './header.init'

$documentInfo
  .on([getEstimateLotFx.doneData, updateDocumentInfo], (_, data) => data)
  .on(sendFilesFx.doneData, (data, attachments) => (data ? { ...data, attachments } : null))
  .reset(EstimateLotGate.close)

$projects
  .on(getProjectsFx.doneData, (_, data) => mapCatalogItemsToOptions(data))
  .reset(EstimateLotGate.close)

$directions
  .on(getDirectionsFx.doneData, (_, data) => mapCatalogItemsToOptions(data))
  .reset(EstimateLotGate.close)

$advanceTypes
  .on(getEstimateLotAdvanceTypesFx.doneData, (_, data) => mapCatalogItemsToOptions(data))
  .reset(EstimateLotGate.close)

$typesOfSecurity
  .on(getTypesOfSecurityFx.doneData, (_, data) => mapCatalogItemsToOptions(data))
  .reset(EstimateLotGate.close)

sample({
  clock: EstimateLotGate.open,
  filter: Boolean,
  fn: (id) => ({ id }),
  target: getEstimateLotFx,
})

sample({
  clock: getEstimateLotFx.doneData,
  source: $documentId,
  fn: (id) => ({ id }),
  target: [
    getDirectionsFx,
    getTypesOfSecurityFx,
    getProjectsFx,
    getEstimateLotAdvanceTypesFx,
    getMorphologyFx,
  ],
})

sample({
  clock: getEstimateLotFx.fail,
  fn: () => RouterPaths.EstimateLots,
  target: replaceNavigate,
})

sample({
  clock: [updateEstimateLotFx.doneData, cancelEstimateLotApprovingFx.doneData],
  target: updateDocumentInfo,
})

sample({
  clock: $documentInfo,
  filter: Boolean,
  fn: (info) => ({
    is_typical_form_of_contract: info.is_typical_form_of_contract,
    advance_type: info.advance_type_id,
    percent_advance: trimTrailingZeros(info.percent_advance) ?? '',
    percent_warranty_retention: trimTrailingZeros(info.percent_warranty_retention) ?? '',
    type_security: info.type_of_security_id,
    comment: info.comment ?? '',
  }),
  target: lotInfoForm.setForm,
})

sample({
  clock: openAdditionalModal,
  source: {
    isOpen: EstimateLotGate.status,
    document: $documentInfo,
  },
  filter: ({ isOpen, document }) => Boolean(isOpen && document),
  fn: ({ document }) => ({
    docType: DocumentAlias.ESTIMATE_LOT as VisasDocType,
    docId: document?.id as EstimateLotId,
    status: document?.status as DocumentStatuses,
  }),
  target: openAdditionalRequestModal,
})

sample({
  clock: lotInfoForm.$values,
  filter: lotInfoForm.$touched,
  target: onLotInfoChanged,
})

sample({
  clock: debounce({
    source: onLotInfoChanged,
    timeout: 1000,
  }),
  source: {
    id: $documentId,
    form: lotInfoForm.$values,
  },
  filter: ({ id }) => Boolean(id),
  fn: ({ id, form }) => ({
    ...form,
    id: id!,
  }),
  target: [updateEstimateLotFx, lotInfoForm.resetTouched],
})

sample({
  clock: openAttachmentsModal,
  source: $documentId,
  filter: Boolean,
  fn: (id) => ({ id }),
  target: attachmentsModel.open,
})

createToast({
  effect: updateEstimateLotFx,
  doneText: 'Изменения успешно сохранены',
  // TODO не выводится
  errorText: 'Произошла ошибка при сохранении изменений',
})
