import { sample } from 'effector'

import { DocumentAlias } from '@/dal'
import { getDocumentNameByType } from '@/lib/documentResolvers'
import {
  onAdditionalRequestAnswerRead,
  onAdditionalRequestUpdated,
} from '@/features/additional-request/model'
import { redirectOnFail, RouterPaths } from '@/features/app/model'
import { createToast } from '@/features/toast-service/model'

import { postActReportingDatesFx, sendFilesFx, updateActCommentFx } from './info.private'
import { deletePaymentOrdersFx, postPaymentOrdersFx } from './payment-orders.private'
import {
  $hasChangedTables,
  abortGetActInfoFx,
  getActInfoFx,
  markTablesAsChanged,
  refetchAct,
  resetHasChangedTables,
  updateDocumentInfo,
  updateFeaturesFx,
} from './private'
import {
  $allDocumentInfo,
  $documentId,
  $isDocumentPending,
  ActGate,
  updateFeatures,
  updateKSData,
} from './public'

import './header.init'
import './info.init'
import './payment-orders.init'

$isDocumentPending.on(getActInfoFx.pending, (_, isPending) => isPending)

$allDocumentInfo
  .on([getActInfoFx.doneData, updateDocumentInfo], (_, info) => info)
  .on(sendFilesFx.doneData, (info, attachments) =>
    !info
      ? null
      : {
          ...info,
          attachments,
        },
  )
  .on(updateFeaturesFx.doneData, (info, features) =>
    !info
      ? null
      : {
          ...info,
          features,
        },
  )
  .on(updateKSData, (info, { ...data }) =>
    !info
      ? null
      : {
          ...info,
          ...data,
        },
  )
  .on(postPaymentOrdersFx.doneData, (info, payment_orders) =>
    !info
      ? null
      : {
          ...info,
          payment_orders,
        },
  )
  .on(deletePaymentOrdersFx.done, (info, { params }) =>
    !info
      ? null
      : {
          ...info,
          payment_orders: info.payment_orders?.filter(({ id }) => params.toDeleteId !== id) ?? null,
        },
  )
  .on(updateActCommentFx.doneData, (info, { comment }) => (!info ? null : { ...info, comment }))
  .on(postActReportingDatesFx.doneData, (info, { date_to, name }) =>
    !info?.report_period
      ? null
      : {
          ...info,
          name,
          report_period: {
            ...info.report_period,
            date_end: date_to,
          },
        },
  )
  .on(onAdditionalRequestAnswerRead, (info) =>
    !info
      ? null
      : {
          ...info,
          features: {
            ...info.features,
            has_unread_answer: false,
          },
        },
  )
  .reset(ActGate.close)

$hasChangedTables.on(markTablesAsChanged, () => true).reset(ActGate.close, resetHasChangedTables)

sample({
  clock: ActGate.close,
  target: abortGetActInfoFx,
})

sample({
  clock: [ActGate.open, ActGate.state, onAdditionalRequestUpdated, refetchAct],
  source: {
    isOpen: ActGate.status,
    actId: ActGate.state,
  },
  filter: ({ actId, isOpen }) => isOpen && Boolean(actId) && !Number.isNaN(actId),
  fn: ({ actId }) => ({ actId }),
  target: getActInfoFx,
})

sample({
  clock: updateFeatures,
  source: $documentId,
  filter: (currId, id) => currId === id,
  fn: (_, id) => id,
  target: updateFeaturesFx,
})

redirectOnFail({
  effect: getActInfoFx,
  route: RouterPaths.Administrative,
})

// тосты

createToast({
  effect: getActInfoFx,
  errorText: `Не удалось загрузить ${getDocumentNameByType(DocumentAlias.ACT)}`,
})

createToast({
  effect: deletePaymentOrdersFx,
})
