import React, { MouseEvent } from 'react'
import styled, { css } from 'styled-components'

import { NewIconButton } from '../buttons'
import { Icon2, IconName2 } from '../icons'
import { palette } from '../palette'
import { TextMLight } from '../typography'
import { InputSize } from './types'

type Props = {
  children: React.ReactElement
  value: string
  size?: InputSize
  isInvalid?: boolean
  disabled?: boolean
  postfixIcon?: IconName2
  readOnly?: boolean
  isSelect?: boolean
  isSelectAfterClear?: boolean
  dataTestId: string
  error?: string | null
  isMaskInput?: boolean
  selectedItemsCount?: number
  isFill?: boolean
  onPostfixIconClick?: () => void
  onClear?: () => void
}

export const NewInputWrapper = ({
  children,
  onClear,
  onPostfixIconClick,
  error,
  size = 'M',
  dataTestId,
  value,
  isInvalid,
  disabled,
  postfixIcon,
  readOnly,
  isSelect = false,
  isSelectAfterClear = true,
  isMaskInput,
  selectedItemsCount,
  isFill,
}: Props) => {
  const ref = React.useRef<HTMLInputElement>(null)

  const refPropName = isMaskInput ? 'getInputRef' : 'ref'
  const input = React.cloneElement(children, { [refPropName]: ref })

  const handleClickWrapper = () => {
    ref.current?.focus()
  }

  const showClearIcon = value.length > 0 && Boolean(onClear) && !disabled

  const handleClear = (e: MouseEvent) => {
    if (!onClear) return
    onClear()
    e.stopPropagation()
    if (isSelectAfterClear) {
      ref.current?.focus()
    }
  }

  React.useEffect(() => {
    if (isSelect) {
      ref.current?.focus()
    } else {
      ref.current?.blur()
    }
  }, [isSelect])

  return (
    <Container isFill={isFill} className="input-container">
      <Wrapper
        onClick={handleClickWrapper}
        size={size}
        isInvalid={Boolean(isInvalid)}
        disabled={Boolean(disabled)}
        readOnly={readOnly}
        error={error}
      >
        {input}

        {Boolean(selectedItemsCount) && (
          <SelectedItemsCount>({selectedItemsCount})</SelectedItemsCount>
        )}

        {showClearIcon && (
          <ClearIconWrapper>
            <NewIconButton
              onClick={handleClear}
              color="grey60"
              hoverColor="grey80"
              size={16}
              icon="cross"
              dataTestId={`${dataTestId}-clear`}
            />
          </ClearIconWrapper>
        )}

        {postfixIcon &&
          (onPostfixIconClick ? (
            <NewIconButton
              onClick={onPostfixIconClick}
              color="grey60"
              hoverColor="grey80"
              disabled={disabled}
              size={16}
              dataTestId={`${dataTestId}-postfix-icon`}
              icon={postfixIcon}
            />
          ) : (
            <Icon2 size={16} icon={postfixIcon} color="grey60" />
          ))}
      </Wrapper>

      {error && (
        <ErrorWrapper>
          <Icon2 icon="alert" color="red100" />
          <Error>{error}</Error>
        </ErrorWrapper>
      )}
    </Container>
  )
}

const Container = styled.div<{ isFill?: boolean }>`
  ${({ isFill }) =>
    isFill &&
    css`
      width: 100%;
    `}
`

type WrapperProps = {
  size: InputSize
  isInvalid: boolean
  disabled: boolean
  readOnly?: boolean
  error?: string | null
}

const paddingSize: { [key in InputSize]: string } = {
  L: '11px',
  M: '7px 11px',
  S: '0px 7px',
}

const Wrapper = styled.div<WrapperProps>`
  padding: ${({ size }) => paddingSize[size]};
  border-radius: 8px;
  border: 1px solid ${palette.grey40};
  transition: border-color 0.15s linear;
  background-color: ${palette.white};
  display: flex;
  align-items: center;
  gap: 4px;
  user-select: none;
  cursor: text;

  ${({ disabled }) =>
    !disabled &&
    css`
      @media (hover: hover) {
        &:hover {
          border-color: ${palette.grey60};
        }
      }
    `}

  &:focus-within {
    border-color: ${palette.accent100};
  }

  ${({ isInvalid, error }) =>
    (isInvalid || error) &&
    css`
      border-color: ${palette.red100} !important;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${palette.grey10};
      cursor: not-allowed !important;
    `}

  ${({ readOnly }) =>
    readOnly &&
    css`
      cursor: pointer;
    `}
`

const ErrorWrapper = styled.div`
  display: flex;
  margin-top: 6px;
  gap: 4px;
`

const Error = styled.div`
  ${TextMLight}
  color: ${palette.red100};
`

const SelectedItemsCount = styled.div`
  ${TextMLight}
`

const ClearIconWrapper = styled.div`
  position: relative;
  z-index: 1;
  display: inline-flex;
`
