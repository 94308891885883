import React from 'react'
import styled, { css } from 'styled-components'

import { NewIconButton } from '../../buttons'

export type CellExpansionButtonProps = {
  expandButtonAlign?: 'center' | 'bottom'
  groupNumber?: number
  isExpanded?: boolean
  expandButtonZIndex?: number
  onExpandButton?: (groupNumber: number) => void
}

export const CellExpansionButton = ({
  expandButtonAlign = 'center',
  groupNumber,
  isExpanded,
  expandButtonZIndex,
  onExpandButton,
}: CellExpansionButtonProps) => {
  return groupNumber !== undefined && onExpandButton ? (
    <ButtonWrapper
      $isExpanded={Boolean(isExpanded)}
      $zIndex={expandButtonZIndex}
      $expandButtonAlign={expandButtonAlign}
    >
      <NewIconButton
        padding="4px"
        dataTestId={`expand-group-${groupNumber}`}
        icon={isExpanded ? 'arrowLeft' : 'arrowRight'}
        size={16}
        borderRadius="50%"
        color="white"
        backgroundColor={isExpanded ? 'grey80' : 'grey50'}
        hoverBackgroundColor={'accent80'}
        onClick={() => onExpandButton(groupNumber)}
      />
    </ButtonWrapper>
  ) : null
}

const ButtonWrapper = styled.div<{
  $isExpanded: boolean
  $zIndex?: number
  $expandButtonAlign: CellExpansionButtonProps['expandButtonAlign']
}>`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  transform: ${({ $isExpanded }) =>
    $isExpanded ? 'translateX(-50%)' : 'translateX(calc(-50% - 2px))'};
  align-items: center;

  ${({ $zIndex }) =>
    $zIndex &&
    css`
      z-index: ${$zIndex};
    `}

  ${({ $expandButtonAlign }) =>
    $expandButtonAlign === 'bottom' &&
    css`
      align-items: flex-end;
      bottom: 12px;
    `}
`
