import React from 'react'
import { useUnit } from 'effector-react'

import { DocumentAlias } from '@/dal'
import { DocumentHeader } from '@/ui'
import { RouterPaths } from '@/features/app/model'
import { usePendingDocument } from '@/features/document-manager/hooks'
import {
  $activeTabId,
  $documentId,
  changeActiveTab,
  ESTIMATE_LOT_TABS,
} from '@/features/estimate-lot/model'

import {
  $headDocumentInfo,
  cancelEstimateLotApprovingFx,
  onCopy,
  onDeleteButton,
  onSendToApprove,
  sendEstimateLotToApproveFx,
} from '../../../model/header.private'

export const EstimateLotHeader = React.memo(() => {
  const [info, id, activeTab, isPendingApprove, isCancelPending] = useUnit([
    $headDocumentInfo,
    $documentId,
    $activeTabId,
    sendEstimateLotToApproveFx.pending,
    cancelEstimateLotApprovingFx.pending,
  ])

  const { isDeleting } = usePendingDocument({
    id,
    type: DocumentAlias.ESTIMATE_LOT,
  })

  if (!info) return null

  const canDelete = info.features.can_delete
  const showApproveButton = info.features.can_send_to_approving || info.features.can_approve
  const canCancel = info.features.can_cancel_approving
  const canCopy = info.features.can_copy
  const canRejectApproving = info.features.can_reject_approving

  const approveTitle = info.features.can_send_to_approving
    ? 'Отправить на согласование'
    : 'Согласовать'

  return (
    <DocumentHeader
      documentName={info.name}
      lastUpdate={info.lastUpdate}
      link={RouterPaths.EstimateLots}
      canCancelApproving={canCancel}
      canCopy={canCopy}
      canDelete={canDelete}
      canRejectApproving={canRejectApproving}
      approveTitle={approveTitle}
      showApproveButton={showApproveButton}
      isPendingApprove={isPendingApprove}
      isCancelPending={isCancelPending}
      isPendingDelete={isDeleting}
      downloadInstructionURL={info.instructionLink}
      tabs={ESTIMATE_LOT_TABS}
      activeTabId={activeTab}
      onApproveButton={onSendToApprove}
      // TODO нет бэка
      // onCancelApprove={openCancelApprovingModal}
      onDeleteButton={onDeleteButton}
      onCopy={onCopy}
      onChangeTab={changeActiveTab}
    />
  )
})
