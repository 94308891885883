import React from 'react'
import styled, { css } from 'styled-components'

import { palette } from '../../palette'
import { TextMLight } from '../../typography'
import { CellInputWrapper } from './CellInputWrapper'

type Props = {
  textAlign?: 'right' | 'center' | 'left'
  value: string
  isInvalid?: boolean
  placeholder?: string
  suffix?: string
  dataTestId: string
  onChange: (val: string) => void
}

export const CellTextInput = ({
  value,
  isInvalid,
  placeholder,
  textAlign,
  suffix,
  dataTestId,
  onChange,
}: Props) => {
  const ref = React.useRef<HTMLInputElement>(null)

  const [isOpen, setIsOpen] = React.useState(false)

  React.useEffect(() => {
    if (isOpen) {
      ref.current?.focus()
    }
  }, [isOpen])

  return (
    <CellInputWrapper
      value={value || placeholder || ''}
      suffix={suffix}
      textAlign={textAlign}
      padding="12px"
      isInvalid={Boolean(isInvalid)}
      isOpen={isOpen}
      onClick={() => setIsOpen(true)}
    >
      <Input
        ref={ref}
        data-testid={dataTestId}
        value={value}
        textAlign={textAlign}
        placeholder={placeholder}
        isInvalid={Boolean(isInvalid)}
        onChange={(e) => onChange(e.target.value)}
        onBlur={() => setIsOpen(false)}
      />
    </CellInputWrapper>
  )
}

type InputProps = {
  textAlign?: 'right' | 'center' | 'left'
  isInvalid: boolean
}

const Input = styled.input<InputProps>`
  background-color: transparent;
  height: 100%;
  border: none;
  outline: none;
  transition: color 0.15s linear;
  ${TextMLight}
  width: 100%;
  ${({ textAlign }) =>
    textAlign &&
    css`
      text-align: ${textAlign};
    `}

  ${({ isInvalid: isError }) =>
    isError &&
    css`
      color: ${palette.red100};
      &::placeholder {
        color: ${palette.red100};
      }
    `}
`
