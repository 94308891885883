import React, { KeyboardEvent } from 'react'
import styled from 'styled-components'

import { NewInputWrapper } from './NewInputWrapper'
import { InputStyle } from './styles'
import { DefaultInputProps } from './types'

type Props = DefaultInputProps

export const NewInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      onChange,
      onClear,
      onPostfixIconClick,
      onEnterPress,
      onBlur,
      onFocus,
      error,
      size = 'M',
      dataTestId,
      value,
      isInvalid,
      placeholder,
      disabled,
      type = 'text',
      name,
      postfixIcon,
      maxLength,
      readOnly,
      isSelect = false,
      isSelectAfterClear = true,
      selectedItemsCount,
      showMaxLengthError,
    },
    ref,
  ) => {
    const [inputError, setInputError] = React.useState('')

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        onEnterPress?.()
      }
    }

    const handleChange = (value: string) => {
      if (maxLength && value.length > maxLength) {
        setInputError(`Введено максимальное кол-во символов – ${maxLength}`)
        onChange(value.slice(0, maxLength))
      } else {
        setInputError('')
        onChange(value)
      }
    }

    const handleBlur = () => {
      setInputError('')
      onBlur?.()
    }

    return (
      <NewInputWrapper
        onClear={onClear}
        onPostfixIconClick={disabled ? undefined : onPostfixIconClick}
        value={value}
        size={size}
        error={inputError || error}
        isInvalid={Boolean(isInvalid || inputError)}
        disabled={Boolean(disabled)}
        readOnly={readOnly}
        postfixIcon={postfixIcon}
        isSelect={isSelect}
        isSelectAfterClear={isSelectAfterClear}
        dataTestId={dataTestId}
        selectedItemsCount={selectedItemsCount}
      >
        <Input
          ref={ref}
          data-testid={dataTestId}
          maxLength={maxLength && showMaxLengthError ? maxLength + 1 : maxLength}
          readOnly={readOnly}
          placeholder={placeholder}
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          disabled={disabled}
          name={name}
          type={type}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          onFocus={onFocus}
        />
      </NewInputWrapper>
    )
  },
)

const Input = styled.input`
  ${InputStyle}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
