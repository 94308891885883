import React from 'react'
import { combine } from 'effector'
import { useUnit } from 'effector-react'

import { Typography } from '@/ui'
import { ActionModal } from '@/features/estimate-lot/shared'

import {
  deleteLotFolder,
  deleteLotFolderFx,
  deleteLotWork,
  deleteLotWorkFx,
  workDeletionModal,
} from '../../model/actions.private'

const $isPending = combine(deleteLotWorkFx.pending, deleteLotFolderFx.pending, (...args) =>
  args.some((val) => val),
)

export const WorkDeletionModal = React.memo(() => {
  const [item, isPending] = useUnit([workDeletionModal.$meta, $isPending])

  if (!item) return null

  // пока так, мб потом будут ещё группы и разделы работ в этой же модалке
  const isWork = true

  const handleDelete = () => {
    if (isWork) {
      deleteLotWork(item)
    } else {
      deleteLotFolder(item)
    }
  }

  return (
    <ActionModal
      title="Удалить работу"
      submitLabel="Удалить"
      isPending={isPending}
      onClose={workDeletionModal.close}
      onSubmit={handleDelete}
    >
      <p>Вы действительно хотите удалить работу «{item.name}»?</p>
      {isWork && (
        <Typography textStyle="LL" color="grey70">
          Все материалы, добавленные к данной работе, также будут удалены
        </Typography>
      )}
    </ActionModal>
  )
})
