import { Domain, Unit } from 'effector'

import { root } from '@/root-domain'

type Params = {
  domain?: Domain
  resetUnits?: Unit<unknown>[]
}

export const createSelectedIds = <T = number>({ domain, resetUnits = [] }: Params = {}) => {
  const d = domain ?? root.domain()

  const $idSet = d.store<Set<T>>(new Set())

  const toggleId = d.event<T>()
  const selectId = d.event<T>()
  const deselectId = d.event<T>()

  const selectIds = d.event<T[]>()
  const deselectIds = d.event<T[]>()

  const replaceAll = d.event<T[]>()
  const resetAll = d.event()

  const $ids = $idSet.map((set) => [...set])

  $idSet
    .on(toggleId, (set, id) => set.symmetricDifference(new Set([id])))
    .on(selectId, (set, id) => set.union(new Set([id])))
    .on(deselectId, (set, id) => set.difference(new Set([id])))
    .on(selectIds, (set, ids) => {
      const setToSelect = new Set(ids)
      return set.union(setToSelect)
    })
    .on(deselectIds, (set, ids) => {
      const setToDeselect = new Set(ids)
      return set.difference(setToDeselect)
    })
    .on(replaceAll, (_, ids) => new Set(ids))
    .reset(resetAll, ...resetUnits)

  return {
    $ids,
    toggleId,
    selectId,
    deselectId,
    selectIds,
    deselectIds,
    replaceAll,
    resetAll,
  }
}
