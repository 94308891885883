import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { useCheckVerticallyOverflowed } from '@/lib/hooks/useCheckVerticallyOverflowed'
import { ExpandableTableWrapper } from '@/ui/v2/components/expandable-table/ExpandableTableWrapper'

import { $activeTabId } from '../../../model'
import {
  $filteredAdvanceTableItems,
  isTableExpanded,
  isTableOverflowed,
} from '../../../model/table.private'
import { AvansTable, TableFilters, TableHeader } from '../containers'

export const PaymentAvansTable = React.memo(() => {
  const [tableItems, activeTabId, isExpanded] = useUnit([
    $filteredAdvanceTableItems,
    $activeTabId,
    isTableExpanded.$value,
  ])

  const { ref, isOverflowed } = useCheckVerticallyOverflowed<HTMLDivElement>({
    deps: [tableItems, activeTabId, isExpanded],
  })

  React.useEffect(() => {
    isTableOverflowed.set(isOverflowed)
  }, [isOverflowed])

  return (
    <Wrapper>
      <ExpandableTableWrapper isExpanded={isExpanded}>
        <TableFilters />
        <TableWrapper ref={ref}>
          <TableHeader />
          <AvansTable />
        </TableWrapper>
      </ExpandableTableWrapper>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  overflow: auto;
  height: 0;
`
