import React from 'react'
import styled from 'styled-components'

import { numberWithSpaces } from '@/lib/number-represent'
import { EditableValue } from '@/ui'

import { Col, Value } from './sharedCSS'

type Props = {
  type?: 'currency' | 'percent'
  isBold?: boolean
  value: string
  isChangeable?: boolean
  isGrey?: boolean
  isApproved?: boolean
  onSubmit?: (val: string) => void
}

export const CurrencyCol = ({
  type = 'currency',
  value,
  isBold,
  isChangeable,
  isGrey,
  isApproved,
  onSubmit,
}: Props) => {
  const formatString = (string: string) =>
    type === 'currency' ? numberWithSpaces(string.toString()) : `${string} %`

  return (
    <Col>
      {isChangeable && onSubmit ? (
        <EditableValueWrapper>
          <EditableValue
            onSubmit={onSubmit}
            value={value}
            cellType="currency"
            placeholder="Введите цену"
          />
        </EditableValueWrapper>
      ) : (
        <Value $isBold={isBold} $isGrey={isGrey} $isApproved={isApproved}>
          {formatString(value)}
        </Value>
      )}
    </Col>
  )
}

const EditableValueWrapper = styled.div`
  position: relative;
  height: 18px;
`
