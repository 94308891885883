import React from 'react'
import styled from 'styled-components'

import {
  DocumentAlias,
  DocumentFeatures,
  DocumentProgress,
  DocumentStatuses,
  RejectionReason,
} from '@/dal'

import { palette } from '../../palette'
import { DocumentStatusLabel } from '../labels'
import { DocumentInfoTop } from './DocumentInfoTop'
import { DocumentInfoBottom } from './DocumentInfoBottom'
import { AdditionalRequestButton } from './AdditionalRequestButton'
import { ConnectedPayment } from './ConnectedPayment'
import { ConnectedPaymentType } from '../../types'
import { RejectionInfo } from './RejectionInfo'
import { AdditionalAgreementNote } from './AdditionalAgreementNote'

type Props = {
  status: DocumentStatuses
  progress?: DocumentProgress | null
  rejectionReason?: RejectionReason
  cancelComment?: {
    description: string
    help_description: string
  } | null
  features: DocumentFeatures
  contract?: {
    name?: string,
    id?: number,
    project: {
      id: number
      name?: string
    }
  }
  newDocument?: {
    name: string
    type: DocumentAlias
    id: number
    status: DocumentStatuses
  } | null
  connectedPayment?: ConnectedPaymentType | null
  /** @deprecated */
  bottomSlot?: React.ReactNode | null
  onOpenAdditional: () => void
}

export const DocumentInfoBlock = ({
  status,
  progress,
  rejectionReason,
  cancelComment,
  features,
  contract,
  newDocument,
  connectedPayment,
  bottomSlot,
  onOpenAdditional,
}: Props) => {
  return (
    <Wrapper>
      {progress ? (
        <DocumentInfoTop
          progress={progress}
          status={status}
          rejectionReason={rejectionReason}
          cancelComment={cancelComment}
          connectedPayment={connectedPayment}
        />
      ) : (
        <StatusWrapper>
          <DocumentStatusLabel status={status} />
          {newDocument && <AdditionalAgreementNote {...newDocument} />}
          {connectedPayment && <ConnectedPayment {...connectedPayment} />}
          <RejectionInfo
            status={status}
            rejectionReason={rejectionReason}
            cancelComment={cancelComment}
          />
        </StatusWrapper>
      )}

      <AdditionalRequestButton features={features} onOpenAdditional={onOpenAdditional} />

      <Divider />

      {contract && (
        <DocumentInfoBottom contract={contract} />
      )}
      {bottomSlot}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 20px;
  border-radius: 20px;
  background-color: ${palette.white};
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${palette.grey30};
`
