import React, { memo } from 'react'
import styled from 'styled-components'

import { DocumentAlias, EstimateLotListItem } from '@/dal'
import { formatDateString } from '@/lib/date'
import { numberWithSpaces } from '@/lib/number-represent'
import { ActionButton, DocumentStatusLabel, NewTextButton, palette, TextMLight } from '@/ui'
import { RouterPaths } from '@/features/app/model'

import { onAdditionalButtonClicked } from '../../model/private'
import { Cell, GridTemplate } from '../parts'

type Props = {
  item: EstimateLotListItem
}

export const EstimateLotItem = memo(({ item }: Props) => {
  const {
    id,
    date,
    name,
    period,
    status,
    sums,
    progress,
    project_name: projectName,
    contract_id: contractId,
    contract_name: contractName,
    answer_required: isAnswerRequired,
    available_actions: availableActions,
  } = item

  const dates =
    period.date_start && period.date_stop
      ? `${formatDateString(period.date_start)} – ${formatDateString(period.date_stop)}`
      : ''

  const hasBadge = Boolean(availableActions.additional_request && isAnswerRequired)

  return (
    <Row>
      <Cell>
        <NewTextButton
          url={`${RouterPaths.EstimateLots}/${id}`}
          label={name}
          textStyle="ML"
          dataTestId={`${id}-lot-list-doc-link"`}
          hasUnderline
          isNewBlank
          isInline
        />
      </Cell>

      <Cell>{formatDateString(date)}</Cell>

      <Cell>{projectName}</Cell>

      <Cell>{dates}</Cell>

      <Cell>
        {contractName ? (
          <NewTextButton
            url={`${RouterPaths.Administrative}/${contractId}`}
            label={contractName}
            textStyle="ML"
            dataTestId={`${id}-lot-list-contract-link"`}
            hasUnderline
            isNewBlank
            isInline
          />
        ) : (
          <GreyText>Новый договор</GreyText>
        )}
      </Cell>

      <Cell>{numberWithSpaces(sums)}</Cell>

      <StatusCell>
        <DocumentStatusLabel status={status} progress={progress} progressPosition="right" isShort />
      </StatusCell>

      <ActionsWrapper>
        <ActionButton
          icon={hasBadge ? 'mailWithDot' : 'mail'}
          color={hasBadge ? 'accent100' : 'grey60'}
          isDisabled={false}
          dataTestId={`${id}-open-additional-request-btn`}
          onClick={() =>
            onAdditionalButtonClicked({
              status,
              docId: id,
              docType: DocumentAlias.ESTIMATE_LOT,
              hasUnreadAnswer: isAnswerRequired,
            })
          }
        />
      </ActionsWrapper>
    </Row>
  )
})

const Row = styled.div`
  ${GridTemplate}
  transition: background-color 0.3s linear;

  &:not(:first-child) {
    border-top: 1px solid ${palette.grey20};
  }

  &:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${palette.grey10};
    }
  }
`

const GreyText = styled.p`
  ${TextMLight}
  color: ${palette.grey70};
`

const StatusCell = styled(Cell)`
  padding: 12px;
`

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
