import { attach, combine } from 'effector'

import { cancelEstimateLotApprovingReqFx, sendEstimateLotToApproveReqFx } from '@/dal'

import { $documentInfo } from './public'
import { d } from './domain'
import { updateEstimateLotFx } from './private'

export const $headDocumentInfo = $documentInfo.map(
  (info) =>
    info && {
      name: info.name,
      lastUpdate: info.updated_at,
      features: info.features,
      instructionLink: info.instruction_link,
    },
)

export const onSendToApprove = d.event()
export const onDeleteButton = d.event()
export const onCopy = d.event()

export const sendEstimateLotToApproveFx = attach({
  effect: sendEstimateLotToApproveReqFx,
})

export const cancelEstimateLotApprovingFx = attach({
  effect: cancelEstimateLotApprovingReqFx,
})

export const $isActionPending = combine(
  sendEstimateLotToApproveFx.pending,
  cancelEstimateLotApprovingFx.pending,
  updateEstimateLotFx.pending,
  (...args) => args.some(Boolean),
)
