import React, { memo } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import {
  DateRangeInput,
  Field,
  InputField,
  NewButton,
  NewDropDownField,
  palette,
  TextL,
} from '@/ui'
import { $appealProjects, $appealTypeOptions, getSupportAppealFiltersFx } from '@/features/support/request-history/model/private'

import {
  $filters,
  $hasAcceptedFilters,
  acceptFilters,
  clearFilter,
  removeFilter,
  setFilter,
} from '../../model'
import { $hasUpdatedFilters } from '../../model/private'

export const SupportFilters = memo(() => {
  const [filters, projectOptions, typeOptions, hasAcceptedFilters, isPending] = useUnit([
    $filters,
    $appealProjects,
    $appealTypeOptions,
    $hasAcceptedFilters,
    getSupportAppealFiltersFx.pending,
  ])
  const hasUpdatedFilters = useUnit($hasUpdatedFilters)

  return (
    <Wrapper>
      <Title>Фильтры</Title>

      <Fields>
        <InputField
          label="Поиск"
          placeholder="Поиск..."
          value={filters.search_query ?? ''}
          postfixIcon="search"
          disabled={isPending}
          dataTestId="support-page-filters-search-input"
          onChange={(val) => setFilter({ key: 'search_query', val })}
          onClear={() => removeFilter({ key: 'search_query' })}
        />
        <NewDropDownField
          label="Тип обращения"
          value={filters.issue_type}
          options={typeOptions}
          placeholder="Выберите тип обращения"
          dataTestId="support-page-filters-appeal-type-select"
          isDisabled={isPending}
          isMultiple
          hasSearch
          onChange={(val) => setFilter({ key: 'issue_type', val })}
        />
        <NewDropDownField
          label="Проект"
          value={filters.project_id}
          options={projectOptions}
          placeholder="Выберите проект"
          dataTestId="support-page-filters-company-select"
          isDisabled={isPending}
          isMultiple
          hasSearch
          onChange={(val) => setFilter({ key: 'project_id', val })}
        />
        <Field label="Период обращения">
          <DateRangeInput
            inputValue={{ from: filters.date_from ?? '', to: filters.date_to ?? '' }}
            disabled={isPending}
            dataTestId="support-page-filters-date-input"
            onChangeFrom={(val) => setFilter({ key: 'date_from', val })}
            onChangeTo={(val) => setFilter({ key: 'date_to', val })}
          />
        </Field>
      </Fields>

      <Buttons>
        <NewButton
          label="Применить"
          isDisabled={isPending || !hasUpdatedFilters}
          type="submit"
          size="S"
          dataTestId="support-page-accept-filters-btn"
          isFill
          onClick={acceptFilters}
        />
        <NewButton
          label="Сбросить"
          type="button"
          buttonType="line-grey"
          prefixIcon="refresh"
          size="S"
          isDisabled={!hasAcceptedFilters || isPending}
          dataTestId="support-page-clear-filters-btn"
          isFill
          onClick={clearFilter}
        />
      </Buttons>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  background-color: ${palette.white};
  border-radius: 12px;
`

const Title = styled.header`
  ${TextL}
`

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const Buttons = styled.div`
  display: flex;
  gap: 16px;
`
