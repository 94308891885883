import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { StepType } from '@/dal'
import { NewTextArea, TextL, TextMLight } from '@/ui'

import {
  $currentStep,
  $currentStepForm,
  changeStepFormRating,
  changeStepFormRatingText,
} from '../../model/private'
import { ScrollableContainer, StarScale } from '../parts'
import { QuestionsProgress } from './QuestionsProgress'
import { StepActions } from './StepActions'

export const RatingStep = React.memo(() => {
  const [step, currentStepForm] = useUnit([$currentStep, $currentStepForm])

  if (!step || !currentStepForm || currentStepForm.type !== StepType.Rating) return null

  const { id, rating, text, descriptions } = currentStepForm

  return (
    <>
      <QuestionsProgress />
      <ScrollableContainer>
        <QuestionWrapper>{step.title}</QuestionWrapper>

        <QuestionDescription>{step.description}</QuestionDescription>

        <StarScale
          rating={rating}
          descriptions={descriptions ?? []}
          onRate={(value) => changeStepFormRating({ stepId: step.id, rating: value })}
        />

        <TextareaWrapper>
          <NewTextArea
            placeholder="Пожалуйста, оставьте комментарий по вашей оценке. Нам очень знать ваше мнение и развивать функционал продукта."
            value={text}
            height={100}
            isInvalid={Boolean(rating && rating < 4 && !text)}
            dataTestId="survey-rating-step-textarea"
            onChange={(text) => changeStepFormRatingText({ text, stepId: id })}
          />
        </TextareaWrapper>
      </ScrollableContainer>

      <StepActions />
    </>
  )
})

const QuestionWrapper = styled.p`
  ${TextL}
`

const QuestionDescription = styled.p`
  ${TextMLight}
  margin: 8px 0 20px;
`

const TextareaWrapper = styled.div`
  margin-top: 20px;
`
