import React from 'react'
import styled, { css } from 'styled-components'

import { numberWithSpaces } from '@/lib/number-represent'

import { DefaultButton } from '../../buttons'
import { NewIconButton } from '../../buttons/NewIconButton'
import { DialogSheetWrapper } from '../../components/DialogSheetWrapper'
import { IconName2 } from '../../icons/Icon2'
import { palette, PaletteColor } from '../../palette/palette'
import { TextMLight } from '../../typography'

export type CellInputWrapperProps = {
  children: React.ReactElement
  value: string
  suffix?: string
  textAlign?: 'right' | 'center' | 'left'
  isInvalid?: boolean
  padding?: string
  maxLines?: number
  popupWidth?: number
  icon?: IconName2
  isDisable?: boolean
  iconTooltip?: string
  color?: PaletteColor
  hasAbsoluteIcon?: boolean
  isCentered?: boolean
  decimalScale?: number
  dot?: string
  popupZIndex?: number
  verticalAlign?: 'top' | 'center' | 'bottom'
  isOpen: boolean
  onClick: () => void
  onIconClick?: () => void
}

export const CellInputWrapper = ({
  children,
  suffix,
  value,
  textAlign,
  isInvalid = false,
  padding,
  maxLines,
  popupWidth,
  icon = 'edit',
  isDisable,
  iconTooltip,
  color,
  hasAbsoluteIcon = false,
  isCentered = false,
  decimalScale,
  dot = '.',
  popupZIndex,
  verticalAlign = 'top',
  isOpen,
  onClick,
  onIconClick,
}: CellInputWrapperProps) => {
  const handleIconClick = (e: React.MouseEvent) => {
    if (!onIconClick) {
      onClick()
      return
    }
    e.stopPropagation()
    onIconClick()
  }
  const formattedValue = decimalScale ? numberWithSpaces(value, { decimalScale, dot }) : value

  return (
    <>
      <InputWrapper
        $padding={padding}
        $isInvalid={isInvalid}
        $isOpen={isOpen}
        $isDisabled={isDisable}
        $verticalAlign={verticalAlign}
      >
        <OverlayButton type="button" onClick={onClick} />
        <Value $isInvalid={isInvalid} $textAlign={textAlign} $maxLines={maxLines} $color={color}>
          {isOpen ? '' : `${formattedValue} ${suffix ? ` ${suffix}` : ''}`}
        </Value>

        {!isOpen && (
          <IconWrapper $isAbsolute={hasAbsoluteIcon}>
            <NewIconButton
              dataTestId=""
              icon={icon}
              color={isInvalid ? 'red100' : isOpen ? 'grey40' : 'accent80'}
              hoverColor={!isInvalid ? 'accent100' : undefined}
              size={16}
              tooltipText={iconTooltip}
              disabled={isDisable}
              onClick={handleIconClick}
            />
          </IconWrapper>
        )}
      </InputWrapper>

      {isOpen && (
        <FieldWrapper $popupWidth={popupWidth} $zIndex={popupZIndex} $isCentered={isCentered}>
          {children}
        </FieldWrapper>
      )}
    </>
  )
}

type ValueProps = {
  $textAlign?: 'right' | 'center' | 'left'
  $isInvalid: boolean
  $maxLines?: number
  $color?: PaletteColor
}

const Value = styled.span<ValueProps>`
  ${TextMLight};
  ${({ $textAlign }) =>
    $textAlign &&
    css`
      text-align: ${$textAlign};
    `}
  ${({ $isInvalid }) =>
    $isInvalid &&
    css`
      color: ${palette.red100};
    `}
  ${({ $isInvalid, $color }) =>
    !$isInvalid &&
    $color &&
    css`
      color: ${palette[$color]};
    `}
  ${({ $maxLines }) =>
    $maxLines &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: ${$maxLines};
      -webkit-box-orient: vertical;
    `}
  width: 100%;
`

const IconWrapper = styled.div<{ $isAbsolute?: boolean }>`
  position: relative;
  z-index: 1;
  height: 16px;

  ${({ $isAbsolute }) =>
    $isAbsolute &&
    css`
      position: absolute;
      top: 0;
      left: calc(100% + 4px);
    `}
`

type InputWrapperProps = {
  $isOpen: boolean
  $isInvalid: boolean
  $padding?: string
  $isDisabled?: boolean
  $verticalAlign: CellInputWrapperProps['verticalAlign']
}

const verticalAlignments = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end',
}

const OverlayButton = styled(DefaultButton)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
`

const InputWrapper = styled.div<InputWrapperProps>`
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 4px;
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};
  transition: border-color 0.15s linear;
  height: 100%;
  border-radius: 4px;
  word-break: break-word;

  padding: ${({ $padding }) => $padding || '0px'};

  ${({ $isInvalid }) =>
    $isInvalid &&
    css`
      color: ${palette.red100};
    `}

  ${({ $verticalAlign }) =>
    $verticalAlign &&
    css`
      align-items: ${verticalAlignments[$verticalAlign]};
    `}
`

type FieldWrapperProps = {
  $popupWidth?: number
  $zIndex?: number
  $isCentered?: boolean
}

const FieldWrapper = styled(DialogSheetWrapper)<FieldWrapperProps>`
  position: absolute;

  display: flex;
  align-items: center;

  width: ${({ $popupWidth }) => ($popupWidth ? `${$popupWidth}px` : 'auto')};
  min-height: 40px;
  padding: 12px;
  box-shadow: 0px 1px 6px 0px ${palette.shadow};
  border-radius: 4px;

  ${({ $isCentered }) =>
    !$isCentered &&
    css`
      top: 0;
      left: 0;
      right: 0;
    `}

  ${({ $isCentered }) =>
    $isCentered &&
    css`
      top: 50%;
      left: 50%;
      right: -50%;
      transform: translate(-50%, -50%);
    `}

  ${({ $zIndex }) =>
    $zIndex &&
    css`
      z-index: ${$zIndex};
    `};
`
