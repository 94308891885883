import React from 'react'
import styled, { css } from 'styled-components'
import { useUnit } from 'effector-react'

import { Summ } from '@/dal'
import { numberWithSpaces } from '@/lib/number-represent'
import { palette, TextM, TextMLight } from '@/ui'

import { $sums, getAvansSumsFx } from '../../../model/table.private'
import { TableTemplate } from '../parts'

export const TableHeader = React.memo(() => {
  const sums = useUnit($sums)
  const isLoadingSums = useUnit(getAvansSumsFx.pending)

  const getCol = (key: keyof Summ, isHighlighted?: boolean) => {
    if (!sums?.[key] || isLoadingSums) return <ColSpan>—</ColSpan>
    return <ColSpan isHighlight={isHighlighted}>{numberWithSpaces(sums[key])}</ColSpan>
  }

  return (
    <Wrapper>
      <Col>Статья оборотов</Col>
      <Col>
        Сумма договора
        {getCol('sum_contract')}
      </Col>
      <Col>
        Лимит аванса
        {getCol('sum_avans')}
      </Col>
      <Col>
        Выплачено аванса
        {getCol('avans_paid')}
      </Col>
      <Col>
        Аванс на согласовании
        {getCol('sum_advance_on_agreed')}
      </Col>
      <Col>
        Зачтено аванса
        {getCol('avans_offset')}
      </Col>
      <Col>
        Остаток лимита
        {getCol('avans_remain')}
      </Col>
      <Col>
        Запрос аванса
        {getCol('sum_avans_request', true)}
      </Col>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  ${TableTemplate}
  position: sticky;
  top: 0;
  z-index: 2;

  width: fit-content;
`

const Col = styled.div`
  ${TextM}
  padding: 16px 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-end;
  background-color: ${palette.grey10};

  &:first-child {
    padding-left: 20px;
    align-items: flex-start;
    justify-content: center;
  }

  &:not(:first-child) {
    text-align: right;
  }

  &:last-child {
    padding-right: 20px;
  }

  &:not(:last-child) {
    border-right: 1px solid ${palette.grey40};
  }
`

const ColSpan = styled.span<{ isHighlight?: boolean }>`
  ${TextMLight}
  color: ${palette.grey70};

  ${({ isHighlight }) =>
    isHighlight &&
    css`
      ${TextM}
      color: ${palette.accent100}
    `}
`
