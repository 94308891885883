import styled from 'styled-components'

import { SquareButton } from '../../buttons'

type Props = {
  isTableOverflowed: boolean
  isTableExpanded: boolean
  onToggleExpansion: (value: boolean) => void
}

export const ExpandableTableButton = ({
  isTableOverflowed,
  isTableExpanded,
  onToggleExpansion,
}: Props) => {
  if (!isTableOverflowed && !isTableExpanded) return null

  return (
    <Wrapper>
      <SquareButton
        icon={isTableExpanded ? 'resize-back' : 'resize'}
        buttonType={isTableExpanded ? 'secondary' : 'grey'}
        size="S"
        dataTestId=""
        onClick={() => onToggleExpansion(!isTableExpanded)}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  flex-shrink: 0;
`
