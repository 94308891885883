import { sample } from 'effector'

import { SendSummaryInfoParams } from '@/dal'
import {
  onAdditionalRequestAnswerRead,
  onAdditionalRequestUpdated,
} from '@/features/additional-request/model'
import { redirectOnFail, RouterPaths } from '@/features/app/model'
import { onDocumentFilesLoaded } from '@/features/document-files/model'
import { requestActualSurvey } from '@/features/survey/model'
import { createToast } from '@/features/toast-service/model'

import { mapAndSortMorphologyList } from './helpers'
import {
  abortGetDocumentFx,
  getEstimateInfoByIdFx,
  getKorDocDirectoriesFx,
  getKorDocGaFilters,
  getKorDocGaFiltersFx,
  onDocumentUnlocked,
  onFieldSubmit,
  sendSummaryInfoFx,
  updateFeaturesInfoFx,
  updateSummary,
} from './private'
import {
  $actingGroupOptions,
  $allDocumentInfo,
  $buildings,
  $documentId,
  $floors,
  $groupList,
  $isDocumentPending,
  $morphologyDepth,
  $morphologyDepthFromDocInfo,
  $projectList,
  $sections,
  $unitOptions,
  EstimateCorrectionGate,
  getUpdatedFeatures,
  onUpdateSummary,
  refetchEstimateDocument,
  setMorphologyDepth,
  updateAttachments,
  updateDocumentInfo,
  updateEstimateDirectories,
  updateFeatures,
} from './public'

$isDocumentPending.on(getEstimateInfoByIdFx, () => true).reset(getEstimateInfoByIdFx.finally)

$allDocumentInfo
  .on(getEstimateInfoByIdFx.doneData, (_, info) => info)
  .on(updateDocumentInfo, (_, info) => info)
  .on(updateSummary, (info, summary) =>
    info
      ? {
          ...info,
          summary_table: summary,
        }
      : null,
  )
  .on(updateAttachments, (info, attachments) =>
    !info
      ? null
      : {
          ...info,
          attachments,
        },
  )
  .on(updateFeatures, (info, features) =>
    !info
      ? null
      : {
          ...info,
          features,
        },
  )
  .on(onAdditionalRequestAnswerRead, (info) =>
    !info
      ? null
      : {
          ...info,
          features: {
            ...info.features,
            has_unread_answer: false,
          },
        },
  )
  .reset(EstimateCorrectionGate.close)

$morphologyDepth
  .on(setMorphologyDepth, (_, val) => val)
  .on($morphologyDepthFromDocInfo.updates, (_, val) => val)
  .reset(EstimateCorrectionGate.close)

$unitOptions
  .on(getKorDocDirectoriesFx.doneData, (_, { units }) =>
    units.map((item) => ({ label: item.short_name, id: item.id })),
  )
  .reset(EstimateCorrectionGate.close)

$groupList
  .on(getKorDocDirectoriesFx.doneData, (_, { groups }) => groups)
  .reset(EstimateCorrectionGate.close)

$projectList
  .on(getKorDocDirectoriesFx.doneData, (_, { projects }) => projects)
  .reset(EstimateCorrectionGate.close)

$actingGroupOptions
  .on(
    getKorDocDirectoriesFx.doneData,
    (_, { acting_groups }) => acting_groups?.map(({ id, name }) => ({ id, label: name })) ?? [],
  )
  .reset(EstimateCorrectionGate.close)

$buildings
  .on(getKorDocDirectoriesFx.doneData, (_, { buildings }) => mapAndSortMorphologyList(buildings))
  .reset(EstimateCorrectionGate.close)

$sections
  .on(getKorDocDirectoriesFx.doneData, (_, { sections }) => mapAndSortMorphologyList(sections))
  .reset(EstimateCorrectionGate.close)

$floors
  .on(getKorDocDirectoriesFx.doneData, (_, { floors }) => mapAndSortMorphologyList(floors))
  .reset(EstimateCorrectionGate.close)

sample({
  clock: [EstimateCorrectionGate.open, EstimateCorrectionGate.state],
  source: {
    isOpen: EstimateCorrectionGate.status,
    id: EstimateCorrectionGate.state,
  },
  filter: ({ id, isOpen }) => isOpen && Boolean(id) && !Number.isNaN(id),
  target: [
    getEstimateInfoByIdFx,
    getKorDocDirectoriesFx,
    getKorDocGaFiltersFx,
    requestActualSurvey,
  ],
})

sample({
  clock: [refetchEstimateDocument, onAdditionalRequestUpdated],
  source: {
    isOpen: EstimateCorrectionGate.status,
    id: EstimateCorrectionGate.state,
  },
  filter: ({ id, isOpen }) => isOpen && Boolean(id) && !Number.isNaN(id),
  target: getEstimateInfoByIdFx,
})

sample({
  clock: EstimateCorrectionGate.close,
  target: abortGetDocumentFx,
})

sample({
  clock: updateEstimateDirectories,
  source: $documentId,
  filter: Boolean,
  fn: (id) => ({ id }),
  target: getKorDocDirectoriesFx,
})

sample({
  clock: getKorDocGaFilters,
  source: $documentId,
  filter: Boolean,
  fn: (id) => ({ id }),
  target: getKorDocGaFiltersFx,
})

sample({
  clock: getUpdatedFeatures,
  source: $documentId,
  filter: Boolean,
  target: updateFeaturesInfoFx,
})

sample({
  clock: updateFeaturesInfoFx.doneData,
  target: updateFeatures,
})

sample({
  clock: onUpdateSummary,
  source: $documentId,
  filter: (id, { docId }) => id === docId,
  fn: (_, { summary }) => summary,
  target: updateSummary,
})

sample({
  clock: [onDocumentFilesLoaded, onDocumentUnlocked],
  target: [refetchEstimateDocument, updateEstimateDirectories],
})

sample({
  clock: onFieldSubmit,
  source: $documentId,
  filter: Boolean,
  fn: (id, field) => ({
    id,
    ...field,
  }),
  target: sendSummaryInfoFx,
})

sample({
  clock: sendSummaryInfoFx.done,
  fn: ({ params, result }) => ({ docId: params.id, summary: result }),
  target: [
    onUpdateSummary,
    getUpdatedFeatures.prepend(({ docId }: { docId: SendSummaryInfoParams['id'] }) => docId),
  ],
})

redirectOnFail({
  effect: getEstimateInfoByIdFx,
  route: RouterPaths.Administrative,
})

createToast({
  effect: sendSummaryInfoFx,
})

createToast({
  effect: getEstimateInfoByIdFx,
  errorText: 'Не удалось загрузить предварительную спецификацию',
})
