import styled from 'styled-components'

import { Icon2, NewButton, Typography } from '@/ui'

type Props = {
  onAdd: () => void
}

export const EmptyTableState = ({ onAdd }: Props) => {
  return (
    <Wrapper>
      <Icon2 icon="emptyTasks" size={170} />
      <Typography textStyle="LL" color="grey70">
        Сейчас тут ничего нет. Добавьте свою первую работу
      </Typography>
      <NewButton
        label="Добавить работу"
        size="S"
        prefixIcon="plusWithCircle"
        buttonType="line-primary"
        width={210}
        dataTestId="lot-add-work-btn"
        onClick={onAdd}
      />
    </Wrapper>
  )
}

const Wrapper = styled.td`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  height: 100%;
  padding: 64px;
`
