import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { formatTimeString } from '@/lib/date'
import { getRouterPathByDocumentAlias } from '@/lib/documentResolvers'

import { NewButton } from '../../buttons'
import { Icon2 } from '../../icons'
import { palette } from '../../palette'
import { TextL, TextMLight } from '../../typography'
import { ToolTipWrapper } from '../tooltip'
import { ButtonsProps } from './types'

type Props = ButtonsProps

export const DocumentHeaderButtons = ({
  approveTitle,
  canCancelApproving,
  canCopy,
  canDelete,
  isDisabled,
  isApproveButtonDisabled,
  isBlocked,
  isPendingApprove,
  isCancelPending,
  isPendingDelete,
  docOnApproving,
  unlockDate,
  showApproveButton,
  isSecondApproving,
  canRejectApproving,
  isPendingReject,
  onReject,
  onCancelApprove,
  onCopy,
  onDeleteButton,
  onApproveButton,
}: Props) => {
  if (isBlocked && unlockDate) {
    return (
      <Buttons>
        <UnlockTimeText>Доступ к редактированию откроется в</UnlockTimeText>
        <UnlockTime>
          {/* значение по МСК, но заблокировано независимо от часового пояса */}
          {formatTimeString(unlockDate)}
        </UnlockTime>
      </Buttons>
    )
  }

  const isActionPending = Boolean(
    isPendingApprove || isCancelPending || isPendingDelete || isPendingReject,
  )

  return isBlocked ? null : (
    <Buttons>
      {canDelete && (
        <NewButton
          dataTestId="show-delete-confrim"
          label="Удалить"
          size="S"
          buttonType="line-grey"
          prefixIcon="delete"
          onClick={onDeleteButton}
          isDisabled={isActionPending || isDisabled}
          isPending={isPendingDelete}
        />
      )}
      {canCancelApproving && (
        <NewButton
          dataTestId="cancel-approve"
          label="Отменить согласование"
          size="S"
          buttonType="line-primary"
          isDisabled={isActionPending || isDisabled}
          isPending={isCancelPending}
          onClick={onCancelApprove}
        />
      )}
      {canRejectApproving && (
        <NewButton
          dataTestId="reject-approve"
          label="Отклонить"
          size="S"
          buttonType="grey"
          isDisabled={isActionPending || isDisabled}
          isPending={isPendingReject}
          onClick={onReject}
          tooltip={isSecondApproving ? 'Отклонить принятое решение' : undefined}
        />
      )}
      {showApproveButton && (
        <NewButton
          dataTestId="show-approve-modal"
          label={approveTitle}
          onClick={onApproveButton}
          isPending={isPendingApprove}
          isDisabled={isApproveButtonDisabled || isActionPending || isDisabled}
          size="S"
          tooltip={isSecondApproving ? 'Согласовать принятое решение' : undefined}
        />
      )}
      {canCopy && (
        <NewButton
          dataTestId="copy-button"
          label="Создать копию"
          size="S"
          buttonType="grey"
          isDisabled={isActionPending || isDisabled}
          onClick={onCopy}
        />
      )}
      {docOnApproving?.type && (
        <ToolTipWrapper
          bgColor="white"
          placement="bottom"
          tooltipOffset={-6}
          referenceComponent={
            <ApproveDocWarning>
              Невозможно отправить на согласование
              <Icon2 icon="info" color="red100" />
            </ApproveDocWarning>
          }
        >
          <TooltipText>
            Функциональность будет доступна после согласования{' '}
            <Link
              to={`${getRouterPathByDocumentAlias(docOnApproving?.type)}/${docOnApproving?.id}`}
              target="_blank"
            >
              {docOnApproving.name}
            </Link>
          </TooltipText>
        </ToolTipWrapper>
      )}
    </Buttons>
  )
}

const Buttons = styled.div`
  display: flex;
  gap: 8px;
`

const UnlockTimeText = styled.p`
  ${TextMLight}
`

const UnlockTime = styled.div`
  ${TextL}
  padding: 7px 10px 5px;
  border-radius: 4px;
  background-color: ${palette.accent10};
  color: ${palette.accent100};
`

const ApproveDocWarning = styled.div`
  color: ${palette.red100};
  padding: 6px 20px;
  background-color: ${palette.red10};
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
`

const TooltipText = styled.div`
  padding: 12px;
  ${TextMLight}
  max-width: 300px;
`
