import { attach, combine } from 'effector'
import { createForm } from 'effector-forms'

import { EstimateStringCell, EstimateTableRow, updateEstimateItemReqFx } from '@/dal'
import { rules, withDisableCondition } from '@/lib/form-validators'

import { $buildings } from '../../shared-model'
import { d } from './domain'
import { $flatItems } from './private'

export const $editTargetId = d.store<EstimateTableRow['id'] | null>(null)
export const editRow = d.event<EstimateTableRow['id']>()
export const acceptChanges = d.event<void>()
export const cancelChanges = d.event<void>()

export const resetEditableState = d.event<void>()

export const $editTarget = combine([$flatItems, $editTargetId], ([list, id]) => {
  if (!id) return null
  const item = list?.find((item) => item.id === id)
  return item || null
})

type Keys = keyof EstimateTableRow
type AdditionalRequisitesKeys = NonNullable<
  EstimateTableRow['additional_requisites']
> extends infer O
  ? O extends object
    ? keyof O
    : never
  : never
type NewLinesKeys = NonNullable<EstimateTableRow['new_lines']>[number] extends infer O
  ? O extends object
    ? keyof O
    : never
  : never

type SecondKeys = NewLinesKeys | AdditionalRequisitesKeys

const conditionForValidator = (key: Keys, secondKey?: SecondKeys) => {
  // eslint-disable-next-line effector/no-getState
  const row = $editTarget.getState()
  if (!row) return false
  if (row[key] === null) return false
  if (secondKey) {
    if (Array.isArray(row[key])) return row[key]?.[0]?.[secondKey]?.editable
    return ((row as any)[key]?.[secondKey] as EstimateStringCell)?.editable
  }
  return (row[key] as EstimateStringCell)?.editable
}

const isBuildingRequired = (fields?: { project: number | null }) =>
  Boolean(
    // eslint-disable-next-line effector/no-getState
    fields?.project && $buildings.getState().some(({ parent_id }) => parent_id === fields?.project),
  )

export const editItemForm = createForm({
  domain: d,
  fields: {
    project: {
      init: null as number | null,
    },
    count_in_estimate: {
      init: '',
      rules: [
        withDisableCondition(() => conditionForValidator('count_in_estimate'), rules.required()),
      ],
    },
    material: {
      init: '',
      rules: [
        withDisableCondition(() => conditionForValidator('price_material'), rules.required()),
      ],
    },
    work: {
      init: '',
      rules: [withDisableCondition(() => conditionForValidator('price_work'), rules.required())],
    },
    justification_of_changes_current: {
      init: '',
    },
    unit: {
      init: null as number | null,
      rules: [withDisableCondition(() => conditionForValidator('unit'), rules.required())],
    },
    name: {
      init: '',
      rules: [withDisableCondition(() => conditionForValidator('name'), rules.required())],
    },
    // новые поля после разделения объёмов
    new_count_in_estimate: {
      init: '',
      rules: [
        withDisableCondition(
          () => conditionForValidator('new_lines', 'count_in_estimate'),
          rules.greaterThanZero(),
        ),
      ],
    },
    new_justification_of_changes_current: {
      init: '',
    },
    new_material: {
      init: '',
      rules: [
        withDisableCondition(
          () => conditionForValidator('new_lines', 'price_material'),
          rules.required(),
        ),
      ],
    },
    new_work: {
      init: '',
      rules: [
        withDisableCondition(
          () => conditionForValidator('new_lines', 'price_work'),
          rules.required(),
        ),
      ],
    },
    // ПС с группами актирования
    acting_group: {
      init: null as number | null,
    },
    building: {
      init: null as number | null,
      rules: [withDisableCondition(isBuildingRequired, rules.required())],
    },
    section: {
      init: null as number | null,
    },
    floor: {
      init: null as number | null,
    },
  },
})

export const updateEstimateItemFx = attach({
  effect: updateEstimateItemReqFx,
})
