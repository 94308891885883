import React from 'react'
import styled from 'styled-components'

import { useTooltip } from '@/lib/hooks'
import { noop } from '@/lib/noop'

import { ToolTipV2 } from '../../components/tooltip'
import { Icon2, IconName2 } from '../../icons'
import { DefaultButton } from '../DefaultButton'
import { ButtonLoader } from './ButtonLoader'
import { styles } from './styles'
import { ButtonSize, ButtonType } from './types'
import { AnimationWrapper } from './wrappers'

type Props = {
  ariaLabel?: string
  size?: ButtonSize
  isPending?: boolean
  buttonType?: ButtonType
  icon: IconName2
  iconSize?: number
  isDisabled?: boolean
  type?: 'button' | 'submit'
  tooltip?: string
  dataTestId: string
  onClick: () => void
}

export const SquareButton = ({
  isPending,
  ariaLabel,
  size = 'M',
  buttonType = 'primary',
  type = 'button',
  icon,
  iconSize = 16,
  isDisabled,
  tooltip,
  dataTestId,
  onClick,
}: Props) => {
  const { floatingStyles, getFloatingProps, getReferenceProps, isOpen, refs } = useTooltip()

  const handleClick = isPending ? noop : onClick

  return (
    <AnimationWrapper {...getReferenceProps()} ref={refs.setReference}>
      <Button
        disabled={Boolean(isDisabled || isPending)}
        onClick={() => handleClick?.()}
        $size={size}
        $buttonType={buttonType}
        type={type}
        data-testid={dataTestId}
        aria-label={ariaLabel}
        data-pending={isPending || undefined}
      >
        <Icon2 size={iconSize} icon={icon} />
      </Button>

      {isPending && <ButtonLoader buttonType={buttonType} />}

      {tooltip && (
        <ToolTipV2
          floatingStyles={floatingStyles}
          getFloatingProps={getFloatingProps}
          isOpen={isOpen}
          refs={refs}
          size="M"
        >
          {tooltip}
        </ToolTipV2>
      )}
    </AnimationWrapper>
  )
}

type ButtonProps = {
  $size: ButtonSize
  $buttonType: ButtonType
}

const Button = styled(DefaultButton)<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;

  width: ${({ $size }) => ($size === 'M' ? 40 : 32)}px;
  height: ${({ $size }) => ($size === 'M' ? 40 : 32)}px;
  border: 1px solid transparent;

  border-radius: 8px;
  transition: color 0.15s linear, background-color 0.15s linear, border 0.15s linear;

  ${({ $buttonType: type }) => styles[type]}
`
