import React, { memo } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { DocumentStatuses } from '@/dal'
import { HeadXL, palette, SpecificDocumentCounter } from '@/ui'

import { $headerInfo, setFilter } from '../../model/private'

export const EstimateLotsHeader = memo(() => {
  const info = useUnit($headerInfo)

  return (
    <Wrapper>
      <TitleWrapper>
        <p>Коммерческое предложение</p>
        {info && <Count>{info.all_estimate_lots_count}</Count>}
      </TitleWrapper>

      {info && (
        <SpecificDocumentCounter
          text="На согласовании"
          count={info.on_agreed_estimate_lots}
          onClick={() => setFilter({ key: 'status', val: [DocumentStatuses.ToBeAgreed] })}
        />
      )}
    </Wrapper>
  )
})

const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`

const TitleWrapper = styled.div`
  ${HeadXL}
  display: flex;
  gap: 12px;
`

const Count = styled.div`
  color: ${palette.grey70};
`
