import React, { Key } from 'react'
import styled from 'styled-components'

import { palette } from '../../palette'
import { SwiperWrapper } from '../SwiperWrapper'
import { TabItem } from './TabItem'
import { Tab, TabSize, TabsProps } from './types'

type Props<T> = TabsProps<T> & {
  disableArrow?: boolean
  size?: TabSize
}

export const TabSwiper = <T extends Key>({
  activeId,
  items,
  onItemClick,
  size = 'M',
  disableArrow,
}: Props<T>) => {
  const renderFunc = React.useCallback(
    (item: Tab<T>) => (
      <TabItem
        item={item}
        activeId={activeId}
        size={size}
        count={item.count}
        onItemClick={onItemClick}
      />
    ),
    [items, activeId, size, onItemClick],
  )

  if (!items) return null

  return (
    <Wrapper>
      <SwiperWrapper
        itemRenderFn={renderFunc}
        items={items}
        spaceBetween={24}
        disableArrow={disableArrow}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: ${palette.grey10};
  }
`
