import { Domain, Effect, sample } from 'effector'

import { CustomResponseError } from '@/dal'
import { root } from '@/root-domain'

type Params<T, R, P extends { items: T[] }, D extends { items: R[] }, E> = {
  fetchEffect: Effect<P, D, E>
  itemsPerRequest?: number
  domain?: Domain
}

export const createChunkRequest = <
  T,
  R,
  Payload extends { items: T[] } = { items: T[] },
  Done extends { items: R[] } = { items: R[] },
  E extends CustomResponseError = CustomResponseError,
>({
  fetchEffect,
  itemsPerRequest = 20,
  domain,
}: Params<T, R, Payload, Done, E>) => {
  const d = domain || root.domain()

  const effectFx = d.effect<Payload, Done, E>()

  const $failedItemCount = d.store(0)
  const chunkFailed = d.event<number>()
  const requestEndedWithErrors = d.event<{ failed: number; total: number }>()

  const $counter = d.store(0)
  const incrementCounter = d.event<number>()

  const $totalItems = d.store(0)
  const setTotalItems = d.event<number>()

  $counter.on(incrementCounter, (counter, count) => counter + count).reset(effectFx)

  $failedItemCount
    .on(chunkFailed, (counter, count) => counter + count)
    .reset(effectFx, requestEndedWithErrors)

  $totalItems.on(setTotalItems, (_, count) => count).reset(effectFx)

  sample({
    clock: effectFx.finally,
    source: {
      failed: $failedItemCount,
      total: $totalItems,
    },
    filter: ({ failed }) => Boolean(failed),
    target: requestEndedWithErrors,
  })

  effectFx.use(async (payload) => {
    const { items } = payload
    const requestAmount = Math.ceil(items.length / itemsPerRequest)
    const updatedItems: R[] = []
    let data = {}

    setTotalItems(items.length)

    for (let i = 0; i < requestAmount; i += 1) {
      const chunk = items.slice(itemsPerRequest * i, itemsPerRequest * (i + 1))

      await fetchEffect({ ...payload, items: chunk })
        .then((res) => {
          const { items, ...otherData } = res
          updatedItems.push(...items)
          data = otherData
          incrementCounter(res.items.length)
        })
        .catch(() => chunkFailed(chunk.length))
    }
    if (!updatedItems.length) {
      throw new Error('Ошибка при сохранении всех строк')
    }
    return {
      ...data,
      items: updatedItems,
    } as Done
  })

  return {
    effectFx,
    chunkFailed,
    requestEndedWithErrors,
    $counter,
    $totalItems,
  }
}
