import { sample } from 'effector'

import { AddLotWorksPayload } from '@/dal'
import { $documentId } from '@/features/estimate-lot/model'

import { addWorksModal } from '../../model/private'
import { EstimateLotTableGate, onWorkOrFolderDeleted } from '../../table/model'
import {
  $catalogRoot,
  $catalogTree,
  $searchValue,
  $selectedKerIds,
  acceptSearch,
  addLotWorksFx,
  addWorksToLot,
  changeSearchValue,
  getCatalogRootFx,
  getKvrChildren,
  getKvrItemsFx,
  resetWorkCatalog,
  toggleExpandedKvr,
} from './private'
import { onWorkAdded } from './public'

$catalogRoot.on(getCatalogRootFx.doneData, (_, data) => data).reset(resetWorkCatalog)

$catalogTree
  .on(getKvrItemsFx.done, (map, { params, result }) => ({
    ...map,
    [params.parentId!]: result,
  }))
  .reset(resetWorkCatalog)

$searchValue
  .on(changeSearchValue, (_, value) => value)
  .reset(EstimateLotTableGate.close, addWorksModal.close)

sample({
  clock: [EstimateLotTableGate.close, addWorksModal.close, onWorkOrFolderDeleted, getCatalogRootFx],
  target: resetWorkCatalog,
})

sample({
  clock: [EstimateLotTableGate.open, acceptSearch, addWorksModal.open],
  source: {
    id: $documentId,
    search: $searchValue,
  },
  filter: ({ id }) => Boolean(id),
  fn: ({ id, search }) => ({
    search,
    id: id!,
    parentId: null,
  }),
  target: getCatalogRootFx,
})

sample({
  clock: toggleExpandedKvr,
  source: $catalogTree,
  filter: (tree, id) => !tree[id],
  fn: (_, id) => id,
  target: getKvrChildren,
})

sample({
  clock: getKvrChildren,
  source: {
    id: $documentId,
    search: $searchValue,
  },
  filter: ({ id }) => Boolean(id),
  fn: ({ id, search }, parentId) => ({
    id: id!,
    search,
    parentId,
  }),
  target: getKvrItemsFx,
})

sample({
  clock: addWorksToLot,
  source: {
    id: $documentId,
    selectedIds: $selectedKerIds,
  },
  filter: ({ id, selectedIds }) => Boolean(id && selectedIds.length),
  fn: ({ id, selectedIds }) => {
    const worksToAdd: AddLotWorksPayload['worksToAdd'] = {}

    for (const str of selectedIds) {
      const [kvrId, kerId] = str.split('-').map(Number)
      if (worksToAdd[kvrId]) {
        worksToAdd[kvrId].push(kerId)
      } else {
        worksToAdd[kvrId] = [kerId]
      }
    }

    return {
      worksToAdd,
      id: id!,
    }
  },
  target: addLotWorksFx,
})

sample({
  clock: addLotWorksFx.done,
  target: onWorkAdded,
})
