import React from 'react'

import { SummaryFieldAlias } from '@/dal'
import { noop } from '@/lib/noop'

import {
  AvansCol,
  CurrencyRow,
  GridWrapper,
  Label,
} from '../parts'
import { SummaryTableItemProps } from '../../model/types'

export const AvansRow = React.memo(({
  summary, isDraft, onFieldSubmit,
}: SummaryTableItemProps) => {
  const memoHandleAvansSubmit = React.useCallback((val: string) => {
    onFieldSubmit({ field: SummaryFieldAlias.AVANS, value: val })
  }, [onFieldSubmit])

  if (!summary) return null

  const { avans, nds } = summary

  return (
    <>
      <CurrencyRow
        {...nds}
        onSubmit={nds.is_changeable ? noop : undefined}
        label="НДС в т.ч."
        isDraft={isDraft}
        type="percent"
      />
      <GridWrapper>
        <Label>Аванс</Label>
        <AvansCol
          value={avans.percentage_current}
          currencyValue={avans.current}
        />
        <AvansCol
          value={avans.percentage_adjusted ?? '0.00'}
          currencyValue={avans.adjusted}
          isChangeable={avans.is_changeable}
          onSubmit={memoHandleAvansSubmit}
        />
        {!isDraft && (
          <AvansCol
            value={avans.percentage_approved}
            currencyValue={isDraft ? undefined : avans.approved}
            isGrey={isDraft}
            isApproved
          />
        )}
      </GridWrapper>
    </>
  )
})
