import React from 'react'
import styled from 'styled-components'
import { useStoreMap } from 'effector-react'

import { LotRowType } from '@/dal'
import { useTooltip } from '@/lib/hooks'
import {
  DropDownList,
  Icon2,
  NewIconButton,
  OverflowText,
  palette,
  TextMLight,
  ToolTipV2,
  Typography,
} from '@/ui'

import { LotTableItemWithDepth } from '../../model'
import { onOptionClicked } from '../../model/actions.private'
import { getActionsForItem } from '../../model/helpers'
import {
  $expandedGroupIds,
  $pendingFolderIds,
  collapseGroup,
  expandGroup,
} from '../../model/private'

type Props = {
  item: LotTableItemWithDepth
  isEditMode: boolean
}

const BASE_LEFT_PADDING = 28
const DEPTH_STEP = 20

export const FirstCell = React.memo(({ item, isEditMode }: Props) => {
  const {
    id,
    name,
    depth,
    hierarchy_code: hierarchyCode,
    section_name: sectionName,
    folder_name: folderName,
    node_type: rowType,
    has_children: hasChildren,
    is_required: isRequired,
  } = item

  const isExpandable = hasChildren && rowType === LotRowType.Work

  const areChildrenLoading = useStoreMap({
    store: $pendingFolderIds,
    keys: [id, isExpandable],
    fn: (arr, [id, isExpandable]) => isExpandable && arr.includes(id),
  })

  const isExpanded = useStoreMap({
    store: $expandedGroupIds,
    keys: [id],
    fn: (ids, [rowId]) => Boolean(ids.includes(rowId)),
  })

  const { referenceProps, tooltipProps, refs, isOpen } = useTooltip({
    tooltipOffset: 18,
    placement: 'top',
    hasArrow: true,
  })

  const actions = getActionsForItem({
    ...item,
    handleOptionClick: (type) => onOptionClicked({ item, type }),
  })

  const handleToggleExpansion = () => {
    if (areChildrenLoading) return
    if (isExpanded) {
      collapseGroup(id)
    } else {
      expandGroup(id)
    }
  }

  const leftPadding = BASE_LEFT_PADDING + depth * DEPTH_STEP

  return (
    <Wrapper $leftPadding={leftPadding}>
      <Info>
        <IconWrapper>
          {isExpandable && (
            <NewIconButton
              icon={isExpanded ? 'minusWithCircle' : 'plusWithCircle'}
              color={isExpanded ? 'accent100' : 'grey60'}
              size={12}
              dataTestId="lot-table-item-toggle-expansion-btn"
              onClick={handleToggleExpansion}
            />
          )}
          {isRequired && !isExpandable && (
            <>
              <div {...referenceProps}>
                <Icon2 icon="info" color="lightBlue" size={12} />
              </div>
              <ToolTipV2 {...tooltipProps} isOpen={isOpen} bgColor="grey80" size="M">
                Обязательный материал. Добавлен автоматически.
              </ToolTipV2>
            </>
          )}
        </IconWrapper>

        <NameAndGroups ref={refs.setPositionReference}>
          <OverflowText maxTooltipWidth={350} maxLines={3}>
            {name}
          </OverflowText>

          {(sectionName || folderName) && (
            <SectionAndFolderNames>
              {sectionName && (
                <OverflowText maxTooltipWidth={350}>
                  <Typography>{sectionName}</Typography>
                </OverflowText>
              )}

              {sectionName && folderName && <p>/</p>}

              {folderName && (
                <OverflowText maxTooltipWidth={350}>
                  <Typography>{folderName}</Typography>
                </OverflowText>
              )}
            </SectionAndFolderNames>
          )}
        </NameAndGroups>

        <Typography color="grey70">{hierarchyCode}</Typography>
      </Info>

      <DropDownList options={actions}>
        <NewIconButton
          icon="verticalDots"
          size={16}
          padding={10}
          color="grey60"
          hoverBackgroundColor="accent20"
          hoverColor="accent100"
          borderRadius="50%"
          disabled={isEditMode}
          dataTestId="lot-table-item-actions-btn"
        />
      </DropDownList>
    </Wrapper>
  )
})

const Wrapper = styled.div<{ $leftPadding: number }>`
  ${TextMLight}
  position: relative;

  display: flex;
  align-items: center;
  gap: 2px;

  width: 100%;
  height: 100%;
  padding: ${({ $leftPadding }) => `12px 8px 12px ${$leftPadding}px`};
  background-color: var(--row-bg-color);
  transition: 0.15s linear background-color;
`

const Info = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 6px;
  flex-grow: 1;

  min-width: 0;
`

const IconWrapper = styled.div`
  position: absolute;
  top: 3px;
  right: calc(100% + 8px);
`

const NameAndGroups = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

const SectionAndFolderNames = styled.div`
  display: flex;
  gap: 2px;
  color: ${palette.grey70};

  & > div {
    width: 100%;
    min-width: 0;
  }
`
