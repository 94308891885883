import React from 'react'

import { SummaryFieldAlias } from '@/dal'

import {
  Col, DateCol, GridWrapper, Label, Value,
} from '../parts'
import { SummaryTableItemProps } from '../../model/types'

export const DateRow = React.memo(({
  summary, isDraft, onFieldSubmit,
}: SummaryTableItemProps) => {
  const memoHandleAvansSubmit = React.useCallback((val: string) => {
    onFieldSubmit({ field: SummaryFieldAlias.DATE, value: val })
  }, [onFieldSubmit])

  if (!summary) return null

  const {
    date_end_work: { current, adjusted, approved, is_changeable: isChangeable },
  } = summary

  return (
    <GridWrapper>
      <Label>Срок окончания работ</Label>
      {current ? (
        <DateCol date={current} />
      ) : (
        <Col>
          <Value>
            Бессрочный
          </Value>
        </Col>
      )}
      <DateCol
        date={adjusted}
        isChangeable={isChangeable}
        onSubmit={memoHandleAvansSubmit}
      />
      {!isDraft &&
        (approved ? (
          <DateCol
            date={approved}
            isGrey={isDraft}
          />
        ) : (
          <Col />
        )
      )}
    </GridWrapper>
  )
})
