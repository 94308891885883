import React from 'react'
import styled, { css } from 'styled-components'

import { VERTICAL_CONTENT_WRAPPER_PADDING } from '../const'

type Props = {
  children: React.ReactNode
  isAutoHeight?: boolean
  isAutoOverflow?: boolean
  gap?: number
}

export const ContentContainer = ({
  children,
  isAutoHeight = false,
  isAutoOverflow = false,
  gap,
}: Props) => (
  <Wrapper $isAutoHeight={isAutoHeight} $isAutoOverflow={isAutoOverflow} $gap={gap}>
    {children}
  </Wrapper>
)

const Wrapper = styled.div<{ $isAutoHeight: boolean; $isAutoOverflow?: boolean; $gap?: number }>`
  padding: ${VERTICAL_CONTENT_WRAPPER_PADDING}px 32px;
  position: relative;
  height: ${({ $isAutoHeight }) => ($isAutoHeight ? 'auto' : '100%')};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: ${({ $gap }) => `${$gap || 0}px`};

  ${({ $isAutoOverflow }) =>
    $isAutoOverflow &&
    css`
      overflow: auto;
    `}
`
