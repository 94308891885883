import { sample } from 'effector'
import { condition } from 'patronum'

import { CreateEstimateRowParams, EstimateDoc, EstimateDocId, EstimateTableRow } from '@/dal'
import { EXISTING_FILE_FLAG } from '@/lib/form-data'
import { createToast } from '@/features/toast-service/model'

import {
  $documentId,
  $morphologyDepth,
  $unitOptions,
  EstimateCorrectionGate,
  onUpdateSummary,
  setMorphologyDepth,
} from '../../shared-model'
import { PAGINATION_LIMIT } from './const'
import {
  $createdTableItem,
  $isCopying,
  $isCreateFolder,
  $isModalOpen,
  closeModal,
  copyKorDocRowFx,
  createEstimateFolderFx,
  createEstimateRowFx,
  createFolderForm,
  createRowForm,
  getEstimateRowIndexFx,
  resetCreatedTableItem,
  setCreatedTableItem,
} from './create-table-item.private'
import { openCreateItemModal } from './create-table-item.public'
import { $acceptedTableFilters } from './filters.private'
import {
  $isTreeTable,
  $offset,
  addCreatedItemsFx,
  incrementItems,
  onLoadMore,
  paginateFlatTableFx,
  scrollToItemById,
  TableGate,
} from './private'
import { onItemCreate } from './public'

$isModalOpen.on(openCreateItemModal, () => true).reset(closeModal)

$isCreateFolder.on(openCreateItemModal, (_, { isFolder }) => isFolder).reset(closeModal)

$isCopying.on(openCreateItemModal, (_, { isCopying }) => Boolean(isCopying)).reset(closeModal)

$createdTableItem
  .on(setCreatedTableItem, (_, item) => item)
  .reset(resetCreatedTableItem, TableGate.close, scrollToItemById)

sample({
  clock: openCreateItemModal,
  filter: ({ parentFolder, isFolder }) => Boolean(parentFolder && isFolder),
  fn: ({ parentFolder }) => parentFolder,
  target: createFolderForm.fields.group_id.set,
})

sample({
  clock: openCreateItemModal,
  filter: ({ parentFolder, isFolder }) => Boolean(parentFolder && !isFolder),
  fn: ({ parentFolder }) => parentFolder,
  target: createRowForm.fields.group_id.set,
})

sample({
  clock: openCreateItemModal,
  source: $unitOptions,
  filter: (_, { isCopying }) => Boolean(isCopying),
  fn: (unitOptions, { item }) => ({
    name: item.name.value,
    project: item.project?.id ?? null,
    // если ед. изм. нет в справочнике, то не сетаем
    unit: item.unit?.id && unitOptions.some(({ id }) => item.unit?.id === id) ? item.unit.id : null,
    count_in_estimate:
      item.count_in_estimate?.value_adjusted ?? item.count_in_estimate?.value_current ?? '',
    material: item.price_material?.value_adjusted ?? item.price_material?.value_current ?? '',
    work: item.price_work?.value_adjusted ?? item.price_work?.value_current ?? '',
    justification_of_changes_current: item.justification_of_changes?.value ?? '',
    acting_group: item.acting_group?.id ?? null,
    building: item.building?.id ?? null,
    section: item.section?.id ?? null,
    floor: item.floor?.id ?? null,
  }),
  target: createRowForm.setForm,
})

sample({
  clock: openCreateItemModal,
  filter: ({ item, isCopying }) => Boolean(isCopying && item.attachments?.length),
  fn: ({ item }) =>
    item.attachments!.map(
      (item) =>
        new File([''], item.name, {
          type: `${EXISTING_FILE_FLAG}/${item.id}`,
        }),
    ),
  target: createRowForm.fields.files.set,
})

sample({
  clock: [EstimateCorrectionGate.close, EstimateCorrectionGate.state],
  target: closeModal,
})

sample({
  clock: closeModal,
  target: [createFolderForm.reset, createRowForm.reset],
})

sample({
  clock: [createRowForm.fields.material.$value, createRowForm.fields.work.$value],
  target: [createRowForm.fields.material.resetErrors, createRowForm.fields.work.resetErrors],
})

sample({
  clock: createRowForm.fields.project.onChange,
  target: createRowForm.fields.building.reset,
})

sample({
  clock: createRowForm.fields.building.onChange,
  target: createRowForm.fields.section.reset,
})

sample({
  clock: createRowForm.fields.section.onChange,
  target: createRowForm.fields.floor.reset,
})

condition({
  source: sample({
    clock: createRowForm.formValidated,
    source: {
      id: $documentId,
      isTree: $isTreeTable,
    },
    filter: ({ id }) => Boolean(id),
    fn: ({ id, isTree }, form) =>
      ({
        documentId: id!,
        is_tree: isTree,
        ...form,
        unit: form.unit!,
      } as CreateEstimateRowParams),
  }),
  if: $isCopying,
  then: copyKorDocRowFx,
  else: createEstimateRowFx,
})

sample({
  clock: createFolderForm.formValidated,
  source: {
    id: $documentId,
    isTree: $isTreeTable,
  },
  filter: ({ id }) => Boolean(id),
  fn: ({ id, isTree }, form) => ({
    documentId: id as EstimateDocId,
    is_tree: isTree,
    ...form,
  }),
  target: createEstimateFolderFx,
})

sample({
  clock: [createEstimateRowFx.done, copyKorDocRowFx.done, createEstimateFolderFx.done],
  target: closeModal,
})

sample({
  clock: [createEstimateRowFx.done, copyKorDocRowFx.done, createEstimateFolderFx.done],
  source: $isTreeTable,
  filter: Boolean,
  fn: (_, { params, result }) => ({
    ...result,
    documentId: params.documentId,
  }),
  target: onItemCreate,
})

sample({
  clock: [createEstimateRowFx.done, copyKorDocRowFx.done, createEstimateFolderFx.done],
  filter: ({ result }) => Boolean(result.summary),
  fn: ({ params, result }) => ({
    docId: params.documentId,
    summary: result.summary as EstimateDoc['summary_table'],
  }),
  target: onUpdateSummary,
})

sample({
  clock: [createEstimateRowFx.doneData, copyKorDocRowFx.doneData],
  source: {
    isTreeTable: $isTreeTable,
    docId: $documentId,
    filters: $acceptedTableFilters,
  },
  filter: ({ isTreeTable, docId }, { target }) => Boolean(!isTreeTable && target && docId),
  fn: ({ docId, filters }, { target }) => ({
    id: target?.id as EstimateTableRow['id'],
    documentId: docId as EstimateDocId,
    ...filters,
  }),
  target: getEstimateRowIndexFx,
})

sample({
  clock: [createEstimateRowFx.doneData, copyKorDocRowFx.doneData],
  source: $isTreeTable,
  filter: (isTreeTable, data) => Boolean(!isTreeTable && data),
  fn: (_, { target }) => target as EstimateTableRow,
  target: setCreatedTableItem,
})

sample({
  clock: [createEstimateRowFx.doneData, copyKorDocRowFx.doneData],
  source: $morphologyDepth,
  filter: (_, { target }) => Boolean(target),
  fn: (currentDepth, { target }) => {
    const newRowDepth = target?.floor?.id
      ? 3
      : target?.section?.id
      ? 2
      : target?.building?.id
      ? 1
      : 0
    return Math.max(currentDepth, newRowDepth)
  },
  target: setMorphologyDepth,
})

sample({
  clock: getEstimateRowIndexFx.done,
  source: { offset: $offset, target: $createdTableItem },
  filter: ({ offset, target }, { result: index }) =>
    Boolean(target && index >= 0 && index < offset),
  fn: ({ target }, { params, result: index }) => ({
    target: target as EstimateTableRow,
    documentId: params.documentId,
    index,
  }),
  target: onItemCreate,
})

sample({
  clock: getEstimateRowIndexFx.doneData,
  source: $offset,
  filter: (offset, index) => index >= 0 && index >= offset,
  fn: (offset, index) => Math.floor((index - offset) / PAGINATION_LIMIT) + 1,
  target: onLoadMore,
})

sample({
  clock: getEstimateRowIndexFx.doneData,
  filter: (index) => index < 0,
  target: resetCreatedTableItem,
})

sample({
  clock: getEstimateRowIndexFx.fail,
  target: resetCreatedTableItem,
})

sample({
  clock: [addCreatedItemsFx.done, paginateFlatTableFx.done],
  source: $createdTableItem,
  filter: Boolean,
  fn: ({ id }) => id,
  target: scrollToItemById,
})

sample({
  clock: addCreatedItemsFx.done,
  source: $isTreeTable,
  filter: (isTree) => !isTree,
  target: incrementItems,
})

// тосты

createToast({
  effect: createEstimateRowFx,
  doneText: 'Строка создана',
})

createToast({
  effect: copyKorDocRowFx,
  doneText: 'Строка успешно скопирована',
  errorText: 'Ошибка копирования строки. Попробуйте еще раз',
})

createToast({
  effect: createEstimateFolderFx,
  doneText: 'Папка создана',
})
