export const mapAndSortMorphologyList = <T extends { id: number; name: string }>(
  items: T[] | null,
) => {
  return (
    items
      ?.map(({ name, ...item }) => ({ label: name, ...item }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, undefined, { numeric: true, sensitivity: 'base' }),
      ) ?? []
  )
}
