import { ContractItem } from '../administrative'
import { ProjectItemType } from '../contract-filters'
import { DefaultErrorResponseData } from '../error-handler'
import {
  AbortableRequest,
  ApprovementProcessItem,
  DefaultUpdateFileParams,
  DocumentAlias,
  DocumentExclusiveBlock,
  DocumentFeatures,
  DocumentProgress,
  DocumentStatuses,
  EstimateDocId,
  ExistingKorDoc,
  FileDTO,
  RejectionReason,
  SummaryFieldAlias,
  SummaryTable,
} from '../interfaces'

export type SendEstimateToApproveParams = {
  id: EstimateDocId
} & (
  | {
      comment?: string
    }
  | {
      ds_text_file?: File
      gfpr_file?: File
      gpr_file?: File
    }
)

export type EstimateSumInfo = {
  current: string
  adjusted: string
  approved: string | null
  is_changeable?: boolean
  is_edited?: boolean
}

export type EstimateDoc = {
  id: EstimateDocId
  name: string
  last_update: string // isoDate
  status: DocumentStatuses
  type: DocumentAlias.KOR_DOC
  contract: {
    name: string
    id: ContractItem['id']
    project: {
      id: ProjectItemType['id']
      name: string
    }
  }
  summary_table: SummaryTable
  features: DocumentFeatures & {
    show_business_offer?: boolean
    is_document_changed?: boolean
    document_exclusive_block: DocumentExclusiveBlock
  }
  status_text?: string
  approving_additional_agreement: {
    name: string
    type: DocumentAlias
    id: number
    status: DocumentStatuses
  } | null
  progress?: DocumentProgress | null
  attachments: FileDTO[] | null
  approve_comment?: string | null
  reject_comment: RejectionReason
  cancel_comment: RejectionReason
  /** Есть ли группы актирования */
  is_act_group_used: boolean
  /** Максимальная вложенность морфологии во всех строках */
  morphology_depth: number
  is_valid: boolean
  has_splitting_objects: boolean
  conflicting_draft_kor_doc: ExistingKorDoc
  approvement_process_log: ApprovementProcessItem[] | null
}

export type PostAttachmentsParams = {
  id: EstimateDocId
} & DefaultUpdateFileParams

export type PostTableItemAttachmentsParams = {
  id: EstimateTableRow['id']
} & DefaultUpdateFileParams

export type SendSummaryInfoParams = {
  field: SummaryFieldAlias
  value: string
  id: EstimateDocId
}

export enum EstimateCorrectionType {
  /** Скорректированные */
  Corrected = 'Corrected',
  /** Неподтвержденные */
  NotApproved = 'NotApproved',
  /** Выполненные */
  Done = 'Done',
  /** Новые добавленные */
  New = 'New',
  /** Гранд-тендер */
  GrandTender = 'GrandTender',
}

export type GetEstimateTableParams = {
  documentId: EstimateDocId
  group_id?: EstimateTableRow['id']
} & KorDocFiltersPayload

export type GetEstimateFolderRowsParams = GetEstimateTableParams & {
  group_id: EstimateTableRow['id']
}

export type EstimateStringCell = {
  value: string
  editable: boolean
  valid: boolean
}

export type EstimateStringCellWithId = {
  id: number
} & EstimateStringCell

export type EstimateNumberCell = {
  value_current: string | null
  value_adjusted: string | null
  value_approved: string | null
  editable: boolean
  valid: boolean
}

export type GetEstimateTreeTableResponse = {
  data: EstimateTableRow[]
  total: number
}

export type TableRowFields = {
  id: string
  note: EstimateStringCell | null
  cost_item: EstimateStringCell | null
  unit: EstimateStringCellWithId | null
  count_in_estimate: EstimateNumberCell | null
  approved: EstimateNumberCell | null
  on_approving: EstimateNumberCell | null
  remain: EstimateNumberCell | null
  price_material: EstimateNumberCell | null
  price_work: EstimateNumberCell | null
  price_all: EstimateNumberCell | null
  cost_material: EstimateNumberCell | null
  cost_work: EstimateNumberCell | null
  cost_all: EstimateNumberCell | null
  justification_of_changes: EstimateStringCell | null
  attachments: FileDTO[] | null
  features?: {
    can_delete?: boolean
    can_split_volume?: boolean
    can_edit?: boolean
    can_reset?: boolean
    can_add_attachments?: boolean
  }
  // теперь это неактуальные поля
  additional_requisites: {
    provider: EstimateStringCell
    specifications: EstimateStringCell
    stamp: EstimateStringCell
  } | null
}

export type EstimateTableRow = TableRowFields & {
  name: EstimateStringCell
  code_1c: string
  depth: number
  project: {
    id: number
    name: string
    editable: boolean
  } | null
  children: string[] | null
  parent: EstimateTableRow['id'] | null
  is_folder: boolean
  is_grand_tender: boolean
  is_valid: boolean
  is_changed: boolean
  comment_info: {
    new_count: number
    total: number
  } | null
  total_items_count: number | null
  new_lines: [TableRowFields] | null
  // только для ПС с ГА
  acting_group: EstimateStringCellWithId | null
  building: EstimateStringCellWithId | null
  section: EstimateStringCellWithId | null
  floor: EstimateStringCellWithId | null
}

export type CreateEstimateRowParams = {
  is_tree: boolean
  documentId: EstimateDocId
  group_id: EstimateTableRow['id'] | null
  name: string
  project: number | null

  unit: number | null
  count_in_estimate: string
  material: string
  work: string

  justification_of_changes_current: string
  files: File[]
  // только ПС с ГА
  acting_group: number | null
  building: number | null
  section: number | null
  floor: number | null
}

export type UpdateEstimateRowItem = {
  project: number | null
  unit: number | null
  count_in_estimate: string
  material: string
  work: string
  justification_of_changes_current: string
  name: string

  new_count_in_estimate: string
  new_justification_of_changes_current: string
  new_material: string
  new_work: string
}

export type UpdateEstimateRowItemWithId = {
  id: EstimateTableRow['id']
} & UpdateEstimateRowItem

export type UpdateEstimateRowParams = {
  isFolder: boolean

  // path params
  documentId: EstimateDocId
  itemId: EstimateTableRow['id']

  // query params
  isTree: boolean

  // body params
} & UpdateEstimateRowItem

export type UpdateMultipleEstimateRowsParams = {
  documentId: EstimateDocId
  items: UpdateEstimateRowItemWithId[]
}

export type UpdateMultipleEstimateRowsResponse = {
  items: EstimateTableRow[]
  features: EstimateDoc['features']
  summary: EstimateDoc['summary_table']
}

export type CreateEstimateFolderParams = {
  // path params
  documentId: EstimateDocId

  // query params
  is_tree: boolean
  group_id: EstimateTableRow['id'] | null

  // body params
  name: string
}

export type SplitVolumeParams = {
  // path params
  documentId: EstimateDocId

  // query params
  itemId: EstimateTableRow['id']
  is_tree: boolean

  // body params
  old_count_in_estimate: string
  old_files_to_save: File[]
  old_files_to_delete: FileDTO['id'][]
  old_justification_of_changes_current: string

  new_material: string
  new_work: string
  new_count_in_estimate: string
  new_files_to_save: File[]
  new_justification_of_changes_current: string
}

export type DefaultChangedTableResponse = {
  // updated root item, for update a root array
  root?: EstimateTableRow
  // tree updated items
  tree?: { [key in EstimateTableRow['id']]: EstimateTableRow[] }
  // updated summary table date
  summary?: EstimateDoc['summary_table']
  // target item or created item
  target?: EstimateTableRow
}

export type DeleteResponse = {
  summary?: EstimateDoc['summary_table']
}

export type DeleteResetTableItemParams = {
  isFolder: boolean

  // path params
  documentId: EstimateDocId
  itemId: EstimateTableRow['id']

  // query params
  isTree: boolean
}

export type GroupListItem = {
  id: number
  name: string
  code_1c: string
}

export type UnitListItem = {
  id: number
  short_name: string
  full_name: string
}

export type ProjectListItem = GroupListItem

type GaItem = {
  id: number
  name: string
}

export type KorDocMorphologyItem = {
  id: number
  name: string
  parent_id: number
}

export type GetCatalogInfoResponse = {
  groups: GroupListItem[]
  units: UnitListItem[]
  projects: ProjectListItem[]
  // только у ПС с ГА
  acting_groups: GaItem[] | null
  buildings: KorDocMorphologyItem[] | null
  sections: KorDocMorphologyItem[] | null
  floors: KorDocMorphologyItem[] | null
}

export type AvailableGaFiltersResponse = {
  act_groups: GaItem[]
  buildings: KorDocMorphologyItem[]
  sections: KorDocMorphologyItem[]
  floors: KorDocMorphologyItem[]
  // не используется на фронте
  projects: {
    id: string
    name: string
  }[]
}

export type KorDocFiltersPayload = {
  search?: string
  types?: EstimateCorrectionType[]
  act_groups?: number[]
  buildings?: number[]
  sections?: number[]
  floors?: number[]
}

export type GetEstimateRowCommentsParams = AbortableRequest<{
  // path params
  documentId: EstimateDocId
  itemId: EstimateTableRow['id']
}>

export type EstimateRowCommentDTO = {
  id: number
  author: string
  date_time: string
  body: string
  is_new: boolean
}

export type ResetKorDocRowsPayload = {
  id: EstimateDocId
  uuids?: EstimateTableRow['id'][]
  groups?: EstimateTableRow['id'][]
  clear_all?: boolean
}

export enum EstimateCorrectionErrorCode {
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  KOR_DOC_NEW_CONFLICT = 'KOR_DOC_NEW_CONFLICT',
}

export type KorDocValidationErrorData = {
  code: EstimateCorrectionErrorCode.VALIDATION_ERROR
  detail: string
}

export type EstimateNewConflictErrorData = {
  code: EstimateCorrectionErrorCode.KOR_DOC_NEW_CONFLICT
  detail: string
  doc_type: DocumentAlias
  id: string | number
  name: string
}

export type EstimateCorrectionErrorData =
  | KorDocValidationErrorData
  | EstimateNewConflictErrorData
  | DefaultErrorResponseData
