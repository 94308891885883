import React from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import styled from 'styled-components'
import { useStoreMap, useUnit } from 'effector-react'

import { useOnChangeLocation } from '@/lib/useOnChangeLocation'
import { HeadL, ModalLayoutV2, NewButton, palette, TextL, TextLLight } from '@/ui'

import {
  $contacts,
  $deleteTargetContactId,
  deleteContact,
  deleteContactFx,
  rejectConfirm,
} from '../../model/private'

export const DeleteContactConfirm = React.memo(() => {
  const targetId = useUnit($deleteTargetContactId)
  const contact = useStoreMap({
    store: $contacts,
    keys: [targetId],
    fn: (items, [id]) => items?.find((item) => item.id === id) ?? null,
  })
  const isPending = useUnit(deleteContactFx.pending)

  const roles = React.useMemo(
    () =>
      contact?.projects.flatMap((item) =>
        item.roles.map((role) => ({
          projectName: item.name,
          roleName: role.name,
        })),
      ),
    [contact],
  )

  useOnChangeLocation(rejectConfirm)
  if (!targetId) return null

  return (
    <ModalLayoutV2 dataTestId="delete-contact-confirm" closeModal={rejectConfirm}>
      <Wrapper>
        <Header>Удаление контакта</Header>
        <Scrollbars autoHeight autoHeightMax="60dvh">
          <Body>
            При нажатии на кнопку «Удалить контакт» {contact?.fio} будет полностью удален из
            справочника контактов.
            <RoleList>
              <ListTitle>Присвоенные роли:</ListTitle>

              {roles?.map((item, index) => (
                <div key={index}>
                  <ItemRole>{item.roleName}</ItemRole>
                  <ItemProject>{item.projectName}</ItemProject>
                </div>
              ))}
            </RoleList>
          </Body>
        </Scrollbars>

        <Buttons>
          <NewButton
            dataTestId="confirm-button"
            label="Да, удалить"
            onClick={deleteContact}
            isPending={isPending}
          />
          <NewButton
            dataTestId="reject-button"
            label="Отменить"
            buttonType="grey"
            onClick={rejectConfirm}
          />
        </Buttons>
      </Wrapper>
    </ModalLayoutV2>
  )
})

const Wrapper = styled.div`
  background-color: ${palette.white};
  padding: 32px 0 20px 0;
`

const Header = styled.h5`
  ${HeadL}
  padding: 0 32px;
`

const Body = styled.p`
  ${TextLLight}
  margin-top: 12px;
  margin-bottom: 24px;
  padding: 0 32px;
  max-width: 370px;
`

const RoleList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 24px;
`

const ListTitle = styled.div`
  ${TextL}
`

const ItemRole = styled.div`
  ${TextLLight}
`
const ItemProject = styled.div`
  ${TextLLight}
  color: ${palette.grey70};
`

const Buttons = styled.div`
  display: flex;
  gap: 16px;
  padding: 24px 32px 0;
`
