import { attach, Domain, Effect, sample, Store, Unit } from 'effector'
import { debounce } from 'patronum'

import { DocumentExclusiveBlock } from '@/dal'
import { root } from '@/root-domain'

type Params<T, F> = {
  domain?: Domain
  getFeaturesFx: Effect<T, F>
  $docId: Store<T | null>
  $features: Store<F | null>
  triggerUnits?: Unit<unknown>[]
}

export const createDocumentBlocking = <
  T,
  F extends { document_exclusive_block: DocumentExclusiveBlock },
>({
  domain,
  getFeaturesFx,
  $docId,
  $features,
  triggerUnits = [],
}: Params<T, F>) => {
  const d = domain ?? root.domain()

  const checkDocumentBlocking = d.event()
  const fetchFeatures = d.event()
  const onDocumentUnlocked = d.event()

  const pollFeaturesFx = attach({ effect: getFeaturesFx })

  sample({
    clock: triggerUnits,
    target: fetchFeatures,
  })

  sample({
    clock: [debounce(fetchFeatures, 1500), checkDocumentBlocking],
    source: $docId,
    filter: Boolean,
    target: pollFeaturesFx,
  })

  sample({
    clock: $features,
    filter: (features) => Boolean(features?.document_exclusive_block.is_blocked),
    target: fetchFeatures,
  })

  sample({
    clock: pollFeaturesFx.fail,
    target: fetchFeatures,
  })

  sample({
    clock: pollFeaturesFx.doneData,
    filter: ({ document_exclusive_block }) => !document_exclusive_block.is_blocked,
    target: onDocumentUnlocked,
  })

  return {
    /** @deprecated */
    checkDocumentBlocking,
    onDocumentUnlocked,
  }
}
