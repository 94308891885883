import { sample } from 'effector'
import { debounce } from 'patronum'
import { $selectedGroupId, onSelectedGroupIdChanged } from '@/features/group-tree/model'
import { createToast } from '@/features/toast-service/model'

import { onWorkAdded } from '../../kvr-ker-catalog/model'
import { $documentId, $documentInfo } from '../../model'
import {
  $editingItemId,
  $filters,
  $isMultipleEditMode,
  $nestedWorks,
  $pendingFolderIds,
  $tableCosts,
  $tableRoot,
  $tableTree,
  $updatingWorkIds,
  $workToDelete,
  changeElementPrice,
  changeElementPriceFx,
  changeLorWorkComment,
  changeLorWorkCommentFx,
  clearFilter,
  exitEditMode,
  expandGroup,
  getFolderNestedWorksFx,
  getLotTableFx,
  getLotTableRowsFx,
  getTableData,
  getUnitsFx,
  resetFilters,
  resetTableData,
  resetWorkToDelete,
  setEditingItem,
  setIsMultipleEditMode,
  setRootUpdate,
  setTreeUpdate,
  setWorkOrFolderToDelete,
  updateItemsFx,
} from './private'
import { $units, EstimateLotTableGate, onWorkOrFolderDeleted } from './public'
import { Tree } from './types'

import './actions.init'
// import './editing.init'

$tableRoot
  .on(setRootUpdate, (_, root) => root)
  .on(getLotTableFx.doneData, (_, { root }) => root)
  .on(
    changeLorWorkCommentFx.done,
    (data, { params, result }) =>
      data?.map((item) =>
        item.id === params.id
          ? {
              ...item,
              comment: {
                ...item.comment,
                value: result,
              },
            }
          : item,
      ) ?? null,
  )
  .reset(resetTableData)

$tableTree
  .on(getLotTableRowsFx.done, (map, { params, result }) => ({
    ...map,
    [params.groupId]: result.root,
  }))
  .on(setTreeUpdate, (map, update) => ({
    ...map,
    ...update,
  }))
  .reset(EstimateLotTableGate.close, getLotTableFx.done)

$editingItemId
  .on(setEditingItem, (_, value) => value)
  .reset(EstimateLotTableGate.close, exitEditMode)

$isMultipleEditMode
  .on(setIsMultipleEditMode, (_, val) => val)
  .reset(EstimateLotTableGate.close, exitEditMode)

$tableCosts.on(getLotTableFx.doneData, (_, { costs }) => costs).reset(EstimateLotTableGate.close)

$pendingFolderIds
  .on(getLotTableRowsFx, (arr, params) => [...arr, params.groupId])
  .on(getLotTableRowsFx.finally, (arr, { params }) => arr.filter((id) => id !== params.groupId))
  .reset(EstimateLotTableGate.close, getLotTableFx)

$workToDelete
  .on(setWorkOrFolderToDelete, (_, data) => data)
  .reset(EstimateLotTableGate.close, resetWorkToDelete)

$nestedWorks
  .on(getFolderNestedWorksFx.doneData, (_, data) => data)
  .reset(EstimateLotTableGate.close, resetWorkToDelete)

$updatingWorkIds
  .on(changeLorWorkComment, (arr, { id }) => (arr.includes(id) ? arr : [...arr, id]))
  .on(changeLorWorkCommentFx.finally, (arr, { params: { id } }) =>
    arr.filter((item) => item !== id),
  )
  .reset(EstimateLotTableGate.close, $documentId.updates)

$units.on(getUnitsFx.doneData, (_, data) => data).reset(EstimateLotTableGate.close)

sample({
  clock: EstimateLotTableGate.open,
  target: getUnitsFx,
})

sample({
  clock: $documentId.updates,
  source: $documentInfo,
  fn: (_, id) => ({ id }),
  target: getTableData,
})

sample({
  clock: EstimateLotTableGate.close,
  target: resetTableData,
})

sample({
  clock: [
    getTableData,
    onSelectedGroupIdChanged,
    onWorkAdded,
    onWorkOrFolderDeleted,
    debounce($filters.updates, 700),
  ],
  source: {
    documentId: $documentId,
    groupId: $selectedGroupId,
    filters: $filters,
  },
  filter: ({ documentId }) => Boolean(documentId),
  fn: ({ documentId, groupId, filters }) => ({
    ...filters,
    groupId,
    id: documentId!,
  }),
  target: getLotTableFx,
})

sample({
  clock: resetFilters,
  target: clearFilter,
})

sample({
  clock: expandGroup,
  source: {
    documentId: $documentId,
    tree: $tableTree,
  },
  filter: ({ documentId, tree }, groupId) => Boolean(documentId && !tree[groupId]),
  fn: ({ documentId }, groupId) => ({
    groupId,
    id: documentId!,
  }),
  target: getLotTableRowsFx,
})

/*
sample({
  clock: changeElementPriceFx.doneData,
  source: {
    currentRoot: $tableRoot,
    currentTree: $tableTree,
  },
  fn: (tableData, { target, root, tree }) => ({
    target, root, tree, ...tableData,
  }),
  target: updateItemsFx,
})
  */

sample({
  clock: [updateItemsFx.doneData, changeElementPriceFx.doneData],
  fn: ({ root }) => root,
  target: setRootUpdate,
})

sample({
  clock: updateItemsFx.doneData,
  filter: ({ tree }) => Boolean(tree),
  fn: ({ tree }) => tree as Tree,
  target: setTreeUpdate,
})

sample({
  clock: changeElementPrice,
  target: changeElementPriceFx,
})

sample({
  clock: changeLorWorkComment,
  target: changeLorWorkCommentFx,
})

createToast({
  effect: changeLorWorkCommentFx,
  doneText: 'Комментарий успешно изменен',
})
