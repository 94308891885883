import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { LoaderOverlay, palette } from '@/ui'
import { updateRolesFx } from '@/features/my-company/model'

import { $roles } from '../../model/private'
import { RoleItem } from './RoleItem'

export const RoleList = React.memo(() => {
  const [roles, isLoading] = useUnit([$roles, updateRolesFx.pending])

  return (
    <Wrapper>
      {roles?.map(
        ({
          id,
          name,
          count_contacts_on_current_project,
          count_unique_projects_of_role_members,
        }) => (
          <li key={id}>
            <RoleItem
              roleId={id}
              roleName={name}
              contactCount={count_contacts_on_current_project}
              projectCount={count_unique_projects_of_role_members}
            />
          </li>
        ),
      )}
      {isLoading && (
        <LoaderWrapper>
          <LoaderOverlay isShown />
        </LoaderWrapper>
      )}
    </Wrapper>
  )
})

const Wrapper = styled.ul`
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 8px;
`

const LoaderWrapper = styled.div`
  position: absolute;
  inset: 0;
  background-color: ${palette.white};
`
