import React from 'react'
import styled from 'styled-components'

import { numberWithSpaces } from '@/lib/number-represent'
import { EditableValue } from '@/ui'

import { Col, Value } from './sharedCSS'

type Props = {
  value: string
  currencyValue?: string | null
  isChangeable?: boolean
  isApproved?: boolean
  isGrey?: boolean
  onSubmit?: (val: string) => void
}

const formatString = (string: string) => numberWithSpaces(string.toString())

export const AvansCol = ({
  currencyValue,
  value,
  isApproved,
  isChangeable,
  isGrey,
  onSubmit,
}: Props) => {
  return (
    <Col>
      <Value $isGrey={isGrey}>
        {formatString(value)}
        {' %'}
      </Value>

      {currencyValue &&
        (isChangeable && onSubmit ? (
          <EditableValueWrapper>
            <EditableValue
              value={currencyValue}
              cellType="currency"
              placeholder="Введите цену"
              isCentered
              onSubmit={onSubmit}
            />
          </EditableValueWrapper>
        ) : (
          <Value $isApproved={isApproved} $isGrey={isGrey}>
            {formatString(currencyValue)}
          </Value>
        ))}
    </Col>
  )
}

const EditableValueWrapper = styled.div`
  position: relative;
  height: 18px;
`
