import React, { Key } from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { ApprovementProcessItem } from '@/dal'
import { getFullTime } from '@/lib/date'
import { $previousRoute, RouterPaths } from '@/features/app/model'

import { Icon2 } from '../../icons'
import { palette } from '../../palette'
import { HeadL, TextMLight } from '../../typography'
import { SectionTabs, Tab } from '../Tabs'
import { ApprovementProcessButton } from './ApprovementProcessButton'
import { DocumentHeaderButtons } from './DocumentHeaderButtons'
import { ButtonsProps } from './types'

type Props<T> = ButtonsProps & {
  link: RouterPaths
  documentName: string
  /** isoDate */
  lastUpdate: string
  downloadInstructionURL?: string | null
  downloadInstructionText?: string
  isBlocked?: boolean
  canCopy?: boolean
  tabs?: Tab<T>[]
  activeTabId?: T
  docSubTypeSlot?: React.ReactNode
  rightBottomSlot?: React.ReactNode
  invalidTabId?: T | null
  approvementProcess?: ApprovementProcessItem[] | null
  onChangeTab?: (id: T) => void
}

export const DocumentHeader = <T extends Key>({
  link,
  documentName,
  lastUpdate,
  downloadInstructionURL,
  activeTabId,
  tabs,
  docSubTypeSlot,
  rightBottomSlot,
  invalidTabId,
  downloadInstructionText = 'Инструкция по использованию',
  approvementProcess,
  onChangeTab,
  ...buttonsProps
}: Props<T>) => {
  const history = useHistory()
  const previousRoute = useUnit($previousRoute)

  const handleGoBack = (e: React.MouseEvent) => {
    if (previousRoute) {
      history.goBack()
      e.preventDefault()
    }
  }

  return (
    <Wrapper>
      <Row>
        <div>
          <TitleWrapper>
            <GoBack to={link} onClick={handleGoBack}>
              <Icon2 icon="arrowLeft" size={24} color="grey90" />
              <Title>{documentName}</Title>
            </GoBack>

            {docSubTypeSlot}
          </TitleWrapper>

          <Subtitle>
            {lastUpdate && <p>Последнее изменение {getFullTime(lastUpdate)}</p>}

            {downloadInstructionURL && (
              <>
                <span>·</span>
                <InstructionWrapper href={downloadInstructionURL} download>
                  <span>{downloadInstructionText}</span>
                  <Icon2 icon="document" color="inherit" />
                </InstructionWrapper>
              </>
            )}
          </Subtitle>
        </div>

        <RightRow>
          {approvementProcess && Boolean(approvementProcess.length) && (
            <ApprovementProcessButton approvementProcess={approvementProcess} />
          )}
          <DocumentHeaderButtons {...buttonsProps} />
        </RightRow>
      </Row>

      {(tabs || rightBottomSlot) && (
        <BottomRow>
          {tabs && onChangeTab && activeTabId && (
            <SectionTabs
              items={tabs}
              activeId={activeTabId}
              invalidTabId={invalidTabId}
              onItemClick={onChangeTab}
            />
          )}

          {rightBottomSlot}
        </BottomRow>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: ${palette.white};
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px;
  border-radius: 20px;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`

const RightRow = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const GoBack = styled(Link)`
  display: flex;
  gap: 6px;
`

const Title = styled.h1`
  ${HeadL}
`

const Subtitle = styled.div`
  ${TextMLight}
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  color: ${palette.grey70};
  margin-left: 30px;
`

const InstructionWrapper = styled.a`
  ${TextMLight}
  display: flex;
  gap: 2px;
  align-items: center;
  color: ${palette.accent100};
`

const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
`
