import { attach, combine } from 'effector'

import { addLotWorksReqFx, getLotKvrItemsReqFx, KvrElement } from '@/dal'
import { getFlatTreeWithDepth } from '@/lib/tree/getFlatTree'
import { createSelectedIds } from '@/features/factories'

import { d } from './domain'
import { CatalogTree, KvrIdWithKerId } from './types'

export const resetWorkCatalog = d.event()

export const $searchValue = d.store('')
export const changeSearchValue = d.event<string>()
export const acceptSearch = d.event()

// TODO поиск
// export const $searchResults = d.store<KvrElement[] | null>(null)
// export const setSearchResults = d.event<KvrElement[] | null>()
// export const $isSearchAccepted = d.store(false)
// export const setIsSearchAccepted = d.event<boolean>()

export const $catalogRoot = d.store<KvrElement[]>([])
export const $catalogTree = d.store<CatalogTree>({})

export const { $ids: $expandedKvrIds, toggleId: toggleExpandedKvr } = createSelectedIds<
  KvrElement['id']
>({
  resetUnits: [resetWorkCatalog],
})

export const { $ids: $selectedKerIds, toggleId: toggleSelectedKer } =
  createSelectedIds<KvrIdWithKerId>({
    resetUnits: [resetWorkCatalog],
  })

export const $hasSelectedKers = $selectedKerIds.map((ids) => Boolean(ids.length))

export const addWorksToLot = d.event()

const $expandedCatalogTree = combine([$catalogTree, $expandedKvrIds], ([tree, expandedIds]) => {
  const resultTree: CatalogTree = {}
  for (const id of expandedIds) {
    resultTree[id] = tree[id]
  }
  return resultTree
})

export const $catalogItems = combine(
  {
    root: $catalogRoot,
    tree: $expandedCatalogTree,
    isTree: true,
  },
  getFlatTreeWithDepth,
)

export const getKvrChildren = d.event<KvrElement['id']>()

export const getCatalogRootFx = attach({
  effect: getLotKvrItemsReqFx,
})
export const getKvrItemsFx = attach({
  effect: getLotKvrItemsReqFx,
})
export const addLotWorksFx = attach({
  effect: addLotWorksReqFx,
})
