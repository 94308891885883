import React from 'react'
import { combine } from 'effector'
import { useUnit } from 'effector-react'

import { DocumentStatuses, EstimateCorrectionType } from '@/dal'
import { MultiSelectPopup, NewButton } from '@/ui'
import { $documentStatus, $isGa } from '@/features/estimate-correction/shared-model'

import { resetFilters } from '../../../model'
import {
  $acceptedTypes,
  $acceptedTypesCount,
  $types,
  setAcceptedTypes,
} from '../../../model/filters.private'
import { AdditionalGaFilters } from './AdditionalGaFilters'

const $filteredTypes = combine([$types, $documentStatus], ([types, status]) =>
  status === DocumentStatuses.Draft
    ? types.filter(({ id }) => id !== EstimateCorrectionType.NotApproved)
    : types,
)

type Props = {
  isDisabled: boolean
}

export const FilterDropdown = React.memo(({ isDisabled }: Props) => {
  const positionRef = React.useRef<HTMLElement>(null)

  const [selectedTypes, types, acceptedTypesCount, isGa] = useUnit([
    $acceptedTypes,
    $filteredTypes,
    $acceptedTypesCount,
    $isGa,
  ])

  const triggerComponent = React.useCallback(() => {
    return (
      <NewButton
        size="S"
        dataTestId=""
        label={`Фильтр таблицы${acceptedTypesCount ? ` (${acceptedTypesCount})` : ''}`}
        buttonType="grey"
        prefixIcon="filter"
        isDisabled={isDisabled}
      />
    )
  }, [acceptedTypesCount, isDisabled])

  return (
    <MultiSelectPopup
      ref={positionRef}
      triggerComponent={triggerComponent}
      testId="estimate-filters"
      items={types}
      selectedIds={selectedTypes}
      isDisabled={isDisabled}
      additionalSlot={isGa ? <AdditionalGaFilters positionRef={positionRef} /> : null}
      onAcceptClick={setAcceptedTypes}
      onClear={resetFilters}
    />
  )
})
