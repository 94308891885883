import React from 'react'
import styled from 'styled-components'

import { DefaultButton } from '../../buttons'
import { Icon2 } from '../../icons'

type Props = {
  isFolder: boolean
  isExpanded: boolean
  hasChildren: boolean
  onClick?: () => void
}

export const FolderComponent = ({ isFolder, isExpanded, hasChildren, onClick }: Props) => {
  if (!isFolder) return null
  return (
    <FolderRow type="button" onClick={onClick}>
      <IconWrapper>
        {hasChildren && (
          <Icon2
            icon={isExpanded ? 'minusWithCircle' : 'plusWithCircle'}
            size={12}
            color={isExpanded ? 'accent100' : 'grey70'}
          />
        )}
      </IconWrapper>
      <FolderIconWrapper>
        <Icon2 icon={isExpanded ? 'activeFolder' : 'folder'} height={14} size={16} />
      </FolderIconWrapper>
    </FolderRow>
  )
}

const FolderRow = styled(DefaultButton)`
  display: flex;
  gap: 2px;
  height: fit-content;
`

const IconWrapper = styled.div`
  min-width: 12px;
`

const FolderIconWrapper = styled.div`
  padding: 0px 5px;
`
