import React from 'react'
import styled, { css } from 'styled-components'
import { ConnectedField } from 'effector-forms'

import { EstimateNumberCell, EstimateStringCell, EstimateTableRow } from '@/dal'
import { numberWithSpaces } from '@/lib/number-represent'
import { getNoun } from '@/lib/text-noun'
import {
  CellDropdownInput,
  CellNumberInput,
  CellTextArea,
  DefaultButton,
  Icon2,
  Option,
  OverflowText,
  palette,
  TextM,
  TextMLight,
  TextSLight,
} from '@/ui'

import { Direction, ItemToFocus, NonFormField, OnEnterSubmitItem } from '../../../model'
import { TABLE_BORDER_STYLE } from '../../../model/const'

const isString = (val: unknown): val is string => typeof val === 'string'

type Props<T> = {
  itemId: EstimateTableRow['id']
  isEmpty?: boolean
  isEditMode?: boolean
  isGray?: boolean
  isActive?: boolean
  isNumber?: boolean
  decimalScale?: number
  field?: ConnectedField<T> | NonFormField<T>
  options?: Option[]
  placeholder?: string
  hasRightDivider?: boolean
  fileCount?: number
  itemToFocus?: ItemToFocus | null
  hasFileAttach?: boolean
  onOpenAttachments?: () => void
  onEnter?: (item: Omit<OnEnterSubmitItem, 'isNewLines'>) => void
  onFocus?: () => void
} & (
  | {
      isNumber?: false
      cellInfo?: EstimateStringCell | null
    }
  | {
      isNumber: true
      cellInfo?: EstimateNumberCell | null
    }
)

export const TableCell = <T,>({
  itemId,
  isActive = false,
  isEditMode = false,
  isEmpty,
  isGray,
  isNumber,
  cellInfo,
  fileCount,
  hasFileAttach,
  options,
  field,
  decimalScale = 2,
  placeholder,
  hasRightDivider = false,
  itemToFocus,
  onOpenAttachments,
  onEnter,
  onFocus,
}: Props<T>) => {
  if (isEmpty || !cellInfo) {
    return <Wrapper $isGray={isGray} $hasRightDivider={hasRightDivider} />
  }

  const align = isNumber ? 'right' : options ? 'center' : 'left'

  const isEdit = isEditMode && cellInfo.editable
  const hasToFocus = itemToFocus?.id === itemId && itemToFocus?.fieldName === field?.name

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!field) return
    const { key, shiftKey } = e
    let direction: Direction | null = null
    if (key === 'ArrowUp') {
      direction = 'up'
    }
    if (key === 'ArrowDown') {
      direction = 'down'
    }
    if (key === 'Enter') {
      direction = shiftKey ? 'left' : 'right'
    }
    if (!direction) return
    onEnter?.({ id: itemId, fieldName: field.name, direction })
  }

  if (!isNumber) {
    const { value } = cellInfo

    const getValue = () => {
      return <OverflowText maxLines={4}>{value}</OverflowText>
    }

    const getField = () => {
      if (!field) return getValue()
      if (options) {
        return (
          <CellDropdownInput
            dataTestId={`${field.name}-input`}
            options={options}
            selectedId={field.value}
            isInvalid={!field.isValid}
            sameComponentWidth={false}
            listWidth="150px"
            padding="12px 12px 0"
            onChange={field.onChange}
          />
        )
      }
      if (isString(field.value)) {
        return (
          <CellTextArea
            dataTestId={`${field.name}-input`}
            value={field.value}
            autoGrow
            isInvalid={!field.isValid}
            placeholder={placeholder}
            maxHeight={650}
            maxLines={4}
            onChange={(val) => field.onChange(val as T)}
          />
        )
      }
      return null
    }
    return (
      <Wrapper
        $isGray={isGray}
        $isActive={isActive}
        $align={align}
        $isEditMode={isEdit}
        $hasRightDivider={hasRightDivider}
        data-testid={field ? `${field.name}-${itemId}` : undefined}
      >
        <TextWrapper>
          {isEdit ? getField() : getValue()}

          {hasFileAttach && fileCount !== undefined && (
            <FileRow $hasPadding={isEditMode} onClick={onOpenAttachments}>
              <p>{`${fileCount} ${getNoun(fileCount, 'файл', 'файла', 'файлов')}`}</p>
              <Icon2 icon="attach" size={16} />
            </FileRow>
          )}
        </TextWrapper>
      </Wrapper>
    )
  }
  const { value_adjusted, value_approved, value_current } = cellInfo
  const hasChanged = value_current !== value_adjusted
  const currentValue = value_adjusted || value_current
  const oldValue = value_adjusted ? value_current : value_approved
  const defaultValue = `0.${Array(decimalScale + 1).join('0')}`

  return (
    <Wrapper
      $isGray={isGray}
      $isActive={isActive}
      $align={align}
      $isEditMode={isEdit}
      $hasRightDivider={hasRightDivider}
      data-testid={field ? `${field.name}-${itemId}` : undefined}
    >
      {isEdit && isString(field?.value) ? (
        <CellNumberInput
          dataTestId={`${field.name}-input`}
          inputValue={field.value || defaultValue}
          displayValue={field.value || defaultValue}
          isInvalid={!field.isValid}
          decimalScale={decimalScale}
          fixedDecimalScale
          textAlign={align}
          allowLeadingZeros={false}
          placeholder={placeholder}
          padding="12px"
          popupZIndex={2}
          hasToFocus={hasToFocus}
          submitKeys={['Enter', 'ArrowDown', 'ArrowUp']}
          onChange={(val) => field.onChange(val as T)}
          onKeyDown={handleKeyDown}
          onFocus={onFocus}
        />
      ) : (
        <div>
          {value_approved && (
            <ApprovedValue>{numberWithSpaces(value_approved, { decimalScale })}</ApprovedValue>
          )}
          {currentValue && (
            <CurrentValue>{numberWithSpaces(currentValue, { decimalScale })}</CurrentValue>
          )}
          {oldValue && (
            <OldValue $hasChanged={hasChanged}>
              {numberWithSpaces(oldValue, { decimalScale })}
            </OldValue>
          )}
        </div>
      )}
    </Wrapper>
  )
}

type WrapperProps = {
  $isActive?: boolean
  $align?: 'center' | 'right' | 'left'
  $isGray?: boolean
  $isEditMode?: boolean
  $hasRightDivider: boolean
}

const Wrapper = styled.div.attrs({ className: 'cell' })<WrapperProps>`
  ${TextMLight}
  position: relative;
  width: 100%;
  padding: 12px;
  color: ${({ $isActive }) => ($isActive ? palette.grey90 : palette.grey70)};
  text-align: ${({ $align }) => $align || 'inherit'};

  display: flex;
  flex-direction: column;
  gap: 10px;

  border-right: ${TABLE_BORDER_STYLE};
  border-width: ${({ $hasRightDivider }) => ($hasRightDivider ? '4px' : '1px')};
  transition: 0.15s linear;
  transition-property: background-color, border-color;
  border-bottom: 1px solid transparent;
  background-color: ${({ $isGray }) => ($isGray ? palette.grey10 : 'var(--row-bg-color)')};

  &:last-child:not(:first-child) {
    border-right: none;
  }

  ${({ $isEditMode }) =>
    $isEditMode &&
    css`
      padding: 0;
      background-color: ${palette.white};
      border-bottom-color: ${palette.accent100};
    `}
`

const CurrentValue = styled.p`
  ${TextMLight}
`

const OldValue = styled.p<{ $hasChanged: boolean }>`
  ${TextSLight}

  ${({ $hasChanged }) =>
    $hasChanged &&
    css`
      color: ${palette.grey70};
      text-decoration: line-through;
    `}
`

const ApprovedValue = styled.div`
  ${TextM}
  width: max-content;
  margin-inline: auto 0;
  color: ${palette.accent100};
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const FileRow = styled(DefaultButton)<{ $hasPadding: boolean }>`
  ${TextMLight}
  display: flex;
  justify-content: space-between;

  width: 100%;
  text-align: left;
  color: ${palette.accent100};

  ${({ $hasPadding }) =>
    $hasPadding &&
    css`
      padding: 0 12px 12px;
    `}
`
