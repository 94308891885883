import styled from 'styled-components'

import { palette, TextM, TextS } from '@/ui'

export const SelectAppealFooter = () => {
  return (
    <Wrapper>
      <Col>
        <PhoneLabel>Телефон технической поддержки</PhoneLabel>
        <Phone>+7 (495) 660-15-55, доб.: 4444, нажмите 3</Phone>
      </Col>

      <RightText>Отвечаем в рабочие дни с 9:00 до 18:00</RightText>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  gap: 8px;
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const PhoneLabel = styled.p`
  ${TextS}
  color: ${palette.grey60};
`

const Phone = styled.p`
  ${TextS}
  color: ${palette.grey70};
`

const RightText = styled.p`
  ${TextM}
  color: ${palette.grey60};
`
