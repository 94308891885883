import React from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import { useParams } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { useGate, useUnit } from 'effector-react'

import { PaymentId } from '@/dal'
import { useBlockNestedInputs } from '@/lib/hooks/useBlockNestedInputs'
import {
  ContentContainer,
  DocumentBlockingOverlay,
  FlexScrollbar,
  LayoutGrid,
  Loader,
  LoaderOverlay,
  RejectReasonNotification,
} from '@/ui'
import {
  CreateBankAccountModal,
  CreateContragentModal,
  DocumentInfo,
  DocumentStatusAndAttacments,
  PaymentAvansTable,
  PaymentHeader,
} from '@/features/payment/single-payment'
import { CancelPaymentApproveModal } from '@/features/payment/single-payment/cancel-approve-modal/view'
import {
  $activeTabId,
  $isPaymentLoading,
  $paymentExclusiveBlock,
  $paymentRejectReason,
  PaymentGate,
} from '@/features/payment/single-payment/model'
import { $isChangePaymentTypePending } from '@/features/payment/single-payment/model/edit-public'
import { PaymentTab } from '@/features/payment/single-payment/model/types'

export const PaymentPage = () => {
  const { id } = useParams<{ id: string }>()

  const ref = React.useRef<Scrollbars>(null)
  const tabsRef = React.useRef<HTMLDivElement>(null)

  const rejectionReason = useUnit($paymentRejectReason)
  const isPending = useUnit($isPaymentLoading)
  const isPaymentTypeChanging = useUnit($isChangePaymentTypePending)
  const activeTab = useUnit($activeTabId)
  const exclusiveBlock = useUnit($paymentExclusiveBlock)

  useBlockNestedInputs(tabsRef, Boolean(exclusiveBlock?.is_blocked))
  useGate(PaymentGate, { id: (parseInt(id, 10) as PaymentId) || null })

  if (isPending) return <Loader />

  return (
    <>
      <FlexScrollbar ref={ref}>
        <ContentContainer isAutoHeight>
          <Wrapper>
            <PaymentHeader />

            <RejectReasonNotification rejectReason={rejectionReason} />

            <Tabs ref={tabsRef}>
              <TabWrapper isActive={activeTab === PaymentTab.Info}>
                <LayoutGrid>
                  <LeftBlock>
                    <DocumentStatusAndAttacments />
                  </LeftBlock>
                  <RightBlock>
                    <DocumentInfo />
                  </RightBlock>
                </LayoutGrid>
              </TabWrapper>

              <TabWrapper isActive={activeTab === PaymentTab.Table}>
                <PaymentAvansTable />
              </TabWrapper>
              {exclusiveBlock && (
                <DocumentBlockingOverlay
                  description={exclusiveBlock.description}
                  isShown={exclusiveBlock.is_blocked}
                />
              )}
            </Tabs>

            <LoaderOverlay isShown={isPaymentTypeChanging} />
          </Wrapper>
        </ContentContainer>
      </FlexScrollbar>

      <CancelPaymentApproveModal />
      <CreateContragentModal />
      <CreateBankAccountModal />
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
`

const Tabs = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const TabWrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ${({ isActive }) =>
    !isActive &&
    css`
      display: none;
    `}
`

const LeftBlock = styled.div`
  grid-column: span 5;
`

const RightBlock = styled.div`
  grid-column: span 7;
`
