import React from 'react'
import styled from 'styled-components'
import { useStoreMap, useUnit } from 'effector-react'

import { LotRowType } from '@/dal'
import { numberWithSpaces } from '@/lib/number-represent'
import { CellTextArea, getColor, PaletteColor, SimpleLoader, TABLE_BORDER_STYLE } from '@/ui'
import { CellWrapper, SplitCellRow, SplitCellWrapper } from '@/features/estimate-lot/shared'

import { LotTableItemWithDepth } from '../../model'
import { MATERIAL_TYPE_NAME_MAP } from '../../model/const'
// import { $editingRowsMap, RowFields } from '../../model/editing.private'
import {
  $editingItemId,
  $endNodeCount,
  $isMultipleEditMode,
  $pendingFolderIds,
} from '../../model/private'
import { EditableCell, FirstCellWrapper, GridRow, MaterialSpecieLabel } from '../parts'
import { FirstCell } from './FirstCell'
import { MorphologyCells } from './MorphologyCells'

type Props = {
  item: LotTableItemWithDepth
  isScrolled: boolean
}

export const TableItem = React.memo(({ item, isScrolled }: Props) => {
  const {
    id,
    spend_coefficient,
    unit,
    count_total,
    price,
    cost,
    comment,
    volumes,
    node_type: rowType,
    material_type: materialType,
    material_names: materialNames,
    is_grand_tender: isGrandTender,
  } = item

  const isFolder = rowType === LotRowType.Group || rowType === LotRowType.Work

  const [endNodeCount, editingItemId, isMultipleEditMode] = useUnit([
    $endNodeCount,
    $editingItemId,
    $isMultipleEditMode,
  ])

  // const fields = useStoreMap({
  //   store: $editingRowsMap,
  //   keys: [id],
  //   fn: (map, [id]) => (map[id] ?? null) as any,
  // })

  const areChildrenLoading = useStoreMap({
    store: $pendingFolderIds,
    keys: [id, isFolder],
    fn: (arr, [id, isFolder]) => isFolder && arr.includes(id),
  })

  const isEditMode = Boolean(editingItemId) || isMultipleEditMode
  const isEditingItem = editingItemId === id || isMultipleEditMode

  const handleToggleComment = (val: boolean) => {}

  const isMaterial = Boolean(rowType) && rowType !== LotRowType.Work
  const bgColor = isEditingItem ? 'yellow10' : 'white'

  return (
    <>
      <Wrapper as="td" $endNodeCount={endNodeCount} $bgColor={bgColor}>
        <FirstCellWrapper $hasShadow={isScrolled}>
          <FirstCell item={item} isEditMode={isEditMode} />
        </FirstCellWrapper>

        <CellWrapper>{unit?.name}</CellWrapper>

        <CellWrapper align="right">
          {numberWithSpaces(spend_coefficient ?? '', { decimalScale: 4 })}
        </CellWrapper>

        <CellWrapper align="right" isBold>
          {numberWithSpaces(count_total, { decimalScale: 4 })}
        </CellWrapper>

        <CellWrapper>{materialType && MATERIAL_TYPE_NAME_MAP[materialType]}</CellWrapper>

        <CellWrapper>
          <MaterialSpecieLabel
            materialNames={materialNames}
            isGrandTender={isGrandTender}
            isEditMode={isEditingItem}
          />
        </CellWrapper>

        <MorphologyCells
          cellsTree={volumes}
          endNodeCount={endNodeCount}
          rowId={id}
          canEdit={isMaterial && isEditingItem}
        />

        <SplitCellWrapper>
          <SplitCellRow>
            <CellWrapper isBold>{numberWithSpaces(price.total)}</CellWrapper>
          </SplitCellRow>

          <SplitCellRow>
            {price.material.can_edit && isEditingItem ? (
              <EditableCell value={price.material.value} canEdit isBold onSubmit={() => {}} />
            ) : (
              <CellWrapper isBold>{numberWithSpaces(price.material.value)}</CellWrapper>
            )}

            {price.work.can_edit && isEditingItem ? (
              <EditableCell value={price.work.value ?? ''} canEdit isBold onSubmit={() => {}} />
            ) : (
              <CellWrapper isBold={Boolean(price.work.value)}>
                {price.work.value ? numberWithSpaces(price.work.value) : '–'}
              </CellWrapper>
            )}
          </SplitCellRow>
        </SplitCellWrapper>

        <SplitCellWrapper>
          <SplitCellRow>
            <CellWrapper isBold>{numberWithSpaces(cost.total)}</CellWrapper>
          </SplitCellRow>

          <SplitCellRow>
            <CellWrapper isBold>{numberWithSpaces(cost.material)}</CellWrapper>
            <CellWrapper isBold={Boolean(cost.work)}>
              {cost.work ? numberWithSpaces(cost.work) : '–'}
            </CellWrapper>
          </SplitCellRow>
        </SplitCellWrapper>

        {comment.can_edit && isEditingItem ? (
          <CellWrapper bgColor="white" padding={0}>
            <CellTextArea
              placeholder="Комментарий"
              value=""
              maxHeight={350}
              maxLines={2}
              padding="8px"
              dataTestId=""
              autoGrow
              onChange={() => {}}
            />
          </CellWrapper>
        ) : (
          <CellWrapper isBold>{comment.value}</CellWrapper>
        )}
      </Wrapper>

      {areChildrenLoading && (
        <LoadingRow $bgColor={bgColor}>
          <LoaderWrapper>
            <SimpleLoader sizePx={40} />
          </LoaderWrapper>
        </LoadingRow>
      )}
    </>
  )
})

const Wrapper = styled(GridRow)<{ $bgColor: PaletteColor }>`
  --row-bg-color: ${({ $bgColor }) => getColor($bgColor)};

  background-color: var(--row-bg-color);
  transition: 0.15s linear background-color;
`

const LoadingRow = styled.td<{ $bgColor: PaletteColor }>`
  display: flex;
  padding: 20px 0;
  border-bottom: ${TABLE_BORDER_STYLE};
  background-color: ${({ $bgColor }) => getColor($bgColor)};
  transition: 0.15s linear background-color;
`

const LoaderWrapper = styled.div`
  position: sticky;
  left: 120px;
`
