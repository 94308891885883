import styled from 'styled-components'

import { palette, TextL, TextM, WINDOW_WIDTH } from '@/ui'

export const CardDescription = styled.p`
  ${TextL}
  color: ${palette.grey70};

  @media (max-width: ${WINDOW_WIDTH.desktop}px) {
    ${TextM}
    color: ${palette.grey70};
  }
`
