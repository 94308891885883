import { OptionWithParentId } from '@/features/estimate-correction/shared-model'

import { MorphologyFilterObject } from '../types.filters'

export const mergeMorphologyOptions = (options: OptionWithParentId[]) => {
  const mergedOptions: { id: string; label: string }[] = []

  options.forEach((item) => {
    const existingItem = mergedOptions.find(({ label }) => label === item.label)
    if (!existingItem) {
      mergedOptions.push({ id: item.label, label: item.label })
    }
  })

  return mergedOptions
}

export const recalculateMorphologyFilter = (
  { allOptions, selectedNames }: Pick<MorphologyFilterObject, 'allOptions' | 'selectedNames'>,
  selectedParentIds?: number[],
) => {
  const filteredOptions = selectedParentIds
    ? allOptions.filter(({ parent_id }) => selectedParentIds.includes(parent_id))
    : allOptions

  const updatedSelectedIds = filteredOptions.reduce((acc, option) => {
    if (selectedNames.includes(option.label)) {
      acc.push(option.id)
    }
    return acc
  }, [] as number[])

  const mergedOptions = mergeMorphologyOptions(filteredOptions)

  const updatedSelectedNames = mergedOptions.reduce((acc, option) => {
    if (selectedNames.includes(option.label)) {
      acc.push(option.label)
    }
    return acc
  }, [] as string[])

  return {
    allOptions,
    mergedOptions,
    selectedIds: updatedSelectedIds,
    selectedNames: updatedSelectedNames,
  }
}

export const initFiltersObject = (noParents = false) =>
  recalculateMorphologyFilter(
    {
      allOptions: [],
      selectedNames: [],
    },
    noParents ? undefined : [],
  )
