import { EstimateTableRow } from '@/dal'

import { d } from './domain'

export const openCreateItemModal = d.event<{
  item: EstimateTableRow
  isFolder: boolean
  isCopying?: boolean
  parentFolder: EstimateTableRow['parent'] | null
}>()
