import styled from 'styled-components'

import { HeadM, TextL, WINDOW_WIDTH } from '@/ui'

export const CardTitle = styled.p`
  ${HeadM}

  @media (max-width: ${WINDOW_WIDTH.desktop}px) {
    ${TextL}
  }
`
