import { TableHeaderCell, TableHeaderCellProps } from '@/ui'

type Props = Omit<TableHeaderCellProps, 'label'> &
  (
    | {
        label: string
        isEmpty?: false
      }
    | {
        isEmpty: true
        label?: never
      }
  )

export const FilterCell = ({ label, isEmpty, ...props }: Props) => {
  return <TableHeaderCell label={label ?? ''} padding="8px 12px" gap={2} {...props} />
}
