import { Domain } from 'effector'

import { root } from '@/root-domain'

export type SetFilterParams<T> = {
  [K in keyof T]: {
    key: K
    val: T[K]
  }
}[keyof T]

export type RemoveFilterParams<T> = {
  [K in keyof T]: {
    key: K
    val?: T[K]
  }
}[keyof T]

export const createFilters = <Fields extends { [key: string]: unknown }>(
  initialState: Fields,
  domain?: Domain,
) => {
  const d = domain || root.domain()
  const $filters = d.store<Fields>(initialState)

  const clearFilter = d.event<void>()

  const setFilter = d.event<SetFilterParams<Fields>>()
  const removeFilter = d.event<RemoveFilterParams<Fields>>()
  const setFilters = d.event<Fields>()

  const $hasAcceptedFilters = $filters.map((filters) =>
    Object.values(filters).some((item: unknown) => (Array.isArray(item) ? item.length : item)),
  )

  $filters
    .on(setFilter, (filters, { key, val }) => ({ ...filters, [key]: val }))
    .on(setFilters, (_, filters) => ({ ...initialState, ...filters }))
    .on(removeFilter, (filters, { key, val }) => {
      if (val && Array.isArray(filters[key])) {
        return {
          ...filters,
          [key]: filters[key].filter((el: unknown) => el !== val),
        }
      }

      return {
        ...filters,
        [key]: undefined,
      }
    })
    .reset(clearFilter)

  return {
    setFilter,
    $filters,
    removeFilter,
    clearFilter,
    setFilters,
    $hasAcceptedFilters,
  }
}
