import styled, { css } from 'styled-components'

type Props = {
  hiddenFirstGroup: boolean
  hiddenSecondGroup: boolean
  hiddenThirdGroup: boolean
  $morphologyDepth: number
}

// TODO перепроверить актуальную ширину столбцов по дизайну
export const TableGridWrapper = styled.div<Props>`
  display: grid;
  ${({ hiddenFirstGroup, hiddenSecondGroup, hiddenThirdGroup, $morphologyDepth }) => css`
    grid-template-columns:
      minmax(330px, 330fr)
      ${hiddenFirstGroup ? '' : 'repeat(2, minmax(150px, 150fr))'}
      ${$morphologyDepth === 3
        ? 'repeat(3, 92px)'
        : $morphologyDepth
        ? `repeat(${$morphologyDepth}, 138px)`
        : ''}
      minmax(90px, 90fr) repeat(${hiddenSecondGroup ? 4 : 7}, minmax(145px, 145fr))
      repeat(${hiddenThirdGroup ? 2 : 4}, minmax(150px, 150fr));
  `}
`
