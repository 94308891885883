import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { mockCellsTreeWithoutPrice } from '@/dal/estimate-lot/mock'
import { numberWithSpaces } from '@/lib/number-represent'
import { palette, TableHeaderCell, TextMLight } from '@/ui'
import { SplitCellRow, SplitCellWrapper } from '@/features/estimate-lot/shared/cellWrappers'

import { $endNodeCount, $hasTableItems, $tableCosts } from '../../model/private'
import { FirstCellWrapper, GridRow } from '../parts'
import { MorphologyCells } from './MorphologyCells'

type Props = {
  isScrolled: boolean
}

export const TableHeader = React.memo(({ isScrolled }: Props) => {
  const [hasTableItems, endNodeCount, costs] = useUnit([$hasTableItems, $endNodeCount, $tableCosts])

  if (!hasTableItems) return null

  return (
    <Wrapper>
      <GridRow as="td" $endNodeCount={endNodeCount} $hasBorder={false}>
        <FirstCellWrapper $hasShadow={isScrolled}>
          <TableHeaderCell label="Наименование работ и материалов" />
        </FirstCellWrapper>

        <TableHeaderCell label="Единицы измерения" />
        <TableHeaderCell label="Коэф-т расхода материала" />
        <TableHeaderCell label="Кол-во итого" />
        <TableHeaderCell label="Тип материала" />
        <TableHeaderCell label="Вид материала" />

        <MorphologyCells
          cellsTree={mockCellsTreeWithoutPrice}
          endNodeCount={endNodeCount}
          areFilterCells
        />

        <SplitCellWrapper>
          <SplitCellRow>
            <TableHeaderCell label="Общая цена" />
          </SplitCellRow>
          <SplitCellRow>
            <TableHeaderCell label="Цена материалов" />
            <TableHeaderCell label="Цена работ" />
          </SplitCellRow>
        </SplitCellWrapper>

        <SplitCellWrapper>
          <SplitCellRow>
            <TableHeaderCell label="Общая стоимость">
              <Price>{numberWithSpaces(costs?.total)}</Price>
            </TableHeaderCell>
          </SplitCellRow>
          <SplitCellRow>
            <TableHeaderCell label="Стоим. материалов">
              <Price>{numberWithSpaces(costs?.material)}</Price>
            </TableHeaderCell>
            <TableHeaderCell label="Стоим. работ">
              <Price>{numberWithSpaces(costs?.work)}</Price>
            </TableHeaderCell>
          </SplitCellRow>
        </SplitCellWrapper>

        <TableHeaderCell label="Комментарий" />
      </GridRow>
    </Wrapper>
  )
})

const Wrapper = styled.tr`
  --table-border-color: ${palette.grey40};
`

const Price = styled.p`
  ${TextMLight}
  color: ${palette.accent100};
`
