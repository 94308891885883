import React from 'react'
import styled from 'styled-components'
import { useForm } from 'effector-forms'
import { useUnit } from 'effector-react'

import { noop } from '@/lib/noop'
import {
  DropAreaWithList,
  NewDateInput,
  NewDropDownField,
  NewTextButton,
  NotificationItem,
  NotificationType,
  NumberInputField,
} from '@/ui'
import { $documentInfo } from '@/features/estimate-lot/model'
import { lotInfoForm } from '@/features/estimate-lot/model/forms'
import { $isActionPending } from '@/features/estimate-lot/model/header.private'
import {
  $advanceTypes,
  $typesOfSecurity,
  getEstimateLotAdvanceTypesFx,
  getTypesOfSecurityFx,
} from '@/features/estimate-lot/model/private'

import { Row } from '../parts'

const roundPercentInput = (value: string) => (parseFloat(value) > 100 ? '100' : value)

export const CommercialInfoForm = React.memo(() => {
  const [
    info,
    advanceTypes,
    typesOfSecurity,
    isPending,
    areAdvanceTypesLoading,
    areTypesOfSecurityLoading,
  ] = useUnit([
    $documentInfo,
    $advanceTypes,
    $typesOfSecurity,
    $isActionPending,
    getEstimateLotAdvanceTypesFx.pending,
    getTypesOfSecurityFx.pending,
  ])

  const { fields } = useForm(lotInfoForm)

  if (!info) return null

  return (
    <>
      <Row>
        <NewDropDownField
          label="Вид аванса"
          placeholder="Вид аванса"
          options={advanceTypes}
          value={fields.advance_type.value}
          size="L"
          isDisabled={isPending}
          isLoading={areAdvanceTypesLoading}
          dataTestId="lot-advance-type-select"
          hasClear={false}
          required
          onChange={fields.advance_type.onChange}
        />

        <NumberInputField
          label="Размер аванса в %"
          value={fields.percent_advance.value}
          decimalScale={2}
          error={fields.percent_advance.firstError?.errorText}
          size="L"
          disabled={isPending}
          suffix="%"
          dataTestId="lot-advance-size-input"
          onChange={(val) => fields.percent_advance.onChange(roundPercentInput(val))}
          onClear={fields.percent_advance.reset}
        />

        <NumberInputField
          label="Размер гарантийного удержания в %"
          value={fields.percent_warranty_retention.value}
          decimalScale={2}
          error={fields.percent_warranty_retention.firstError?.errorText}
          size="L"
          disabled={isPending}
          suffix="%"
          dataTestId="lot-guarantee-retention-size-input"
          onChange={(val) => fields.percent_warranty_retention.onChange(roundPercentInput(val))}
          onClear={fields.percent_warranty_retention.reset}
        />
      </Row>

      <Row>
        <NewDropDownField
          label="Вид обеспечения"
          placeholder="Вид обеспечения"
          options={typesOfSecurity}
          value={fields.type_security.value}
          size="L"
          isDisabled={isPending}
          isLoading={areTypesOfSecurityLoading}
          dataTestId="lot-type-of-security-select"
          hasClear={false}
          required
          onChange={fields.type_security.onChange}
        />
        <NewDateInput
          label="Сроки строительства"
          placeholder="Начало строительства"
          inputValue={info.start_work ?? ''}
          size="L"
          disabled
          dataTestId="lot-start-date-input"
          onChange={noop}
        />
        <ConstructionEndInputWrapper>
          <NewDateInput
            placeholder="Окончание строительства"
            inputValue={info.end_work ?? ''}
            size="L"
            disabled
            dataTestId="lot-end-date-input"
            onChange={noop}
          />
        </ConstructionEndInputWrapper>
      </Row>

      {!fields.is_typical_form_of_contract.value && (
        <>
          <NotificationItem
            body="Приложите свою форму договора или протокол разногласий"
            type={NotificationType.Info}
          />
          {/* TODO нет бэка, пока без отображения ранее загруженных файлов */}
          <DropAreaWithList
            value={fields.files.value}
            uploadedFiles={[]}
            listPosition="top"
            colCount={1}
            dataTestId="lot-files-input"
            onChange={fields.files.onChange}
          />
        </>
      )}
      <NewTextButton
        url="mock"
        label="Скачать типовую форму"
        textStyle="LL"
        dataTestId="lot-download-typical-contract-form-btn"
        isDownload
        isInline
      />
    </>
  )
})

const ConstructionEndInputWrapper = styled.div`
  margin-top: auto;
`
