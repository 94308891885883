import React from 'react'
import NumberFormat from 'react-number-format'
import styled, { css } from 'styled-components'

import { IconName2 } from '../../icons'
import { palette, PaletteColor } from '../../palette'
import { TextMLight } from '../../typography'
import { MaskInputProps } from '../types'
import { CellInputWrapper, CellInputWrapperProps } from './CellInputWrapper'

type Props = MaskInputProps & {
  onChange: (val: string) => void
  onFocus?: () => void
  onBlur?: () => void
  onSubmit?: () => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onIconClick?: () => void
  displayValue: string
  iconTooltip?: string
  icon?: IconName2
  dataTestId?: string
  textAlign?: 'right' | 'center' | 'left'
  inputValue: string
  placeholder?: string
  color?: PaletteColor
  padding?: string
  submitKeys?: string[]
  popupZIndex?: number
  isDisabled?: boolean
  isInvalid?: boolean
  hasAbsoluteIcon?: boolean
  hasToFocus?: boolean
  isCentered?: boolean
} & Pick<CellInputWrapperProps, 'verticalAlign'>

export const CellNumberInput = ({
  onChange,
  onBlur,
  onFocus,
  onIconClick,
  onSubmit,
  onKeyDown,
  displayValue,
  iconTooltip,
  isDisabled,
  icon,
  textAlign = 'left',
  dataTestId,
  inputValue,
  isInvalid = false,
  placeholder,
  color,
  padding,
  hasAbsoluteIcon,
  submitKeys = ['Enter'],
  hasToFocus,
  isCentered,
  popupZIndex,
  verticalAlign,
  suffix,
  decimalScale,
  allowNegative = false,
  ...maskInputProps
}: Props) => {
  const ref = React.useRef<HTMLInputElement>(null)

  const [isOpen, setIsOpen] = React.useState(false)

  const open = () => setIsOpen(true)

  const focus = () => {
    ref.current?.focus()
    ref.current?.select()
  }

  const handleBlur = () => {
    onBlur?.()
    setIsOpen(false)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    onKeyDown?.(e)
    if (submitKeys.includes(e.key)) {
      e.preventDefault()
      ref.current?.blur()
      onSubmit?.()
    }
    if (e.key === 'Escape') {
      ref.current?.blur()
    }
  }

  React.useEffect(() => {
    // сначала setIsOpen(true), затем focus() в useEffect
    if (hasToFocus) {
      open()
    }
  }, [hasToFocus])

  React.useEffect(() => {
    if (isOpen) {
      focus()
    }
  }, [isOpen])

  return (
    <CellInputWrapper
      suffix={suffix}
      value={isOpen ? '' : displayValue}
      textAlign={textAlign}
      isInvalid={isInvalid}
      icon={icon}
      color={color}
      padding={padding}
      isCentered={isCentered}
      hasAbsoluteIcon={hasAbsoluteIcon}
      iconTooltip={iconTooltip}
      isDisable={isDisabled}
      decimalScale={decimalScale}
      popupZIndex={popupZIndex}
      verticalAlign={verticalAlign}
      isOpen={isOpen}
      onClick={open}
      onIconClick={onIconClick}
    >
      <Input
        getInputRef={ref}
        thousandSeparator=" "
        allowedDecimalSeparators={[',', '.']}
        suffix={suffix}
        decimalScale={decimalScale}
        value={inputValue}
        placeholder={placeholder}
        $textAlign={textAlign}
        $isInvalid={isInvalid}
        data-testid={dataTestId}
        disabled={isDisabled}
        allowNegative={allowNegative}
        {...maskInputProps}
        onValueChange={(e) => onChange(e.value)}
        onKeyDown={handleKeyDown}
        onFocus={onFocus}
        onBlur={handleBlur}
      />
    </CellInputWrapper>
  )
}

type InputProps = {
  $textAlign: 'right' | 'center' | 'left'
  $isInvalid: boolean
}

const Input = styled(NumberFormat)<InputProps>`
  ${TextMLight}
  background-color: transparent;
  height: fit-content;
  border: none;
  outline: none;
  transition: color 0.15s linear;
  width: 100%;

  ${({ $textAlign }) =>
    $textAlign &&
    css`
      text-align: ${$textAlign};
    `}

  ${({ $isInvalid }) =>
    $isInvalid &&
    css`
      color: ${palette.red100};
      &::placeholder {
        color: ${palette.red100};
      }
    `}
`
