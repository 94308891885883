import React from 'react'

import { Field } from './Field'
import { NewDropDownInput, SelectProps } from './NewDropDownInput'

type Props = SelectProps & {
  label: string
  required?: boolean
  isLabelGrey?: boolean
}

export const NewDropDownField = ({ label, required, isLabelGrey, ...selectProps }: Props) => (
  <Field label={label} isLabelGrey={isLabelGrey} required={required}>
    <NewDropDownInput {...selectProps} />
  </Field>
)
