import { css } from 'styled-components'

import { TextMLight } from '../typography'
import { palette } from '../palette'

export const InputStyle = css`
  border: none;
  ${TextMLight}
  padding: 0;
  width: 100%;
  height: 16px;
  outline: none;
  background-color: inherit;
  flex-grow: 1;

  &:not(:disabled):not(:read-only)::placeholder {
    color: ${palette.grey60};
  }

  &:read-only:not(:disabled) {
    cursor: default;
  }

  &:disabled {
    color: ${palette.grey70};
    cursor: not-allowed;
  }
`
