import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'

import { numberWithSpaces } from '@/lib/number-represent'
import { OverflowText, palette, PaletteColor, TextMLight } from '@/ui'

import { LIGHT_GREY_COLOR, TABLE_BORDER_STYLE } from './const'

export type CellProps = {
  hasLightGreyBackground?: boolean
  align?: 'left' | 'center' | 'right'
  isGrey?: boolean
  valueType?: 'string' | 'number'
  decimalScale?: number
  bgColor?: PaletteColor
  padding?: string | number
  hasRightDivider?: boolean
  isBold?: boolean
  borderBottomColor?: PaletteColor
  color?: PaletteColor
  verticalAlign?: 'top' | 'center' | 'bottom'
} & (
  | {
      children?: React.ReactNode
      value?: never
    }
  | {
      value: string | null
      children?: never
    }
)

export const Cell = ({
  children,
  value,
  hasLightGreyBackground = false,
  align = 'center',
  isGrey = false,
  valueType = 'number',
  decimalScale = 2,
  bgColor = 'white',
  padding = 12,
  hasRightDivider = false,
  isBold = false,
  borderBottomColor,
  color = 'grey70',
  verticalAlign = 'center',
}: CellProps) => {
  const content = useMemo(() => {
    if (valueType === 'string') {
      return value ?? ''
    }
    return !value ? '0' : numberWithSpaces(value, { decimalScale })
  }, [value, valueType])

  return (
    <CellWrapper
      align={align}
      bgColor={bgColor}
      hasLightGreyBackground={hasLightGreyBackground}
      padding={padding}
      hasRightDivider={hasRightDivider}
      isBold={isBold}
      borderBottomColor={borderBottomColor}
      color={color}
      verticalAlign={verticalAlign}
    >
      {value !== undefined ? (
        <Value isGrey={isGrey}>
          <OverflowText maxLines={4} maxTooltipWidth={500}>
            {content}
          </OverflowText>
        </Value>
      ) : (
        children
      )}
    </CellWrapper>
  )
}

type WrapperProps = Pick<
  CellProps,
  | 'align'
  | 'hasLightGreyBackground'
  | 'bgColor'
  | 'padding'
  | 'hasRightDivider'
  | 'isBold'
  | 'borderBottomColor'
  | 'color'
  | 'verticalAlign'
>

const verticalAlignments = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end',
}

export const CellWrapper = styled.div<WrapperProps>`
  ${TextMLight}
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  color: ${palette.grey70};
  background-color: ${({ bgColor }) => (bgColor ? palette[bgColor] : 'var(--row-bg-color)')};
  transition: 0.15s linear background-color;

  &:not(:last-child) {
    border-right: ${TABLE_BORDER_STYLE};
  }

  ${({ padding = 12, color = 'grey90' }) => css`
    padding: ${typeof padding === 'string' ? padding : `${padding}px`};
    color: ${palette[color]};
  `}

  ${({ align = 'center' }) => {
    switch (align) {
      case 'center':
        return css`
          align-items: center;
          text-align: center;
        `
      case 'left':
        return css`
          align-items: flex-start;
          text-align: left;
        `
      case 'right':
        return css`
          align-items: flex-end;
          text-align: right;
        `
    }
  }}

  ${({ verticalAlign }) =>
    verticalAlign &&
    css`
      justify-content: ${verticalAlignments[verticalAlign]};
    `}

  ${({ isBold }) =>
    isBold &&
    css`
      font-weight: 400;
    `}

  ${({ hasLightGreyBackground }) =>
    hasLightGreyBackground &&
    css`
      background-color: ${LIGHT_GREY_COLOR};
    `}

  ${({ hasRightDivider }) =>
    hasRightDivider &&
    css`
      border-right-width: 4px !important;
    `}

  ${({ borderBottomColor }) =>
    borderBottomColor &&
    css`
      border-bottom-color: ${palette[borderBottomColor]};
    `}
`

const Value = styled.div<{ isGrey: boolean }>`
  ${TextMLight}

  ${({ isGrey }) =>
    isGrey &&
    css`
      color: ${palette.grey70};
    `}
`
