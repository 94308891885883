import styled, { css } from 'styled-components'

import { TABLE_BORDER_STYLE } from '@/features/estimate-lot/shared'

import { END_NODE_CELL_WIDTH } from './const'

type Props = {
  $endNodeCount: number
  $hasBorder?: boolean
}

export const GridRow = styled.div<Props>`
  display: grid;
  padding: 0;

  ${({ $hasBorder = true }) =>
    $hasBorder &&
    css`
      border-bottom: ${TABLE_BORDER_STYLE};
    `}

  ${({ $endNodeCount }) => css`
    grid-template-columns:
      minmax(287px, 1fr) repeat(2, 101px) 110px 93px 100px
      repeat(${$endNodeCount}, ${END_NODE_CELL_WIDTH}) repeat(2, 325px) 120px;
  `}
`
