import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { getDayJsObject } from '@/lib/date'
import { palette, TextM, TextMLight } from '@/ui'
import { $documentInfo } from '@/features/estimate-lot/model'
import { $advanceTypes, $typesOfSecurity } from '@/features/estimate-lot/model/private'

import { Row } from '../parts'

const formatDate = (date: string | null) => (date ? getDayJsObject(date).format('DD/MM/YYYY') : '')

export const CommercialInfoData = React.memo(() => {
  const [info, advanceTypes, typesOfSecurity] = useUnit([
    $documentInfo,
    $advanceTypes,
    $typesOfSecurity,
  ])

  if (!info) return null

  const advanceType = advanceTypes.find(({ id }) => id === info.advance_type_id)?.label ?? ''
  const typeOfSecurity =
    typesOfSecurity.find(({ id }) => id === info.type_of_security_id)?.label ?? ''

  return (
    <>
      <Row>
        <Col>
          <ColLabel>Вид аванса</ColLabel>
          <p>{advanceType}</p>
        </Col>

        <Col>
          <ColLabel>Размер аванса в %</ColLabel>
          <p>{info.percent_advance}%</p>
        </Col>

        <Col>
          <ColLabel>Размер гарантийного удержания в %</ColLabel>
          <p>{info.percent_warranty_retention}%</p>
        </Col>
      </Row>

      <Row>
        <Col>
          <ColLabel>Вид обеспечения</ColLabel>
          <p>{typeOfSecurity}</p>
        </Col>

        <Col>
          <ColLabel>Сроки строительства</ColLabel>
          <p>
            <GreyText>с</GreyText> {formatDate(info.start_work)} <GreyText>по</GreyText>{' '}
            {formatDate(info.end_work)}
          </p>
        </Col>
      </Row>
    </>
  )
})

const Col = styled.div`
  ${TextMLight}
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const ColLabel = styled.p`
  ${TextM}
`

const GreyText = styled.span`
  color: ${palette.grey70};
`
