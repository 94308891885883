import React from 'react'
import styled, { css } from 'styled-components'
import { useUnit } from 'effector-react'

import { useOverflowedContent } from '@/lib/hooks/useOverflowedContent'
import { DefaultButton, Icon2, palette, TextL, TextMLight } from '@/ui'

import { selectGroup } from '../../model'
import { $breadcrumbs } from '../../model/private'
import { BreadcrumbsItem } from '../parts'

export const TreeBreadcrumbs = React.memo(() => {
  const items = useUnit($breadcrumbs)
  const [isExpand, setIsExpand] = React.useState(false)

  const { contentRef, isOverflowed } = useOverflowedContent()

  const clickHandle = (id: number | string) => {
    selectGroup(id)
    setIsExpand(false)
  }

  if (!items) return null
  return (
    <Wrapper>
      <List ref={contentRef} isExpand={isExpand}>
        {items?.map((item, i) => {
          const isLast = i + 1 === items.length
          return (
            <React.Fragment key={item.id}>
              <BreadcrumbsItem
                id={item.id}
                name={item.name}
                onClick={!isLast ? clickHandle : undefined}
              />
              {!isLast ? <Slash>/</Slash> : null}
            </React.Fragment>
          )
        })}
      </List>
      {(isOverflowed || isExpand) && (
        <ExpandButton onClick={() => setIsExpand(!isExpand)}>
          {isExpand ? 'Свернуть' : 'Развернуть'}
          <Icon2 icon={isExpand ? 'arrowUp' : 'arrowDown'} size={16} />
        </ExpandButton>
      )}
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  margin-left: 44px;
`

const List = styled.span<{ isExpand: boolean }>`
  flex-grow: 1;
  display: inline-block;
  text-overflow: ellipsis;
  ${TextMLight}
  color: ${palette.grey70};
  gap: 6px 0px;

  ${({ isExpand }) =>
    isExpand
      ? css``
      : css`
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        `}
`

const ExpandButton = styled(DefaultButton)`
  display: flex;
  padding-left: 5px;
  gap: 4px;
  ${TextL}
  color: ${palette.grey70};
  transition: color 0.15s linear;
  height: fit-content;

  @media (hover: hover) {
    &:hover {
      color: ${palette.grey90};
    }
  }
`

const Slash = styled.span`
  margin: 0 6px 6px;
  display: inline-block;
`
