import { CheckboxState, MorphologyNode } from '@/dal'

import { parseNumberInName } from '../../model'
import { BuildingSelectionGroup, MorphologyTrees, SectionGroupOption } from './types'

const calculateParentCheckboxState = <T extends { isSelected: boolean }>(items: T[]) => {
  let hasChecked = false
  let hasUnchecked = false
  for (const item of items) {
    if (item.isSelected) {
      hasChecked = true
    } else {
      hasUnchecked = true
    }
  }
  return hasChecked && hasUnchecked
    ? CheckboxState.Indeterminate
    : hasChecked && !hasUnchecked
    ? CheckboxState.Checked
    : CheckboxState.Unchecked
}

export const recalculateSectionState = (catalog: MorphologyTrees, sectionId: number) => {
  const section = catalog.section[sectionId]
  if (!section.children.length) {
    return section
  }
  const state = calculateParentCheckboxState(section.children.map((id) => catalog.floor[id]))
  return {
    ...section,
    isSelected: state === CheckboxState.Checked,
    isIndeterminate: state === CheckboxState.Indeterminate,
  }
}

export const mapMorphologyListToTrees = (items: MorphologyNode[]) => {
  const catalog: MorphologyTrees = {
    object: {},
    building: {},
    section: {},
    floor: {},
  }
  for (const { is_selected, ...item } of items) {
    catalog[item.type][item.id] = {
      ...item,
      isSelected: is_selected,
      isIndeterminate: false,
    }
  }
  // сейчас выбор ограничивается только секциями/этажами, поэтому особо нет обобщения логики
  for (const sectionId in catalog.section) {
    catalog.section[sectionId] = recalculateSectionState(catalog, Number(sectionId))
  }
  return catalog
}

export const mapMorphologyTreesToBuildingItems = (
  catalog: MorphologyTrees,
): BuildingSelectionGroup[] => {
  const result = []

  for (const building of Object.values(catalog.building)) {
    const objectName = catalog.object[building.parent_id!].name
    const sections = building.children.map((sectionId) => {
      const section = catalog.section[sectionId]
      const floors = section.children.map((floorId) => {
        const { name, isSelected } = catalog.floor[floorId]
        return {
          id: floorId,
          isSelected,
          label: parseNumberInName(name),
        }
      })
      return {
        ...section,
        floors,
        label: section.name,
      }
    })
    result.push({
      objectName,
      sections,
      id: building.id,
      name: building.name,
    })
  }
  return result
}

// вычисление лейбла типа «Секция 1: 1,2-4,5-7»
export const getSectionTagName = (item: SectionGroupOption) => {
  const { floors, label: sectionName } = item
  const selectedFloors = floors
    .filter((floor) => floor.isSelected)
    .map((floor) => Number(floor.label))
    .sort((a, b) => a - b)

  if (!selectedFloors.length || floors.length === selectedFloors.length) {
    return sectionName
  }

  const result = []
  let start = selectedFloors[0],
    end = start

  for (let i = 1; i < selectedFloors.length; i++) {
    if (selectedFloors[i] === end + 1) {
      end = selectedFloors[i]
    } else {
      result.push(start === end ? `${start}` : `${start}-${end}`)
      start = end = selectedFloors[i]
    }
  }
  result.push(start === end ? `${start}` : `${start}-${end}`)

  return `${sectionName}: ${result.join(',')}`
}
