import { HashRouter, Route, Switch } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { RouterPaths, SubSystemId } from './features/app/model'
import { TopBar } from './features/header'
import { ModalProvider } from './ModalProvider'
import { ActPage } from './pages/acts'
import { AdministrativePage, ContractPage } from './pages/administrative'
import { CompensationPage } from './pages/compensation'
import { DocumentFlowPage } from './pages/document-flow'
import { EditProfilePage } from './pages/edit-profile'
import { EstimateCorrectionPage } from './pages/estimate-correction'
import { EstimateLotListPage, EstimateLotPage } from './pages/estimate-lots'
import { ForceAuth } from './pages/force-auth'
import { PlanEditPage } from './pages/funding-schedule'
import { SupportPage } from './pages/help'
import { LoginPage } from './pages/login'
import { MainPage } from './pages/main'
import { MaintenancePage } from './pages/maintenance/view'
import { MyCompanyPage } from './pages/my-company/view'
import { PaymentPage } from './pages/payment'
import { RequestForAccessPage } from './pages/request-for-access'
import { RestoreAccessPage } from './pages/restore-access'
import { RouteWrapper } from './routes/RouteWrapper'
import { RouteItem } from './routes/types'
import { PageLayout } from './ui'

export const privateRoutes: RouteItem[] = [
  {
    path: RouterPaths.Home,
    Component: MainPage,
    subsystemId: SubSystemId.Home,
    isExact: true,
  },
  {
    path: `${RouterPaths.ActPage}/:id`,
    Component: ActPage,
    subsystemId: SubSystemId.ActDoc,
    isExact: true,
  },
  {
    path: `${RouterPaths.Payment}/:id`,
    Component: PaymentPage,
    subsystemId: SubSystemId.PaymentDoc,
    isExact: false,
  },
  {
    path: RouterPaths.DocumentFlow,
    Component: DocumentFlowPage,
    subsystemId: SubSystemId.DocumentFlow,
    isExact: true,
  },
  {
    path: `${RouterPaths.PlanPage}/:id`,
    Component: PlanEditPage,
    subsystemId: SubSystemId.PlanDoc,
    isExact: true,
  },
  {
    path: RouterPaths.Administrative,
    Component: AdministrativePage,
    subsystemId: SubSystemId.Administrative,
    isExact: true,
  },
  {
    path: `${RouterPaths.Administrative}/:id`,
    Component: ContractPage,
    subsystemId: SubSystemId.Administrative,
    isExact: true,
  },
  {
    path: `${RouterPaths.AdditionalAgreement}/:id`,
    Component: ContractPage,
    subsystemId: SubSystemId.Administrative,
    isExact: true,
  },
  {
    path: RouterPaths.Support,
    Component: SupportPage,
    subsystemId: SubSystemId.Help,
    isExact: true,
  },
  {
    path: RouterPaths.MyCompany,
    Component: MyCompanyPage,
    subsystemId: SubSystemId.MyCompany,
    isExact: false,
  },
  {
    path: `${RouterPaths.EstimateCorrection}/:id`,
    Component: EstimateCorrectionPage,
    subsystemId: SubSystemId.EstimateCorrectionDoc,
    isExact: false,
  },
  {
    path: `${RouterPaths.EstimateLots}`,
    Component: EstimateLotListPage,
    subsystemId: SubSystemId.CreateEstimate,
    isExact: true,
  },
  {
    path: `${RouterPaths.EstimateLots}/:id`,
    Component: EstimateLotPage,
    subsystemId: SubSystemId.CreateEstimate,
    isExact: true,
  },
  {
    path: `${RouterPaths.Compensation}/:id`,
    Component: CompensationPage,
    subsystemId: SubSystemId.Compensation,
    isExact: false,
  },
  {
    path: RouterPaths.UserInfo,
    Component: EditProfilePage,
    subsystemId: SubSystemId.EditUserInfo,
    isExact: true,
  },
]

const publicRoutes: RouteItem[] = [
  {
    path: RouterPaths.Login,
    Component: LoginPage,
    isExact: true,
  },
  {
    path: RouterPaths.RestoreAccess,
    Component: RestoreAccessPage,
    isExact: false,
  },
  {
    path: `${RouterPaths.ForceAuth}/:token`,
    Component: ForceAuth,
    isForceAuth: true,
    isExact: true,
  },
  {
    path: RouterPaths.RequestForAccess,
    Component: RequestForAccessPage,
    subsystemId: SubSystemId.EstimateCorrectionDoc,
    isExact: true,
  },
]

const SentryRoute = Sentry.withSentryRouting(Route)

export const Routes = () => (
  <HashRouter>
    <Switch>
      {/* Страница не зависит от статуса авторизации */}
      <SentryRoute key={RouterPaths.Maintenance} path={RouterPaths.Maintenance}>
        <MaintenancePage />
      </SentryRoute>
      {privateRoutes.map(({ Component, path, subsystemId, isExact }) => (
        <SentryRoute key={path} path={path} exact={isExact}>
          <RouteWrapper isPrivate subsystemId={subsystemId}>
            <PageLayout header={<TopBar />}>
              <Component />
            </PageLayout>
          </RouteWrapper>
        </SentryRoute>
      ))}

      {publicRoutes.map(({ Component, path, isForceAuth, isExact }) => (
        <SentryRoute key={path} path={path} exact={isExact}>
          <RouteWrapper isPrivate={false} isForceAuth={isForceAuth}>
            <Component />
          </RouteWrapper>
        </SentryRoute>
      ))}
    </Switch>
    <ModalProvider />
  </HashRouter>
)
