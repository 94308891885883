import { attach } from 'effector'

import {
  EstimateLot,
  getEstimateLotAdvanceTypesReqFx,
  getEstimateLotDirectionsReqFx,
  getEstimateLotMorphologyReqFx,
  getEstimateLotReqFx,
  getEstimateLotTypesOfSecurityReqFx,
  getProjectsReqFx,
  LotTableItem,
  postEstimateLotAttachmentsReqFx,
  updateEstimateLotReqFx,
} from '@/dal'
import { Option } from '@/ui'
import { createModal } from '@/features/factories'
import { createAttachmentsModalModel } from '@/features/factories/attach-modal'

import { d } from './domain'
import { $documentFeatures, $documentInfo } from './public'

export const openAdditionalModal = d.event()
export const openAttachmentsModal = d.event()

export const onLotInfoChanged = d.event()

export const updateDocumentInfo = d.event<EstimateLot>()

export const $projects = d.store<Option[]>([])
export const $directions = d.store<Option[]>([])
export const $advanceTypes = d.store<Option[]>([])
export const $typesOfSecurity = d.store<Option[]>([])

export const addWorksModal = createModal()
export const openAddWorksModal = addWorksModal.open

export const addMaterialsModal = createModal<LotTableItem>()
export const $openedWorkId = addMaterialsModal.$meta.map((data) => data?.id ?? null)

export const getEstimateLotFx = attach({
  effect: getEstimateLotReqFx,
})

export const getDirectionsFx = attach({
  effect: getEstimateLotDirectionsReqFx,
})

export const getTypesOfSecurityFx = attach({
  effect: getEstimateLotTypesOfSecurityReqFx,
})

export const getProjectsFx = attach({
  effect: getProjectsReqFx,
})

export const getEstimateLotAdvanceTypesFx = attach({
  effect: getEstimateLotAdvanceTypesReqFx,
})

export const getMorphologyFx = attach({
  effect: getEstimateLotMorphologyReqFx,
})

export const updateEstimateLotFx = attach({
  effect: updateEstimateLotReqFx,
})

export const sendFilesFx = attach({
  effect: postEstimateLotAttachmentsReqFx,
})

export const $canSendFiles = $documentFeatures.map((features) =>
  Boolean(features?.can_add_attachments),
)

export const attachmentsModel = createAttachmentsModalModel({
  domain: d,
  sendFx: sendFilesFx,
  $canSendFiles,
  $files: $documentInfo.map((info) => info?.attachments || null),
})
