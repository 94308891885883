import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { ModalLayoutV2 } from '@/ui'

import { CreateForm, ModalTitle } from '../containers'
import {
  $isItemCreationPending,
  $isModalOpen,
  closeModal,
} from '../../../model/create-table-item.private'

export const CreateTableItem = React.memo(() => {
  const isOpen = useUnit($isModalOpen)
  const isPending = useUnit($isItemCreationPending)

  if (!isOpen) return null

  return (
    <ModalLayoutV2 canClose={!isPending} closeModal={closeModal}>
      <Wrapper>
        <ModalTitle />
        <CreateForm />
      </Wrapper>
    </ModalLayoutV2>
  )
})

const Wrapper = styled.div`
  padding: 32px 0 20px;
  position: relative;
  width: 550px;
`
