import React from 'react'
import styled from 'styled-components'

import { Icon2, palette, PaletteColor, TextM } from '@/ui'

type Props = {
  children: React.ReactNode
  name: string
  bgColor: PaletteColor
  isChecked: boolean
}

export const ObjectChildWrapper = ({ children, name, bgColor, isChecked }: Props) => {
  return (
    <Wrapper $bgColor={bgColor}>
      <Header>
        <Title>{name}</Title>
        {isChecked && <Icon2 icon="checkboxMark" color="grey60" size={18} />}
      </Header>
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ $bgColor: PaletteColor }>`
  display: flex;
  flex-direction: column;
  gap: 12px;

  padding: 12px;
  border-radius: 12px;
  background-color: ${({ $bgColor }) => palette[$bgColor]};
`

const Header = styled.header`
  display: flex;
  gap: 6px;
`

const Title = styled.header`
  ${TextM}
  flex-grow: 1;
`
