import { DefaultErrorResponseData } from '../error-handler'
import {
  CompanyItem,
  Contract,
  DefaultUpdateFileParams,
  DocumentAlias,
  DocumentExclusiveBlock,
  DocumentFeatures,
  DocumentProgress,
  DocumentStatuses,
  FileDTO,
  PaymentId,
  RejectionReason,
} from '../interfaces'

export type PaymentDetailResponseItem = {
  status: number
  summ: number
}

export enum PaymentErrorCode {
  NotFound = 'CONTRAGENT_NOT_FOUND',
  NeedChangeTable = 'NEED_CHANGE_ERROR',
}

export type CheckInnErrorPayload = {
  code: PaymentErrorCode.NotFound
  detail: string
  contragent_info: {
    inn: string
    name: string
    kpp: string
    ogrn: string
  }
}

export type NeedChangeTableErrorPayload = {
  code: PaymentErrorCode.NeedChangeTable
  detail: string
}

export type PutAvansItemErrorData = {
  detail: string
  item: AvansTableItem
}

export type PaymentErrorResponse =
  | NeedChangeTableErrorPayload
  | CheckInnErrorPayload
  | PutAvansItemErrorData
  | DefaultErrorResponseData

export enum PaymentSubType {
  /** Оплата третьим лицам */
  THIRD = 'TYPE_THIRD_PARTIES',
  /** На основе акта / Постоплата */
  ACT = 'TYPE_ACT',
  /** Оплата СМР */
  TYPE_SMR = 'TYPE_SMR',
}

export type BillDetails = {
  number: string | null
  payment_date: string | null // YYYY-MM-DD
  avans_date: string // YYYY-MM-DD
  avans_sum: string
}

export type PaymentDocItem = {
  id: PaymentId
  number: string
  date: string // YYYY-MM-DD
  status: DocumentStatuses
  sum: string
  additional_request?: boolean
  payment_type: PaymentSubType
}

export type PutAvansItemParams = {
  value: string
  id: AvansTableItem['id']
}

export type GetPaymentAvansTableParams = {
  id: PaymentId
  showOnlyFilled: boolean
  availableAvans: boolean
}

export type AvansTableItem = {
  id: number
  /** Статья оборотов */
  turnover_article: {
    name: string
    project: string
  }
  sum_contract: string // 48344559.38
  sum_avans: string // 29006735.63
  avans_paid: string // 13190997.17
  avans_offset: string // 0.00
  sum_avans_request: string // 4074165.00
  avans_remain: string // 13190997.17
  sum_advance_on_agreed: string // float
  advance_on_agreed: string // 123.12
}

export type Summ = {
  available_avans: string
  avans_offset: string
  avans_paid: string
  avans_remain: string
  sum_avans: string
  sum_avans_request: string
  sum_contract: string
  summa: string
  sum_advance_on_agreed: string // float
}

export type GetAvansTableResponse = {
  items: AvansTableItem[]
  sums: Summ
}

export type ThirdPersonInfo = {
  contract_id: string
  contract: string
  contragent: string
  contragent_id: string
  bankAccount: string
  bank_account_id: string
  outgoing_mail_number: string
  outgoing_mail_date: string // YYYY-MM-DD
}

export type NewContragentInfo = {
  name: string
  inn: string
  kpp: string
  ogrn: string
  bic: string
  account: string
}

export type GuaranteeContract = {
  id: number
  period: {
    date_start: string // YYYY-MM-DD
    date_end: string // YYYY-MM-DD
  }
  sum_of_security: string // float
  name: string
  is_period_expired: boolean
}

export type PaymentInfo = {
  id: PaymentId
  progress: DocumentProgress | null
  features: {
    are_attachments_visible: boolean
    show_bank_warranty_splash: boolean
    document_exclusive_block: DocumentExclusiveBlock
  } & DocumentFeatures
  type: DocumentAlias.PAYMENT
  contract: {
    id: Contract['id']
    name: string
    project: {
      id: number
      name: string
    }
  }
  company_id: CompanyItem['id']
  attachments: FileDTO[] | null
  comment: string
  name: string // Платеж ***
  last_update: string // ISODate
  pay_type: PaymentSubType
  requisites: {
    payer: {
      account_number: string
      name: string
      bank_name: string
    }
    payee: {
      name: string | null
      account_number: string | null
      bank_name: string | null
    }
  }
  orders: {
    date: string // YYYY-MM-DD,
    number: string
    sum: string
    url: string
  }[]
  inn: string | null
  date: string // YYYY-MM-DD
  summa: string
  additional_request: boolean
  status: DocumentStatuses
  reason_rejection: string | null
  // Заполнено по остатку или нет
  is_fill: boolean
  printable_form_link: string

  guarantee_contracts: {
    total_sum_of_security: string // float
    items: GuaranteeContract[]
  } | null

  // PaymentSubType.THIRD PaymentSubType.TYPE_SMR
  bill_details: BillDetails | null
  plan_payment_date: string | null // YYYY-MM-DD

  // PaymentSubType.THIRD
  third_person_info: ThirdPersonInfo | null
  contragent_request: NewContragentInfo | null

  // PaymentSubType.ACT
  basic_document: {
    id: number
    name: string
  } | null
  postpayment: string | null
  reject_comment: RejectionReason
  cancel_comment: RejectionReason
}

export type PutPaymentInfoParams =
  | {
      id: PaymentId
      number: string
      payment_date: string // YYYY-MM-DD
      avans_date: string // YYYY-MM-DD
      comment?: string | null
      isThirdPerson: boolean
    }
  | {
      id: PaymentId
      number: string
      payment_date: string // YYYY-MM-DD
      avans_date: string // YYYY-MM-DD
      isThirdPerson: boolean
      comment: string

      inn: string
      contragent: ContragentDTO['uuid']
      current_account: ContragentDTO['bank_accounts'][0]['uuid']
      outgoing_mail_number: string
      outgoing_mail_date: string // YYYY - MM - DD
    }

export type CancelPaymentApproveParams = {
  id: PaymentInfo['id']
  message: string
}

export type BankAccountDTO = {
  uuid: string
  number: string
  bik: string
  bank_name: string
}

export type ContragentContractDTO = {
  uuid: string
  name: string
}

export type ContragentDTO = {
  uuid: string
  short_name: string
  full_name: string
  inn: string
  kpp: string
  ogrn: string
  bank_accounts: BankAccountDTO[]
}

export type CreateContragentOrBankAccountParams = {
  inn: string
  bic: string
  account: string
  payment: PaymentId
  contragent_uuid?: string
}

export type CreateContragentResponse = NewContragentInfo

export type PostPaymentFilesParams = {
  id: PaymentId
} & DefaultUpdateFileParams
