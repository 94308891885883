import React from 'react'
import styled, { css } from 'styled-components'

import { SimpleLoader } from '../../Loader'

type Position = 'absolute' | 'fixed' | 'sticky'

type Props = {
  size?: number
  isShown: boolean
  position?: Position
  inset?: number
  height?: string
}

export const LoaderOverlay = ({
  isShown, size = 64, position = 'absolute', inset = 0, height,
}: Props) => {
  if (!isShown) return null

  return (
    <Overlay $position={position} $inset={inset} $height={height}>
      <SimpleLoader sizePx={size} />
    </Overlay>
  )
}

type OverlayProps = {
  $position: Position
  $inset: number
  $height?: string
}

const Overlay = styled.div<OverlayProps>`
  position: ${({ $position }) => $position};
  inset: ${({ $inset }) => $inset}px;
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
  background: rgba(255, 255, 255, 0.3);

  ${({$height}) => $height && css`
    height: ${$height};
  `}
`
