import { attach, combine } from 'effector'

import {
  changeEstimateLotWorkCommentReqFx,
  changeMorphologyElementPriceReqFx,
  ChangeWorkVolumesPayload,
  getEstimateLotFolderNestedWorksReqFx,
  getEstimateLotTableItemContentReqFx,
  getEstimateLotTableReqFx,
  getUnitsReqFx,
  LotTableFilters,
  LotTableItem,
  LotTableResponse,
  UpdateLotWorkCommentPayload,
} from '@/dal'
import { createUpdateTreeItemsFx } from '@/lib/tree'
import { getFlatTreeWithDepth } from '@/lib/tree/getFlatTree'
import { createSelectedIds } from '@/features/factories'
import { createFilters } from '@/features/shared/filterFactory/createFilters'

import { d } from './domain'
import { EstimateLotTableGate } from './public'
import { Tree } from './types'

export const getTableData = d.event()
export const resetFilters = d.event()

export const $tableRoot = d.store<LotTableItem[] | null>(null)
export const setRootUpdate = d.event<LotTableItem[] | null>()

export const $tableTree = d.store<Tree>({})
export const setTreeUpdate = d.event<Tree>()

export const $isMultipleEditMode = d.store(false)
export const setIsMultipleEditMode = d.event<boolean>()

export const $editingItemId = d.store<LotTableItem['id'] | null>(null)
export const setEditingItem = d.event<LotTableItem['id']>()
export const changeLorWorkComment = d.event<UpdateLotWorkCommentPayload>()

export const exitEditMode = d.event()

export const $isEditMode = combine(
  [$editingItemId, $isMultipleEditMode],
  ([itemId, isMultipleMode]) => Boolean(itemId || isMultipleMode),
)

export const {
  $ids: $expandedGroupIds,
  selectId: expandGroup,
  deselectId: collapseGroup,
} = createSelectedIds<LotTableItem['id']>({
  resetUnits: [EstimateLotTableGate.close],
})

export const $endNodeCount = d.store(11 + 6 * 4)
// export const $tableHeaderMorphology = d.store()
export const $tableCosts = d.store<LotTableResponse['costs'] | null>(null)

export const $pendingFolderIds = d.store<LotTableItem['id'][]>([])

export const $totalWorkCount = d.store(0)

export const $workToDelete = d.store<LotTableItem | null>(null)
export const setWorkOrFolderToDelete = d.event<LotTableItem>()
export const resetWorkToDelete = d.event()

export const $nestedWorks = d.store<string[]>([])

export const resetTableData = d.event()

export const $updatingWorkIds = d.store<string[]>([])

export const changeElementPrice = d.event<ChangeWorkVolumesPayload>()

export const $openedTableItem = d.store<LotTableItem | null>(null)
export const openTableItemFiles = d.event<LotTableItem>()

const $expandedTableTree = combine([$tableTree, $expandedGroupIds], ([tree, expandedIds]) => {
  const resultTree: Tree = {}
  for (const id of expandedIds) {
    resultTree[id] = tree[id]
  }
  return resultTree
})

export const $tableItems = combine(
  {
    root: $tableRoot,
    tree: $expandedTableTree,
    isTree: true,
  },
  getFlatTreeWithDepth,
)

export const $hasTableItems = $tableItems.map((items) => Boolean(items?.length))

export const { $filters, setFilter, clearFilter, $hasAcceptedFilters } =
  createFilters<LotTableFilters>({}, d)

export const getLotTableFx = attach({
  effect: getEstimateLotTableReqFx,
})

export const getLotTableRowsFx = attach({
  effect: getEstimateLotTableItemContentReqFx,
})

export const getUnitsFx = attach({
  effect: getUnitsReqFx,
})

export const getFolderNestedWorksFx = attach({
  effect: getEstimateLotFolderNestedWorksReqFx,
})

export const changeElementPriceFx = attach({
  effect: changeMorphologyElementPriceReqFx,
})

export const changeLorWorkCommentFx = attach({
  effect: changeEstimateLotWorkCommentReqFx,
})

export const updateItemsFx = createUpdateTreeItemsFx<LotTableItem>()

export const $isTablePending = combine(
  getLotTableFx.pending,
  changeElementPriceFx.pending,
  (...args) => args.some(Boolean),
)
