import React from 'react'
import { TableVirtuoso, TableVirtuosoHandle } from 'react-virtuoso'
import { useUnit } from 'effector-react'

import { EstimateTableRow } from '@/dal'
import { useCheckScrolled } from '@/lib/hooks'
import { ScrollTopButton } from '@/ui'

import { $flatItems, $hasMore, onLoadMore, paginateFlatTableFx } from '../../../model/private'
import { TableHeader } from './TableHeader'
import { TableItemContainer } from './TableItemContainer'

type Props = {
  onScrollToTop: () => void
}

export const Table = React.memo(
  React.forwardRef(({ onScrollToTop }: Props, ref: React.ForwardedRef<TableVirtuosoHandle>) => {
    const [items, hasMore, isPaginationPending] = useUnit([
      $flatItems,
      $hasMore,
      paginateFlatTableFx.pending,
    ])

    const { isScrolled, containerRef } = useCheckScrolled()

    const MemoHeader = React.useCallback(() => {
      return <TableHeader isScrolled={isScrolled.horizontal} />
    }, [isScrolled])

    const MemoComponent = React.useCallback(
      (_: number, item: EstimateTableRow) => {
        return <TableItemContainer item={item} isScrolled={isScrolled.horizontal} />
      },
      [isScrolled],
    )

    const handleScrollerRef = React.useCallback((ref) => {
      containerRef.current = ref
    }, [])

    if (!items) return null

    return (
      <>
        <TableVirtuoso
          ref={ref}
          scrollerRef={handleScrollerRef}
          style={{ height: '100%' }}
          data={items}
          fixedHeaderContent={MemoHeader}
          itemContent={MemoComponent}
          endReached={() => hasMore && !isPaginationPending && onLoadMore()}
        />
        <ScrollTopButton
          scrollContainerRef={containerRef}
          bgColor="grey100"
          iconColor="grey60"
          hoverIconColor="white"
          threshold={150}
          hasToScrollRef={false}
          onClick={onScrollToTop}
        />
      </>
    )
  }),
)
