import React from 'react'

import { useTooltip } from '@/lib/hooks'

import { Icon2 } from '../../icons'
import { ToolTipV2 } from '../tooltip'

type Props = {
  tooltipText: string
}

export const InfoIcon = ({ tooltipText }: Props) => {
  const { referenceProps, tooltipProps } = useTooltip()

  return (
    <>
      <div style={{ height: 20 }} {...referenceProps}>
        <Icon2 icon="info" color="accent80" size={20} />
      </div>
      <ToolTipV2 {...tooltipProps} size="M">
        {tooltipText}
      </ToolTipV2>
    </>
  )
}
