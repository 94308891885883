import React from 'react'
import styled from 'styled-components'
import { useStoreMap, useUnit } from 'effector-react'

import { Work } from '@/dal'
import { numberWithSpaces } from '@/lib/number-represent'
import { CellNumberInput } from '@/ui'

import { $isBlockChanges } from '../../../model/private'
import {
  $disabledRowIds,
  onAvansInputChanged,
  onMaxValueError,
  putAvansItemFx,
} from '../../../model/table.private'

type Props = {
  sumAvansRequest: string
  id: Work['id']
  avansRemain: string
}

export const TableRowInput = React.memo(({ avansRemain, id, sumAvansRequest }: Props) => {
  const [inputVal, setInputVal] = React.useState<string>(sumAvansRequest)
  const isBlockChanges = useUnit($isBlockChanges)

  const isLoading = useStoreMap({
    store: $disabledRowIds,
    keys: [id],
    fn: (ids, [itemId]) => ids.includes(itemId),
  })

  React.useEffect(() => {
    setInputVal(sumAvansRequest)
  }, [sumAvansRequest])

  const sendValue = () => {
    if (inputVal === sumAvansRequest) return
    if (parseFloat(inputVal) > parseFloat(avansRemain)) {
      onMaxValueError()
      setInputVal(avansRemain)
    } else {
      onAvansInputChanged({
        id,
        value: inputVal,
      })
    }
  }

  const handleFill = () => {
    if (inputVal === avansRemain) return
    onAvansInputChanged({
      id,
      value: avansRemain,
    })
  }

  React.useEffect(() => {
    const unWatch = putAvansItemFx.fail.watch(({ params }) => {
      if (params.id === id) {
        setInputVal(sumAvansRequest)
      }
    })
    return () => {
      unWatch()
    }
  }, [])

  return (
    <Wrapper>
      <CellNumberInput
        displayValue={numberWithSpaces(inputVal)}
        inputValue={inputVal}
        decimalScale={2}
        dataTestId={`avans-input-${id}`}
        textAlign="right"
        icon="copy"
        isDisabled={isLoading || isBlockChanges}
        iconTooltip="Заполнить по остатку"
        isCentered
        onChange={setInputVal}
        onBlur={sendValue}
        onIconClick={handleFill}
      />
    </Wrapper>
  )
})

const Wrapper = styled.div`
  position: relative;
  padding: 12px;
`
