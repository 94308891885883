import React from 'react'
import styled, { css } from 'styled-components'
import { useForm } from 'effector-forms'
import { useStoreMap, useUnit } from 'effector-react'

import { CheckboxState, DocumentStatuses, EstimateTableRow } from '@/dal'
import { NewCheckbox, Option, palette, PaletteColor, SimpleLoader, TABLE_BOX_SHADOW } from '@/ui'
import {
  $buildings,
  $documentStatus,
  $floors,
  $morphologyDepth,
  $sections,
  $unitOptions,
} from '@/features/estimate-correction/shared-model'

import {
  $hiddenCellGroups,
  $isResetRowsPending,
  FIRST_GROUP_ID,
  SECOND_GROUP_ID,
  TABLE_BORDER_STYLE,
  THIRD_GROUP_ID,
} from '../../../model'
import {
  $invalidRowIds,
  $isMultipleEditModeEnabled,
  $itemToFocus,
  $rowsFormDataMap,
  changeSpecificField,
  onEnterSubmitItem,
  resetItemToFocus,
} from '../../../model/edit-multiple-items.private'
import { $editTargetId, editItemForm } from '../../../model/edit-table-item.private'
import {
  $pendingFolderIds,
  $tableTree,
  onCloseFolder,
  onOpenTableItemFiles,
  onOpenTableItemSplitRowFiles,
  openFolder,
} from '../../../model/private'
import {
  $isResetModeEnabled,
  $isResettingAll,
  $resettingRows,
  toggleResettingRow,
} from '../../../model/reset-multiple-items.private'
import { TableCell, TableGridWrapper, VolumeRow } from '../parts'
import { FirstTableCell } from './FirstTableCell'

const EMPTY_OPTION: Option = { id: null, label: '—' }

type Props = {
  item: EstimateTableRow
  isScrolled: boolean
}

export const TableItemContainer = React.memo(({ item, isScrolled }: Props) => {
  const { fields: formFields } = useForm(editItemForm)
  const [
    hiddenCols,
    editTargetId,
    unitOptions,
    buildings,
    sections,
    floors,
    status,
    itemToFocus,
    morphologyDepth,
    isResetMode,
    isMultipleEditMode,
    isResettingAll,
    isResetRowsPending,
  ] = useUnit([
    $hiddenCellGroups,
    $editTargetId,
    $unitOptions,
    $buildings,
    $sections,
    $floors,
    $documentStatus,
    $itemToFocus,
    $morphologyDepth,
    $isResetModeEnabled,
    $isMultipleEditModeEnabled,
    $isResettingAll,
    $isResetRowsPending,
  ])

  const { id, is_folder: isFolder } = item

  const isLoading = useStoreMap({
    store: $pendingFolderIds,
    keys: [id, isFolder],
    fn: (arr, [id, isFolder]) => isFolder && arr.includes(id),
  })

  const isExpanded = useStoreMap({
    store: $tableTree,
    keys: [id],
    fn: (map, [rowId]) => Boolean(map[rowId]),
  })

  const checkboxState = useStoreMap({
    store: $resettingRows,
    keys: [id],
    fn: (map, [id]) => map[id]?.state ?? CheckboxState.Unchecked,
  })

  const genericFields = useStoreMap({
    store: $rowsFormDataMap,
    keys: [id],
    fn: (map, [id]) => map[id] || {},
  })

  const isValidEditingRow = useStoreMap({
    store: $invalidRowIds,
    keys: [id],
    fn: (arr, [id]) => !arr.some((item) => item === id),
  })

  const isEditMode = id === editTargetId || isMultipleEditMode
  const isHoverStyleIgnored = isEditMode || isLoading || status !== DocumentStatuses.Draft

  const genericFieldsWithHandler = React.useMemo(
    () =>
      Object.fromEntries(
        Object.entries(genericFields).map(([name, item]) => [
          name,
          {
            name: item.name,
            value: item.value,
            isValid: item.isValid,
            onChange: (value: any) => changeSpecificField({ id, value, name }),
          },
        ]),
      ),
    [genericFields, id],
  )

  const handleOpenAttachments = () => {
    onOpenTableItemFiles({ id: item.id })
  }

  const handleOpenSplitRowAttachments = () => {
    if (!item.new_lines) return
    onOpenTableItemSplitRowFiles({ id: item.new_lines[0].id })
  }

  const hiddenFirstGroup = hiddenCols.includes(FIRST_GROUP_ID)
  const hiddenSecondGroup = hiddenCols.includes(SECOND_GROUP_ID)
  const hiddenThirdGroup = hiddenCols.includes(THIRD_GROUP_ID)

  const fields = isMultipleEditMode ? genericFieldsWithHandler : formFields

  const splitFields = {
    count_in_estimate: fields.new_count_in_estimate,
    material: fields.new_material,
    work: fields.new_work,
    justification_of_changes_current: fields.new_justification_of_changes_current,
  }

  const isChecked = checkboxState === CheckboxState.Checked || isResettingAll
  const isIndeterminate = checkboxState === CheckboxState.Indeterminate
  const isInvalid = !item.is_valid || !isValidEditingRow
  const canExpand = Boolean(item.children) && !isEditMode && !isLoading

  const onItemClick = () => {
    if (!canExpand) return
    if (isExpanded) {
      onCloseFolder(id)
    } else {
      openFolder(id)
    }
  }

  const rowColor: PaletteColor = (() => {
    if (isChecked || isIndeterminate) return 'accent10'
    if (isEditMode && isValidEditingRow) return 'yellow10'
    if (isInvalid) return 'red10'
    if (item.is_changed) return 'yellow10'
    return 'white'
  })()
  const actingGroupColor = (item.is_changed || isEditMode) && !isInvalid ? 'yellow30' : 'grey10'

  const newLine = item.new_lines?.[0]

  const isBuildingRequired = Boolean(
    fields.project.value && buildings.some(({ parent_id }) => parent_id === fields.project.value),
  )
  const buildingOptions = (isBuildingRequired ? [] : [EMPTY_OPTION]).concat(
    buildings.filter(({ parent_id }) => parent_id === fields.project.value),
  )
  const sectionOptions = [EMPTY_OPTION].concat(
    sections.filter(({ parent_id }) => parent_id === fields.building.value),
  )
  const floorOptions = [EMPTY_OPTION].concat(
    floors.filter(({ parent_id }) => parent_id === fields.section.value),
  )
  const hasSelectedBuilding = Boolean(fields.building.value)
  const hasSelectedSection = Boolean(fields.section.value)

  return (
    <RowWrapper
      $rowColor={rowColor}
      $actingGroupColor={actingGroupColor}
      $isHoverStyleIgnored={isHoverStyleIgnored}
    >
      <TableGridWrapper
        hiddenFirstGroup={hiddenFirstGroup}
        hiddenSecondGroup={hiddenSecondGroup}
        hiddenThirdGroup={hiddenThirdGroup}
        $morphologyDepth={morphologyDepth}
      >
        <FirstCellWrapper $hasShadow={isScrolled}>
          <FirstTableCell
            item={item}
            fields={fields}
            isExpanded={isExpanded}
            isEditMode={isEditMode}
            disableActionMenu={Boolean(editTargetId)}
            hasRightDivider={hiddenFirstGroup}
            canExpand={canExpand}
            onToggleExpand={onItemClick}
          />
          {isResetMode && (
            <CheckboxWrapper onClick={(e: React.MouseEvent) => e.stopPropagation()}>
              <NewCheckbox
                isChecked={isChecked}
                isDisabled={isResettingAll || isResetRowsPending}
                isIndeterminate={isIndeterminate}
                isBoolean
                onChange={() => toggleResettingRow({ id: item.id })}
              />
            </CheckboxWrapper>
          )}
        </FirstCellWrapper>
        {!hiddenFirstGroup && (
          <>
            <TableCell itemId={id} isEmpty={isFolder} cellInfo={item.note} isGray />
            <TableCell itemId={id} isEmpty={isFolder} cellInfo={item.cost_item} isGray />
          </>
        )}
        {/* TODO вынести места работы отдельно */}
        {morphologyDepth >= 1 && (
          <TableCell
            itemId={id}
            isEmpty={isFolder}
            cellInfo={item.building}
            field={fields.building}
            options={buildingOptions}
            isEditMode={isEditMode}
            isActive
          />
        )}
        {morphologyDepth >= 2 && (
          <TableCell
            itemId={id}
            isEmpty={
              isFolder || (isEditMode && (!hasSelectedBuilding || sectionOptions.length < 2))
            }
            cellInfo={item.section}
            field={fields.section}
            options={sectionOptions}
            isEditMode={isEditMode && hasSelectedBuilding}
            isActive
          />
        )}
        {morphologyDepth >= 3 && (
          <TableCell
            itemId={id}
            isEmpty={isFolder || (isEditMode && (!hasSelectedSection || floorOptions.length < 2))}
            cellInfo={item.floor}
            field={fields.floor}
            options={floorOptions}
            isEditMode={isEditMode && hasSelectedSection}
            isActive
          />
        )}
        <TableCell
          itemId={id}
          isEmpty={isFolder}
          cellInfo={item.unit}
          isActive
          field={fields.unit}
          options={unitOptions}
          isEditMode={isEditMode}
        />

        <VolumeWrapper $hiddenSecondGroup={hiddenSecondGroup} $hiddenThirdGroup={hiddenThirdGroup}>
          <VolumeRow
            hiddenSecondGroup={hiddenSecondGroup}
            hiddenThirdGroup={hiddenThirdGroup}
            fields={fields}
            isEditMode={isEditMode}
            isFolder={isFolder}
            rowId={id}
            item={item}
            itemToFocus={!itemToFocus?.isNewLines ? itemToFocus : null}
            onOpenAttachments={handleOpenAttachments}
            onEnter={(val) => onEnterSubmitItem({ ...val, isNewLines: false })}
            onFocus={resetItemToFocus}
          />
          {newLine && (
            <VolumeRow
              rowId={id}
              hiddenSecondGroup={hiddenSecondGroup}
              hiddenThirdGroup={hiddenThirdGroup}
              fields={splitFields}
              isEditMode={isEditMode}
              isFolder={isFolder}
              item={newLine}
              itemToFocus={itemToFocus?.isNewLines ? itemToFocus : null}
              onOpenAttachments={handleOpenSplitRowAttachments}
              onEnter={(val) => onEnterSubmitItem({ ...val, isNewLines: true })}
              onFocus={resetItemToFocus}
            />
          )}
        </VolumeWrapper>

        {isLoading && (
          <LoaderWrapper>
            <SimpleLoader sizePx={40} />
          </LoaderWrapper>
        )}
      </TableGridWrapper>
    </RowWrapper>
  )
})

const LoaderWrapper = styled.div`
  padding: 12px 0;
  display: flex;
  justify-content: center;
  grid-column-start: 1;
  grid-column-end: -1;
`

type FirstCellWrapperProps = {
  $hasShadow: boolean
}

const FirstCellWrapper = styled.div<FirstCellWrapperProps>`
  position: sticky;
  left: 0;
  z-index: 3;

  transition: 0.15s linear;
  transition-property: border-color, background-color, box-shadow;
  border-bottom: 1px solid transparent;
  background-color: var(--row-bg-color);
  clip-path: inset(0px -16px 0px 0px);

  ${({ $hasShadow }) =>
    $hasShadow &&
    css`
      box-shadow: ${TABLE_BOX_SHADOW};
    `}
`

const CheckboxWrapper = styled.div`
  position: absolute;
  left: 20px;
  bottom: 12px;
`

type RowWrapperProps = {
  $rowColor: PaletteColor
  $actingGroupColor: PaletteColor
  $isHoverStyleIgnored: boolean
}

const RowWrapper = styled.td<RowWrapperProps>`
  --row-bg-color: ${({ $rowColor }) => palette[$rowColor]};
  --acting-group-bg-color: ${({ $actingGroupColor }) => palette[$actingGroupColor]};

  border-bottom: ${TABLE_BORDER_STYLE};
  position: relative;
  width: 100%;
  padding: 0;
  background-color: var(--row-bg-color);
  transition: background-color 0.15s linear;

  ${({ $isHoverStyleIgnored }) =>
    !$isHoverStyleIgnored &&
    css`
      @media (pointer: fine) {
        &:hover {
          --row-bg-color: ${palette.grey20};
          --acting-group-bg-color: ${palette.grey30};
        }
      }
    `}
`

const STARTED_COLUMN_COUNT = 6
const COLUMN_IN_SECOND_GROUP_COUNT = 3
const COLUMN_IN_THIRD_COLUMN_COUNT = 2

type VolumeWrapperProps = {
  $hiddenSecondGroup: boolean
  $hiddenThirdGroup: boolean
}

const VolumeWrapper = styled.div<VolumeWrapperProps>`
  display: grid;
  grid-auto-rows: minmax(0, 1fr);

  ${({ $hiddenSecondGroup, $hiddenThirdGroup }) => css`
    grid-column: span
      ${STARTED_COLUMN_COUNT +
      ($hiddenSecondGroup ? 0 : COLUMN_IN_SECOND_GROUP_COUNT) +
      ($hiddenThirdGroup ? 0 : COLUMN_IN_THIRD_COLUMN_COUNT)};
  `};
`
