import React from 'react'
import styled from 'styled-components'
import { useForm } from 'effector-forms'
import { useUnit } from 'effector-react'

import { NewCheckbox, NewInput, palette, Switch } from '@/ui'
import { ExpandableTableButton } from '@/ui/v2/components/expandable-table/ExpandableTableButton'

import { $isBlockChanges, $isPaymentAct, $paymentInfo } from '../../../model/private'
import {
  $isFilled,
  fillTableFx,
  isTableExpanded,
  isTableOverflowed,
  onFillTable,
  tableSearch,
  workFilters,
} from '../../../model/table.private'

const $canFill = $paymentInfo.map((info) => Boolean(info?.features.can_fill_by_remaining))

export const TableFilters = React.memo(() => {
  const { fields } = useForm(workFilters)

  const search = useUnit(tableSearch.$value)
  const isFilled = useUnit($isFilled)
  const isFillPending = useUnit(fillTableFx.pending)
  const isPaymentAct = useUnit($isPaymentAct)
  const canFill = useUnit($canFill)
  const isBlockChanges = useUnit($isBlockChanges)
  const isExpanded = useUnit(isTableExpanded.$value)
  const isOverflowed = useUnit(isTableOverflowed.$value)

  return (
    <Wrapper>
      <InputWrapper>
        <NewInput
          placeholder="Поиск по статье оборотов"
          value={search}
          postfixIcon="search"
          dataTestId="payment-table-search-input"
          onChange={tableSearch.set}
          onClear={tableSearch.reset}
        />
      </InputWrapper>

      <RightRow>
        {!isPaymentAct && (
          <CheckboxFields>
            <NewCheckbox
              label="Доступный аванс"
              isChecked={fields.availableAvans.value}
              checkPosition="left"
              isBoolean
              onChange={fields.availableAvans.onChange}
            />

            <NewCheckbox
              label="Показать заполненные"
              onChange={fields.showOnlyFilled.onChange}
              checkPosition="left"
              isBoolean
              isChecked={fields.showOnlyFilled.value}
            />
          </CheckboxFields>
        )}

        {!isPaymentAct && canFill && (
          <Switch
            label="Заполнить по остатку"
            onChange={() => onFillTable(!isFilled)}
            isChecked={isFilled}
            disabled={isFillPending || isBlockChanges}
          />
        )}

        <ExpandableTableButton
          isTableExpanded={isExpanded}
          isTableOverflowed={isOverflowed}
          onToggleExpansion={isTableExpanded.set}
        />
      </RightRow>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  background-color: ${palette.white};
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;

  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
`

const InputWrapper = styled.div`
  width: 535px;
`

const RightRow = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`

const CheckboxFields = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`
