import React from 'react'
import { TableVirtuoso, TableVirtuosoHandle } from 'react-virtuoso'
import styled from 'styled-components'
import { useGate, useUnit } from 'effector-react'

import { ActTableRow, isGaMorphologyElement, isPIRActWork } from '@/dal'
import { useCheckScrolled } from '@/lib/hooks'
import { LoaderOverlay } from '@/ui'

import { $isPIRAct } from '../../../model'
import {
  $flatItems,
  $hasMore,
  $indexToFocus,
  $isTableLoading,
  $isTree,
  ActTableGate,
  onLoadMore,
  paginateFlatTableFx,
} from '../../model/private'
import { GaMorphologyRow } from './GaMorphologyRow'
import { ActTableFolder } from './TableFolder'
import { TableHeader } from './TableHeader'
import { TableHeaderPIR } from './TableHeaderPIR'
import { TableRow } from './TableRow'
import { TableRowPIR } from './TableRowPIR'

type Props = {
  hasMorphology?: boolean
}

export const Table = React.memo(({ hasMorphology = false }: Props) => {
  const tableRef = React.useRef<TableVirtuosoHandle>(null)

  const [items, isPIR, indexToFocus, isShowPendingOverlay, isTree, hasMore, isPaginationPending] =
    useUnit([
      $flatItems,
      $isPIRAct,
      $indexToFocus,
      $isTableLoading,
      $isTree,
      $hasMore,
      paginateFlatTableFx.pending,
    ])

  const { isScrolled, containerRef } = useCheckScrolled([items])

  const MemoHeader = React.useCallback(() => {
    if (isPIR) {
      return <TableHeaderPIR isScrolled={isScrolled.horizontal} />
    }
    return <TableHeader isScrolled={isScrolled.horizontal} />
  }, [isScrolled, isPIR])

  const MemoComponent = React.useCallback(
    (_: number, item: ActTableRow) => {
      if (isGaMorphologyElement(item)) {
        return <GaMorphologyRow item={item} isScrolled={isScrolled.horizontal} />
      }
      if (isPIRActWork(item)) {
        return <TableRowPIR item={item} isScrolled={isScrolled.horizontal} />
      }
      // группа актирования и элемент морфологии тоже могут иметь is_folder: true
      if (item.is_folder) {
        return <ActTableFolder item={item} isScrolled={isScrolled.horizontal} />
      }
      return <TableRow item={item} isScrolled={isScrolled.horizontal} />
    },
    [isScrolled],
  )

  const handleScrollerRef = React.useCallback(
    (ref) => {
      containerRef.current = ref
    },
    [containerRef],
  )

  const scrollTableToIndex = (index: number) => {
    setTimeout(() => {
      tableRef.current?.scrollToIndex({
        align: 'center',
        behavior: 'smooth',
        index,
      })
    }, 150)
  }

  useGate(ActTableGate, { hasMorphology })

  React.useLayoutEffect(() => {
    if (indexToFocus === null || !tableRef.current) return
    scrollTableToIndex(indexToFocus)
  }, [indexToFocus, tableRef.current])

  return (
    <TableWrapper>
      <TableVirtuoso
        ref={tableRef}
        style={{ flexGrow: 1 }}
        data={items ?? []}
        fixedHeaderContent={MemoHeader}
        itemContent={MemoComponent}
        endReached={!isTree ? () => hasMore && !isPaginationPending && onLoadMore() : undefined}
        scrollerRef={handleScrollerRef}
      />
      <LoaderOverlay isShown={isShowPendingOverlay} />
    </TableWrapper>
  )
})

const TableWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  table {
    width: 100%;
  }

  thead {
    z-index: 5 !important;
  }
`
