import React from 'react'
import { useUnit } from 'effector-react'

import { Typography } from '@/ui'
import { ActionModal } from '@/features/estimate-lot/shared'

import {
  deleteLotMaterial,
  deleteLotMaterialFx,
  materialDeletionModal,
} from '../../model/actions.private'

export const MaterialDeletionModal = React.memo(() => {
  const [item, isPending] = useUnit([materialDeletionModal.$meta, deleteLotMaterialFx.pending])

  if (!item) return null

  return (
    <ActionModal
      title="Удалить материал"
      submitLabel="Удалить"
      isPending={isPending}
      onClose={materialDeletionModal.close}
      onSubmit={() => deleteLotMaterial(item)}
    >
      <p>Вы действительно хотите удалить материал «{item.name}»?</p>
      <Typography textStyle="LL" color="grey70">
        Все сопутствующие к нему материалы также будут удалены
      </Typography>
    </ActionModal>
  )
})
