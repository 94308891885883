import React from 'react'
import styled from 'styled-components'
import { useGate, useUnit } from 'effector-react'

import { useBlockNestedInputs } from '@/lib/hooks/useBlockNestedInputs'
import { LoaderOverlay, palette } from '@/ui'
import { $documentId } from '@/features/estimate-lot/model'

import { EstimateLotTableGate } from '../../model'
import { $isTablePending } from '../../model/private'
import {
  MaterialDeletionModal,
  RowClearingModal,
  Table,
  TableActions,
  WorkDeletionModal,
} from '../containers'

export const EstimateLotTable = React.memo(() => {
  const ref = React.useRef<HTMLDivElement>(null)

  const [id, isPending] = useUnit([$documentId, $isTablePending])

  useGate(EstimateLotTableGate, id)
  useBlockNestedInputs(ref, isPending)

  if (!id) return null

  return (
    <>
      <Wrapper>
        <Container>
          <TableActions />

          <TableWrapper ref={ref}>
            <Table />
            <LoaderOverlay isShown={isPending} />
          </TableWrapper>
        </Container>
      </Wrapper>

      <WorkDeletionModal />
      <MaterialDeletionModal />
      <RowClearingModal />
    </>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${palette.white};
  border-radius: 12px;
`

const TableWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: max(500px, 70dvh);

  & table {
    width: 100%;
  }

  & thead {
    z-index: 5 !important;
  }
`
