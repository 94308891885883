import React from 'react'
import styled, { css } from 'styled-components'

import { NewIconButton } from './NewIconButton'

type Props = {
  isDisabled?: boolean
  isAbsolute?: boolean
  dataTestId: string
  onClick: () => void
}

export const CloseModalButton = ({ isDisabled, isAbsolute = true, dataTestId, onClick }: Props) => (
  <IconWrapper $isAbsolute={isAbsolute}>
    <NewIconButton
      dataTestId={dataTestId}
      icon="cross"
      size={20}
      padding="6px"
      color="grey60"
      hoverBackgroundColor="grey20"
      borderRadius="50%"
      hoverColor="grey100"
      disabled={isDisabled}
      onClick={onClick}
    />
  </IconWrapper>
)

const IconWrapper = styled.div<{ $isAbsolute: boolean }>`
  display: flex;

  ${({ $isAbsolute }) =>
    $isAbsolute &&
    css`
      position: absolute;
      right: 12px;
      top: 12px;
      z-index: 11;
    `}
`
