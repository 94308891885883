import { attach, combine } from 'effector'

import {
  cancelEstimateApproveReqFx,
  checkValidationBeforeApproveReqFx,
} from '@/dal'
import { createSingleEffect } from '@/lib/createSingleEffect'

import { $isResetRowsPending } from '../../estimate-table/model'
import { TableGate } from '../../estimate-table/model/private'
import { $documentInfo } from '../../shared-model'
import { d } from './domain'

export const $headDocumentInfo = $documentInfo.map((info) =>
  info
    ? {
        name: info.name,
        lastUpdate: info.last_update,
        feature: info.features,
        approvementProcess: info.approvement_process_log,
      }
    : null,
)

export const $isShowBusinessOffer = $documentInfo.map((info) =>
  Boolean(info?.features.show_business_offer),
)
export const $hasError = $documentInfo.map((info) => !info?.is_valid)

export const onApproveButton = d.event<void>()
export const openSendToApproveModal = d.event<void>()
export const openApproveModal = d.event<void>()
export const onSecondApproving = d.event<void>()
export const onReject = d.event<void>()
export const onDeleteButton = d.event<void>()

export const onCancelApprove = d.event<void>()
export const onCopy = d.event<void>()

export const $isHeaderEditingBlocked = combine($isResetRowsPending, (...args) =>
  args.some((val) => val),
)

export const cancelApproveFx = attach({
  effect: cancelEstimateApproveReqFx,
})

export const { requestFx: checkValidationBeforeApproveFx } = createSingleEffect(
  checkValidationBeforeApproveReqFx,
  {
    abortEvents: [TableGate.status, TableGate.state],
  },
)
