import React from 'react'
import {
  arrow,
  autoUpdate,
  flip,
  offset,
  Placement,
  safePolygon,
  shift,
  size,
  Strategy,
  useFloating,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react'

export type UseTooltipParams = {
  strategy?: Strategy
  placement?: Placement
  tooltipOffset?: number
  tooltipCrossOffset?: number
  sameComponentWidth?: boolean
  hasCloseDelay?: boolean
  hasArrow?: boolean
  onTooltipClose?: () => void
  onTooltipOpen?: () => void
  onOpenChange?: (val: boolean) => void
}

export const useTooltip = ({
  onTooltipClose,
  onTooltipOpen,
  onOpenChange,
  placement = 'bottom',
  strategy = 'fixed',
  tooltipOffset = 0,
  tooltipCrossOffset = 0,
  sameComponentWidth,
  hasCloseDelay,
  hasArrow,
}: UseTooltipParams = {}) => {
  const arrowRef = React.useRef<SVGSVGElement>(null)
  const [isOpen, setIsOpen] = React.useState(false)
  const close = () => {
    onTooltipClose?.()
    onOpenChange?.(false)
    setIsOpen(false)
  }
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: (isOpen) => {
      if (isOpen) onTooltipOpen?.()
      else onTooltipClose?.()
      onOpenChange?.(isOpen)
      setIsOpen(isOpen)
    },
    middleware: [
      offset({
        mainAxis: tooltipOffset,
        crossAxis: tooltipCrossOffset,
      }),
      flip(),
      shift(),
      arrow({
        element: arrowRef,
      }),
      sameComponentWidth &&
        size({
          apply({ rects, elements }) {
            Object.assign(elements.floating.style, {
              width: `${rects.reference.width}px`,
            })
          },
        }),
    ],
    placement,
    strategy,
    whileElementsMounted: autoUpdate,
  })

  const hover = useHover(context, {
    move: true,
    delay: {
      open: 100,
      close: hasCloseDelay ? 100 : 0,
    },
    handleClose: safePolygon({
      requireIntent: false,
    }),
  })
  const role = useRole(context, { role: 'tooltip' })
  const { getReferenceProps, getFloatingProps } = useInteractions([hover, role])

  const arrowProps = {
    ref: arrowRef,
    context,
  }

  return {
    refs,
    floatingStyles,
    getReferenceProps,
    getFloatingProps,
    isOpen,
    arrowProps,
    close,

    // почти то же самое, что сверху, для удобства использования
    referenceProps: {
      ref: refs.setReference,
      ...getReferenceProps(),
    },
    tooltipProps: {
      isOpen,
      refs,
      getFloatingProps,
      floatingStyles,
      arrowProps: hasArrow ? arrowProps : undefined,
    },
  }
}
