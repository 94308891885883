import React from 'react'
import styled from 'styled-components'
import { useStoreMap } from 'effector-react'

import { CatalogMaterial, CatalogMaterialGroup } from '@/dal'
import { Icon2, NewCheckbox, TextL } from '@/ui'

import {
  $expandedGroupIds,
  $selectedMaterialIds,
  deselectMaterials,
  selectMaterials,
  toggleExpandedGroup,
} from '../../model/private'
import { MaterialItem } from './MaterialItem'

type Props = {
  group: CatalogMaterialGroup
  childrenIds: CatalogMaterial['uuid'][]
  isDisabled: boolean
}

export const MaterialGroup = React.memo(({ group, childrenIds, isDisabled }: Props) => {
  const { id, name } = group

  const isExpanded = useStoreMap({
    store: $expandedGroupIds,
    keys: [id],
    fn: (ids, [id]) => ids.includes(id),
  })

  const selectedChildrenCount = useStoreMap({
    store: $selectedMaterialIds,
    keys: [childrenIds],
    fn: (selectedIds, [childrenIds]) => {
      const filteredIds = childrenIds.filter((id) => selectedIds.includes(id))
      return filteredIds.length
    },
  })

  const isChecked = Boolean(childrenIds.length) && selectedChildrenCount === childrenIds.length
  const isIndeterminate = Boolean(selectedChildrenCount) && !isChecked

  const handleChangeGroupSelection = () => {
    if (isChecked) {
      deselectMaterials(childrenIds)
    } else {
      selectMaterials(childrenIds)
    }
  }

  return (
    <>
      <Wrapper type="button" onClick={() => toggleExpandedGroup(id)}>
        <Icon2 icon={isExpanded ? 'arrow-up-lg' : 'arrow-down-lg'} color="grey90" size={24} />
        <NewCheckbox
          value={group.id}
          isChecked={isChecked}
          isDisabled={isDisabled}
          isIndeterminate={isIndeterminate}
          dataTestId=""
          onChange={handleChangeGroupSelection}
        />
        <Name>{name}</Name>
      </Wrapper>

      {isExpanded && (
        <Children>
          {childrenIds.map((id) => (
            <MaterialItem key={id} id={id} isDisabled={isDisabled} />
          ))}
        </Children>
      )}
    </>
  )
})

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;

  width: 100%;
  text-align: left;
  user-select: none;
`

const Name = styled.p`
  ${TextL}
  margin-left: 8px;
`

const Children = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;

  padding-left: 48px;
`
