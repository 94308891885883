import React, { useEffect } from 'react'

import { numberWithSpaces } from '@/lib/number-represent'

import { PaletteColor } from '../../palette'
import { CellDateInput } from './CellDateInput'
import { CellNumberInput } from './CellNumberInput'

type Props = {
  value: string
  defaultSubmitValue?: string
  suffix?: string
  cellType?: 'date' | 'currency'
  placeholder?: string
  color?: PaletteColor
  hasAbsoluteIcon?: boolean
  hasToFocus?: boolean
  decimalScale?: number
  isCentered?: boolean
  onSubmit: (val: string) => void
  onFocus?: () => void
  onBlur?: () => void
  onSanitizeChange?: (val: string) => string
}

export const EditableValue = ({
  value,
  defaultSubmitValue,
  suffix,
  cellType = 'currency',
  placeholder,
  color,
  hasAbsoluteIcon,
  hasToFocus,
  decimalScale = 2,
  isCentered,
  onSubmit,
  onFocus,
  onBlur,
  onSanitizeChange,
}: Props) => {
  const [inputVal, setInputVal] = React.useState(value)

  const handleInputChange = (newVal: string) => {
    let value = newVal
    if (onSanitizeChange) {
      value = onSanitizeChange(newVal)
    }
    setInputVal(value)
    if (cellType === 'date') {
      onSubmit(newVal)
    }
  }

  const handleBlur = () => {
    onBlur?.()
    if (inputVal === value) return
    if (!inputVal && defaultSubmitValue) return onSubmit(defaultSubmitValue)
    onSubmit(inputVal)
  }

  const handleFocus = () => {
    onFocus?.()
    setInputVal(value)
  }

  useEffect(() => {
    if (inputVal !== value) {
      setInputVal(value)
    }
  }, [value])

  return cellType === 'currency' ? (
    <CellNumberInput
      displayValue={numberWithSpaces(value)}
      onChange={handleInputChange}
      inputValue={inputVal}
      suffix={suffix}
      textAlign="right"
      placeholder={placeholder}
      decimalSeparator="."
      hasAbsoluteIcon={hasAbsoluteIcon}
      color={color}
      hasToFocus={hasToFocus}
      decimalScale={decimalScale}
      isCentered={isCentered}
      onFocus={handleFocus}
      onBlur={handleBlur}
    />
  ) : (
    <CellDateInput
      dataTestId="summary-date-input"
      inputValue={inputVal}
      textAlign="right"
      onChange={handleInputChange}
    />
  )
}
