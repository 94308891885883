import { EstimateDoc, EstimateDocId } from '@/dal'

export enum KorDocTab {
  Info = 1,
  Works = 2,
}

export type UpdateSummaryParams = {
  docId: EstimateDocId
  summary: EstimateDoc['summary_table']
}

export type OptionWithParentId = {
  id: number
  label: string
  parent_id: number
}
