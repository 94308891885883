import React, { memo } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { NewDateInput, NewDropDownInput, NewInput, NewTextButton } from '@/ui'

import {
  $filters,
  $projects,
  $statuses,
  clearFilter,
  getFiltersFx,
  removeFilter,
  search,
  setFilter,
} from '../../model/private'

export const EstimateLotsFilters = memo(() => {
  const [filters, searchValue, projectOptions, statusOptions, areFiltersLoading] = useUnit([
    $filters,
    search.$value,
    $projects,
    $statuses,
    getFiltersFx.pending,
  ])

  const hasSetFilters = Object.values(filters).some(Boolean)

  return (
    <Wrapper>
      <NewInput
        placeholder="Поиск..."
        value={searchValue}
        postfixIcon="search"
        dataTestId="lot-list-search-input"
        onChange={search.set}
        onClear={() => search.reset()}
      />
      <NewDateInput
        placeholder="Дата"
        inputValue={filters.date ?? ''}
        dataTestId="lot-list-date-from-select"
        onChange={(val) => setFilter({ key: 'date', val })}
        onClear={() => removeFilter({ key: 'date' })}
      />
      <NewDropDownInput
        placeholder="Выберите проект"
        options={projectOptions}
        value={filters.project_name ?? null}
        isLoading={areFiltersLoading}
        dataTestId="lot-list-project-select"
        hasClear
        onChange={(val) => setFilter({ key: 'project_name', val })}
      />
      <NewDropDownInput
        placeholder="Статус"
        options={statusOptions}
        value={filters.status ?? []}
        isLoading={areFiltersLoading}
        dataTestId="lot-list-status-select"
        isMultiple
        hasClear
        onChange={(val) => setFilter({ key: 'status', val })}
      />
      <ClearFiltersWrapper>
        <NewTextButton
          label="Очистить фильтры"
          textStyle="L"
          isDisabled={!hasSetFilters}
          dataTestId="lot-list-clear-filters-btn"
          onClick={clearFilter}
        />
      </ClearFiltersWrapper>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 230px);
  gap: 16px;

  padding: 12px;
`

const ClearFiltersWrapper = styled.div`
  padding: 7px 0;
`
