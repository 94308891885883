import styled from 'styled-components'

import { TextMLight } from '@/ui'

export const Cell = styled.div<{ align?: 'left' | 'right' | 'center' }>`
  ${TextMLight}
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px;
  text-align: ${({ align = 'left' }) => align};
`
