import { combine, restore } from 'effector'

import { EstimateCorrectionType } from '@/dal'
import { Option } from '@/ui'

import { TYPE_FILTER_ITEMS } from './const'
import { d } from './domain'
import { initFiltersObject } from './helpers'
import { AcceptedGaFilters, AcceptMorphologyFiltersPayload } from './types.filters'

export const $searchInputValue = d.store<string>('')
export const changeSearch = d.event<string>()
export const clearSearch = d.event<void>()
export const acceptSearch = d.event<void>()

export const $types = d.store(TYPE_FILTER_ITEMS)

export const $acceptedTypes = d.store<EstimateCorrectionType[]>([])
export const setAcceptedTypes = d.event<EstimateCorrectionType[]>()

// фильтры для ПС с группами актирования

export const $actingGroupOptions = d.store<Option[]>([])
export const $acceptedActingGroupIds = d.store<number[]>([])
export const setAcceptedActingGroupIds = d.event<number[]>()

export const $morphologyFilterObjects = d.store<AcceptedGaFilters>({
  buildings: initFiltersObject(true),
  sections: initFiltersObject(),
  floors: initFiltersObject(),
})
export const acceptMorphologyFilters = d.event<AcceptMorphologyFiltersPayload>()
export const updateAcceptedMorphologyFiltersFx = d.effect<
  {
    filters: AcceptedGaFilters
    payload: AcceptMorphologyFiltersPayload
  },
  AcceptedGaFilters
>()

export const $acceptedMorphologyFilterObjects = restore(
  updateAcceptedMorphologyFiltersFx.doneData,
  null,
)

// отправляем id только конечной морфологии
export const $acceptedMorphologyFilterIds = $acceptedMorphologyFilterObjects.map((data) => {
  if (!data) return {}
  const { buildings, sections, floors } = data
  if (floors.selectedIds.length) {
    return {
      floors: floors.selectedIds,
    }
  }
  if (sections.selectedIds.length) {
    return {
      sections: sections.selectedIds,
    }
  }
  if (buildings.selectedIds.length) {
    return {
      buildings: buildings.selectedIds,
    }
  }
  return {}
})

export const $acceptedTypesCount = combine(
  [$acceptedTypes, $acceptedActingGroupIds, $morphologyFilterObjects],
  ([acceptedTypes, actingGroupIds, morphologyFilterGroups]) => {
    let gaFilterCount = actingGroupIds.length ? 1 : 0
    if (morphologyFilterGroups) {
      Object.values(morphologyFilterGroups).forEach(
        ({ selectedIds }) => (gaFilterCount += selectedIds.length ? 1 : 0),
      )
    }
    return acceptedTypes.length + gaFilterCount
  },
)

export const $acceptedTableFilters = combine(
  [$searchInputValue, $acceptedTypes, $acceptedActingGroupIds, $acceptedMorphologyFilterIds],
  ([search, types, actingGroupIds, morphologyFilters]) => ({
    search,
    types,
    act_groups: actingGroupIds,
    ...morphologyFilters,
  }),
)
