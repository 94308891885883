import { attach, combine } from 'effector'
import { createForm } from 'effector-forms'

import {
  copyKorDocRowReqFx,
  createEstimateFolderReqFx,
  createEstimateRowReqFx,
  EstimateTableRow,
  getEstimateRowIndexReqFx,
} from '@/dal'
import { rules, withDisableCondition } from '@/lib/form-validators'
import { $buildings, $hasSplittingObjects } from '@/features/estimate-correction/shared-model'

import { d } from './domain'

export const $isModalOpen = d.store<boolean>(false)
export const closeModal = d.event<void>()

export const $isCreateFolder = d.store(false)
export const $isCopying = d.store(false)

export const $createdTableItem = d.store<EstimateTableRow | null>(null)
export const setCreatedTableItem = d.event<EstimateTableRow>()
export const resetCreatedTableItem = d.event()

// eslint-disable-next-line effector/no-getState
const isProjectRequired = () => !$hasSplittingObjects.getState()
const isBuildingRequired = (fields?: { project: number | null }) =>
  Boolean(
    // eslint-disable-next-line effector/no-getState
    fields?.project && $buildings.getState().some(({ parent_id }) => parent_id === fields?.project),
  )

export const createFolderForm = createForm({
  domain: d,
  fields: {
    group_id: {
      init: null as null | EstimateTableRow['id'],
    },
    name: {
      init: '',
      rules: [rules.required()],
    },
  },
})

export const createRowForm = createForm({
  domain: d,
  validateOn: ['change'],
  fields: {
    group_id: {
      init: null as null | EstimateTableRow['id'],
    },
    name: {
      init: '',
      rules: [rules.required()],
    },
    project: {
      init: null as null | number,
      rules: [withDisableCondition(isProjectRequired, rules.required())],
    },
    unit: {
      init: null as null | number,
      rules: [rules.required()],
    },
    count_in_estimate: {
      init: '',
      rules: [rules.required()],
    },
    material: {
      init: '',
      rules: [rules.sumBothFieldsMustBeGreaterThanZero('work')],
    },
    work: {
      init: '',
      rules: [rules.sumBothFieldsMustBeGreaterThanZero('material')],
    },
    justification_of_changes_current: {
      init: '',
    },
    files: {
      init: [] as File[],
    },
    // нижнее только для ПС с группами актирования
    acting_group: {
      init: null as number | null,
    },
    building: {
      init: null as number | null,
      rules: [withDisableCondition(isBuildingRequired, rules.required())],
    },
    section: {
      init: null as number | null,
    },
    floor: {
      init: null as number | null,
    },
  },
})

export const createEstimateRowFx = attach({
  effect: createEstimateRowReqFx,
})

export const copyKorDocRowFx = attach({
  effect: copyKorDocRowReqFx,
})

export const createEstimateFolderFx = attach({
  effect: createEstimateFolderReqFx,
})

export const getEstimateRowIndexFx = attach({
  effect: getEstimateRowIndexReqFx,
})

export const $isItemCreationPending = combine(
  createEstimateRowFx.pending,
  copyKorDocRowFx.pending,
  createEstimateFolderFx.pending,
  (...args) => args.some(Boolean),
)
