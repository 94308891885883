import React, { Key } from 'react'
import styled from 'styled-components'

import { mergeRefs } from '@/lib/mergeRefs'

import { NewButton } from '../../buttons'
import { palette } from '../../palette'
import { TooltipProps } from '../../types'
import { TextMLight } from '../../typography'
import { DialogSheetWrapper } from '../DialogSheetWrapper'
import { FlexScrollbar } from '../FlexScrollbar'
import { NewCheckbox } from '../NewCheckbox'

type Props<T> = TooltipProps & {
  options: {
    id: T
    label: string
  }[]
  value: T[]
  testId: string
  width: number
  listMaxHeight?: string
  additionalSlot?: React.ReactNode
  onSubmit: () => void
  onToggle: (value: T) => void
  onClear: () => void
}

export const MultiSelectList = React.forwardRef(
  <T extends Key>(
    {
      options,
      value,
      testId,
      floatingStyles,
      refs,
      width,
      listMaxHeight = '275px',
      additionalSlot,
      getFloatingProps,
      onSubmit,
      onToggle,
      onClear,
    }: Props<T>,
    ref: React.ForwardedRef<HTMLElement>,
  ) => {
    return (
      <List
        ref={mergeRefs([refs.setFloating, ref])}
        style={floatingStyles}
        $width={width}
        {...getFloatingProps()}
      >
        <FlexScrollbar autoHeightMax={listMaxHeight}>
          {options.map((item) => (
            <ListItem onClick={() => onToggle(item.id)} key={item.id}>
              <NewCheckbox
                label={item.label}
                checkPosition="left"
                value={item.id}
                isChecked={value.includes(item.id)}
                onChange={() => onToggle(item.id)}
              />
            </ListItem>
          ))}
        </FlexScrollbar>

        {additionalSlot}

        <Buttons>
          <NewButton
            dataTestId={`${testId}-accept`}
            label="Применить"
            buttonType="secondary"
            size="S"
            onClick={onSubmit}
          />
          <NewButton
            label="Сбросить"
            dataTestId={`${testId}-reset`}
            buttonType="line-grey"
            prefixIcon="refresh"
            size="S"
            onClick={onClear}
          />
        </Buttons>
      </List>
    )
  },
)

const List = styled(DialogSheetWrapper)<{ $width: number }>`
  ${TextMLight}
  z-index: 11;

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  width: ${({ $width }) => $width}px;
  padding: 8px 0;
`

const ListItem = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.15s linear;

  @media (pointer: fine) {
    &:hover {
      background-color: ${palette.grey20};
    }
  }
`

const Buttons = styled.div`
  gap: 10px;
  display: flex;
  padding: 12px 12px 8px;
`
