import axiosLib, { AxiosError, AxiosHeaders } from 'axios'
import { pushNavigate, RouterPaths } from '@/features/app/model'

import { isCancelError, RequestCancelError } from '../error-handler'
import { fetchFileFx, mockRequestFx, requestFx } from './units'

const baseURL = process?.env.API_HOST ? `${process?.env.API_HOST}/api` : '/api'

const axios = axiosLib.create({
  baseURL,
})

axios.interceptors.response.use(undefined, (error: AxiosError | RequestCancelError) => {
  if (!isCancelError(error) && error?.response?.status === 530) {
    pushNavigate(RouterPaths.Maintenance)
  }
  throw error
})

requestFx.use((params) => {
  const defaultHeaders = params.headers || {}
  const headers = {
    ...defaultHeaders,
  }

  if (params.accessToken) {
    headers.Authorization = `Token ${params.accessToken}`
  }

  return axios.request({
    headers,
    method: params.method,
    url: params.url,
    params: params.query,
    data: params.body,
    baseURL: params.baseUrl,
    signal: params.signal,
  })
})

mockRequestFx.use(
  () =>
    new Promise((res) => {
      setTimeout(() => {
        res({
          config: {
            headers: new AxiosHeaders(),
          },
          data: null,
          headers: {},
          status: 200,
          statusText: 'success',
        })
      }, 500)
    }),
)

fetchFileFx.use(({ accessToken, query, url }) => {
  const params = new URLSearchParams()
  if (query && Object.keys(query).length) {
    Object.keys(query).forEach((key) => {
      const val = query[key]
      params.append(key, String(val))
    })
  }
  return fetch(`/api${url}${query ? `?${params}` : ''}`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Token ${accessToken}`,
    }),
  }).then((response) => {
    if (response.status > 399) {
      throw Error(response.statusText)
    }
    return response.blob()
  })
})
