import React from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

import { AppealAlias } from '@/dal'
import { palette, TooltipProps, WINDOW_WIDTH } from '@/ui'
import { APPEAL_SECTIONS } from '@/features/support/model'

import { onSelectAppealTypeClicked } from '../../model/private'
import { SelectAppealButton, SelectAppealFooter, TelegramLink } from '../parts'

type Props = TooltipProps & {
  onClose: () => void
}

export const SelectAppealWindow = React.memo(
  ({ floatingStyles, refs, getFloatingProps, onClose }: Props) => {
    const handleSelectAppealType = (alias: AppealAlias) => {
      onSelectAppealTypeClicked(alias)
      onClose()
    }

    return createPortal(
      <>
        <Wrapper
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
          onClick={(e) => e.stopPropagation()}
        >
          <Cards>
            {APPEAL_SECTIONS.filter(({ description }) => description).map((item) => (
              <SelectAppealButton key={item.alias} {...item} onClick={handleSelectAppealType} />
            ))}
            <TelegramLink />
          </Cards>

          <Separator />

          <SelectAppealFooter />
        </Wrapper>
        <Overlay onClick={onClose} />
      </>,
      document.body,
    )
  },
)

const Overlay = styled.div`
  position: fixed;
  inset: 0;
  z-index: 10;
  background-color: #a7a7a74d;
`

const Wrapper = styled.div`
  z-index: 11;

  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 1081px;
  max-width: 100dvw;
  max-height: 85dvh;
  padding: 28px 32px 32px;
  background-color: ${palette.white};
  border-radius: 20px;
  box-shadow: 0px 4px 20px 0px #1c1d1e1f;
  overflow: auto;

  @media (max-width: ${WINDOW_WIDTH.desktop}px) {
    width: 948px;
  }
`

const Cards = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 24px;

  @media (max-width: ${WINDOW_WIDTH.desktop}px) {
    gap: 16px;
  }

  & > * {
    height: 100%;
    min-height: 179px;
  }
`

const Separator = styled.div`
  height: 1px;
  background-color: ${palette.grey30};
`
