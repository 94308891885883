import React from 'react'
import styled, { css } from 'styled-components'

import { DefaultButton } from '../../buttons'
import { palette } from '../../palette'
import { TextM } from '../../typography'
import { TabsProps } from './types'

type Props<T> = TabsProps<T> & {
  invalidTabId?: T | null
}

export const SectionTabs = <T extends React.Key>({
  items,
  activeId,
  invalidTabId,
  onItemClick,
}: Props<T>) => {
  if (!items.length) return null

  return (
    <Wrapper>
      {items.map(({ id, label, count }) => {
        const isActive = activeId === id
        return (
          <TabItem
            key={id}
            type="button"
            $isActive={isActive}
            $isInvalid={invalidTabId === id}
            onClick={() => onItemClick(id)}
          >
            <p>{label}</p>
            {typeof count === 'number' && <Counter $isActive={isActive}>{count}</Counter>}
          </TabItem>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  width: fit-content;
  padding: 6px;
  border: 1px solid ${palette.grey40};
  border-radius: 12px;
  background-color: ${palette.white};
`

type TabItemProps = {
  $isActive: boolean
  $isInvalid: boolean
}

const TabItem = styled(DefaultButton)<TabItemProps>`
  ${TextM}
  display: flex;
  align-items: center;
  gap: 4px;

  padding: 5px 12px;
  background-color: ${palette.white};
  border-radius: 8px;
  transition: 0.15s linear;
  transition-property: background-color, color;
  user-select: none;
  border: 1px solid transparent;

  ${({ $isInvalid }) =>
    $isInvalid &&
    css`
      border-color: ${palette.red100};
    `}

  ${({ $isActive }) =>
    $isActive
      ? css`
          background-color: ${palette.grey80};
          color: ${palette.white};
        `
      : css`
          @media (hover: hover) {
            &:hover {
              background-color: ${palette.grey40};
            }
          }
        `}
`

const Counter = styled.p<{ $isActive: boolean }>`
  padding: 3px 4px 2px;
  border-radius: 99px;

  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  transition: 0.15s linear;
  transition-property: background-color, color;

  ${({ $isActive }) =>
    $isActive
      ? css`
          background-color: ${palette.white};
          color: ${palette.grey90};
        `
      : css`
          background-color: ${palette.grey100};
          color: ${palette.white};
        `}
`
