import { sample } from 'effector'

import { CheckboxState, EstimateDoc } from '@/dal'
import { createToast } from '@/features/toast-service/model'

import { $documentId, EstimateCorrectionGate } from '../../shared-model'
import { getEstimateInfoByIdFx } from '../../shared-model/private'
import {
  mapRowsToResettingRowsTree,
  mutateStateOfAncestors,
  mutateStateOfDescendants,
} from './helpers'
import {
  $isTreeTable,
  changeIsTree,
  getEstimateFolderRowsFx,
  getEstimateTreeTableFx,
} from './private'
import {
  $flatResettingRows,
  $isResetModeEnabled,
  $isResettingAll,
  $previousIsTree,
  $resettingRowsMap,
  $selectedRowsData,
  changeIsResettingAll,
  enableResetMode,
  exitResetMode,
  resetKorDocRows,
  resetKorDocRowsFx,
  setPreviousIsTree,
  toggleResettingRow,
} from './reset-multiple-items.private'

// не имеет отношения к сбросу изменений (reset-table-item), это обнуление строк

$isResetModeEnabled.on(enableResetMode, () => true).reset(exitResetMode)

$flatResettingRows
  .on(toggleResettingRow, (map, { id }) => {
    const newState =
      map[id]?.state === CheckboxState.Checked ? CheckboxState.Unchecked : CheckboxState.Checked
    return {
      ...map,
      [id]: {
        id,
        state: newState,
      },
    }
  })
  .reset(exitResetMode, $isTreeTable.updates, $isResettingAll.updates)

$resettingRowsMap
  .on(toggleResettingRow, (resettingRows, { id }) => {
    const target = resettingRows[id]
    if (!target) return

    const newState =
      target.state === CheckboxState.Unchecked ? CheckboxState.Checked : CheckboxState.Unchecked
    const map = {
      ...resettingRows,
      [id]: {
        ...target,
        state: newState,
      },
    }
    mutateStateOfDescendants({ map, id, state: newState })
    mutateStateOfAncestors({ map, id, state: newState })
    return map
  })
  .on(getEstimateTreeTableFx.doneData, (existingMap, { data }) => {
    const newMap = mapRowsToResettingRowsTree(data)
    return {
      ...newMap,
      ...existingMap,
    }
  })
  .on(getEstimateFolderRowsFx.done, (existingMap, { params, result }) => {
    const parent = existingMap[params.group_id]
    if (!parent) return

    const newMap = mapRowsToResettingRowsTree(result.data, parent.state)
    return {
      ...newMap,
      ...existingMap,
      [params.group_id]: {
        ...parent,
        children: result.data.map(({ id }) => id),
      },
    }
  })
  .on([exitResetMode, $isResettingAll.updates], (map) => {
    const uncheckedEntries = Object.entries(map).map(([id, data]) => [
      id,
      { ...data, state: CheckboxState.Unchecked },
    ])
    return Object.fromEntries(uncheckedEntries)
  })
  .reset(EstimateCorrectionGate.close, resetKorDocRowsFx.done, $isTreeTable.updates)

$isResettingAll.on(changeIsResettingAll, (_, value) => value).reset(exitResetMode)

$previousIsTree.on(setPreviousIsTree, (_, value) => value).reset(EstimateCorrectionGate.close)

// принудительно переводим в папочный режим
// не удаляем функционал для плоского вида, может понадобиться вернуть
sample({
  clock: enableResetMode,
  source: $isTreeTable,
  target: [
    setPreviousIsTree.prepend((isTreeTable: boolean) => isTreeTable),
    changeIsTree.prepend(() => true),
  ],
})

sample({
  clock: $isResetModeEnabled.updates,
  source: $previousIsTree,
  filter: (_, isResetMode) => !isResetMode,
  target: changeIsTree,
})

sample({
  clock: resetKorDocRows,
  source: {
    id: $documentId,
    selectedRows: $selectedRowsData,
    isResettingAll: $isResettingAll,
  },
  filter: ({ id }) => Boolean(id),
  fn: ({ id, selectedRows, isResettingAll }) =>
    isResettingAll
      ? {
          id: id as EstimateDoc['id'],
          clear_all: true,
        }
      : {
          id: id as EstimateDoc['id'],
          uuids: selectedRows.rows,
          groups: selectedRows.groups,
        },
  target: resetKorDocRowsFx,
})

sample({
  clock: resetKorDocRowsFx.done,
  source: $documentId,
  filter: Boolean,
  fn: (id) => ({ id }),
  target: getEstimateInfoByIdFx,
})

sample({
  clock: [EstimateCorrectionGate.close, resetKorDocRowsFx.done],
  target: exitResetMode,
})

createToast({
  effect: resetKorDocRowsFx,
  doneText: 'Строки успешно обнулены',
})
