import styled from 'styled-components'

import { AppealAlias } from '@/dal'
import { DefaultButton, Icon2, palette } from '@/ui'
import { AppealSection } from '@/features/support/model/types'

import { CardDescription } from './CardDescription'
import { CardTitle } from './CardTitle'
import { cardStyle } from './styles'

type Props = {
  onClick: (alias: AppealAlias) => void
} & Omit<AppealSection, 'description'>

export const SelectAppealButton = ({ alias, name, shortDescription, onClick }: Props) => {
  return (
    <Wrapper onClick={() => onClick(alias)}>
      <Header>
        <CardTitle>{name}</CardTitle>
        <IconWrapper>
          <Icon2 icon="arrowRight" size={20} />
        </IconWrapper>
      </Header>

      <CardDescription>{shortDescription}</CardDescription>
    </Wrapper>
  )
}

const IconWrapper = styled.div`
  flex-shrink: 0;
  color: ${palette.grey60};
  transition: color 0.15s linear;
`

const Wrapper = styled(DefaultButton)`
  ${cardStyle}
  width: 100%;
  text-align: left;

  &:hover {
    ${IconWrapper} {
      color: ${palette.grey100};
    }
  }
`

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`
