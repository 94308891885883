import { Domain } from 'effector'

import { root } from '@/root-domain'

export const createValue = <T>(initialValue: T, domain?: Domain) => {
  const d = domain ?? root.domain()

  const $value = d.store<T>(initialValue)
  const set = d.event<T>()
  const reset = d.event()

  $value.on(set, (_, value) => value).reset(reset)

  return {
    $value,
    set,
    reset,
  }
}
