import React from 'react'

import { EstimateTableRow, ProjectListItem } from '@/dal'
import {
  NewDropDownInput,
  Option,
  OverflowText,
} from '@/ui'

import { GreyText } from './GreyText'

type Props = {
  isFolder: boolean,
  isEditMode: boolean,
  value: Option['id']
  projects: ProjectListItem[] | null
  id: EstimateTableRow['id']
  project?: string | React.JSX.Element
  isEditable: boolean
  onChange: (val: Option['id']) => void
}

export const ProjectField = ({
  isEditMode,
  isFolder,
  projects,
  value,
  id,
  project,
  isEditable,
  onChange,
}: Props) => {
  const projectOptions = React.useMemo(() => {
    if (!projects) return []
    return projects.map((item) => ({ label: item.name, id: item.id }))
  }, [projects])

  if (!isFolder && isEditMode && isEditable && onChange) {
    return (
      <NewDropDownInput
        size="S"
        dataTestId={`row-project-${id}`}
        value={value}
        onChange={onChange}
        options={projectOptions}
        hasClear={false}
        placeholder="Выберите объект строительства"
      />
    )
  }

  if (project) {
    return (
      <GreyText>
        <OverflowText
          maxTooltipWidth={250}
          maxLines={1}
        >
          {project}
        </OverflowText>
      </GreyText>
    )
  }

  return null
}
