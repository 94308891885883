import React from 'react'
import styled, { css } from 'styled-components'

import { Icon2 } from '../../icons'
import { palette } from '../../palette'
import { TextMLight, TextSLight } from '../../typography'
import { MultiSelectPopup } from './MultiSelectPopup'

type Props<T> = {
  value: T[]
  items: {
    id: T
    label: string
  }[]
  name: string
  description?: string
  acceptedCount: number
  paddingBlock?: number
  isDisabled?: boolean
  positionRef?: React.RefObject<HTMLElement>
  onSubmit: (items: T[]) => void
}

export const MultiSelectGroup = <T extends React.Key>({
  value,
  items,
  name,
  description,
  acceptedCount,
  paddingBlock = 8,
  isDisabled = false,
  positionRef,
  onSubmit,
}: Props<T>) => {
  const triggerComponent = React.useCallback(() => {
    return (
      <GroupButton type="button" disabled={isDisabled} $paddingBlock={paddingBlock}>
        {description && <Description>{description}</Description>}
        <NameWrapper>
          <Name $isGrey={isDisabled}>
            {name}
            {acceptedCount ? ` (${acceptedCount})` : ''}
          </Name>
          <Icon2 icon="arrowRightS" size={12} color={isDisabled ? 'grey50' : 'grey80'} />
        </NameWrapper>
      </GroupButton>
    )
  }, [name, paddingBlock, description, acceptedCount, isDisabled])

  return (
    <MultiSelectPopup
      positionRef={positionRef}
      triggerComponent={triggerComponent}
      testId="estimate-filters"
      items={items}
      selectedIds={value}
      isDisabled={isDisabled}
      placement="right-end"
      width={280}
      listMaxHeight="100%"
      maxHeightAsComponentHeight
      hasSelectAllOption
      onAcceptClick={onSubmit}
    />
  )
}

const GroupButton = styled.button<{ $paddingBlock: number }>`
  display: flex;
  flex-direction: column;
  gap: 4px;

  width: 100%;
  padding: ${({ $paddingBlock }) => $paddingBlock}px 38px;

  transition: background-color 0.15s linear;
  text-align: left;
  cursor: pointer;

  @media (pointer: fine) {
    &:hover:not(:disabled) {
      background-color: ${palette.grey20};
    }
  }
`

const Description = styled.p`
  ${TextSLight}
  color: ${palette.grey70};
`

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
`

const Name = styled.p<{ $isGrey: boolean }>`
  ${TextMLight}
  flex-grow: 1;

  ${({ $isGrey }) =>
    $isGrey &&
    css`
      color: ${palette.grey50};
    `}
`
