import React from 'react'
import { usePopperTooltip } from 'react-popper-tooltip'
import styled from 'styled-components'

import { noop } from '@/lib/noop'

import { IconButton } from './buttons/iconButton'
import { PopperToolTip } from './PopperToolTip'
import { themeVar } from './theming'

type Props = {
  mail: string | null
  onCopy: () => void
  copyDataTestId: string
}

export const MailToolTip = ({ mail, onCopy, copyDataTestId }: Props) => {
  const { getArrowProps, getTooltipProps, setTriggerRef, setTooltipRef, visible } =
    usePopperTooltip({
      placement: 'top',
      trigger: 'click',
    })

  if (!mail) return null

  return (
    <>
      <PopperToolTip
        getArrowProps={getArrowProps}
        getTooltipProps={getTooltipProps}
        setTooltipRef={setTooltipRef}
        tooltip={
          <Tooltip>
            <ToooltipText>{mail}</ToooltipText>
            <IconButton
              size={20}
              iconSize={20}
              dataTestId={copyDataTestId}
              icon="copy"
              onClick={onCopy}
            />
          </Tooltip>
        }
        visible={visible}
      />

      <span ref={setTriggerRef}>
        <IconButton
          borderRadius="2px"
          backgroundColor="main"
          size={17}
          iconSize={15}
          dataTestId={`trigger-${copyDataTestId}`}
          icon="envelope"
          onClick={noop}
        />
      </span>
    </>
  )
}

const ToooltipText = styled.span`
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;

  white-space: nowrap;
  color: ${themeVar('text')};
  font-family: ${themeVar('baseFont')};
`

const Tooltip = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: ${themeVar('lightBackground')};
`
