import React from 'react'
import { useUnit } from 'effector-react'
import { useForm } from 'effector-forms'

import { FormModalWrapper } from '@/ui'
import { useCheckFieldsDirty } from '@/lib/hooks/useCheckFieldsDirty'

import {
  $splitTargetId,
  closeModal,
  splitVolumeForm,
  splitVolumeFx,
} from '../../../model/split-volume.private'
import { SplitVolumeForm } from '../containers'

export const SplitVolumeModal = React.memo(() => {
  const [isPending, itemId] = useUnit([
    splitVolumeFx.pending,
    $splitTargetId,
  ])

  const { fields, isValid } = useForm(splitVolumeForm)

  const isNewWorkFieldRequired = (
    !fields.new_material.value || parseFloat(fields.new_material.value) <= 0
  )

  const isNewMaterialFieldRequired = (
    !fields.new_work.value || parseFloat(fields.new_work.value) <= 0
  )

  const areFieldsDirty = useCheckFieldsDirty(fields, [
    'old_justification_of_changes_current',
    'old_files_to_save',
    'old_files_to_delete',
    'new_justification_of_changes_current',
    'new_files_to_save',
    !isNewMaterialFieldRequired ? 'new_material' : null,
    !isNewWorkFieldRequired ? 'new_work' : null,
  ])

  if (!itemId) return null

  return (
    <FormModalWrapper
      closeModal={closeModal}
      dataTestId="split-modal"
      isDisableSubmit={!isValid || !areFieldsDirty}
      showLoader={isPending}
      isPending={isPending}
      submitLabel="Разделить"
      title="Разделение объемов"
      onSubmit={splitVolumeForm.submit}
    >
      <SplitVolumeForm />
    </FormModalWrapper>
  )
})
