import { EstimateNumberCell, EstimateTableRow } from '@/dal'

export const getFormValuesFromRow = (row: EstimateTableRow) => {
  const newLine = row.new_lines?.[0]

  const getValue = (col?: EstimateNumberCell | null) => {
    return (col?.value_adjusted || col?.value_current) ?? ''
  }

  return {
    project: row.project?.id ?? null,
    count_in_estimate: getValue(row.count_in_estimate),
    material: getValue(row.price_material),
    work: getValue(row.price_work),
    justification_of_changes_current: row.justification_of_changes?.value ?? '',
    name: row.name.value,
    unit: row.unit?.id ?? null,
    acting_group: row.acting_group?.id ?? null,
    building: row.building?.id ?? null,
    section: row.section?.id ?? null,
    floor: row.floor?.id ?? null,

    new_count_in_estimate: getValue(newLine?.count_in_estimate),
    new_material: getValue(newLine?.price_material),
    new_work: getValue(newLine?.price_work),
    new_justification_of_changes_current: newLine?.justification_of_changes?.value ?? '',
  }
}
