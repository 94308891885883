import React, { Key } from 'react'

import { noop } from '@/lib/noop'

import { MultiSelectPopup } from '../components/popup-components/MultiSelectPopup'
import { NewInput } from './NewInput'

type Props<T> = {
  onAcceptClick: (ids: T[]) => void
  disabled?: boolean
  items: {
    id: T
    label: string
  }[]
  testId: string
  selectedIds: T[]
  placeholder: string
}

export const MultiSelectInput = <T extends Key>({
  items,
  testId,
  selectedIds,
  disabled,
  placeholder,
  onAcceptClick,
}: Props<T>) => {
  const label = React.useMemo(() => {
    return items
      .filter((item) => selectedIds.includes(item.id))
      .map((item) => item.label)
      .join(', ')
  }, [selectedIds, items])

  const TriggerComponent = React.useCallback(
    ({ isOpen }) => (
      <NewInput
        onChange={noop}
        readOnly
        value={label}
        postfixIcon={isOpen ? 'arrowUp' : 'arrowDown'}
        dataTestId=""
        selectedItemsCount={selectedIds.length || undefined}
        placeholder={placeholder}
        disabled={disabled}
      />
    ),
    [label, selectedIds, disabled, placeholder],
  )

  return (
    <MultiSelectPopup
      items={items}
      onAcceptClick={onAcceptClick}
      selectedIds={selectedIds}
      testId={testId}
      triggerComponent={TriggerComponent}
      isDisabled={disabled}
    />
  )
}
