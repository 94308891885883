import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { useTooltip } from '@/lib/hooks'
import { noop } from '@/lib/noop'
import { NewButton, NewInput, palette, Switch, ToolTipV2 } from '@/ui'
import { openAddWorksModal } from '@/features/estimate-lot/model/private'

import {
  $filters,
  $hasTableItems,
  $isEditMode,
  exitEditMode,
  setFilter,
  setIsMultipleEditMode,
} from '../../model/private'

export const TableActions = React.memo(() => {
  const [filters, isEditMode, hasTableItems] = useUnit([$filters, $isEditMode, $hasTableItems])

  const { referenceProps, tooltipProps } = useTooltip({
    tooltipOffset: 10,
    tooltipCrossOffset: 12,
    placement: 'top-start',
    hasArrow: true,
  })

  return (
    <Wrapper>
      <Row>
        <div {...referenceProps}>
          <Switch isChecked={false} label="Папки" disabled onChange={noop} />
        </div>

        <ToolTipV2 {...tooltipProps} size="M" bgColor="grey80">
          Режим отображения папок пока не доступен
        </ToolTipV2>

        <VerticalLine />

        <SearchWrapper>
          <NewInput
            placeholder="Поиск работ и материалов"
            value={filters.search ?? ''}
            postfixIcon="search"
            disabled={!hasTableItems}
            dataTestId="lot-table-search-input"
            onChange={(val) => setFilter({ val, key: 'search' })}
          />
        </SearchWrapper>
      </Row>

      {hasTableItems && (
        <Row key={isEditMode ? 'edit-mode' : 'view-mode'}>
          {isEditMode ? (
            <>
              <NewButton
                label="Отменить"
                size="S"
                buttonType="grey"
                dataTestId="lot-table-edit-mode-cancel-btn"
                onClick={() => exitEditMode()}
              />
              <NewButton
                label="Сохранить изменения"
                size="S"
                dataTestId="lot-table-edit-mode-save-btn"
                onClick={() => exitEditMode()}
              />
            </>
          ) : (
            <>
              <NewButton
                label="Массовое редактирование"
                size="S"
                prefixIcon="editStroke"
                buttonType="line-primary"
                dataTestId="lot-table-multiple-edit-mode-btn"
                onClick={() => setIsMultipleEditMode(true)}
              />
              <NewButton
                label="Добавить работу"
                size="S"
                prefixIcon="plusWithCircle"
                buttonType="line-primary"
                width={210}
                dataTestId="lot-add-work-btn"
                onClick={openAddWorksModal}
              />
            </>
          )}
        </Row>
      )}
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;
  padding: 20px 20px 24px;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const VerticalLine = styled.div`
  width: 1px;
  min-width: 1px;
  height: 32px;
  background-color: ${palette.grey50};
`

const SearchWrapper = styled.div`
  width: 412px;
`
