import React from 'react'
import styled from 'styled-components'

import { useTooltip } from '@/lib/hooks'
import { EditableValue, ToolTipV2 } from '@/ui'

import { Cell } from './Cell'

type Props = {
  value: string
  tooltipText?: string
  onSubmit: (val: string) => void
}

export const EditableCell = ({ value, tooltipText, onSubmit }: Props) => {
  const [isInputFocused, setIsInputFocused] = React.useState(false)

  const { referenceProps, tooltipProps, close } = useTooltip({
    tooltipOffset: 3,
  })

  React.useEffect(() => {
    if (!isInputFocused) {
      close()
    }
  }, [isInputFocused])

  return (
    <Cell>
      <EditableValueWrapper {...referenceProps}>
        <EditableValue
          value={value}
          color="accent100"
          hasAbsoluteIcon
          isCentered
          onFocus={() => setIsInputFocused(true)}
          onBlur={() => setIsInputFocused(false)}
          onSubmit={onSubmit}
        />
      </EditableValueWrapper>

      {tooltipText && (
        <ToolTipV2 {...tooltipProps} size="M" isOpen={tooltipProps.isOpen && !isInputFocused}>
          <TooltipText>{tooltipText}</TooltipText>
        </ToolTipV2>
      )}
    </Cell>
  )
}

const TooltipText = styled.p`
  width: 295px;
  text-align: center;
`

const EditableValueWrapper = styled.div`
  position: relative;
  height: 18px;
`
