import React, { FormEvent, useMemo } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'
import { useForm } from 'effector-forms'
import { v4 as uuidv4 } from 'uuid'

import {
  DropArea,
  HeadL,
  InputField,
  NewDropDownField,
  NewFileItem,
  TextAreaField,
  TextMLight,
  palette,
} from '@/ui'
import { createAppealModal } from '@/features/support/support-appeals/model/private'
import { ButtonsRow } from '@/features/support/shared'
import { FILE_MAX_COUNT } from '@/features/support/model'
import { useCheckFieldsDirty } from '@/lib/hooks/useCheckFieldsDirty'

import {
  $documentOptions,
  $selectedResponsibleRole,
  getDocumentListFx,
  sendAppealForResponsibleFx,
} from '../../model/private'
import { appealForm } from '../../model/forms'

export const SecondStep = React.memo(() => {
  const responsibleType = useUnit($selectedResponsibleRole)
  const documentOptions = useUnit($documentOptions)
  const isSending = useUnit(sendAppealForResponsibleFx.pending)
  const areDocumentsLoading = useUnit(getDocumentListFx.pending)

  const { fields, isValid, submit } = useForm(appealForm)

  const areFieldsDirty = useCheckFieldsDirty(fields, ['files'])

  // полукостыльный способ избежать проблем с файлами, имеющими одинаковое название
  const filesWithId = useMemo(() => (
    fields.files.value.map((file) => ({ file, id: uuidv4() }))
  ), [fields.files.value])

  if (!responsibleType) return null

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    if (!isSending) submit()
  }

  const onDeleteFile = (uuid: string) => {
    const files = filesWithId.filter(({ id }) => uuid !== id)
    fields.files.onChange(files.map(({ file }) => file))
  }

  return (
    <Wrapper>
      <Form onSubmit={onSubmit}>
        <Header>
          <ResponsibleName>
            {responsibleType.name}
          </ResponsibleName>

          <ResponsibleDescription>
            {responsibleType.description}
          </ResponsibleDescription>
        </Header>

        <FormContent>
          <InputField
            value={fields.subject.value}
            onChange={fields.subject.onChange}
            onClear={fields.subject.reset}
            error={fields.subject.firstError?.errorText}
            label="Тема"
            placeholder="Введите тему обращения"
            size="L"
            dataTestId="new-responsible-appeal-topic-input"
            disabled={isSending}
            isRequired
          />

          <NewDropDownField
            label="Документ"
            value={fields.document.value}
            error={fields.document.firstError?.errorText}
            onChange={(id) => fields.document.onChange(id)}
            options={documentOptions}
            placeholder="Выберите документ из списка"
            size="L"
            dataTestId="new-responsible-appeal-document-dropdown"
            isDisabled={isSending || areDocumentsLoading}
            isLoading={areDocumentsLoading}
            required
            hasSearch
          />

          <TextAreaField
            value={fields.description.value}
            onChange={fields.description.onChange}
            error={fields.description.firstError?.errorText}
            label="Текст обращения"
            placeholder="Введите текст..."
            dataTestId="new-responsible-appeal-text-textarea"
            disabled={isSending}
            height={100}
            required
          />

          <FilesContainer>
            <DropArea
              value={fields.files.value}
              maxFiles={FILE_MAX_COUNT}
              isDisabled={isSending}
              isMultiple
              onChange={fields.files.onChange}
            />
            {filesWithId.length ? (
              <FileList>
                {filesWithId.map(({ file, id }) => (
                  <NewFileItem
                    key={id}
                    size="M"
                    name={file.name}
                    isDense
                    onDelete={() => onDeleteFile(id)}
                  />
                ))}
              </FileList>
            ) : null}
          </FilesContainer>
        </FormContent>

        <ButtonsRow
          isPending={isSending}
          isDisabled={!areFieldsDirty || !isValid || areDocumentsLoading}
          canTakeScreenshot={fields.files.value.length < FILE_MAX_COUNT}
          hasScreenshotButton
          onClose={createAppealModal.close}
          onSubmit={submit}
        />
      </Form>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 542px;
  padding: 32px;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const ResponsibleName = styled.div`
  ${HeadL}
`

const ResponsibleDescription = styled.div`
  ${TextMLight}
  color: ${palette.grey70};
`

const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 8px;
`

const FileList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
