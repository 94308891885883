import React from 'react'
import styled, { css } from 'styled-components'

import { ContentWithTooltip } from './ContentWithTooltip'

type Props = {
  children: string | React.JSX.Element
  maxLines?: number
  maxTooltipWidth?: number
}

export const OverflowText = ({ children, maxLines = 1, maxTooltipWidth }: Props) => {
  const [isOverflowed, setIsOverflowed] = React.useState(false)
  const [tooltipText, setTooltipText] = React.useState<string | null>(null)

  const contentRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (!contentRef.current) return

    const isOverflowed =
      maxLines > 1
        ? contentRef.current.clientHeight < contentRef.current.scrollHeight
        : contentRef.current.clientWidth < contentRef.current.scrollWidth
    setIsOverflowed(isOverflowed)
  })

  React.useEffect(() => {
    if (!contentRef.current) return
    setTooltipText(contentRef.current.textContent)
  }, [children, contentRef.current])

  return (
    <ContentWithTooltip
      canDisplayTooltip={isOverflowed}
      maxTooltipWidth={maxTooltipWidth}
      tooltipContent={tooltipText}
      useTooltipParams={{ tooltipOffset: 4, hasArrow: true }}
      size="M"
    >
      <ContentWrapper $maxLines={maxLines} ref={contentRef}>
        {children}
      </ContentWrapper>
    </ContentWithTooltip>
  )
}

const ContentWrapper = styled.div<{ $maxLines: number }>`
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  width: 100%;

  ${({ $maxLines }) =>
    $maxLines === 1 &&
    css`
      white-space: nowrap;
    `}

  ${({ $maxLines }) =>
    $maxLines > 1 &&
    css`
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: ${$maxLines};
    `}

  & > * {
    display: inline;
  }
`
