import React from 'react'
import styled from 'styled-components'
import { useGate, useUnit } from 'effector-react'
import { useLocation } from 'react-router-dom'

import { DocumentAlias } from '@/dal'
import {
  NewDropDownInput,
  NewDateInput,
  NewInput,
  NewButton,
  NewTextButton,
} from '@/ui'
import { getDocumentNameByType } from '@/lib/documentResolvers'
import { GridTemplate, Cell } from '@/features/document-flow/shared'
import { getDocSubtypeNameByAlias } from '@/features/document-flow/list/model'

import {
  CONTRACT_ID_KEY,
  CONTRACT_NAME_KEY,
  getDocSubtypesByDocType,
  getDocTypesByDocSubtype,
} from '../../model'
import {
  $filters,
  removeFilter,
  setFilter,
  onClearFilter,
  DocumentFlowGate,
  $docNames,
  $statuses,
  downloadDocFlowReport,
  downloadDocFlowReportFx,
  $organizations,
  $projects,
  getFiltersFx,
  $estimateLotDirections,
} from '../../model/private'

export const FilterInputs = React.memo(() => {
  const [filters, docNames, statuses, organizations, projects, lotDirections] = useUnit([
    $filters,
    $docNames,
    $statuses,
    $organizations,
    $projects,
    $estimateLotDirections,
  ])
  const [areFiltersLoading, isDownloadReportPending] = useUnit([
    getFiltersFx.pending,
    downloadDocFlowReportFx.pending,
  ])

  const { search } = useLocation()

  const contractId = React.useMemo(() => {
    return new URLSearchParams(search).get(CONTRACT_ID_KEY) || undefined
  }, [search])

  const contractName = React.useMemo(() => {
    return new URLSearchParams(search).get(CONTRACT_NAME_KEY) || undefined
  }, [search])

  useGate(DocumentFlowGate, {
    contractId,
    contractName,
  })

  const docTypeOptions = React.useMemo(() => {
    if (filters.filter === DocumentAlias.ESTIMATE_LOT && filters.document_type) {
      return [{
        id: DocumentAlias.ESTIMATE_LOT,
        label: getDocumentNameByType(DocumentAlias.ESTIMATE_LOT),
      }]
    }
    const availableItems = getDocTypesByDocSubtype(filters.document_type)
    return Object.values(availableItems)
      // TODO! скрытие лотов
      .filter((alias) => ![DocumentAlias.ESTIMATE, DocumentAlias.ESTIMATE_LOT].includes(alias))
      .map((id) => ({
        id,
        label: getDocumentNameByType(id),
      }))
  }, [filters])

  const docSubtypeOptions = React.useMemo(() => {
    if (filters.filter === DocumentAlias.ESTIMATE_LOT) {
      return lotDirections
    }
    const availableItems = getDocSubtypesByDocType(filters.filter)
    return Object.values(availableItems)
      .map((id) => ({
        id,
        label: getDocSubtypeNameByAlias(id),
      }))
  }, [filters, lotDirections])

  const hasSetFilters = Object.values(filters).some(Boolean)
  const areIncomingInputsDisabled = filters.filter && ![
    DocumentAlias.ACT,
    DocumentAlias.PAYMENT,
  ].includes(filters.filter)

  const handleChangeDocType = (val: DocumentAlias | null) => {
    if (filters.filter === DocumentAlias.ESTIMATE_LOT) {
      removeFilter({ key: 'document_type' })
    }
    setFilter({ key: 'filter', val: val ?? undefined })
  }

  return (
    <Row>
      <Col>
        <NewDropDownInput
          options={docTypeOptions}
          value={filters.filter ?? null}
          placeholder="Документ"
          dataTestId="docflow-doc-type-select"
          hasClear
          onChange={handleChangeDocType}
        />
        <NewDropDownInput
          options={docSubtypeOptions}
          value={filters.document_type ?? null}
          placeholder="Тип"
          dataTestId="docflow-doc-subtype-select"
          hasClear
          onChange={(val) => setFilter({ key: 'document_type', val })}
        />
      </Col>

      <Col>
        <NewDropDownInput
          placeholder="Название"
          options={docNames}
          value={filters.name ?? null}
          isLoading={areFiltersLoading}
          dataTestId="docflow-doc-name-select"
          hasSearch
          hasClear
          onChange={(val) => setFilter({ key: 'name', val })}
        />
        <NewDropDownInput
          placeholder="Проект"
          options={projects}
          value={filters.project_name ?? null}
          isLoading={areFiltersLoading}
          dataTestId="docflow-doc-project-select"
          hasSearch
          hasClear
          onChange={(val) => setFilter({ key: 'project_name', val })}
        />
      </Col>

      <Col>
        <NewDropDownInput
          placeholder="Организация"
          options={organizations}
          value={filters.organization_name ?? null}
          isLoading={areFiltersLoading}
          dataTestId="docflow-doc-organization-select"
          hasSearch
          hasClear
          onChange={(val) => setFilter({ key: 'organization_name', val })}
        />
      </Col>

      <Col>
        <NewDateInput
          placeholder="Начиная с"
          inputValue={filters.date_from ?? ''}
          dataTestId="docflow-doc-date-from-select"
          onChange={(val) => setFilter({ key: 'date_from', val })}
          onClear={() => removeFilter({ key: 'date_from' })}
        />
        <NewDateInput
          placeholder="Заканчивая по"
          inputValue={filters.date_to ?? ''}
          dataTestId="docflow-doc-date-to-select"
          onChange={(val) => setFilter({ key: 'date_to', val })}
          onClear={() => removeFilter({ key: 'date_to' })}
        />
      </Col>

      <div />

      <Col>
        <NewDropDownInput
          placeholder="Статусная модель"
          options={statuses}
          value={filters.status ?? []}
          isLoading={areFiltersLoading}
          dataTestId="docflow-doc-status-select"
          isMultiple
          onChange={(val) => setFilter({ key: 'status', val })}
        />
      </Col>

      <Col>
        <NewInput
          placeholder="№ акта"
          value={filters.incoming_number ?? ''}
          disabled={areIncomingInputsDisabled}
          dataTestId="docflow-incoming-number-input"
          onChange={(val) => setFilter({ key: 'incoming_number', val })}
          onClear={() => removeFilter({ key: 'incoming_number' })}
        />
      </Col>

      <Col>
        <NewDateInput
          placeholder="Начиная с"
          inputValue={filters.incoming_date_from ?? ''}
          disabled={areIncomingInputsDisabled}
          dataTestId="docflow-incoming-date-from-input"
          onChange={(val) => setFilter({ key: 'incoming_date_from', val })}
          onClear={() => removeFilter({ key: 'incoming_date_from' })}
        />
        <NewDateInput
          placeholder="Заканчивая по"
          inputValue={filters.incoming_date_to ?? ''}
          disabled={areIncomingInputsDisabled}
          dataTestId="docflow-incoming-date-to-input"
          onChange={(val) => setFilter({ key: 'incoming_date_to', val })}
          onClear={() => removeFilter({ key: 'incoming_date_to' })}
        />
      </Col>

      <Col />

      <ActionsWrapper>
        <NewButton
          label="Скачать в отчет Excel"
          prefixIcon="download"
          size="S"
          dataTestId="docflow-download-report-btn"
          isPending={isDownloadReportPending}
          onClick={downloadDocFlowReport}
        />
        <NewTextButton
          label="Очистить фильтры"
          isDisabled={!hasSetFilters}
          dataTestId="docflow-clear-filters-btn"
          onClick={onClearFilter}
        />
      </ActionsWrapper>
    </Row>
  )
})

const Row = styled.div`
  ${GridTemplate}
`

const Col = styled(Cell)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 0;
`

const ActionsWrapper = styled(Col)`
  align-items: flex-end;
  gap: 15px;
  padding-inline: 0;
`
