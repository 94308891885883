import {
  Contract,
  DefaultUpdateFileParams,
  DocumentFeatures,
  DocumentProgress,
  DocumentStatuses,
  EstimateLotId,
  FileDTO,
  PaginationRequestParams,
  PaginationType,
} from '../interfaces'

export type CostObject = {
  declared: string
  approved: string
  diff: string | null
}

export enum MorphologyType {
  Object = 'object',
  Building = 'building',
  Section = 'section',
  Floor = 'floor',
}

export type MorphologyNode = {
  id: number
  name: string
  type: MorphologyType
  parent_id: MorphologyNode['id'] | null
  is_selected: boolean
  children: MorphologyNode['id'][]
}

export type SelectedMorphologyItems = {
  id: MorphologyNode['id']
  type: MorphologyType
  is_selected: boolean
}[]

export type ChangeSelectedMorphologyPayload = {
  id: EstimateLotId
  items: SelectedMorphologyItems
}

export type EstimateLot = {
  id: EstimateLotId
  name: string
  status: DocumentStatuses
  features: DocumentFeatures
  progress: DocumentProgress | null
  /** Номер документа */
  number_1c: string
  /** ISO date */
  created_at: string
  /** Проект строительства */
  project_name: string
  /** Направление деятельности */
  direction_name: string
  contract_id: number | null
  attachments: FileDTO[] | null
  /** url */
  documents_link: string
  /** url */
  instruction_link: string
  /** Вид аванса */
  advance_type_id: number
  /** Вид обеспечения */
  type_of_security_id: number
  /** float */
  percent_advance: string
  /** float */
  percent_warranty_retention: string

  // сроки строительства
  /** YYYY-MM-DD */
  start_work: string | null
  /** YYYY-MM-DD */
  end_work: string | null

  /** isoDate */
  updated_at: string
  comment: string | null
  is_typical_form_of_contract: boolean
}

export type LotCatalogItem = {
  id: number
  name: string
}

export type EstimateLotsFilterPayload = PaginationRequestParams & {
  search?: string
  /** YYYY-MM-DD */
  date?: string
  project_name?: string
  status?: DocumentStatuses[]
}

export type EstimateLotListItem = {
  id: EstimateLotId
  name: string
  sums: string
  /** YYYY-MM-DD */
  date: string
  period: {
    /** YYYY-MM-DD */
    date_start: string
    /** YYYY-MM-DD */
    date_stop: string
  }
  status: DocumentStatuses
  answer_required: boolean
  download_link: string | null
  progress: DocumentProgress | null
  project_name: string | null
  available_actions: {
    can_copy: boolean
    can_delete: boolean
    additional_request: boolean
  }
  document_type: string
  contract_id: Contract['id'] | null
  contract_name: string | null
}

export type UpdateEstimateLotPayload = {
  id: EstimateLotId
  advance_type: number | null
  type_security: number | null
  is_typical_form_of_contract: boolean
  comment: string
  percent_advance: string
  percent_warranty_retention: string
}

export type PostEstimateLotAttachmentsParams = {
  id: EstimateLotId
} & DefaultUpdateFileParams

export type GetEstimateLotListData = {
  items: EstimateLotListItem[]
  all_estimate_lots_count: number
  on_agreed_estimate_lots: number
}

export type GetEstimateLotListResponse = PaginationType<GetEstimateLotListData>

export type EstimateLotMorphologyResponse = MorphologyNode[]

export enum LotRowType {
  /** Работа */
  Work = 'work',
  /** Материал */
  Material = 'material',
  /** Группа работ, *пока* не используется */
  Group = 'group',
}

export type LotVolumes = {
  id: number
  type: MorphologyType
  /** float */
  volume: string
  can_edit: boolean
  children: LotVolumes[]
}

export type MorphologyCellInfo = {
  id?: number
  name: string
  children: MorphologyCellInfo[] | null
}

export enum LotMaterialSpecie {
  GrandTender = 'grand_tender',
  NotNominated = 'not_nominated',
  Nominated = 'nominated',
}

export type LotTableItem = {
  id: string
  name: string
  hierarchy_code: string
  node_type: LotRowType
  unit: {
    id: number
    name: string
  } | null
  spend_coefficient: string | null
  count_total: string
  material_type: MaterialType | null
  material_names: string[] | null
  parent_id: LotTableItem['id'] | null
  comment: {
    value: string | null
    can_edit: boolean
  }
  section_name: string | null
  folder_name: string | null
  volumes: LotVolumes[]
  price: {
    /** float */
    total: string
    material: {
      /** float */
      value: string
      can_edit: boolean
    }
    work: {
      /** float */
      value: string | null
      can_edit: boolean
    }
  }
  cost: {
    /** float */
    total: string
    /** float */
    material: string
    /** float */
    work: string | null
  }
  is_grand_tender: boolean
  is_required: boolean
  is_closed_price: boolean
  has_children: boolean

  // TODO скорее всего, неактуально
  material_specie: LotMaterialSpecie | null
  is_automatically_added: boolean
  requires_materials: boolean
}

export type KerElement = {
  id: number
  name: string
  hierarchy_code: string | null
  unit: number | null
  description: string | null
  kvr_id: KvrElement['id']
  is_ker_in_use: boolean
}

export type KvrElement = {
  id: number
  name: string
  hierarchy_code: string | null
  parent_id: KvrElement['id'] | null
  ker_elements: KerElement[] | null
  is_kvr_in_use: boolean
}

export type LotTableFilters = {
  search?: string
  unitId?: number
}

export type GetLotTableParams = LotTableFilters & {
  id: EstimateLotId
  name?: string
  unitId?: number
}

export type GetLotTableRowsParams = GetLotTableParams & {
  groupId: LotTableItem['id']
}

export type GetLotWorkStructureParams = {
  id: EstimateLotId
  workId: LotTableItem['id']
}

// TODO доуточнить контракт
export type ChangeWorkVolumesPayload = {
  workId: number | string
  elementId: number
  type: MorphologyType
  price: string
}

export type AddLotWorkMaterialsPayload = {
  workId: LotTableItem['id']
  materials: string[]
}

export type LotTableResponse = {
  root: LotTableItem[]
  total: number
  costs: {
    total: string
    material: string
    work: string
  }
  // TODO предварительно
  morphology: LotVolumes[]
}

export type UpdateLotWorkCommentPayload = {
  id: LotTableItem['id']
  comment: string
  morphology?: string[]
}

export type AddLotWorksPayload = {
  id: EstimateLotId
  worksToAdd: Record<KvrElement['id'], KerElement['id'][]>
}

export type ChangeTableItemsPayload = {
  id: EstimateLotId
  // TODO массовое редактирование
  items: any
}

export type EstimateLotFiltersResponse = {
  available_statuses: DocumentStatuses[]
  available_names: string[]
  available_projects: string[]
  available_contracts: boolean[]
  available_directions: {
    [key: number]: string
  }
}

export type UnitItem = {
  id: number
  short_name: string
  full_name: string | null
}

export type EditableValue = {
  value: string
  can_edit: boolean
}

export type EstimateLotWorkElementsPayload = PaginationRequestParams & {
  object_id?: number
  building_id?: number
  section_id?: number
  name?: string
  description?: string
  row_type?: LotRowType
  unit_id?: number[]

  count_from?: string
  count_to?: string
  price_from?: string
  price_to?: string
  cost_from?: string
  cost_to?: string
}

// TODO предварительно
/** Тип материала в работе */
export enum MaterialType {
  /** Обязательный */
  Required = 'required',
  /** Сопутствующий */
  Accompanying = 'accompanying',
  /** Дополнительный */
  Additional = 'additional',
}

export type CatalogMaterialGroup = {
  id: number
  name: string
}

// TODO предварительно
export type CatalogMaterial = {
  uuid: string
  name: string
  is_required: boolean
  type: MaterialType
  group_id: CatalogMaterialGroup['id']
}
