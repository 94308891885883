import React from 'react'
import styled from 'styled-components'

import { DocumentTracking } from '@/dal'

import { DefaultButton } from '../buttons'
import { Icon2 } from '../icons'
import { palette } from '../palette'
import { TextM } from '../typography'
import { StatusTrackingItem } from './StatusTrackingItem'

type Props = {
  items: DocumentTracking | null
}

export const StatusTracking = ({ items }: Props) => {
  const [isExpanded, setIsExpanded] = React.useState(false)

  if (!items?.length) return null

  const firstUpcomingIndex = items.findIndex(({ date }) => !date)
  const hasUpcomingStatuses = firstUpcomingIndex !== -1

  const length = isExpanded || !hasUpcomingStatuses ? items.length : firstUpcomingIndex
  const shownItems = items.slice(0, length)

  return (
    <Wrapper>
      <Header>
        <p>Трекинг документа</p>
        {hasUpcomingStatuses && (
          <ExpandButton type="button" onClick={() => setIsExpanded(!isExpanded)}>
            <span>{isExpanded ? 'свернуть' : 'развернуть'}</span>
            <Icon2 icon={isExpanded ? 'arrowUp' : 'arrowDown'} size={12} />
          </ExpandButton>
        )}
      </Header>

      <StatusList>
        {shownItems.map(({ text, date }) => (
          <StatusTrackingItem key={text} name={text} date={date} />
        ))}
      </StatusList>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Header = styled.header`
  ${TextM}
  display: flex;
  align-items: baseline;
  gap: 8px;
`

const ExpandButton = styled(DefaultButton)`
  display: flex;
  align-items: center;
  gap: 2px;

  color: ${palette.grey70};
  transition: 0.15s linear color;

  @media (hover: hover) {
    &:hover {
      color: ${palette.accent100};
    }
  }
`

const StatusList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
