import React from 'react'
import styled from 'styled-components'
import { useForm } from 'effector-forms'
import { useUnit } from 'effector-react'

import { ContentWithTooltip, NewDropDownField } from '@/ui'
import {
  $actingGroupOptions,
  $buildings,
  $floors,
  $sections,
} from '@/features/estimate-correction/shared-model'

import { $isItemCreationPending, createRowForm } from '../../../model/create-table-item.private'

const TOOLTIP_PARAMS = {
  useTooltipParams: {
    tooltipOffset: -26,
    placement: 'top',
    hasArrow: true,
  },
  size: 'M',
} as const

export const ActingGroupInputs = React.memo(() => {
  const { fields } = useForm(createRowForm)

  const [actingGroupOptions, buildings, sections, floors, isSending] = useUnit([
    $actingGroupOptions,
    $buildings,
    $sections,
    $floors,
    $isItemCreationPending,
  ])

  const buildingOptions = buildings.filter(({ parent_id }) => parent_id === fields.project.value)
  const sectionOptions = sections.filter(({ parent_id }) => parent_id === fields.building.value)
  const floorOptions = floors.filter(({ parent_id }) => parent_id === fields.section.value)

  const isBuildingRequired = Boolean(fields.project.value && buildingOptions.length)

  const hasNoBuildingsToChoose = Boolean(fields.project.value && !buildingOptions.length)
  const hasNoSectionsToChoose =
    hasNoBuildingsToChoose || Boolean(fields.building.value && !sectionOptions.length)
  const hasNoFloorsToChoose =
    hasNoSectionsToChoose || Boolean(fields.section.value && !floorOptions.length)

  return (
    <>
      {/* TODO убрать, если в итоге вообще не понадобится
      <NewDropDownField
        label="Группа актирования"
        dataTestId="create-row-acting-group-input"
        options={actingGroupOptions}
        placeholder="Выберите группу актирования"
        size="L"
        value={fields.acting_group.value}
        error={fields.acting_group.firstError?.errorText}
        isDisabled={isSending}
        hasSearch
        onChange={fields.acting_group.onChange}
      />
      */}

      <Row>
        <ContentWithTooltip
          tooltipContent="Выберите объект строительства"
          canDisplayTooltip={!fields.project.value && !hasNoBuildingsToChoose}
          {...TOOLTIP_PARAMS}
        >
          <NewDropDownField
            label="Корпус"
            dataTestId="create-row-building-input"
            options={buildingOptions}
            placeholder={hasNoBuildingsToChoose ? 'Нет корпусов' : 'Выберите корпус'}
            size="L"
            value={fields.building.value}
            isDisabled={isSending || !fields.project.value || hasNoBuildingsToChoose}
            error={fields.building.firstError?.errorText}
            required={isBuildingRequired}
            isLabelGrey={hasNoBuildingsToChoose}
            hasSearch
            onChange={fields.building.onChange}
          />
        </ContentWithTooltip>

        <ContentWithTooltip
          tooltipContent="Выберите корпус"
          canDisplayTooltip={!fields.building.value && !hasNoSectionsToChoose}
          {...TOOLTIP_PARAMS}
        >
          <NewDropDownField
            label="Секция"
            dataTestId="create-row-section-input"
            options={sectionOptions}
            placeholder={hasNoSectionsToChoose ? 'Нет секций' : 'Выберите секцию'}
            size="L"
            value={fields.section.value}
            isDisabled={isSending || !fields.building.value || hasNoSectionsToChoose}
            error={fields.section.firstError?.errorText}
            isLabelGrey={hasNoSectionsToChoose}
            hasSearch
            onChange={fields.section.onChange}
          />
        </ContentWithTooltip>

        <ContentWithTooltip
          tooltipContent="Выберите секцию"
          canDisplayTooltip={!fields.section.value && !hasNoFloorsToChoose}
          {...TOOLTIP_PARAMS}
        >
          <NewDropDownField
            label="Этаж"
            dataTestId="create-row-floor-input"
            options={floorOptions}
            placeholder={hasNoFloorsToChoose ? 'Нет этажей' : 'Выберите этаж'}
            size="L"
            value={fields.floor.value}
            isDisabled={isSending || !fields.section.value || hasNoFloorsToChoose}
            error={fields.floor.firstError?.errorText}
            isLabelGrey={hasNoFloorsToChoose}
            hasSearch
            onChange={fields.floor.onChange}
          />
        </ContentWithTooltip>
      </Row>
    </>
  )
})

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 16px;
`
