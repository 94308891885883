import { sample, split } from 'effector'

import { DocumentAlias, EstimateDoc } from '@/dal'
import { replaceNavigate, RouterPaths } from '@/features/app/model'
import { copyDocument, deleteDocument, onKorDocDeleted } from '@/features/document-manager/model'
import { createToast } from '@/features/toast-service/model'

import {
  openApproveDocumentModal,
  openSecondApprovingModal,
} from '../../approve-document-modal/model'
import { getTableData } from '../../estimate-table/model'
import { openRejectAprovingModal } from '../../reject-approving'
import { openSendApproveModal } from '../../send-to-approve/model'
import {
  $allDocumentInfo,
  $documentId,
  $documentInfo,
  EstimateCorrectionGate,
  updateDocumentInfo,
} from '../../shared-model'
import {
  cancelApproveFx,
  checkValidationBeforeApproveFx,
  onApproveButton,
  onCancelApprove,
  onCopy,
  onDeleteButton,
  onReject,
  onSecondApproving,
  openApproveModal,
  openSendToApproveModal,
} from './private'

const cases = {
  openSendToApproveModal,
  approve: openApproveModal,
  isSecondApproving: onSecondApproving,
}

split({
  source: sample({
    clock: checkValidationBeforeApproveFx.doneData,
    source: $documentInfo,
    filter: Boolean,
    fn: (info) => info.features,
  }),
  match: {
    openSendToApproveModal: (features: EstimateDoc['features']) =>
      Boolean(features.can_send_to_approving),
    approve: (features: EstimateDoc['features']) =>
      Boolean(features.can_approve && !features.is_second_approving),
    isSecondApproving: (features: EstimateDoc['features']) =>
      Boolean(features.can_approve && features.is_second_approving),
  },
  cases,
})

sample({
  clock: openSendToApproveModal,
  source: $documentInfo,
  filter: Boolean,
  fn: (info) => info.id,
  target: openSendApproveModal,
})

sample({
  clock: onSecondApproving,
  source: $documentInfo,
  filter: Boolean,
  fn: (info) => info.id,
  target: openSecondApprovingModal,
})

sample({
  clock: openApproveModal,
  source: $documentInfo,
  filter: Boolean,
  fn: (info) => info.id,
  target: openApproveDocumentModal,
})

sample({
  clock: onKorDocDeleted,
  source: {
    docId: $documentId,
    gateStatus: EstimateCorrectionGate.status,
  },
  filter: ({ docId, gateStatus }, { id }) => gateStatus && docId === id,
  fn: () => RouterPaths.Administrative,
  target: replaceNavigate,
})

sample({
  clock: onDeleteButton,
  source: $allDocumentInfo,
  filter: (info: EstimateDoc | null): info is EstimateDoc =>
    Boolean(info && info.features.can_delete),
  fn: (info) => ({
    id: info.id,
    type: DocumentAlias.KOR_DOC as const,
    documentName: info.name,
  }),
  target: deleteDocument,
})

sample({
  clock: onApproveButton,
  source: $documentInfo,
  filter: Boolean,
  fn: (info) => ({ id: info.id }),
  target: checkValidationBeforeApproveFx,
})

sample({
  clock: onCancelApprove,
  source: $documentInfo,
  filter: Boolean,
  fn: ({ id }) => ({ id }),
  target: cancelApproveFx,
})

sample({
  clock: cancelApproveFx.doneData,
  target: [getTableData, updateDocumentInfo],
})

createToast({
  effect: checkValidationBeforeApproveFx,
})

sample({
  clock: onCopy,
  source: $documentInfo,
  filter: Boolean,
  fn: (info) => {
    return {
      id: info.id,
      type: info.type,
      draft: info.conflicting_draft_kor_doc,
      documentName: info.name,
    }
  },
  target: copyDocument,
})

sample({
  clock: onReject,
  source: $documentInfo,
  filter: Boolean,
  fn: ({ id, status }) => ({ id, status }),
  target: openRejectAprovingModal,
})
