import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { ExpandableTableButton, NewInput, palette } from '@/ui'

import {
  $gaTableSearch,
  acceptGaTableSearch,
  changeGaTableSearch,
  isGaTableExpandedValue,
  resetGaTableSearch,
} from '../../model/private'

export const GaTableFilters = React.memo(() => {
  const [search, isTableExpanded] = useUnit([$gaTableSearch, isGaTableExpandedValue.$value])

  return (
    <Wrapper>
      <InputWrapper>
        <NewInput
          placeholder="Поиск по наименованию группы актирования"
          value={search}
          postfixIcon="search"
          dataTestId="ga-act-search-input"
          onChange={changeGaTableSearch}
          onEnterPress={acceptGaTableSearch}
          onClear={resetGaTableSearch}
          onPostfixIconClick={acceptGaTableSearch}
        />
      </InputWrapper>

      <ExpandableTableButton
        isTableExpanded={isTableExpanded}
        isTableOverflowed
        onToggleExpansion={isGaTableExpandedValue.set}
      />
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: ${palette.white};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`

const InputWrapper = styled.div`
  width: 300px;
`
